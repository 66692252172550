import React from "react"
import { connect } from "react-redux"
import moment from "moment-timezone"
import PropTypes from "prop-types"

import CalendarMonth from "../../components/calendar/CalendarMonth"
import getMonths from "../../components/calendar/getMonths"
import ShadowBox from "../../components/ShadowBox"

import { dayIsSale, dayIsAvailable } from "../../../utils/momentHelpers"

import { getServiceSlug, getServicePrice } from "./selectors"
import { browserIsMobile } from "../../redux/browserSelector"

function CalendarContainer({
  date,
  inventoryItem,
  isMobile,
  onClose,
  clickDay,
}) {
  const calendarStyle = isMobile ? { marginBottom: "20px" } : {}

  const wrapperStyle = isMobile
    ? { marginLeft: "auto", marginRight: "auto" }
    : {
        marginLeft: "auto",
        marginRight: "auto",
        display: "grid",
        gridGap: "20px",
        gridTemplateColumns: "repeat(2, calc(50% - 10px))",
      }

  return (
    <ShadowBox header="When?" closeBox={onClose} isFullscreen={isMobile}>
      <div
        style={{
          overflowY: "scroll",
          marginLeft: isMobile ? "-10px" : 0,
          marginRight: isMobile ? "-20px" : "-10px",
          maxHeight: isMobile ? "calc(100vh - 130px)" : "calc(100vh - 250px)",
        }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1, textAlign: "center" }}>
            <div style={wrapperStyle}>
              {getMonths().map((month) => (
                <CalendarMonth
                  style={calendarStyle}
                  isDisabled={(day) =>
                    !dayIsAvailable(inventoryItem.company, day) ||
                    !day.isSameOrBefore(moment().add(1, "year"))
                  }
                  isOnSale={(day) =>
                    dayIsSale(inventoryItem.pricing.lowest, moment(day))
                  }
                  key={`calmonth${month.format()}`}
                  month={month}
                  clickDay={(day) => clickDay(day)}
                  selectedDays={[date]}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </ShadowBox>
  )
}

CalendarContainer.propTypes = {
  date: PropTypes.string.isRequired,
  inventoryItem: PropTypes.shape({
    company: PropTypes.object,
    pricing: PropTypes.shape({
      lowest: PropTypes.shape({
        price: PropTypes.number.isRequired,
      }),
    }),
  }).isRequired,
  isMobile: PropTypes.bool,
  onClose: PropTypes.func,
  clickDay: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    isMobile: browserIsMobile(state),
    price: getServicePrice(state),
    slug: getServiceSlug(state),
  }
}

export default connect(mapStateToProps)(CalendarContainer)
