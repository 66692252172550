import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { setMapZoom } from "../../containers/InventoryView/actions"
import {
  fetchCompanies,
  companySearchUpdate,
} from "../../containers/InventoryFilter/actions"
import { TextInput } from "../forms/Inputs"
import Button from "../button"
import { SpinnerIcon } from "../icons"
import { sortByName } from "./lib"
import { CompanySelectorContext } from "./CompanySelectorContext"
import { ListView } from "./ListView"
import MapView from "./MapView"

function CompanySelector({
  allowMultipleSelection = true,
  closeAction,
  companies: allAvailableCompanies,
  // We could return the country of each company->region from the API and avoid the need for passing in a separate region list
  regions: allRegions,
  companySearch: companySearchProp,
  hideTextSearch,
  isMobile,
  isSearchingCompanies,
  onSelectAllCompaniesInRegion = () => {},
  onSelectCompany,
  scrollContainerStyle = {},
  selectedCompanies = [],
  selectedCampaigns = [],
  selectedRegions = [],
  selectedRegions: initiallySelectedRegions = [],
  showSelectAllRegions = false,
  topCompanies = [],
}) {
  const [tab, setTab] = useState("list")
  const [companySearch, setCompanySearch] = useState(companySearchProp)

  const [expandedCountries, setExpandedCountries] = useState([])

  const [expandedRegions, setExpandedRegions] = useState(
    initiallySelectedRegions
  )

  const companies = useMemo(
    () =>
      allAvailableCompanies.map((eachAvailableCompany) => ({
        ...eachAvailableCompany,
        selected:
          // Selected if explicitly selected
          Boolean(
            selectedCompanies.find(
              (eachSelectedCompany) =>
                eachSelectedCompany.id === eachAvailableCompany.id
            )
          ) ||
          // Selected if region is explicitly selected
          Boolean(
            initiallySelectedRegions.find(
              (selectedRegion) =>
                selectedRegion.id === eachAvailableCompany.district.region.id
            )
          ),
      })),
    [allAvailableCompanies, selectedCompanies]
  )

  const regions = useMemo(
    () =>
      companies
        // Extract the regions from each company
        .map((company) => company.district.region)
        // Make unique. Basically find the first occurance, discard the rest
        .filter(
          (eachRegion, index, regions) =>
            regions.findIndex((region) => region.id === eachRegion.id) === index
        )
        .sort(sortByName)
        // Get the full region (including country) object for each region
        .map((eachRegion) =>
          allRegions.find((region) => region.id === eachRegion.id)
        )
        // Decorate each region with an "expanded" attribute based on if a child company/campaign is selected
        .map((eachRegion) => {
          return {
            ...eachRegion,
            expanded:
              // Expand if a child company/campaign is selected
              Boolean(
                selectedCompanies.find(
                  (company) => company.district.region.id === eachRegion.id
                )
              ) ||
              // Expand if the region is explicitly selected
              Boolean(
                expandedRegions.find(
                  (eachExpandedRegion) =>
                    eachExpandedRegion.id === eachRegion.id
                )
              ),
          }
        }),
    [companies, expandedRegions, selectedCompanies, selectedCampaigns]
  )

  const getAvailableCountries = () =>
    regions
      .filter(
        (eachRegion, index, regions) =>
          regions.findIndex(
            (region) => region.country.id === eachRegion.country.id
          ) === index
      )
      .map((eachRegion) => eachRegion.country)
      .map((eachCountry) => ({
        ...eachCountry,
        expanded:
          Boolean(
            regions.find(
              (region) =>
                region.country.id === eachCountry.id && region.expanded
            )
          ) ||
          Boolean(
            expandedCountries.find(
              (eachExpandedCountry) => eachExpandedCountry.id === eachCountry.id
            )
          ),
      }))
      .sort(sortByName)

  const { countries } = useMemo(
    () => ({
      countries: getAvailableCountries(regions),
    }),
    [regions]
  )

  useEffect(() => {
    // Expand by default country dropdown when only 1 country is available
    if (countries.length === 1 && expandedCountries.length === 0) {
      toggleCountry(countries[0])
    }
  }, [countries])

  useEffect(() => {
    selectedRegions.forEach((eachSelectedRegion) => {
      const eachRegionCountry = countries.find(
        (country) => country.id === eachSelectedRegion.country.id
      )
      if (eachRegionCountry) {
        // Ensure the parent (country) menu of any selected region is expanded
        setExpandedCountries((currentlySelectedCountries) => {
          if (
            !currentlySelectedCountries.find(
              (eachCurrentlySelectedCountry) =>
                eachCurrentlySelectedCountry.id === eachRegionCountry.id
            )
          ) {
            return [...currentlySelectedCountries, eachRegionCountry]
          }
          return currentlySelectedCountries
        })
      }
    })
  }, [selectedRegions])

  useEffect(() => {
    // Expand by default region dropdown when only 1 region is available
    if (regions.length === 1 && expandedRegions.length === 0) {
      toggleRegion(regions[0])
    }
  }, [companies])

  const clickCompany = (company) => {
    if (companies.length < 1) {
      return
    }

    onSelectCompany(company)

    if (closeAction) {
      closeAction()
    }
  }

  const clickRegion = (region) => {
    if (regions.length <= 1) {
      return
    }

    toggleRegion(region)
  }

  const clickCountry = (country) => {
    if (countries.length <= 1) {
      return
    }
    toggleCountry(country)
  }

  const toggleCountry = (country) => {
    const existingIndex = expandedCountries.findIndex(
      (eachSelectedCountry) => eachSelectedCountry.id === country.id
    )

    setExpandedCountries(
      existingIndex !== -1
        ? expandedCountries.filter(
            (eachSelectedCountry) => eachSelectedCountry.id !== country.id
          )
        : [...selectedRegions, country]
    )
  }

  const toggleRegion = (region) =>
    setExpandedRegions((currentlyExpandedRegions) => {
      const isExpanded = currentlyExpandedRegions.find(
        (eachExpandedRegion) => eachExpandedRegion.id === region.id
      )

      const hasSelectedChildren = selectedCompanies.find(
        (eachSelectedCompany) =>
          eachSelectedCompany.district.region.id === region.id
      )

      // Has selected children, so do nothing
      if (hasSelectedChildren) {
        return currentlyExpandedRegions
      }

      if (isExpanded) {
        return currentlyExpandedRegions.filter(
          (eachExpandedRegion) => eachExpandedRegion.id !== region.id
        )
      }

      return [...currentlyExpandedRegions, region]
    })

  const contextValue = useMemo(
    () => ({
      allowMultipleSelection,
      clickCompany,
      clickCountry,
      clickRegion,
      companies,
      regions,
      isMobile,
      onSelectAllCompaniesInRegion,
      selectedCampaigns,
      selectedCompanies,
      selectedRegions,
      selectedCountries: expandedCountries,
      showSelectAllRegions,
      countries,
    }),
    [
      allowMultipleSelection,
      companies,
      isMobile,
      selectedCompanies,
      selectedRegions,
      expandedCountries,
      showSelectAllRegions,
      countries,
      regions,
    ]
  )

  const headerPortalHeight = "123px"

  return (
    <CompanySelectorContext.Provider value={contextValue}>
      <div>
        <div style={{ paddingBottom: 30 }}>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "calc(50% - 10px) calc(50% - 10px)",
              gridTemplateRows: "100%",
              gridColumnGap: 20,
            }}
          >
            <Button
              variant="secondary"
              filled={tab === "list"}
              onClick={(e) => {
                e.preventDefault()
                setTab("list")
              }}
            >
              List
            </Button>
            <Button
              variant="secondary"
              filled={tab === "map"}
              onClick={(e) => {
                e.preventDefault()
                setTab("map")
              }}
            >
              Map
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginTop: 16,
              position: "relative",
            }}
          >
            {!hideTextSearch && (
              <div
                style={{ position: "relative", display: "flex", width: "100%" }}
              >
                <TextInput
                  style={{ flex: 1 }}
                  placeholder="Enter salon name, location or postcode..."
                  value={companySearch}
                  onChange={(event) => setCompanySearch(event.target.value)}
                />
                {isSearchingCompanies && (
                  <SpinnerIcon
                    style={{ position: "absolute", right: 10, top: 12 }}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div
          style={{
            overflowY: "scroll",
            marginLeft: 0,
            marginRight: 0,
            maxHeight: isMobile
              ? `calc(100vh - 150px - ${headerPortalHeight}`
              : `calc(100vh - 270px - ${headerPortalHeight}`,
            ...scrollContainerStyle,
          }}
        >
          <div style={{ display: "flex", minHeight: 400 }}>
            <div style={{ flex: 1 }}>
              {tab === "list" && (
                <ListView
                  topCompanies={topCompanies}
                  isSearchingCompanies={isSearchingCompanies}
                  companySearch={companySearch}
                />
              )}
              {tab === "map" && <MapView companySearch={companySearch} />}
            </div>
          </div>
        </div>
      </div>
    </CompanySelectorContext.Provider>
  )
}

CompanySelector.propTypes = {
  allowMultipleSelection: PropTypes.bool,
  closeAction: PropTypes.func,
  companies: PropTypes.array.isRequired,
  companySearch: PropTypes.string.isRequired,
  hideTextSearch: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  isSearchingCompanies: PropTypes.bool.isRequired,
  onSelectAllCompaniesInRegion: PropTypes.func.isRequired,
  onSelectCompany: PropTypes.func.isRequired,
  regions: PropTypes.array.isRequired,
  scrollContainerStyle: PropTypes.object,
  selectedCampaigns: PropTypes.array,
  selectedCompanies: PropTypes.array,
  selectedRegions: PropTypes.array,
  showSelectAllRegions: PropTypes.bool,
  topCompanies: PropTypes.array,
}

function mapStateToProps(state) {
  return {
    mapZoom: state.inventoryFilter.mapZoom,
    isMobile: state.browser.lessThan.mobile,
    showTopCompanies: state.inventoryFilter.showTopCompanies,
    companySearch: state.inventoryFilter.companySearch,
    isSearchingCompanies: state.inventoryFilter.isSearchingCompanies,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setMapZoom: (zoom) => dispatch(setMapZoom(zoom)),
  fetchCompanies: () => dispatch(fetchCompanies()),
  companySearchUpdate: (search) => dispatch(companySearchUpdate(search)),
  toggleTopCompanies: () => dispatch({ type: "TOGGLE_TOP_COMPANIES" }),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanySelector)
