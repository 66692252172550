/* eslint-disable no-underscore-dangle */

import "polyfill-array-includes"

import React from "react"
import { createRoot } from "react-dom/client"
import ReactDOM from "react-dom"

import { Provider as ReduxProvider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"

import { isIE } from "react-device-detect"

import "./i18n"
import { withTranslation } from "react-i18next"

import rg4js from "raygun4js"

import { useLocation } from "react-router-dom"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import AppComponent from "./app"
import createStore from "./redux/createStore"
import AuthProvider from "./providers/AuthProvider"
import { cookieDecline, cookieOk } from "./containers/NavBar/actions"

if (process.env.RAYGUN_API_KEY) {
  rg4js("apiKey", process.env.RAYGUN_API_KEY)
  rg4js("enableCrashReporting", true)
}

const queryClient = new QueryClient()

const App = withTranslation()(AppComponent)

const initialState = window.__PRELOADED_STATE__ || {}

const root = createRoot(document.getElementById("flossieapp"))

if (!isIE) {
  const { store, history, persistor } = createStore(initialState)

  // notify segment what franchise this build is for
  store.dispatch({ type: "SEGMENT_GROUP" })

  // try to login automatically
  store.dispatch({ type: "TRY_STARTUP_AUTH" })
  store.dispatch({ type: "FETCH_CART" })

  // user has handled cookie popup already, so send OK/DECLINE action based on cookie value
  if (document.cookie.includes("cookiesok")) {
    store.dispatch(
      document.cookie.split(";").some((item) => item.includes("cookiesok=true"))
        ? cookieOk()
        : cookieDecline()
    )
  }

  root.render(
    <React.Suspense key="index" fallback={null}>
      <ReduxProvider store={store}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <PersistGate loading={null} persistor={persistor}>
              <App history={history} />
            </PersistGate>
          </AuthProvider>
        </QueryClientProvider>
      </ReduxProvider>
    </React.Suspense>
  )
} else {
  /* Render unsupported browser message only */
  root.render(
    <div
      style={{
        fontFamily: "proxima-nova, sans-serif",
        borderRadius: 10,
        backgroundColor: "#ef609f",
        color: "#fff",
        position: "fixed",
        width: 400,
        height: 300,
        top: "calc(50% - 200px)",
        left: "calc(50% - 220px)",
        padding: 20,
      }}
    >
      <h1 style={{ textAlign: "center" }}>
        Hi there. You’re using an unsupported browser
      </h1>
      <p>
        For a safer and faster user experience please upgrade to a modern
        browser like Chrome, Firefox, Safari, Opera, or Edge.
      </p>
    </div>
  )
}
