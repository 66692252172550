import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { Step, StepHeader, StepParent } from "./styledComponents"
import CompanySelector from "../../../components/CompanySelector"
import { useCampaignWizardContext } from "./CampaignWizardContext"

function CampaignStep3({ regions, similarCompanies }) {
  const {
    campaign,
    selectedCompany,
    setSelectedCompany,
    selectedService,
    selectedStep,
    setSelectedStep,
  } = useCampaignWizardContext()

  const stepRef = useCallback((node) => {
    if (selectedStep === 3 && node !== null) {
      node.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  if (!selectedService) {
    return null
  }

  return (
    <div ref={stepRef} style={{ marginBottom: "40px", minHeight: 400 }}>
      <StepParent>
        <Step id="step-3">Step 3 of 5</Step>
        <StepHeader>{campaign.title_company_select}</StepHeader>
      </StepParent>
      {Array.isArray(similarCompanies) && similarCompanies.length > 0 && (
        <div>
          <CompanySelector
            allowMultipleSelection={false}
            hideTextSearch={false}
            companies={similarCompanies}
            currency={selectedService.currency}
            selectedCompanies={selectedCompany ? [selectedCompany] : []}
            regions={regions}
            scrollContainerStyle={{
              overflowY: "auto",
              margin: 0,
              maxHeight: "none",
            }}
            onSelectCompany={(company) => {
              setSelectedCompany(
                similarCompanies.find((c) => {
                  return c.slug === company.slug
                })
              )

              setSelectedStep(4)
            }}
          />
        </div>
      )}
    </div>
  )
}

CampaignStep3.propTypes = {
  regions: PropTypes.arrayOf(PropTypes.object).isRequired,
  similarCompanies: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default CampaignStep3
