import React, { useEffect, useMemo, useRef, useState } from "react"
import Pusher from "pusher-js"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { useQuery } from "@tanstack/react-query"
import { addAvailabilityToCartAndRedirect } from "../Cart/actions"

import { clearCart } from "../InventoryView/actions"

import EmployeeSelector from "./EmployeeSelector"
import BookButton from "./BookButton"
import {
  getSelectedEmployee,
  parseAvailabilities,
  buildAvailabilitiesScrapeQueryOptions,
  getInventoryItemEmployeeById,
  buildNextQueryOptions,
  randomString32,
} from "../InventoryView/lib"
import DateAndTimeSelector from "./DateAndTimeSelector/DateAndTimeSelector"
import { getPusherAuthEndpointUrl } from "../../api/api"
import {
  companyToday,
  DATE_TO_STRING_FORMAT,
  getMomentTimeForAvailability,
} from "../../../utils/momentHelpers"
import CalendarBody from "./DateAndTimeSelector/CalendarBody"
import { Scraping } from "./DateAndTimeSelector/Scraping"
import { NoAvailability } from "./DateAndTimeSelector/NoAvailability"
import AvailableEmployeeList from "./AvailableEmployeeList"
import { CalendarBookingModuleContext } from "./CalendarBookingModuleContext"
import { inventoryItemScraped } from "../Analytics/actions"

const { FRANCHISE_ID } = SITE_CONFIG

const pusherClient = new Pusher(process.env.PUSHER_APP_KEY, {
  cluster: process.env.PUSHER_CLUSTER,
  authEndpoint: getPusherAuthEndpointUrl(),
  auth: {
    headers: {
      "X-Franchise-ID": FRANCHISE_ID,
    },
  },
})

function CalendarBookingModule({
  addAvailabilityToCartAndRedirect,
  date: dateProp,
  dispatchInventoryItemScraped,
  employeeSlug,
  inventoryItem,
  onChange,
  time,
}) {
  const [selectedAvailability, setSelectedAvailability] = useState(null)

  const [employeeSelectOpen, setEmployeeSelectOpen] = useState(false)

  const [availabilities, setAvailabilities] = useState(null)

  const [calendarBodyLoadingMessage, setCalendarBodyLoadingMessage] =
    useState(null)

  // If date isn't set, use todays date
  const date =
    dateProp ??
    companyToday(inventoryItem.company).format(DATE_TO_STRING_FORMAT)

  const [
    hasBackgroundScrapeBeenTriggered,
    setHasBackgroundScrapeBeenTriggered,
  ] = useState(false)

  const pusherAvailabilities = useRef([])

  // The params used to determine if the scrape token should change
  const scrapeToken = useRef(null)
  const scrapeTokenParams = useRef(null)

  const newScrapeTokenParams = `${inventoryItem?.id ?? ""}${date ?? ""}${
    employeeSlug ?? ""
  }`

  if (scrapeTokenParams.current !== newScrapeTokenParams) {
    if (scrapeToken.current !== null) {
      pusherClient.unsubscribe(`scrape-${scrapeToken.current}`)
    }

    scrapeTokenParams.current = newScrapeTokenParams
    scrapeToken.current = randomString32()

    pusherClient
      .subscribe(`scrape-${scrapeToken.current}`)
      .bind("client-scrape-status", (event) => {
        const { status } = event
        setCalendarBodyLoadingMessage(status)
      })
      .bind("client-scrape-event", (event) => {
        const { type } = event

        if (["start", "result", "complete"].includes(type)) {
          handlePusherAvailabilityEvent(event)
        }

        if (type === "price") {
          handleFoundBestPriceEvent(event)
        }

        if (type === "next") {
          handleFoundNextAvailabilityEvent(event)
        }
      })
  }

  const employee = getSelectedEmployee(inventoryItem, employeeSlug)

  const {
    isFetching: isScrapeQueryFetching,
    data: fetchAvailabilitiesData,
    refetch: refetchAvailabilities,
  } = useQuery(
    buildAvailabilitiesScrapeQueryOptions({
      item: inventoryItem,
      date,
      scrapeToken: scrapeToken.current,
      employeeId: employee?.id,
      onQueryComplete: () => dispatchInventoryItemScraped(inventoryItem, date),
    })
  )

  const [availabilitesData, setAvailabilitiesData] = useState([])

  useEffect(() => {
    if (Array.isArray(fetchAvailabilitiesData?.availabilities)) {
      setAvailabilitiesData(fetchAvailabilitiesData.availabilities)
    }

    setHasBackgroundScrapeBeenTriggered(
      Boolean(fetchAvailabilitiesData?.isBackgroundScraping)
    )
  }, [fetchAvailabilitiesData])

  useEffect(() => {
    if (isScrapeQueryFetching || hasBackgroundScrapeBeenTriggered) {
      setCalendarBodyLoadingMessage("Connecting")
    }
  }, [isScrapeQueryFetching, hasBackgroundScrapeBeenTriggered])

  const { refetch: fetchNextAvailability } = useQuery(
    buildNextQueryOptions(
      inventoryItem?.id,
      date,
      scrapeToken.current,
      employee?.id
    )
  )

  // When new availability data becomes available, clear the loading message and the 'background scrape' flag
  useEffect(() => {
    if (
      Array.isArray(availabilitesData) &&
      !isScrapeQueryFetching &&
      !fetchAvailabilitiesData?.isBackgroundScraping
    ) {
      setAvailabilities(availabilitesData)
      setCalendarBodyLoadingMessage(null)
      setHasBackgroundScrapeBeenTriggered(false)
    } else {
      setCalendarBodyLoadingMessage("Loading")
    }
  }, [availabilitesData])

  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setIsLoading(calendarBodyLoadingMessage !== null)
  }, [calendarBodyLoadingMessage])

  const timesAreAvailable =
    Array.isArray(availabilities) && availabilities.length > 0

  const handleFoundNextAvailabilityEvent = (event) => {
    const { date: nextAvailableDate, employee_id: employeeId } = event

    const navigateOptions = {
      date: nextAvailableDate,
    }

    if (employeeId) {
      navigateOptions.employeeSlug = getInventoryItemEmployeeById(
        inventoryItem,
        employeeId
      ).slug
    }

    // If the next available date is the same as the current date, just refetch data
    if (nextAvailableDate === date) {
      refetchAvailabilities()
    } else {
      onChange(navigateOptions)
    }
  }

  const handleFoundBestPriceEvent = (event) => {
    const bestPriceEmployee = getInventoryItemEmployeeById(
      inventoryItem,
      event.employee_id
    )

    onChange({
      employeeSlug: bestPriceEmployee.slug,
    })
  }

  const handlePusherAvailabilityEvent = ({ type, res }) => {
    if (type === "start") {
      setCalendarBodyLoadingMessage("Connecting")
      pusherAvailabilities.current = []
    }
    if (type === "result") {
      if (Array.isArray(res)) {
        pusherAvailabilities.current = [
          ...pusherAvailabilities.current,
          ...parseAvailabilities(inventoryItem, res),
        ]
      }
    }
    if (type === "complete") {
      setCalendarBodyLoadingMessage(null)
      setAvailabilities(pusherAvailabilities.current)
      pusherAvailabilities.current = []
    }
  }

  // If time has been set in the URL, select matching availability
  useEffect(() => {
    const selectedAvailability =
      Array.isArray(availabilities) &&
      availabilities.find(
        (availability) =>
          getMomentTimeForAvailability(availability).format("HHmm") === time
      )

    if (selectedAvailability) {
      setSelectedAvailability(selectedAvailability)
    }
  }, [availabilities, time])

  const contextValue = useMemo(
    () => ({
      availabilities,
      calendarBodyLoadingMessage,
      date,
      employee,
      employeeSelectOpen,
      fetchNextAvailability,
      inventoryItem,
      isLoading,
      onChange,
      setEmployeeSelectOpen,
      setCalendarBodyLoadingMessage,
      selectedAvailability,
      setSelectedAvailability,
      scrapeToken: scrapeToken.current,
      timesAreAvailable,
    }),
    [
      availabilities,
      calendarBodyLoadingMessage,
      date,
      employee,
      employeeSelectOpen,
      inventoryItem,
      isLoading,
      selectedAvailability,
      scrapeToken.current,
      timesAreAvailable,
    ]
  )

  return (
    <CalendarBookingModuleContext.Provider value={contextValue}>
      {employeeSelectOpen && (
        <AvailableEmployeeList onClose={() => setEmployeeSelectOpen(false)} />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          padding: "0px 14px",
        }}
      >
        <EmployeeSelector />
        <DateAndTimeSelector>
          {isLoading && <Scraping status={calendarBodyLoadingMessage} />}
          {!isLoading && timesAreAvailable && <CalendarBody />}
          {!isLoading && !timesAreAvailable && (
            <NoAvailability
              showFindNextDate={inventoryItem.company.allow_next_appointment}
              clickFindNextDate={fetchNextAvailability}
            />
          )}
        </DateAndTimeSelector>
        <BookButton
          onClick={() =>
            addAvailabilityToCartAndRedirect(
              selectedAvailability,
              inventoryItem
            )
          }
        />
      </div>
    </CalendarBookingModuleContext.Provider>
  )
}

CalendarBookingModule.propTypes = {
  addAvailabilityToCartAndRedirect: PropTypes.func.isRequired,
  date: PropTypes.string.isRequired,
  dispatchInventoryItemScraped: PropTypes.func.isRequired,
  employeeSlug: PropTypes.string,
  inventoryItem: PropTypes.shape({
    id: PropTypes.string.isRequired,
    company: PropTypes.shape({
      id: PropTypes.string.isRequired,
      allow_next_appointment: PropTypes.bool,
    }),
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  time: PropTypes.string,
}

const mapDispatchToProps = (dispatch) => ({
  clearCart: () => dispatch(clearCart()),
  dispatchInventoryItemScraped: (inventoryItem, date) =>
    dispatch(inventoryItemScraped(inventoryItem, date)),
  addAvailabilityToCartAndRedirect: (availability, inventoryItem) =>
    dispatch(addAvailabilityToCartAndRedirect(availability, inventoryItem)),
})

export default connect(null, mapDispatchToProps)(CalendarBookingModule)
