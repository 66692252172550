import styled from "styled-components"

export const ScrapeStatusParent = styled.div`
  position: absolute;
  width: 90%;
  left: 5%;
  top: calc(50% - 20px);
  text-align: center;
  background-color: transparent;
  z-index: 5;
`
export const ScrapeStatus = styled.div`
  display: inline-block;
  margin: 0;
  padding: 6px 12px;
  background-color: #575757;
  color: #fff;
  font-size: 12px;
  font-weight: 500;
`

export const FindBestPriceText = styled.span`
  display: block;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.primary};

  &:hover {
    text-decoration: underline;
  }
`

export const TimeSelectDiv = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 10px;
  background-color: ${(props) =>
    props.selected
      ? props.theme.timeSelector.selectedBackground
      : "transparent"};
  color: ${(props) =>
    props.selected
      ? props.theme.timeSelector.selectedText
      : props.theme.timeSelector.defaultText};
  border-radius: 999px;
  border: 1px solid ${(props) => props.theme.timeSelector.selectedBackground};

  &:hover {
    background-color: ${(props) =>
      props.selected
        ? props.theme.timeSelector.selectedBackground
        : props.theme.timeSelector.hoverBackground};
    color: ${(props) =>
      props.selected
        ? props.theme.timeSelector.selectedText
        : props.theme.timeSelector.hoverText};
  }
`

export const DaySelectDiv = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  border-radius: 100%;
  height: 50px;
  width: 50px;
  max-width: 50px;
  padding: 5px;
  box-sizing: border-box;
  color: ${(props) =>
    props.selected
      ? props.theme.dateSelector.selectedText
      : props.disabled
      ? props.theme.dateSelector.disabledText
      : props.sale
      ? props.theme.dateSelector.saleText
      : props.theme.dateSelector.defaultText};
  background-color: ${(props) =>
    props.selected ? props.theme.dateSelector.selectedBackground : ""};
  /* &:hover {
    background-color: #ccc;
  } */

  & div {
    color: inherit;
  }
`

export const WrapperDiv = styled.div`
  background-color: ${(props) => props.theme.colors.tertiary};
`

export const TimeSelectorContainer = styled.div`
  display: grid;
  grid-auto-flow: row;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  height: 100%;
  padding: 30px 2.5em 0;
`
