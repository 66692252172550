import { queryOptions } from "@tanstack/react-query"
import PropTypes from "prop-types"
import {
  companySearch,
  fetchCampaigns,
  fetchCategoryData,
  fetchInstantBookServices,
  fetchRegions,
} from "../../api/api"

export const buildCoreDataQueryOptions = () => {
  return queryOptions({
    refetchOnWindowFocus: false,
    queryKey: ["core-data"],
    queryFn: async () => {
      const [campaigns, categoryData, companies, regions] = await Promise.all([
        fetchCampaigns(),
        fetchCategoryData(),
        companySearch({ sort: "popularity" }),
        fetchRegions(),
      ])

      return { campaigns, categoryData, companies, regions }
    },
  })
}

export function instantBookToApiFilter(
  instantBookFilter,
  page = 0,
  limit = 20
) {
  return Object.fromEntries([
    ...Object.entries({
      category_slug: instantBookFilter.selectedCategories
        .map((c) => c.slug.toLowerCase())
        .join(),
      campaign_slug: instantBookFilter.selectedCampaigns
        .map((c) => c.slug.toLowerCase())
        .join(),
      company_slug: instantBookFilter.selectedCompanies
        .map((c) => c.slug.toLowerCase())
        .join(),
      region_slug: instantBookFilter.selectedRegions
        .map((c) => c.slug.toLowerCase())
        .join(),
    }).filter(([, eachValue]) => eachValue !== ""),
    ...instantBookFilter.selectedDays.map((day, index) => [
      `times[${index}][day]`,
      day,
    ]),
    ["order", instantBookFilter.sortBy],
    ["offset", page * limit],
    ["limit", limit],
  ])
}

export function buildInventorySearchQueryOptions({
  credentials,
  filter,
  enabled = false,
}) {
  const queryKey = ["inventory-search", credentials, filter]

  return queryOptions({
    refetchOnWindowFocus: false,
    queryKey,
    queryFn: async () => fetchInstantBookServices(credentials, filter),
    enabled,
    retry: false,
  })
}

buildInventorySearchQueryOptions.propTypes = {
  credentials: PropTypes.shape({
    token: PropTypes.string,
  }),
  filter: PropTypes.shape({
    bbox: PropTypes.string,
    bookmarks: PropTypes.string,
    category_slug: PropTypes.string,
    campaign_slug: PropTypes.string,
    company_slug: PropTypes.string,
    region_slug: PropTypes.string,
    offset: PropTypes.number.isRequired,
    latitude: PropTypes.number,
    limit: PropTypes.number.isRequired,
    longitude: PropTypes.number,
    order: PropTypes.oneOf([
      "distance",
      "name",
      "popularity",
      "price",
      "sale",
      "vip",
    ]).isRequired,
    sale: PropTypes.string,
    times: PropTypes.arrayOf(PropTypes.string),
    vip: PropTypes.string,
  }).isRequired,
}
