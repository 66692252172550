const { DEFAULT_SORT_ORDER } = SITE_CONFIG

export const urlToFilter = (
  pathname,
  campaigns,
  companies,
  categories,
  regions
) => {
  const filterState = {
    selectedDays: [],
    selectedCategories: [],
    selectedCompanies: [],
    selectedRegions: [],
    selectedCampaigns: [],
    sortBy: DEFAULT_SORT_ORDER,
  }
  const parts = pathname.replace(/^\/(browse\/)?/, "").split("/")

  if (parts.length <= 1) {
    return filterState
  }

  for (let i = 0; i < parts.length; i += 2) {
    const filter = parts[i]
    const values = parts[i + 1].split(",")

    switch (filter) {
      case "category":
        filterState.selectedCategories = values.map((eachCategorySlug) =>
          categories.find(
            (eachCategory) =>
              eachCategory.slug.toLowerCase() === eachCategorySlug.toLowerCase()
          )
        )
        break
      case "offer":
        filterState.selectedCampaigns = values.map((eachCampaignSlug) =>
          campaigns.find(
            (eachCampaign) =>
              eachCampaign.slug.toLowerCase() === eachCampaignSlug.toLowerCase()
          )
        )
        break
      case "company":
        filterState.selectedCompanies = values.map((eachCompanySlug) =>
          companies.find(
            (eachCompany) =>
              eachCompany.slug.toLowerCase() === eachCompanySlug.toLowerCase()
          )
        )
        break
      case "region":
        filterState.selectedRegions = values.map((eachRegionSlug) =>
          regions.find(
            (eachRegion) =>
              eachRegion.slug.toLowerCase() === eachRegionSlug.toLowerCase()
          )
        )
        break
      case "date":
        filterState.selectedDays = values
        break
      case "sort":
        const [sortBy] = values
        filterState.sortBy = sortBy
        break
      default:
        console.error("URL ERROR! not found", filter, values)
    }
  }

  return filterState
}

export const filterToUrl = (filter) => {
  let url = "/browse"

  if (filter.selectedCategories.length > 0) {
    url = `${url}/category/${filter.selectedCategories
      .map((eachCategory) => eachCategory.slug.toLowerCase())
      .sort()
      .join()}`
  }

  if (filter.selectedCampaigns.length > 0) {
    url = `${url}/offer/${filter.selectedCampaigns
      .map((eachCampaign) => eachCampaign.slug.toLowerCase())
      .sort()
      .join()}`
  }

  if (filter.selectedCompanies.length > 0) {
    url = `${url}/company/${filter.selectedCompanies
      .map((eachCompany) => eachCompany.slug.toLowerCase())
      .sort()
      .join()}`
  }

  if (filter.selectedDays.length > 0) {
    url = `${url}/date/${filter.selectedDays.sort().join()}`
  }

  if (filter.selectedRegions.length > 0) {
    url = `${url}/region/${filter.selectedRegions
      .map((eachRegion) => eachRegion.slug.toLowerCase())
      .sort()
      .join()}`
  }

  if (filter.sortBy && filter.sortBy !== DEFAULT_SORT_ORDER) {
    url = `${url}/sort/${filter.sortBy}`
  }

  return url === "/browse" ? "/" : url
}

// A bunch of business logic that systematically broadens the filter
export const broadenFilter = (filter) => {
  if (filter.selectedDays.length > 0) {
    return {
      selectedDays: [],
    }
  }
  if (filter.selectedRegions.length > 0) {
    return {
      selectedRegions: [],
    }
  }
  if (filter.selectedCompanies.length > 0) {
    return {
      selectedCompanies: [],
    }
  }
  // Something is likely wrong with what we're getting back from API if we get this far. But for the sake of completeness...
  if (filter.selectedCategories.length > 0) {
    return {
      selectedCategories: [],
    }
  }
  if (filter.selectedCampaigns.length > 0) {
    return {
      selectedCampaigns: [],
    }
  }

  // If we get here we're likely in a bad state.. return nothing so we don't end up in an infinite loop
  return null
}
