import { createSelector } from "reselect"

const getLoginState = (state) =>
  Object.prototype.hasOwnProperty.call(state, "login") ? state.login : {}

export const hasError = createSelector(
  getLoginState,
  (loginState) => loginState.hasError
)

export const getLastError = createSelector(
  getLoginState,
  (loginState) => loginState.lastError ?? ""
)
