import {
  SET_BEST_PRICE_DATA_ACTION,
  SET_BEST_PRICE_ERROR_ACTION,
  SET_INVENTORY_DATE_ACTION,
} from "./constants"

// Consistent state to be used at the beginning of every kind of scrape (get availability/next available date/best price)
function buildInitialScrapeState(token) {
  const state = {
    // API supplied identifier for the scrape
    scrapeId: null,

    // Text scrape status displayed to the user
    scrapeStatus: null,

    // Client generated scrape token used for pusher messaging
    scrapeToken: token,

    // The currently selected availability
    selectedAvailability: null,

    isFetchingAvailability: token !== null,

    // Find best price fields
    findBestPriceError: false,
    isFetchingBestPrice: false,

    // Find next availability fields
    nextAvailabilityError: false,
  }

  // If token is not null we're actually scraping something and don't want to clobber this value
  if (token === null) {
    state.findBestPriceTime = null
  }

  return state
}

// Consistent state to use before loading service/employee/campaign/review data
function buildInitialInventoryState() {
  return {
    // Attempt to get availability returned nothing
    noAvailabilityResults: false,

    // Attempted fetch of service from API returned 404
    fourOhFour: false,

    selectedAvailability: null,
    bestPriceData: null,
  }
}

export const initialState = {
  ...buildInitialInventoryState(),
  ...buildInitialScrapeState(null),

  // The currently selected date
  inventoryDate: null,

  // List of available times for this service for the user to choose from
  availabilities: [],
}

export default function inventoryView(state = initialState, action) {
  switch (action.type) {
    case SET_BEST_PRICE_DATA_ACTION:
      return {
        ...state,
        bestPriceData: action.payload.data,
      }
    case SET_INVENTORY_DATE_ACTION:
      return {
        ...state,
        inventoryDate: action.payload.date,
        selectedAvailability: null,
      }
    case SET_BEST_PRICE_ERROR_ACTION:
      return {
        ...state,
        findBestPriceError: action.payload.error,
      }
    default:
      return state
  }
}
