import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import Carousel from "../../../components/carousel/Carousel"
import CategorySelector from "./CategorySelector"
import { browserIsMobile } from "../../../redux/browserSelector"

function CategoryOptions({ isMobile, services }) {
  const uniqueCategoriesId = []
  let categories = []

  services
    .map((service) => service.description)
    .filter((description) => {
      const { category } = description
      category.images = description.images
      if (!uniqueCategoriesId.includes(category.id)) {
        uniqueCategoriesId.push(category.id)
        categories.push(category)
        return true
      }
      return false
    })

  categories = categories
    .filter((category) => typeof category !== "undefined")
    .sort((a, b) => {
      /* Sort by position first */
      if (parseInt(a.position, 10) > parseInt(b.position, 10)) {
        return -1
      }
      if (parseInt(a.position, 10) < parseInt(b.position, 10)) {
        return 1
      }

      /* Then by name second */
      if (a.name < b.name) {
        return -1
      }

      if (a.name > b.name) {
        return 1
      }
      // a must be equal to b
      return 0
    })

  return (
    <div>
      <Carousel isMobile={isMobile}>
        {categories.map((category) => (
          <div
            key={`category-${category.id}`}
            style={{ height: "100%", padding: "0 5px" }}
          >
            <CategorySelector category={category} />
          </div>
        ))}
      </Carousel>
    </div>
  )
}

CategoryOptions.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  services: PropTypes.array.isRequired,
}

function mapStateToProps(state) {
  return {
    isMobile: browserIsMobile(state),
  }
}

export default connect(mapStateToProps)(CategoryOptions)
