import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledP = styled.p`
  font-weight: 700;
  color: rgba(0, 0, 0, 0.6);
`

export function ListCompanyHeading({ text, location = null }) {
  return (
    <>
      <StyledP>{text}</StyledP>
      {location && <StyledP>{location}</StyledP>}
    </>
  )
}

ListCompanyHeading.propTypes = {
  text: PropTypes.string.isRequired,
  location: PropTypes.string,
}
