import moment from "moment"
import { queryOptions } from "@tanstack/react-query"
import { thumborize } from "../../components/images/thumborize"
import {
  findBestPrice,
  findNextAvailability,
  scrapeAvailability,
} from "../../api/api"
import {
  availabilityStartTimeToApiDateTimeString,
  DATE_TO_STRING_FORMAT,
} from "../../../utils/momentHelpers"

// from https://stackoverflow.com/questions/10726909/random-alpha-numeric-string-in-javascript
export function randomString32() {
  const length = 32
  const chars = "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ"
  let result = ""
  for (let i = length; i > 0; i -= 1)
    result += chars[Math.floor(Math.random() * chars.length)]
  return result
}

export function calculatePrice(price, isVip) {
  if (isVip && price.discount_vip) {
    return (parseFloat(price.price) - parseFloat(price.discount_vip)).toFixed(2)
  }
  return price.price
}

export const getInventoryDateFromString = (dateString) =>
  !dateString || dateString === "today"
    ? moment()
    : moment(dateString, DATE_TO_STRING_FORMAT)

export const getCompanyLogo = (company) => {
  const logo = (company.images ?? []).find((i) => i.placement === "logo")

  return logo ? thumborize(encodeURIComponent(logo.url), 240, 240) : null
}

export const getSelectedEmployee = (inventoryItem, employeeSlug) => {
  const { employees } = inventoryItem

  return employeeSlug && Array.isArray(employees)
    ? employees.find((eachEmployee) => eachEmployee.slug === employeeSlug)
    : null
}

const compareTime = (timea, timeb) => timea.sortKey - timeb.sortKey

export const parseAvailabilities = (item, rawAvailabilities) =>
  rawAvailabilities
    .map((eachAvailability) => ({
      id: eachAvailability.id,
      employee: eachAvailability.employee,
      timezone: item.company.timezone,
      startTime: moment.tz(new Date(eachAvailability.time.start_time), "UTC"),
      sale: eachAvailability.sale,
      default: eachAvailability.default,
      sortKey: moment(eachAvailability.time.start_time)
        .tz(item.company.timezone)
        .valueOf(),
    }))
    .sort(compareTime)

export const buildAvailabilitiesScrapeQueryOptions = ({
  item,
  date,
  scrapeToken,
  employeeId = null,
  force = false,
  isEnabled = null,
  onQueryComplete = null,
}) => {
  const queryKey = ["scrapeAvailabilities", scrapeToken, force]

  const enabled =
    isEnabled !== null ? isEnabled : item?.id !== null && scrapeToken !== null

  return queryOptions({
    queryKey,
    enabled,
    retry: false,
    refetchOnWindowFocus: false,
    queryFn: async () => {
      const json = await scrapeAvailability(
        {},
        item.id,
        date,
        scrapeToken,
        employeeId,
        force
      ).then((response) => response.json())

      if (typeof onQueryComplete === "function") {
        onQueryComplete(json)
      }

      return {
        isBackgroundScraping: Boolean((json?.id ?? "").match(/^[0-9]+$/)),
        availabilities: parseAvailabilities(item, json?.results ?? []),
      }
    },
  })
}

export const buildFindBestPriceQueryOptions = (
  item,
  date,
  scrapeToken,
  enabled = false
) =>
  queryOptions({
    queryKey: ["find-best-price", item?.id, date],
    queryFn: async () =>
      findBestPrice(
        scrapeToken,
        item.id,
        availabilityStartTimeToApiDateTimeString(date)
      ).then((response) => response.json()),
    enabled,
  })

export const buildNextQueryOptions = (
  itemId,
  date,
  scrapeToken,
  employeeId = null,
  enabled = false
) =>
  queryOptions({
    queryKey: ["find-next-availability", itemId, date, employeeId],
    queryFn: async () =>
      findNextAvailability(scrapeToken, date, itemId, employeeId).then(
        (response) => response.json()
      ),
    enabled,
  })

export const getInventoryItemEmployeeById = (availability, employeeId) => {
  return availability.employees.find(
    (eachEmployee) => eachEmployee.id === employeeId
  )
}
