import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"

const PromoDiv = styled.div`
  display: ${(props) => (props.isMobile ? "block" : "flex")};
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
  background-color: #fac8ce;
  cursor: pointer;
`

const PromoText = styled.p`
  margin: 0;
  font-weight: 350;
  font-size: ${(props) => (props.isMobile ? "12px" : "14px")};
  text-align: center;
  padding: 6px 10px;
  letter-spacing: 2px;
`

function PromoBanner({ isMobile = false, loggedIn = false }) {
  const showBanner = SITE_CONFIG.SIGNUP_BANNER_ENABLED

  const navigate = useNavigate()

  let payload = null
  if (showBanner && !loggedIn) {
    payload = (
      <PromoDiv isMobile={isMobile} onClick={() => navigate("/register")}>
        <PromoText isMobile={isMobile}>
          WELCOME! ENJOY <b>$10 OFF YOUR FIRST BOOKING</b> WHEN YOU SIGN-UP
        </PromoText>
      </PromoDiv>
    )
  }
  return payload
}

PromoBanner.propTypes = {
  isMobile: PropTypes.bool,
  loggedIn: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  loggedIn: !!state.profile.profile.id,
  isMobile: state.browser.lessThan.mobile,
})

export default connect(mapStateToProps)(PromoBanner)
