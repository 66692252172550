import moment from "moment-timezone"

export const DATE_TO_STRING_FORMAT = "YYYY-MM-DD"

export function dayIsSale(price, day) {
  if (!price || !day) return null
  if (price.default || price.sale !== true) {
    return false
  }

  let sale = true

  /* If a DOW condition is set then the day must match */
  if (
    price.condition_mon ||
    price.condition_tue ||
    price.condition_wed ||
    price.condition_thu ||
    price.condition_fri ||
    price.condition_sat ||
    price.condition_sun
  ) {
    switch (parseInt(day.format("d"), 10)) {
      case 0:
        sale = price.condition_sun === true
        break
      case 1:
        sale = price.condition_mon === true
        break
      case 2:
        sale = price.condition_tue === true
        break
      case 3:
        sale = price.condition_wed === true
        break
      case 4:
        sale = price.condition_thu === true
        break
      case 5:
        sale = price.condition_fri === true
        break
      case 6:
        sale = price.condition_sat === true
        break
      default:
        throw new Error("Invalid day of week")
    }
  }

  /* If a to/from condition is set then the date must be between them */
  if (price.condition_date_from) {
    sale =
      sale &&
      (day.isSame(price.condition_date_from, "day") ||
        day.isAfter(price.condition_date_from))
  }

  if (price.condition_date_to) {
    sale =
      sale &&
      (day.isSame(price.condition_date_to, "day") ||
        day.isBefore(price.condition_date_to))
  }
  return sale
}

// Either get the same day a week prior to the given date,
// or the nearest day of the previous week that is still in the future
export function getPreviousWeekDate(company, selectedDate) {
  /* Do not display previous week link if viewing the current week */
  const currentlySelectedDateInCompanyTimezone = moment
    .tz(selectedDate, DATE_TO_STRING_FORMAT, company.timezone)
    .startOf("day")

  const sameDayLastWeek = moment(currentlySelectedDateInCompanyTimezone)
    .subtract(1, "week")
    .startOf("day")

  const endOfLastWeek = moment(sameDayLastWeek).endOf("week")

  // End of last week is in the past, no point continuing
  if (dayIsInThePast(endOfLastWeek)) {
    return null
  }

  const dayLastWeekCursor = moment(sameDayLastWeek)
  // Skip any days in the last week that are unavailable
  while (dayLastWeekCursor.isSameOrBefore(endOfLastWeek)) {
    if (dayIsAvailable(company, dayLastWeekCursor)) {
      return dayLastWeekCursor
    }
    dayLastWeekCursor.add(1, "day")
  }

  return null
}

export const getDaysForWeekOfDate = (dateString) => {
  const date = moment(dateString, DATE_TO_STRING_FORMAT)
  const days = []
  const endOfWeek = moment(date).endOf("week")
  const cur = moment(date).startOf("week")
  while (cur.isBefore(endOfWeek)) {
    days.push(moment(cur))
    cur.add(1, "day")
  }
  return days
}

export function companyToday(company) {
  return moment().tz(company.timezone).startOf("day")
}

export function getTimeFromParamString(time) {
  const parts = /^([0-9]{2})([0-9]{2})$/g.exec(time)
  if (!parts) {
    return null
  }

  return `${parts[1]}:${parts[2]}`
}

export function apiDateToUrlTime(date, timezone) {
  return moment.tz(date, timezone).format("HHmm")
}

export function dayIsInThePast(date, timezone = "utc") {
  const startOfDayForNowInTimezone = moment.tz(timezone).startOf("day")
  const startOfDayForDateInTimezone = moment
    .tz(date, DATE_TO_STRING_FORMAT, timezone)
    .startOf("day")

  return startOfDayForDateInTimezone.isBefore(startOfDayForNowInTimezone)
}

export function dayIsAvailable(company, day) {
  if (!company) {
    throw Error("Missing company")
  }

  if (!day) {
    throw Error("Missing day parameter")
  }

  const { hours, unavailability, timezone } = company

  // if (day.isBefore(moment(), "day")) result = true

  /* Days in the past are unavailable */
  if (dayIsInThePast(day, timezone)) {
    return false
  }

  /* Salon not open on that date */
  const dayName = day.format("dddd")
  const hourDay = hours.find((h) => h.day === dayName)
  if (hourDay && !hourDay.is_open) {
    return false
  }

  /* Salon not available on that date */
  if (unavailability.dates.find((un) => un === day.format("YYYY-MM-DD"))) {
    return false
  }

  return true
}

export const getMomentTimeForAvailability = (availability) => {
  const { startTime, timezone } = availability
  return moment(startTime).tz(timezone)
}

export const availabilityStartTimeToApiDateTimeString = (availability) =>
  moment
    .tz(new Date(availability.startTime), availability.timezone)
    .clone()
    .tz("UTC")
    .format("YYYY-MM-DDTHH:mm:ssZ")
