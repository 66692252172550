import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { VARIANT, ThemedButton } from "./ThemedButton"

const InnerIconButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: center;
`
function Button({ children, variant = "primary", icon, ...rest }) {
  return (
    <ThemedButton variant={variant} hasIcon={icon} {...rest}>
      {icon ? (
        <InnerIconButtonWrapper>
          {icon}
          {children}
        </InnerIconButtonWrapper>
      ) : (
        children
      )}
    </ThemedButton>
  )
}

Button.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
    .isRequired,
  variant: PropTypes.oneOf([
    VARIANT.PRIMARY,
    VARIANT.SECONDARY,
    VARIANT.TERTIARY,
  ]),
  icon: PropTypes.element,
}

export default Button

export { default as SelectButton } from "./SelectButton"
export { default as GoToButton } from "./GoToButton"
export { default as Link } from "./Link"
