import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import CompanySelector from "../../components/CompanySelector"

function CompanyFilterContainer({
  closeAction,
  companies,
  selectAllCompaniesInRegion = () => {},
  regions,
  selectCompany,
  selectRegion,
  selectedCompanies,
  selectedRegions,
  topCompanies,
}) {
  if (!companies) return null

  return (
    <CompanySelector
      companies={companies}
      regions={regions}
      topCompanies={topCompanies}
      selectedRegions={selectedRegions}
      selectedCompanies={selectedCompanies}
      showSelectAllRegions
      onSelectRegion={(region) => {
        closeAction()
        selectRegion(region)
      }}
      onSelectAllCompaniesInRegion={selectAllCompaniesInRegion}
      onSelectCompany={(company) => {
        closeAction()
        selectCompany(company)
      }}
      scrollContainerStyle={{}}
    />
  )
}

CompanyFilterContainer.propTypes = {
  selectCompany: PropTypes.func.isRequired,
  selectedCompanies: PropTypes.array.isRequired,
  selectedRegions: PropTypes.array.isRequired,
  selectRegion: PropTypes.func.isRequired,
  selectAllCompaniesInRegion: PropTypes.func,
  companies: PropTypes.array.isRequired,
  regions: PropTypes.array.isRequired,
  closeAction: PropTypes.func.isRequired,
  topCompanies: PropTypes.array,
}

function mapStateToProps(state) {
  return {
    isMobile: state.browser.lessThan.mobile,
    topCompanies:
      state.profile.profile &&
      state.profile.profile.top &&
      state.profile.profile.top.companies,
  }
}

export default connect(mapStateToProps)(CompanyFilterContainer)
