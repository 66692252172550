import { REGISTRATION_SUCCESS } from "./constants"

export const submitRegistration = (
  code,
  firstName,
  lastName,
  phone,
  email,
  password,
  nowrap,
  newsletter
) => ({
  type: "SUBMIT_REGISTRATION",
  payload: {
    code,
    firstName,
    lastName,
    phone,
    email,
    password,
    nowrap,
    newsletter,
  },
})
export const submitRegistrationCodeRequest = (
  firstName,
  lastName,
  phone,
  email,
  password,
  googleRecaptchaToken
) => ({
  type: "SUBMIT_REGISTRATION_CODE_REQUEST",
  payload: {
    firstName,
    lastName,
    phone,
    email,
    password,
    googleRecaptchaToken,
  },
})
export const updateFirstName = (firstName) => ({
  type: "REGISTRATION_FIRST_NAME_UPDATE",
  payload: { firstName },
})
export const updateLastName = (lastName) => ({
  type: "REGISTRATION_LAST_NAME_UPDATE",
  payload: { lastName },
})
export const updateEmail = (email) => ({
  type: "REGISTRATION_EMAIL_UPDATE",
  payload: { email },
})
export const updatePhone = (phone) => ({
  type: "REGISTRATION_PHONE_UPDATE",
  payload: { phone },
})
export const updatePassword = (password) => ({
  type: "REGISTRATION_PASSWORD_UPDATE",
  payload: { password },
})
export const updateCode = (code) => ({
  type: "REGISTRATION_CODE_UPDATE",
  payload: { code },
})

export const updateError = (error) => ({
  type: "REGISTRATION_FAILURE",
  payload: { error_response: { response: { data: { message: error } } } },
})

export const registrationFulfilled = (credentials, noRedirect) => ({
  type: REGISTRATION_SUCCESS,
  payload: { credentials, noRedirect },
})
