import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import buttonize from "../buttonize"
import { thumborize } from "../images/thumborize"
import { CompanyLogo } from "../images/CompanyLogo"
import Pricing from "../Pricing"
import Button from "../button"

const ServiceName = styled.span`
  font-size: 16px;
  font-weight: 700;
  margin: 0;
  padding: 0;
  line-height: 20px;
`
const ServiceContent = styled.div`
  text-align: ${({ centeredContent }) => (centeredContent ? "center" : "left")};
  padding: 0;
  min-height: ${({ isMobile }) => (isMobile ? "auto" : "50px")};
  h3 {
    color: #000;
    max-width: calc(100% - 10px);
    margin: 0 auto;
    font-size: 18px;
    font-weight: 500;
  }
`

const CardContainer = styled.div`
  display: grid;
  width: auto;
  max-width: 250px;
  height: 100%;
  min-height: 200px;
  margin: 0px;
  border: ${({ highlight, theme }) =>
    `2px solid ${highlight ? theme.colors.primary : theme.colors.secondary}`};
  position: relative;
  overflow: hidden;
  background: ${({ highlight, theme }) =>
    highlight ? theme.colors.tertiary : theme.colors.primaryAccent};
`

function ServiceCard({
  onClick,
  company,
  price,
  currency,
  isMobile,
  isVip,
  buttonContent,
  serviceImages,
  descriptionImages = [],
  name,
  highlight,
  logoPosition = "on-image",
  showPriceRange,
  showCompanyName = true,
  showPrice = true,
  centeredContent,
  description,
}) {
  const listImages = serviceImages.find(
    (i) => i.placement === "gallery" && i.position === "0"
  )
  const descImages = descriptionImages.find(
    (i) => i.placement === "gallery" && i.position === "0"
  )
  let showListType = listImages
  if (!listImages) showListType = descImages
  const imageUrl = showListType && thumborize(showListType.url, 800, 600)

  const renderLogo = () => (
    <CompanyLogo
      company={company}
      size={isMobile ? 48 : 60}
      style={{
        display: "block",
        position: "absolute",
        bottom: 10,
        right: 10,
      }}
    />
  )

  return (
    <CardContainer highlight={highlight} {...buttonize(onClick, {})}>
      <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        {imageUrl && (
          <div style={{ position: "relative" }}>
            <img
              alt="service"
              src={imageUrl}
              style={{ display: "block", width: "100%", height: "auto" }}
            />
            {company && logoPosition === "on-image" && renderLogo()}
          </div>
        )}

        <div
          style={{
            padding: "10px",
            textAlign: "left",
            flex: 1,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div>
            <div
              style={{
                marginBottom: 5,
              }}
            >
              <ServiceContent
                isMobile={isMobile}
                centeredContent={centeredContent}
              >
                <p>
                  <ServiceName>{name}</ServiceName>
                </p>
                {description && <p>{description}</p>}
              </ServiceContent>
              {company && showCompanyName && (
                <div
                  style={{
                    marginTop: 4,
                    marginBottom: 10,
                    fontWeight: 300,
                    textAlign: centeredContent ? "center" : "left",
                  }}
                >
                  {company.name}
                </div>
              )}
            </div>
          </div>
          <div style={{ flex: 1 }} />
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
            }}
          >
            <div>
              {showPrice && (
                <Pricing
                  style={{ textAlign: "left" }}
                  hideTag
                  prices={price}
                  currency={currency}
                  isMobile={isMobile}
                  userIsVip={isVip}
                  showPriceRange={showPriceRange}
                />
              )}
            </div>
            {logoPosition === "on-content" && (
              <div style={{ flex: 1, textAlign: "right" }}>{renderLogo()}</div>
            )}
            {buttonContent && (
              <div
                style={{
                  flex: 1,
                  textAlign: centeredContent ? "center" : "right",
                }}
              >
                <Button
                  variant="secondary"
                  filled={highlight}
                  style={{
                    marginBottom: isMobile ? 12 : 8,
                    fontSize: isMobile ? 12 : 13,
                    padding: isMobile ? 5 : 10,
                  }}
                >
                  {buttonContent}
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    </CardContainer>
  )
}

ServiceCard.propTypes = {
  highlight: PropTypes.bool,
  buttonContent: PropTypes.string,
  onClick: PropTypes.func,
  style: PropTypes.object,
  imageUrl: PropTypes.string,
  service: PropTypes.object,
  name: PropTypes.string,
  showPriceRange: PropTypes.bool,
  showCompanyName: PropTypes.bool,
  logoPosition: PropTypes.oneOf(["on-image", "on-content", "hide"]),
  showPrice: PropTypes.bool,
  centeredContent: PropTypes.bool,
  company: PropTypes.object,
  price: PropTypes.object,
  currency: PropTypes.object,
  isMobile: PropTypes.bool,
  isVip: PropTypes.bool,
  serviceImages: PropTypes.array,
  descriptionImages: PropTypes.array,
  description: PropTypes.string,
}

export default ServiceCard
