import React from "react"
import PropTypes from "prop-types"
import Campaigns from "./Campaigns"
import TopCategories from "./TopCategories"
import CategoryGroup from "./CategoryGroup"

function List({
  campaigns,
  campaignsAreExpanded,
  categoryGroups,
  onClickCampaigns,
  onClickTopCategories,
  onClickCategoryGroup,
  topCategories,
  topCategoriesAreExpanded,
  toggleCampaign,
  toggleCategory,
}) {
  return (
    <>
      {campaigns.length > 0 && (
        <Campaigns
          campaigns={campaigns}
          campaignsAreExpanded={campaignsAreExpanded}
          onClick={onClickCampaigns}
          onClickCampaign={toggleCampaign}
        />
      )}
      {topCategories.length > 0 && (
        <TopCategories
          topCategories={topCategories}
          topCategoriesAreExpanded={topCategoriesAreExpanded}
          onClick={onClickTopCategories}
          onClickCategory={toggleCategory}
        />
      )}
      {categoryGroups.map((eachCategoryGroup) => (
        <CategoryGroup
          key={eachCategoryGroup.id}
          categoryGroup={eachCategoryGroup}
          onClick={onClickCategoryGroup}
          onClickCategory={toggleCategory}
        />
      ))}
    </>
  )
}

List.propTypes = {
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  campaignsAreExpanded: PropTypes.bool.isRequired,
  categoryGroups: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickCampaigns: PropTypes.func.isRequired,
  onClickTopCategories: PropTypes.func.isRequired,
  onClickCategoryGroup: PropTypes.func.isRequired,
  topCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  topCategoriesAreExpanded: PropTypes.bool.isRequired,
  toggleCampaign: PropTypes.func.isRequired,
  toggleCategory: PropTypes.func.isRequired,
}

export default List
