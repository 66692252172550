import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { connect } from "react-redux"
import moment from "moment"
import InfiniteScrollService from "./InfiniteScrollService"
import { browserIsMobile } from "../../redux/browserSelector"

const DayHeading = styled.h2`
  color: ${(props) => props.theme.colors.secondary};
  padding: 20px 0px 10px 0px;
  margin: 0px;
  border-bottom: 1px solid #ccc;
  text-align: center;
`

function InfiniteScrollDate({ date, isMobile, items }) {
  return (
    <>
      <DayHeading isMobile={isMobile}>
        {moment(date).format("dddd ")} Appointments
      </DayHeading>
      {items.map((item) => (
        <InfiniteScrollService key={item.id} service={item} />
      ))}
    </>
  )
}

InfiniteScrollDate.propTypes = {
  date: PropTypes.string,
  isMobile: PropTypes.bool,
  items: PropTypes.array,
}

const mapStateToProps = (state) => ({
  isMobile: browserIsMobile(state),
})

export default connect(mapStateToProps)(InfiniteScrollDate)
