import React from "react"
import PropTypes from "prop-types"
import { useCalendarBookingModuleContext } from "../CalendarBookingModuleContext"
import { getCompanyLogo } from "../../InventoryView/lib"
import { PromoTag } from "../../../components/SaleTags"
import Pricing from "../../../components/Pricing"
import { StyledLabel } from "./styledComponents"

function AnyStylistLabel({ role, onSale }) {
  const {
    inventoryItem: { company, currency, pricing },
  } = useCalendarBookingModuleContext()

  const companyLogo = getCompanyLogo(company)

  return (
    <StyledLabel>
      <div style={{ paddingRight: 12 }}>
        <img
          src={companyLogo}
          alt=""
          style={{
            width: 45,
            height: 45,
            borderRadius: 30,
            display: "block",
          }}
        />
      </div>
      <div style={{ flex: 1 }}>
        Any {role}
        {onSale && (
          <PromoTag style={{ padding: "6px 0px 0px 4px" }} align="left" />
        )}
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          paddingRight: "1em",
        }}
      >
        <Pricing
          currency={currency}
          prices={pricing}
          style={{ marginLeft: 10 }}
        />
      </div>
    </StyledLabel>
  )
}

AnyStylistLabel.propTypes = {
  role: PropTypes.string,
  onSale: PropTypes.bool.isRequired,
}

export default AnyStylistLabel
