import { FETCH_INVENTORY_SUCCESS, TRACK_PURCHASE_START } from "./constants"

export const setEmployee = (employeeId) => ({
  type: "SET_EMPLOYEE",
  payload: { employeeId },
})

export const clearCart = () => ({
  type: "CLEAR_CART",
})

export const trackPurchaseStart = (inventoryItem) => ({
  type: TRACK_PURCHASE_START,
  payload: { item: inventoryItem },
})

export const fetchRelatedServices = (campaignId, serviceDescriptionId) => ({
  type: "FETCH_RELATED_SERVICES",
  payload: { campaignId, serviceDescriptionId },
})

export const setMapZoom = (zoom) => ({
  type: "SET_MAP_ZOOM",
  payload: { zoom },
})
export const selectStep = (step) => ({ type: "SELECT_STEP", payload: { step } })

export const fetchInventorySuccess = (item) => ({
  type: FETCH_INVENTORY_SUCCESS,
  payload: { item },
})
