import React, { Component } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { FixedDiv } from "../../../containers/NavBar/components"
import CartContainer from "../../../containers/Cart/CartContainer"
import NavBarMenu from "../../../containers/NavBar/NavBarMenu"

import AvedaNavigation from "./Navigation"

import { BurgerIcon } from "./Icons"
import { CalendarIcon } from "../../../components/icons"
import PoweredByFlossie from "../../../components/PoweredByFlossie"

const HomeLink = styled.a`
  display: block;
  margin-top: 65px;
  line-height: 1;
  font-size: 55px;
  text-align: center;
  text-decoration: none;
  color: #000;

  @media ${({ theme }) => theme.responsive.large} {
    font-size: 24px;
    margin-top: 39px;
    height: 42px;
    line-height: 42px;
    text-align: left;
    margin-left: 54px;
  }
`

const Logo = styled.i`
  font-family: icons;
  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  &::before {
    content: "";
  }

  @media ${({ theme }) => theme.responsive.large} {
  }
`

const AvedaHorizontalNav = styled(NavBarMenu)`
  position: relative;

  #horizontal-menu {
    position: static;
    left: auto !important;
    right: auto !important;
    flex-direction: row;
    min-height: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
    height: 58px;
    flex-wrap: wrap;
  }

  /* Menu links */
  #horizontal-menu > span {
    width: auto;
    border-bottom: none;
    margin: 0;
    padding: 0;
    margin-bottom: -4px;
    margin-top: 0px !important;
    padding: 0 10px;
    transition: color 0.1s ease;
  }

  #horizontal-menu > span:hover {
    color: #a39e9d;
  }

  /* Menu footer/legals */
  #horizontal-menu > div {
    top: 14px;
    right: 12px;
    bottom: auto;
    width: 300px;
  }

  /* Terms and privacy policy */
  #horizontal-menu > div > div {
    display: none !important;
  }

  #horizontal-menu > div > div > a {
    display: block;
    padding: 0px 4px;
    text-transform: uppercase;
    text-decoration: none;
  }

  #horizontal-menu > div > div > a:hover {
    text-decoration: underline;
  }

  /* Copyright notice */
  #horizontal-menu > div > div > p {
    display: none !important;
  }

  /* Back to aveda */
  #horizontal-menu > div > a {
    display: none !important;
  }

  @media ${({ theme }) => theme.responsive.large} {
    display: none;
  }
`

const AvedaFixedDiv = styled(FixedDiv)`
  height: auto;
  padding: 0;
  margin: 0 auto;
  text-align: center;
  max-width: ${({ theme }) => theme.pageMaxWidth};
  display: block;
  box-shadow: none;
`

const AvedaCartContainerDesktop = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1;
  display: block;

  @media ${({ theme }) => theme.responsive.large} {
    display: none;
  }
`

const AvedaCartContainerMobile = styled.div`
  display: none;

  & > div {
    margin-right: 0px !important;
  }

  @media ${({ theme }) => theme.responsive.large} {
    display: block !important;
  }
`

const AvedaProfessional = styled.div`
  position: absolute;
  right: 0;
  top: 69px;

  & > a,
  & > div > a {
    display: none !important;
    vertical-align: middle;
    font-size: 15px;
    text-decoration: none;
    margin-left: 4px;

    transition: color 0.1s ease;

    &:hover {
      color: #a39e9d;
    }
  }

  & > div {
    float: right;
    margin-right: 10px;
  }

  @media ${({ theme }) => theme.responsive.large} {
    top: 46px;

    & > div,
    & > a {
      display: none;
    }

    /* & > div:first-child {
      display: block;
    }*/
  }
`

const AvedaNavigationOverlay = styled.div`
  position: fixed;
  background-color: ${({ theme }) => theme.colors.overlayColor};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
  transition: all 0.5s;
`

const AvedaBurger = styled.a`
  display: none;
  position: absolute;
  top: 50px;
  left: 13px;
  font-size: 18px;

  @media ${({ theme }) => theme.responsive.large} {
    display: block;
  }
`

const AvedaSpacer = styled.div`
  height: 238px;

  @media ${({ theme }) => theme.responsive.large} {
    height: 80px;
  }
`

/* Floating footer bar */
const AvedaFooter = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 2;
  width: 100%;
  min-height: 36px;
  background-color: #fff;
  border-top: 1px solid #000;

  @media ${({ theme }) => theme.responsive.large} {
    display: none !important;
  }
`

const FooterLink = styled.a`
  color: #000;
  font-size: 10px;
  display: block;
  text-decoration: none;
  margin-right: 16px;

  &:hover {
    text-decoration: underline;
  }
`

const FooterLinkContainer = styled.div`
  display: flex;
  padding-top: 10px;
  padding-left: 20px;
  padding-bottom: 10px;

  @media ${({ theme }) => theme.responsive.large} {
    flex-direction: column;
  }
`

const FooterPoweredByFlossie = styled(PoweredByFlossie)`
  position: absolute;
  top: 0;
  right: 20px;
  padding-top: 2px;
  z-index: auto;
`
const AVEDA_WEBSITE =
  SITE_CONFIG.FRANCHISE_ID === "11"
    ? "https://www.aveda.co.uk"
    : "https://www.aveda.com"

class AvedaHeader extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openSubNav: null,
    }
  }

  mouseOut = () => {
    this.setState({ openSubNav: null })
  }

  onOpenSubNav = (subNav) => {
    this.setState({ openSubNav: subNav })
  }

  onCloseSubNav = () => {
    this.setState({ openSubNav: null })
  }

  render() {
    const { browser, onHomeClick, onBurgerClick, menuOpen } = this.props

    // Custom cart icon for aveda
    const renderCart = () => {
      return (
        <CartContainer
          icon={<CalendarIcon style={{ height: "24px", fill: "#000" }} />}
        />
      )
    }

    const { openSubNav } = this.state

    /* Either aveda subnav is open, or the sidebar burber menu is open */
    const showOverlay = openSubNav || menuOpen

    const isDesktop = browser.greaterThan.large

    return (
      <React.Fragment>
        <AvedaFooter>
          <div
            style={{ maxWidth: 1200, margin: "0 auto", position: "relative" }}
          >
            <FooterPoweredByFlossie>
              <a href="https://poweredbyflossie.com">
                Powered&nbsp;by&nbsp;
                <span>FLOSSIE</span>
              </a>
            </FooterPoweredByFlossie>
            <FooterLinkContainer>
              <FooterLink href="/privacy">
                Cookie &amp; Privacy Policy
              </FooterLink>
              <FooterLink href="/terms/customer">
                Booking Terms &amp; Conditions
              </FooterLink>
            </FooterLinkContainer>
          </div>
        </AvedaFooter>
        <AvedaNavigationOverlay
          style={{
            opacity: showOverlay ? "1" : "0",
            visibility: showOverlay ? "visible" : "hidden",
          }}
        />
        <AvedaFixedDiv onMouseLeave={this.mouseOut}>
          <AvedaBurger id="burger" onClick={onBurgerClick} href="/">
            <BurgerIcon />
          </AvedaBurger>

          <AvedaProfessional>
            <AvedaCartContainerMobile>{renderCart()}</AvedaCartContainerMobile>
          </AvedaProfessional>

          <HomeLink
            onClick={isDesktop ? () => {} : onHomeClick}
            href={isDesktop ? AVEDA_WEBSITE : "/"}
          >
            <Logo />
          </HomeLink>

          <AvedaNavigation
            website={AVEDA_WEBSITE}
            onCloseSubNav={this.onCloseSubNav}
            onOpenSubNav={this.onOpenSubNav}
            openSubNav={openSubNav}
          />

          <div style={{ position: "relative" }}>
            <AvedaHorizontalNav id="horizontal-menu" showFooter />
            <AvedaCartContainerDesktop>
              {renderCart()}
            </AvedaCartContainerDesktop>
          </div>

          {/* <AvedaCampaignBanner /> */}
        </AvedaFixedDiv>
        <AvedaSpacer />
      </React.Fragment>
    )
  }
}

AvedaHeader.propTypes = {
  onHomeClick: PropTypes.func.isRequired,
  onBurgerClick: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  browser: PropTypes.object.isRequired,
}

export default AvedaHeader
