import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withTheme } from "styled-components"
import { Trans } from "react-i18next"
import { useGeolocated } from "react-geolocated"
import { setGeo } from "./actions"
import buttonize from "../../components/buttonize"

import {
  SORT_POPULARITY,
  SORT_NAME,
  SORT_PRICE,
  SORT_SALE,
  SORT_DISTANCE,
  SORT_VIP,
} from "./constants"

const { DEFAULT_SORT_ORDER } = SITE_CONFIG

function SortContainer({ sortBy, setSortOption, setGeo, haveGeo }) {
  const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } =
    useGeolocated({
      positionOptions: {
        enableHighAccuracy: false,
      },
      userDecisionTimeout: 10000,
      suppressLocationOnMount: true,
    })

  useEffect(() => {
    if (sortBy === SORT_DISTANCE) {
      if (!coords) {
        getPosition()
      }

      if (!isGeolocationAvailable || !isGeolocationEnabled) {
        setSortOption(SORT_POPULARITY)
      } else if (coords) {
        setSortOption(SORT_DISTANCE)
        setGeo(coords.latitude, coords.longitude)
      }
    }
  }, [sortBy, isGeolocationAvailable, isGeolocationEnabled, coords])

  const renderSortOptions = () => {
    const opts = [
      { name: SORT_POPULARITY, i18n: "sort_popularity" },
      { name: SORT_NAME, i18n: "sort_name" },
      { name: SORT_PRICE, i18n: "sort_price" },
      { name: SORT_SALE, i18n: "sort_sale" },
      { name: SORT_DISTANCE, i18n: "sort_distance" },
      { name: SORT_VIP, i18n: "sort_vip" },
    ].filter((opt) => {
      // if (opt.name === 'distance' && !this.props.haveGeo) return false

      // Explicitly disabled until VIP support is rebuilt
      // if (opt.name === "vip" && !isVip) return false
      if (opt.name === SORT_VIP) return false
      if (opt.name === SORT_SALE) return false
      return true
    })

    return (
      <>
        {opts.map((opt) => {
          const style = {
            padding: "4px 10px",
            fontSize: 13,
            border: "1px solid #f4f2ee",
            borderRadius: "18px",
            marginLeft: 6,
          }
          if (sortBy === opt.name) {
            style.backgroundColor = "#f4f2ee"
          }
          return (
            <span
              key={opt.name}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...buttonize(() => setSortOption(opt.name), style)}
            >
              <Trans i18nKey={opt.i18n} />
            </span>
          )
        })}
      </>
    )
  }

  return (
    <div
      style={{
        marginTop: 20,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ fontWeight: 800, fontSize: 14 }}>Sort By:</div>
      <div
        style={{
          maxWidth: 800,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {renderSortOptions()}
      </div>
    </div>
  )
}

SortContainer.propTypes = {
  sortBy: PropTypes.string,
  haveGeo: PropTypes.bool.isRequired,
  setSortOption: PropTypes.func.isRequired,
  setGeo: PropTypes.func.isRequired,
  isVip: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  theme: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    haveGeo: !!state.inventoryFilter.latitude,
    isVip: !!state.profile.profile.vip,
    loggedIn: !!state.profile.profile.id,
    isMobile: state.browser.lessThan.mobile,
  }
}

const mapDispatchToProps = (dispatch) => ({
  setGeo: (lat, lng) => dispatch(setGeo(lat, lng)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(SortContainer))
