import React from "react"
import styled from "styled-components"
import moment from "moment-timezone"
import PropTypes from "prop-types"

import { useTranslation } from "react-i18next"
import { RoundImage } from "../../components/images/RoundImage"
import CompanyAddressBuilder from "../../components/address/CompanyAddressBuilder"
import { ListTopHeading } from "../../components/datarenderers/ListTopHeading"
import { thumborize } from "../../components/images/thumborize"
import buttonize from "../../components/buttonize"
import Pricing from "../../components/Pricing"
import ExtraTag from "../../components/tags/ExtraTag"
import CampaignTag from "../../components/tags/CampaignTag"
import { RenderStarRatings } from "../InventoryView/components"
import GoToButton from "../../components/button/GoToButton"

const StyledTimeSpan = styled.span`
  font-weight: 600;
  margin: 0px;
  font-size: 15px;
  color: ${({ theme }) => theme.colors.primary};
`

const WrapperDiv = styled.div`
  display: flex;
  margin: 0px 8px;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px solid #ccc;
  max-width: 100%;
  position: relative;
`

const BuyButton = styled(GoToButton)`
  a {
    display: block;
  }
`

function ServiceImage({ company, images, width, category }) {
  if (
    SITE_CONFIG.INSTANT_BOOK_IMAGE_SELECTOR === "logo" &&
    company.images &&
    company.images.find((i) => i.placement === "logo")
  ) {
    return (
      <RoundImage
        src={thumborize(
          company.images.find((i) => i.placement === "logo").url,
          width * 4,
          width * 4
        )}
        size={width}
      />
    )
  }
  // TODO: add this to a global selector.
  const listImages = images.find(
    (i) => i.placement === "list" && i.position === "0"
  )
  const catImages = category.images.find(
    (i) => i.placement === "list" && i.position === "0"
  )
  let showListType = listImages
  if (!listImages) showListType = catImages

  if (
    SITE_CONFIG.INSTANT_BOOK_IMAGE_SELECTOR === "list" &&
    images &&
    showListType
  ) {
    return (
      <RoundImage
        src={thumborize(showListType.url, width * 4, width * 4)}
        size={width}
      />
    )
  }
  return null
}

ServiceImage.propTypes = {
  company: PropTypes.object,
  images: PropTypes.array,
  width: PropTypes.number,
  category: PropTypes.object,
}

function InstantBookService({
  aLink,
  date: dateFromProps,
  isMobile,
  clickAction,
  rating,
  ratingCount,
  service,
  showRating,
  userIsVip,
  onClickRating,
}) {
  const { t } = useTranslation()

  const {
    availabilities = [],
    campaigns,
    company,
    currency,
    date: dateFromService,
    description,
    has_extras,
    images,
    pricing,
  } = service

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <div {...buttonize(clickAction, {}, "link")}>
      <WrapperDiv
        style={{
          margin: "auto",
          padding: "10px",
          paddingTop: isMobile ? 10 : 15,
          paddingBottom: isMobile ? 10 : 15,
        }}
      >
        <ServiceImage
          company={company}
          category={description.category}
          images={images}
          width={isMobile ? 64 : 110}
        />

        <div
          style={{
            flex: 5,
            marginLeft: isMobile ? 15 : 20,
            fontSize: 16,
            position: "relative",
          }}
        >
          <div style={{ display: "flex" }}>
            <div style={{ flex: 3 }}>
              <ListTopHeading style={{ fontSize: isMobile ? 15 : 17 }}>
                {description.name}
              </ListTopHeading>
              <div style={{ margin: "4px 0px" }}>
                <div
                  style={{
                    fontWeight: 400,
                    fontSize: isMobile ? 14 : 16,
                    padding: 0,
                  }}
                >
                  <div>{company.name}</div>
                  <CompanyAddressBuilder
                    company={company}
                    district
                    region
                    inline
                  />
                </div>
              </div>

              {(has_extras || campaigns) && (
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginBottom: -4,
                  }}
                >
                  {has_extras && <ExtraTag />}
                  {campaigns &&
                    campaigns.map((c) => (
                      <CampaignTag key={`campaign-${c.id}`} campaign={c} />
                    ))}
                </div>
              )}
            </div>
            <div
              style={{
                flex: 1,
                textAlign: "right",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                justifyContent: "space-between",
              }}
            >
              {pricing && pricing.default && (
                <Pricing
                  style={{ marginLeft: 4 }}
                  prices={pricing}
                  currency={currency}
                  isMobile={isMobile}
                  userIsVip={userIsVip}
                />
              )}
            </div>
          </div>
          <div
            style={{ display: "flex", marginTop: 12, alignItems: "flex-end" }}
          >
            <div style={{ flex: 1 }}>
              <div style={{ fontWeight: 600, padding: 0 }}>
                {!showRating &&
                  moment(dateFromProps || dateFromService).format(
                    "ddd MMMM Do YYYY"
                  )}
              </div>
              <div style={{ padding: 0 }}>
                {!showRating && !availabilities.length && (
                  <StyledTimeSpan isMobile={isMobile}>
                    Refresh Times?
                  </StyledTimeSpan>
                )}
                {!showRating &&
                  availabilities.length > 0 &&
                  availabilities.slice(0, 3).map((a) => (
                    <StyledTimeSpan
                      key={`availability-${a.id}`}
                      isMobile={isMobile}
                    >
                      {moment(a.time.start_time)
                        .tz(company.timezone)
                        .format("h:mma")}
                      {a !== availabilities.slice(0, 3).slice(-1)[0] && " | "}
                    </StyledTimeSpan>
                  ))}
                <div style={{ marginTop: "5px" }}>
                  {showRating && onClickRating && ratingCount && rating && (
                    <RenderStarRatings
                      clickStars={() => onClickRating()}
                      rating={rating}
                      ratingCount={ratingCount}
                    />
                  )}
                </div>
              </div>
            </div>
            <div>
              <BuyButton variant="primary" aLink={aLink}>
                {t("instant_book_select")}
              </BuyButton>
            </div>
          </div>
        </div>
      </WrapperDiv>
    </div>
  )
}

InstantBookService.propTypes = {
  aLink: PropTypes.string,
  clickAction: PropTypes.func.isRequired,
  date: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  onClickRating: PropTypes.func,
  rating: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  ratingCount: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  service: PropTypes.shape({
    availabilities: PropTypes.array,
    campaigns: PropTypes.array,
    category: PropTypes.object,
    company: PropTypes.object,
    currency: PropTypes.object.isRequired,
    date: PropTypes.string.isRequired,
    description: PropTypes.object.isRequired,
    has_extras: PropTypes.bool.isRequired,
    id: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
    pricing: PropTypes.object.isRequired,
  }).isRequired,
  showRating: PropTypes.bool,
  userIsVip: PropTypes.bool,
}

export default InstantBookService
