import React from "react"
import PropTypes from "prop-types"
import Map from "../map"

function CompaniesMap({
  companies,
  getPoints,
  height,
  mapNeedsPan,
  mapZoom,
  onClick,
  selectedServiceId,
  setMapZoom,
  setMapNeedsPan,
}) {
  const points = companies.map((company) => ({
    latitude: parseFloat(company.latitude),
    longitude: parseFloat(company.longitude),
  }))

  const markers = companies.map((c) => ({
    id: c.id,
    onClick: () => onClick(c),
    latitude: c.latitude,
    longitude: c.longitude,
    highlighted: !selectedServiceId || selectedServiceId === c.service.id,
    name: c.name,
  }))

  return (
    <Map
      companies={companies}
      getPoints={getPoints}
      height={height}
      mapNeedsPan={mapNeedsPan}
      mapZoom={mapZoom}
      markers={markers}
      onClick={onClick}
      points={points}
      setMapNeedsPan={setMapNeedsPan}
      setMapZoom={setMapZoom}
    />
  )
}

CompaniesMap.propTypes = {
  companies: PropTypes.array,
  getPoints: PropTypes.func,
  height: PropTypes.string,
  mapNeedsPan: PropTypes.bool,
  mapZoom: PropTypes.number,
  onClick: PropTypes.func,
  selectedServiceId: PropTypes.string,
  setMapNeedsPan: PropTypes.func,
  setMapZoom: PropTypes.func,
}

export default CompaniesMap
