import React from "react"
import PropTypes from "prop-types"

import { ThemedSelectButton, VARIANT } from "./ThemedButton"

/**
 * This is a select box but looks like a button.
 */
function SelectButton({ variant = VARIANT.PRIMARY, ...rest }) {
  return <ThemedSelectButton variant={variant} {...rest} />
}

SelectButton.propTypes = {
  variant: PropTypes.oneOf([
    VARIANT.PRIMARY,
    VARIANT.SECONDARY,
    VARIANT.TERTIARY,
  ]),
}

export default SelectButton
