import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { BackLink } from "../../components/nav/Links"
import { useNavigate } from "react-router-dom"

const Container = styled.div`
  position: relative;
`

const Header = ({ children }) => {
  const navigate = useNavigate()

  return (
    <Container>
      <BackLink
        style={{ position: "absolute", top: "calc(50% - 15px)", left: 0 }}
        href="/"
        onClick={(e) => {
          e.preventDefault()
          navigate(-1)
        }}
      />
      <div
        style={{ padding: "0px 40px", display: "flex", alignItems: "center" }}
      >
        {children}
      </div>
    </Container>
  )
}

Header.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Header
