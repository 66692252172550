import { thumborize, thumborizeFitIn } from "./thumborize"

export const getServiceImage = (
  images,
  description,
  paramThumborizeOptions = {}
) => {
  // Type is disabled/fitin/resize
  const thumborizeOptions = {
    type: "disabled",
    width: 800,
    height: 800,
    ...paramThumborizeOptions,
  }

  let image = images.find(
    (i) => i.placement === "gallery" && i.position === "0"
  )
  if (!image) {
    image = description.images.find(
      (i) => i.placement === "gallery" && i.position === "0"
    )
  }

  if (!image) {
    return null
  }

  const encodedUrl = encodeURIComponent(image.url)

  if (thumborizeOptions.type === "fitin") {
    return thumborizeFitIn(
      encodedUrl,
      thumborizeOptions.width,
      thumborizeOptions.height
    )
  }
  if (thumborizeOptions.type === "resize") {
    return thumborize(
      encodedUrl,
      thumborizeOptions.width,
      thumborizeOptions.height
    )
  }

  return encodedUrl
}
