import React, { useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"
import { browserIsMobile } from "../../redux/browserSelector"
import {
  clickedFacebookLogin,
  submitFacebookLogin,
  submitLogin,
} from "./actions"
import { GoToButton } from "../../components/button"
import {
  PasswordInput,
  SubmitInput,
  TextInput,
} from "../../components/forms/Inputs"
import ErrorBar from "../../components/ErrorBar"
import { SpanLinkFacebook, StyledLink } from "../../components/nav/Links"
import { FacebookIcon } from "../../components/icons"
import { getLastError, hasError } from "./selectors"

const { FACEBOOK_ENABLED, GOOGLE_RECAPTCHA_SITE_ID } = SITE_CONFIG

function LoginForm({
  hasError,
  isMobile,
  loginError,
  noWrap,
  setFbLoggingIn,
  submitLogin,
  submitFacebookLogin,
  toggleRegistrationHandler = null,
}) {
  const [googleRecaptchaToken, setGoogleRecaptchaToken] = useState(false)
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")

  const doSubmitLogin = (e) => {
    e.preventDefault()

    submitLogin(email, password, googleRecaptchaToken, noWrap)
  }

  const fbComponentClicked = () => {
    setFbLoggingIn(true)
    FB.login((response) => {
      if (response.authResponse) {
        submitFacebookLogin(
          response.authResponse.accessToken,
          googleRecaptchaToken,
          noWrap,
          false
        )
      } else {
        setFbLoggingIn(false)
      }
    })
  }

  const handleReCaptchaVerify = (token) => {
    setGoogleRecaptchaToken(token)
  }

  return (
    <form onSubmit={doSubmitLogin}>
      {GOOGLE_RECAPTCHA_SITE_ID && (
        <GoogleReCaptcha onVerify={handleReCaptchaVerify} />
      )}
      <div
        style={{
          marginLeft: "auto",
          flex: 1,
          marginRight: "auto",
          maxWidth: "600px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: isMobile ? "column-reverse" : "row",
            textAlign: isMobile ? "center" : "left",
            marginBottom: "20px",
          }}
        >
          <span
            style={{
              flex: 1,
              fontWeight: 700,
              fontSize: "22px",
              margin: 0,
              marginTop: isMobile ? "20px" : "0px",
            }}
          >
            Log In
          </span>
          <GoToButton
            variant="secondary"
            style={{ marginBottom: isMobile ? "20px" : "0" }}
            aLink="/register"
            onClick={toggleRegistrationHandler}
          >
            Need an account? Sign up here
          </GoToButton>
        </div>
        {hasError && <ErrorBar message={loginError} />}
        <div style={{ marginBottom: 14, display: "flex" }}>
          <TextInput
            onChange={(e) => {
              e.preventDefault()
              setEmail(e.target.value)
            }}
            value={email}
            label="Email Address*"
            style={{ flexGrow: 1 }}
          />
        </div>
        <div style={{ marginBottom: 14, display: "flex" }}>
          <PasswordInput
            // onKeyPress={this.passwordKeyPress}
            onChange={(e) => {
              e.preventDefault()
              setPassword(e.target.value)
            }}
            value={password}
            label="Password*"
            style={{ flexGrow: 1 }}
          />
        </div>
        <div
          style={{
            marginTop: 20,
            display: "flex",
            alignItems: "center",
            flexDirection: isMobile ? "column-reverse" : "row",
          }}
        >
          {FACEBOOK_ENABLED && (
            <>
              <SpanLinkFacebook onClick={fbComponentClicked}>
                <FacebookIcon variant="secondary" fill="#FFF" />
                Log In with Facebook
              </SpanLinkFacebook>

              {/* If facebook login is enabled, push both buttons to the right */}
              <div style={{ flex: 1 }} />
            </>
          )}

          <StyledLink
            style={{ margin: "0px 10px 0px 0px" }}
            to="/forgot-password"
          >
            Forgot Password?
          </StyledLink>

          {/* If facebook login is disabled, push both buttons apart */}
          {!FACEBOOK_ENABLED && !isMobile && <div style={{ flex: 1 }} />}

          <SubmitInput variant="primary" value="Log In" />
        </div>
      </div>
    </form>
  )
}

LoginForm.propTypes = {
  hasError: PropTypes.bool,
  submitLogin: PropTypes.func.isRequired,
  submitFacebookLogin: PropTypes.func.isRequired,
  loginError: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  noWrap: PropTypes.bool,
  setFbLoggingIn: PropTypes.func.isRequired,
  toggleRegistrationHandler: PropTypes.func,
}

function mapStateToProps(state) {
  return {
    isMobile: browserIsMobile(state),
    hasError: hasError(state),
    loginError: getLastError(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  submitLogin: (email, password, googleRecaptchaToken, noRedirect) =>
    dispatch(submitLogin(email, password, googleRecaptchaToken, noRedirect)),
  submitFacebookLogin: (token, googleRecaptchaToken, noWrap, newsletter) =>
    dispatch(
      submitFacebookLogin(token, googleRecaptchaToken, noWrap, newsletter)
    ),
  clickedFacebookLogin: () => dispatch(clickedFacebookLogin()),
})

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm)
