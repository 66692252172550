import React, { useContext, useEffect, useState } from "react"

import PropTypes from "prop-types"
import moment from "moment-timezone"

import { connect } from "react-redux"
import { useQuery } from "@tanstack/react-query"
import buttonize from "../../../components/buttonize"
import {
  DATE_TO_STRING_FORMAT,
  dayIsAvailable,
  getDaysForWeekOfDate,
  getPreviousWeekDate,
} from "../../../../utils/momentHelpers"
import {
  CalendarIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "../../../components/icons"
import { WrapperDiv } from "./styledComponents"
import CalendarContainer from "../../InventoryView/CalendarContainer"
import DaySelect from "./DaySelect"
import { buildAvailabilitiesScrapeQueryOptions } from "../../InventoryView/lib"
import { useCalendarBookingModuleContext } from "../CalendarBookingModuleContext"
import { isProfileVip } from "../../Profile/selectors"
import { inventoryItemScraped } from "../../Analytics/actions"

function DateAndTimeSelector({ children, dispatchInventoryItemScraped }) {
  const [showServiceCalendar, setShowServiceCalendar] = useState(false)

  const {
    date,
    employee,
    inventoryItem,
    onChange,
    setCalendarBodyLoadingMessage,
    scrapeToken,
  } = useCalendarBookingModuleContext()

  const { slug, company } = inventoryItem

  /* There is always a next week */
  const nextWeekDate = moment(date, DATE_TO_STRING_FORMAT).add(1, "week")
  const clickNextWeek = () => clickDay(nextWeekDate)

  const lastWeekDate = getPreviousWeekDate(company, date)
  const clickLastWeek = lastWeekDate ? () => clickDay(lastWeekDate) : null

  const {
    refetch: forceScrapeAvailabilities,
    isFetching: forceScrapeDataIsFetching,
  } = useQuery(
    buildAvailabilitiesScrapeQueryOptions({
      item: inventoryItem,
      date,
      scrapeToken,
      employeeId: employee?.id,
      force: true,
      isEnabled: false,
      onQueryComplete: () => dispatchInventoryItemScraped(inventoryItem, date),
    })
  )

  useEffect(() => {
    if (forceScrapeDataIsFetching) {
      setCalendarBodyLoadingMessage("Connecting")
    }
  }, [forceScrapeDataIsFetching])

  const clickDay = async (day) => {
    // nav to the correct service by id and date
    if (day.format(DATE_TO_STRING_FORMAT) === date) {
      /* If the user clicks the current date again, force a rescrape of inventory */
      // await scrapeAvailability({}, id, date, scrapeToken, employee?.id, true)
      setCalendarBodyLoadingMessage("Finding availabilities...")
      forceScrapeAvailabilities()
    } else {
      /* Default behaviour is to change to the new date then get existing inventory from API and local Flossie DB if possible */
      onChange({
        serviceSlug: slug,
        date: day.format(DATE_TO_STRING_FORMAT),
      })
    }
  }

  return (
    <>
      <WrapperDiv>
        <div
          style={{
            height: 50,
            display: "flex",
            alignItems: "center",
            fontSize: 16,
            fontWeight: 300,
            padding: "10px 0px 0px 0px",
          }}
        >
          {clickLastWeek && (
            <div
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...buttonize(clickLastWeek, { flex: 1, padding: 10 })}
            >
              <ChevronLeftIcon />
            </div>
          )}
          {!clickLastWeek && <div style={{ flex: 1, padding: 10 }} />}
          <div
            role="button"
            tabIndex={0}
            style={{
              flex: 3,
              padding: 10,
              fontWeight: 700,
              textAlign: "center",
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            onClick={() => setShowServiceCalendar(true)}
            onKeyDown={() => setShowServiceCalendar(true)}
          >
            <div style={{ display: "flex", alignItems: "center" }}>
              <CalendarIcon style={{ height: "20px" }} />
              <span style={{ paddingLeft: "5px" }}>
                {moment(date).format("MMMM YYYY")}
              </span>
            </div>
          </div>
          <div
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...buttonize(clickNextWeek, {
              flex: 1,
              padding: 10,
              textAlign: "right",
            })}
          >
            <ChevronRightIcon />
          </div>
        </div>
        <div
          style={{
            height: 60,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            padding: 3,
            borderBottom: "1px solid #ccc",
          }}
        >
          {getDaysForWeekOfDate(date).map((day) => (
            <DaySelect
              key={day.toString()}
              disabled={!dayIsAvailable(company, day)}
              day={day}
              selected={day.format(DATE_TO_STRING_FORMAT) === date}
              onClick={(day) => clickDay(day)}
            />
          ))}
        </div>
        {children}
      </WrapperDiv>
      {showServiceCalendar && (
        <CalendarContainer
          date={date}
          inventoryItem={inventoryItem}
          onClose={() => setShowServiceCalendar(false)}
          clickDay={(day) => {
            clickDay(day)
            setShowServiceCalendar(false)
          }}
        />
      )}
    </>
  )
}

DateAndTimeSelector.propTypes = {
  children: PropTypes.node.isRequired,
  dispatchInventoryItemScraped: PropTypes.func.isRequired,
  isVip: PropTypes.bool,
}

const mapStateToProps = (state) => {
  return {
    isVip: isProfileVip(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  dispatchInventoryItemScraped: (inventoryItem, date) =>
    dispatch(inventoryItemScraped(inventoryItem, date)),
})

export default connect(mapStateToProps, mapDispatchToProps)(DateAndTimeSelector)
