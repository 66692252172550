import React, { useEffect } from "react"
import PropTypes from "prop-types"

import { loadStripe } from "@stripe/stripe-js/pure"
import { Elements } from "@stripe/react-stripe-js"

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import { FranchiseProvider } from "./providers/franchise"

import RouterProvider from "./providers/router"

const { STRIPE_API_KEY, STRIPE_ENABLED, GOOGLE_RECAPTCHA_SITE_ID } = SITE_CONFIG

function App() {
  useEffect(() => {
    // Global function defined in html-templates/includes/animation.html specifically
    // to clear the initial loading animation on initial app render

    // eslint-disable-next-line no-undef
    clearAnimation()
  }, [])

  const renderCommon = () => {
    return (
      <FranchiseProvider>
        <RouterProvider />
      </FranchiseProvider>
    )
  }

  const renderStripe = (children) => {
    /* Stripe is brutally slow, so only enable and include on an explicit per build basis */
    if (STRIPE_ENABLED) {
      const stripePromise = loadStripe(STRIPE_API_KEY)

      return <Elements stripe={stripePromise}>{children}</Elements>
    }

    return children
  }

  const renderRecaptcha = (children) => {
    if (GOOGLE_RECAPTCHA_SITE_ID) {
      return (
        <GoogleReCaptchaProvider reCaptchaKey={GOOGLE_RECAPTCHA_SITE_ID}>
          {children}
        </GoogleReCaptchaProvider>
      )
    }

    return children
  }

  return renderStripe(renderRecaptcha(renderCommon()))
}

export default App
