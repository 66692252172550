import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"
import buttonize from "../../../components/buttonize"

import { PlusIcon, CloseIcon } from "../../../components/icons"

const LabelContainer = styled.span`
  display: flex;
  align-items: center;
  background-color: #fff;
  margin: 10px;
  max-width: 350px;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: ${({ theme }) => theme.button.borderRadius};
`

const Label = styled.span`
  color: ${({ theme }) => theme.colors.primary};
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 600;
  padding: 5px;
`

const TagSpan = styled.div`
  flex: 3;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: #000;
`

const renderTag = (t, closeAction) => (
  <TagSpan key={`tag${t.id}`}>
    <div style={{ flex: 5 }}>{t.name}</div>
    <div
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonize(closeAction, {
        flex: 1,
        textAlign: "right",
        cursor: "pointer",
      })}
    >
      <CloseIcon style={{ height: 20 }} />
    </div>
  </TagSpan>
)

const renderPlus = () => (
  <TagSpan key="tag-plus">
    <div style={{ flex: 5 }} />
    <div style={{ flex: 1, textAlign: "right", cursor: "pointer" }}>
      <PlusIcon />
    </div>
  </TagSpan>
)

function WhatWhenWhereInput({ label, clickAction, tags, onClear, plural }) {
  const renderTags = (tags, plural) => {
    if (!tags || tags.length === 0) return <>{renderPlus()}</>
    if (tags.length === 1) {
      return <>{tags.map((eachTag) => renderTag(eachTag, onClear))}</>
    }
    // if (tags.length > 1 && tags.length < 5 && !props.isMobile) return tags.map(t => renderTag(t, () => closeAction(t.originalId)))
    return (
      <>
        {renderTag(
          { id: 0, name: `${tags.length} ${plural} Selected` },
          onClear
        )}
      </>
    )
  }

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <LabelContainer {...buttonize(clickAction, {}, "link")}>
      <Label>{label}?</Label>
      {renderTags(tags, plural)}
    </LabelContainer>
  )
}

WhatWhenWhereInput.propTypes = {
  label: PropTypes.string.isRequired,
  clickAction: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired,
  onClear: PropTypes.func.isRequired,
  plural: PropTypes.string.isRequired,
}

export default WhatWhenWhereInput
