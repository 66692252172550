import React from "react"
import PropTypes from "prop-types"
import { PrismicRichText } from "@prismicio/react"

import { useNavigate, useParams } from "react-router-dom"
import ContainerWithSideBar from "./ContainerWithSideBar"
import SearchInput from "./SearchInput"
import Header from "./Header"
import FaqsContentHoc from "./FaqsContentHoc"
import { TopicLink } from "./FaqLink"

function Answer({ faqTopics, isMobile }) {
  const navigate = useNavigate()
  const params = useParams()

  const faqSlug = params.faq

  if (!faqSlug) {
    navigate("/help")
  }

  let faqTopic = false
  let faqData = false

  for (const topic in faqTopics) {
    const eachTopic = faqTopics[topic]

    const findFaq = eachTopic.faqs.find((eachFaq) => eachFaq.slug === faqSlug)

    if (findFaq) {
      faqTopic = eachTopic
      faqData = findFaq
      break
    }
  }

  if (!faqData) {
    navigate("/404-page-not-found")
    return null
  }

  const showEmbedVideo = (faq) => {
    return faq.data.video ? (
      <div
        style={{ width: "100%" }}
        dangerouslySetInnerHTML={{ html: faq.data.video.html }}
      />
    ) : null
  }

  return (
    <div>
      <Header>
        <TopicLink size="h3" topic={faqTopic} suffix=" FAQs" />
      </Header>
      <SearchInput />
      <ContainerWithSideBar isMobile={isMobile} topics={faqTopics}>
        <div style={{ flex: 1 }}>
          <h1>{faqData.data.question}</h1>
          <PrismicRichText field={faqData.data.answer} />
          {showEmbedVideo(faqData)}
        </div>
      </ContainerWithSideBar>
    </div>
  )
}

Answer.propTypes = {
  faqTopics: PropTypes.array.isRequired,
  isMobile: PropTypes.bool,
}

export default FaqsContentHoc(Answer)
