import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import CompanyButtons from "./CompanyButtons"
import HighlightedBorderArea from "../../../components/sections/HighlightedBorderArea"
import { browserIsMobile } from "../../../redux/browserSelector"
import { CompanyGallery } from "../../../components/images/CompanyGallery"
import CompanyAddressBuilder from "../../../components/address/CompanyAddressBuilder"
import SocialMediaLinks from "../../Companies/SocialMediaLinks"
import { Phone } from "../../../components/datarenderers"
import Seperator from "../../../components/Seperator"
import InfoAccordion from "./InfoAccordion"

// We're using company data via a prop here because at this point we had to re-fetch the company specifically
// to get the social media links and phone number. In a future fix we should just add those data points to the
// campaign company data to avoid having to re-fetch, and to avoid having two company data objects floating around
function Company({ companyData, campaignCompanies, isMobile }) {
  return (
    <>
      <CompanyButtons campaignCompanies={campaignCompanies} />

      <HighlightedBorderArea
        style={{ marginBottom: isMobile ? 20 : 0 }}
        showBorder={isMobile}
      >
        <div
          style={{
            height: "100%",
            display: isMobile ? "block" : "grid",
            gridGap: "20px",
            gridTemplateColumns: !isMobile && "repeat(2, calc(50% - 10px))",
          }}
        >
          <CompanyGallery company={companyData} />

          <div style={{ padding: isMobile ? 10 : 0 }}>
            <div style={{ margin: "15px 0" }}>
              <CompanyAddressBuilder
                company={companyData}
                addressOne
                addressTwo
                district
                region
                postCode
                inline
                showIcon
              />
              {companyData?.phone_number && (
                <div style={{ marginTop: "4px" }}>
                  <Phone number={companyData.phone_number} />
                </div>
              )}
              <SocialMediaLinks company={companyData} isMobile={isMobile} />
            </div>

            <Seperator style={{ marginTop: 25 }} />
            <InfoAccordion
              content={companyData.description}
              isOpen
              title="About This Salon"
              tabName="love-salon"
            />
            <Seperator />
          </div>
        </div>
      </HighlightedBorderArea>

      {isMobile && <CompanyButtons campaignCompanies={campaignCompanies} />}
    </>
  )
}

Company.propTypes = {
  campaignCompanies: PropTypes.array.isRequired,
  companyData: PropTypes.shape({
    phone_number: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  isMobile: browserIsMobile(state),
})

export default connect(mapStateToProps)(Company)
