import React, { useEffect } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styled from "styled-components"

import { useNavigate } from "react-router-dom"
import * as faqsSelectors from "./selectors"
import { loadAllFaqs, searchFaqs } from "./actions"
import WrapContainer from "../WrapContainer"
import { setDefaultMetaData, setMetaData } from "../../../utils/metaData"

const ParentDiv = styled.div`
  max-width: 980px;
  margin-left: ${(props) => (props.isLarge ? "auto" : "20px")};
  margin-right: ${(props) => (props.isLarge ? "auto" : "20px")};
  margin-bottom: 80px;
  position: relative;
  padding: 0;
  div > h1 + div {
    text-align: center;
  }
  a {
    cursor: pointer;
  }
  img,
  iframe {
    display: block;
    max-width: 100%;
    margin: 0 auto;
  }
  iframe {
  }

  h1,
  h2,
  h3,
  h4 {
    margin: 20px auto;
  }
`

const FaqsContentHoc = (InnerComponent) => {
  function FaqsContentWrapper({
    faqTopics,
    children,
    loadAllFaqs,
    isLarge,
    ...rest
  }) {
    const navigate = useNavigate()

    useEffect(() => {
      loadAllFaqs()
      window.scrollTo(0, 0)
    }, [])

    const onNavigateTo = (slug) => {
      navigate(slug)
    }

    useEffect(() => {
      setMetaData({
        metaTitle: "Flossie FAQ's",
        metaDescription: "Flossie FAQs",
      })
      return () => setDefaultMetaData()
    })

    if (!faqTopics) return null

    return (
      <WrapContainer loginRequired={false}>
        <ParentDiv isLarge={isLarge}>
          <InnerComponent
            {...rest}
            faqTopics={faqTopics}
            navigateTo={onNavigateTo}
          >
            {children}
          </InnerComponent>
        </ParentDiv>
      </WrapContainer>
    )
  }
  FaqsContentWrapper.propTypes = {
    children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
    faqTopics: PropTypes.array,
    loadAllFaqs: PropTypes.func.isRequired,
    isLarge: PropTypes.bool.isRequired,
  }

  const mapStateToProps = (state) => ({
    faqTopics: faqsSelectors.getFaqsTopic(state),
    searchResults: faqsSelectors.getSearchResults(state),
    isLarge: state.browser.greaterThan.large,
  })

  const mapDispatchToProps = (dispatch) => ({
    loadAllFaqs: () => dispatch(loadAllFaqs()),
    searchFaqs: (query) => dispatch(searchFaqs(query)),
  })

  return connect(mapStateToProps, mapDispatchToProps)(FaqsContentWrapper)
}

export default FaqsContentHoc
