import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useCalendarBookingModuleContext } from "./CalendarBookingModuleContext"
import { ClickableLabel } from "../../components/ClickableLabel"

const EmployeeLinkStyle = styled(ClickableLabel)`
  padding: 10px;
  text-decoration: none;
`

function EmployeeLink({ employeeSlug, children }) {
  const { onChange, setEmployeeSelectOpen } = useCalendarBookingModuleContext()

  const closeEmployeeSelect = () => setEmployeeSelectOpen(false)

  return (
    <EmployeeLinkStyle
      white="white"
      onClick={() => {
        onChange({ employeeSlug })
        closeEmployeeSelect()
      }}
    >
      {children}
    </EmployeeLinkStyle>
  )
}

EmployeeLink.propTypes = {
  employeeSlug: PropTypes.string,
  children: PropTypes.node,
}

export default EmployeeLink
