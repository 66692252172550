import styled, { css } from "styled-components"
import PlusIcon from "../icons/plus.svg"
import ArrowDownIcon from "../icons/chevron-down.svg"

export const iconStyle = css`
  display: block;
  height: 20px;
  width: 20px;
  margin-top: 11px;
  fill: ${({ theme }) => theme.colors.primary};
`

export const ThemedPlusIcon = styled(PlusIcon)`
  ${iconStyle}
`
export const ThemedArrowDownIcon = styled(ArrowDownIcon)`
  ${iconStyle}
`

export const CompanyDiv = styled.div`
  min-height: 42px;
  margin: 0px;

  font-size: 14px;

  font-weight: 300;
  color: #000;
  display: flex;
  padding: 12px 14px;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightHover};
  }
`

export const CompanyName = styled.p`
  font-size: 14px;
  color: #000;
  font-weight: 500;
  margin: 0;
`

export const CountryDiv = styled.div`
  margin: 0px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  text-align: left;
  border-top: 1px solid #ccc;
  height: 42px;
  padding: 0px 14px;

  &:last-child {
    border-bottom: 1px solid #ccc;
    margin-bottom: 20px;
  }
`

export const RegionDiv = styled.div`
  margin: 0px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  text-align: left;
  border-top: 1px dashed #ccc;
  height: 42px;
  padding: 0px 14px;
`

export const CompanyRow = styled.div`
  display: ${(props) => (props.isMobile ? "block" : "flex")};
  flex: 1;
  margin-left: 10px;
  justify-content: space-between;
`
