import { apiGetUserOrder, handleXhrError } from "../../api/api"
import { FETCH_ORDER_ACTION } from "./constants"

const fetchOrderFulfilled = (response) => ({
  type: "FETCH_ORDER_SUCCESS",
  payload: { response }
})

export const fetchOrderEpic = (action$, { getState }) => action$.ofType(FETCH_ORDER_ACTION).mergeMap(action => apiGetUserOrder(getState().credentials.credentials, action.payload.id)
  .map(response => fetchOrderFulfilled(response, action))
  .catch(error => handleXhrError(error, "FETCH_ORDER_ERROR")))
