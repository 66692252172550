import { combineReducers } from "redux"

import { createResponsiveStateReducer } from "redux-responsive"
import { loadingBarReducer as loadingBar } from "react-redux-loading-bar"

import login from "../containers/Login/reducer"
import resetpassword from "../containers/ResetPassword/reducer"
import registration from "../containers/Registration/reducer"
import profile from "../containers/Profile/reducer"
import bookings from "../containers/MyBookings/reducer"
import instantbook from "../containers/InstantBook/reducer"
import inventoryFilter from "../containers/InventoryFilter/reducer"
import inventoryView from "../containers/InventoryView/reducer"
import campaignView from "../containers/InventoryView/campaignReducer"
import cart from "../containers/Cart/reducer"
import purchase from "../containers/Purchase/reducer"
import employees from "../containers/Employees/reducer"
import company from "../containers/Companies/reducer"
import navBar from "../containers/NavBar/reducer"
import credentials from "./credentialsReducer"
import favourites from "../containers/Favourites/reducer"
import faqs from "../containers/Faqs/reducers"
import terms from "../containers/Terms/reducers"

const browser = createResponsiveStateReducer({
  mobile: 767,
  tablet: 768,
  desktop: 2560,

  // TODO: migrate all breakpoints above to redux-responsive defaults below
  extraSmall: 480,
  small: 768,
  medium: 992,
  large: 1200,
})

const createRootReducer = () =>
  combineReducers({
    login,
    resetpassword,
    registration,
    credentials,
    bookings,
    favourites,
    profile,
    instantbook,
    inventoryFilter,
    inventoryView,
    campaignView,
    purchase,
    cart,
    employees,
    company,
    navBar,
    // plugins below
    browser,
    loadingBar,
    faqs,
    terms,
  })

export default createRootReducer
