import React from "react"
import PropTypes from "prop-types"
import Carousel from "react-multi-carousel"
import styled, { createGlobalStyle } from "styled-components"

import ArrowIcon from "../icons/chevron-right.svg"
import "react-multi-carousel/lib/styles.css"

const CarouselStyles = createGlobalStyle`
  .small-carousel {
    margin-top: 15px;
    padding-bottom: 40px;
    justify-content: ${(props) => (props.showControls ? "left" : "center")};
  }
  .flossie-dots {
    width: 80%;
    height: 8%;
    top: 5px;
    left: 10%;
    align-items: center;
  }
  .flossie-dots .react-multi-carousel-dot.react-multi-carousel-dot--active button {
    background: ${(props) => props.theme.colors.primary};
  }
  .flossie-dots .react-multi-carousel-dot {
    padding: 0px 2px;
  }
  .flossie-dots .react-multi-carousel-dot button {
    border: none;
    width: 10px;
    height: 10px;
    background: ${(props) => props.theme.colors.inactive};
    display: block;
  }
`

const CarouselArrows = styled.div`
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  top: 0px;
  margin-bottom: 15px;
  .right-button,
  .left-button {
    outline: 0;
    opacity: 0.8;
    background: none;
    border: none;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
    svg {
      fill: ${(props) => props.theme.colors.primary};
      height: 50px;
      path {
        fill: ${(props) => props.theme.colors.primary};
      }
    }
  }

  .left-button,
  .left-button.disabled {
    svg {
      transform: rotate(180deg);
    }
  }

  .disabled {
    cursor: not-allowed;
    &:hover {
      opacity: 0.8;
    }
  }
`

// Refer to redux-responsive configuration in redus/rootReducers.js for values
const getResponsiveConfig = () => {
  return {
    desktop: {
      breakpoint: { max: 3500, min: 1024 },
      items: 4,
      // we can also display 3.5 items with the last one showing just half.
      // items: 3.5,
      // partialVisibilityGutter: 50,
    },
    tablet: {
      breakpoint: { max: 768, min: 480 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 480, min: 0 },
      items: 2,
    },
  }
}

function ButtonGroup({ next, previous, goToSlide, isMobile, ...rest }) {
  const {
    carouselState: { currentSlide },
  } = rest
  return (
    <CarouselArrows isMobile={isMobile}>
      <button
        type="button"
        className={`left-button ${currentSlide === 0 && "disabled"}`}
        disabled={currentSlide === 0}
        onClick={() => previous()}
      >
        <ArrowIcon />
      </button>
      <button type="button" className="right-button" onClick={() => next()}>
        <ArrowIcon />
      </button>
    </CarouselArrows>
  )
}

function SimpleCarousel({ children, isMobile }) {
  const showControls =
    (isMobile && children.length > 2) || (!isMobile && children.length > 3)

  return (
    <div
      style={{
        position: "relative",
        paddingTop: !showControls ? 0 : 50,
        marginTop: showControls ? "-12px" : 0,
      }}
    >
      <CarouselStyles showControls={showControls} isMobile={isMobile} />
      <Carousel
        ssr
        responsive={getResponsiveConfig()}
        showDots={showControls}
        renderDotsOutside
        arrows={false}
        dotListClass="flossie-dots"
        containerClass="small-carousel"
        renderButtonGroupOutside
        customButtonGroup={showControls && <ButtonGroup isMobile={isMobile} />}
        // itemClass="carousel-item"
      >
        {children}
      </Carousel>
    </div>
  )
}

SimpleCarousel.propTypes = {
  children: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

export default SimpleCarousel
