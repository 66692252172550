import PropTypes from "prop-types"
import React, { useContext, useState, useEffect } from "react"
import styled from "styled-components"
import { useQuery } from "@tanstack/react-query"
import { EMPLOYEE_ID_ANY } from "../../InventoryView/constants"
import { SearchIcon } from "../../../components/icons"
import { FindBestPriceText } from "./styledComponents"
import { useCalendarBookingModuleContext } from "../CalendarBookingModuleContext"
import { buildFindBestPriceQueryOptions } from "../../InventoryView/lib"

const FindBestPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 16px;
  svg {
    margin-right: 4px;
  }
`

function FindBestPrice({ findBestPriceError }) {
  const [searchStrategy, setSearchStrategy] = useState("next-available")

  const {
    availabilities,
    inventoryItem,
    employee,
    selectedAvailability,
    scrapeToken,
  } = useCalendarBookingModuleContext()

  const findBestPriceQuery = useQuery(
    buildFindBestPriceQueryOptions(
      inventoryItem,
      selectedAvailability,
      scrapeToken
    )
  )

  useEffect(() => {
    const strategy =
      !employee?.id || employee.id === EMPLOYEE_ID_ANY
        ? "next-available"
        : "by-stylist"

    setSearchStrategy(strategy)
  }, [employee?.id])

  // we only show best price link if:
  const showFindBestPrice =
    selectedAvailability &&
    !findBestPriceError && // no error
    searchStrategy === "next-available" && // strategy is next available (meaning any stylyst)
    Array.isArray(availabilities) &&
    availabilities.length > 0 && // must have times to find best price
    inventoryItem.employees && // must have employees to find best price
    inventoryItem.employees.length > 0 &&
    inventoryItem.company.allow_lowest_price

  return (
    showFindBestPrice && (
      <FindBestPriceWrapper onClick={() => findBestPriceQuery.refetch()}>
        <SearchIcon />
        <FindBestPriceText>Find Best Price</FindBestPriceText>
      </FindBestPriceWrapper>
    )
  )
}

FindBestPrice.propTypes = {
  findBestPriceError: PropTypes.bool,
}

export default FindBestPrice
