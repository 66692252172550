import React, { useEffect } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { useParams } from "react-router-dom"

import { fetchCompany, clearCompany } from "./actions"

import { setDefaultMetaData, setMetaData } from "../../../utils/metaData"
import WrapContainer from "../WrapContainer"
import { Loading } from "../../components/nav/Loading"

import "react-image-gallery/styles/css/image-gallery.css" // TODO, no glbal css.
import Company from "./Company"

function CompanyViewContainer({
  clearCompany,
  company = null,
  fetchCompany,
  loadingCompany,
}) {
  const params = useParams()

  useEffect(() => {
    clearCompany()
    fetchCompany(params.id)
  }, [])

  useEffect(() => {
    if (!loadingCompany && company) {
      setMetaData({
        canonicalPath: (company && `/company/${company.slug}`) || null,
        metaTitle: (company && company.name) || null,
        metaDescription: (company && company.description) || null,
      })
    } else {
      setDefaultMetaData()
    }
    return () => setDefaultMetaData()
  }, [loadingCompany, company])

  return (
    <WrapContainer loginRequired={false} showNavBack navTitle="Company View">
      {company && <Company company={company} loadingCompany={loadingCompany} />}
      {loadingCompany && <Loading />}
    </WrapContainer>
  )
}

CompanyViewContainer.propTypes = {
  company: PropTypes.object,
  fetchCompany: PropTypes.func.isRequired,
  clearCompany: PropTypes.func.isRequired,
  loadingCompany: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    company: state.company.company,
    loadingCompany: state.company.loadingCompany,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCompany: (id) => dispatch(fetchCompany(id)),
  clearCompany: () => dispatch(clearCompany()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanyViewContainer)
