import React, { useEffect, useMemo, useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { TextInput } from "../../../components/forms/Inputs"

import Button from "../../../components/button"
import { browserIsMobile } from "../../../redux/browserSelector"
import List from "./List"
import SearchResults from "./SearchResults"
import { topCategories } from "../../Profile/selectors"

function CategoryContainer({
  campaigns: allCampaigns,
  categories,
  categoryGroups: allCategoryGroups,
  closeAction,
  isMobile,
  selectedCampaigns,
  selectedCategories,
  toggleCategory,
  toggleCampaign,
  topCategories: allTopCategories,
}) {
  const [query, setQuery] = useState("")

  const [selectedCategoryGroups, setSelectedCategoryGroups] = useState([])
  const [campaignsAreExpanded, setCampaignsAreExpanded] = useState(false)
  const [topCategoriesAreExpanded, setTopCategoriesAreExpanded] =
    useState(false)

  const campaigns = allCampaigns.map((eachCampaign) => ({
    ...eachCampaign,
    selected: Boolean(
      selectedCampaigns.find(
        (eachSelectedCampaign) => eachSelectedCampaign.id === eachCampaign.id
      )
    ),
  }))

  const topCategories = allTopCategories.map((eachTopCategory) => ({
    ...categories.find(
      (eachCategory) => eachCategory.id === eachTopCategory.id
    ),
    selected: Boolean(
      selectedCategories.find(
        (eachSelectedCategory) => eachSelectedCategory.id === eachTopCategory.id
      )
    ),
  }))

  // Decorate groups, and subgroups, with selected state and selected categories
  const decorateCategoryGroups = (groups) =>
    groups.map((eachGroup) => {
      const groupCategories = categories
        .filter((eachCategory) =>
          eachGroup.categories.includes(eachCategory.id)
        )
        .map((eachCategory) => ({
          ...eachCategory,
          selected: Boolean(
            selectedCategories.find(
              (eachSelectedCategory) =>
                eachSelectedCategory.id === eachCategory.id
            )
          ),
        }))
      const groupSubGroups = decorateCategoryGroups(eachGroup.groups ?? [])
      const decoratedGroup = {
        ...eachGroup,
        // Group is selected if it's been explicitly selected, if any of its categories are selected, or any of its subgroups are selected
        selected:
          Boolean(
            selectedCategoryGroups.find(
              (eachSelectedGroup) => eachSelectedGroup.id === eachGroup.id
            )
          ) ||
          Boolean(
            groupCategories.find((eachCategory) => eachCategory.selected)
          ) ||
          Boolean(groupSubGroups.find((eachSubGroup) => eachSubGroup.selected)),
        categories: groupCategories,
        groups: groupSubGroups,
      }

      return decoratedGroup
    })

  const categoryGroups = useMemo(
    () => decorateCategoryGroups(allCategoryGroups),
    [categories, allCategoryGroups, selectedCategoryGroups]
  )

  const toggleCategoryGroup = (categoryGroup) => {
    if (categoryGroup.selected) {
      setSelectedCategoryGroups(
        selectedCategoryGroups.filter(
          (eachGroup) => eachGroup.id !== categoryGroup.id
        )
      )
    } else {
      setSelectedCategoryGroups([...selectedCategoryGroups, categoryGroup])
    }
  }

  useEffect(() => {
    if (categoryGroups.length === 1) {
      // If there is only 1 category we open the dropdown by default
      if (!categoryGroups[0].selected) {
        // only open it on first render
        // is for allowing user to close it
        toggleCategoryGroup(categoryGroups[0])
      }
    }
  }, [])

  const clickClose = (e) => {
    e.preventDefault()
    closeAction()
  }

  const updateSearch = (e) => {
    e.preventDefault()
    setQuery(e.target.value)
  }

  const headerPortalHeight = "70px"
  const bottomConfirm = "20px"

  return (
    <div>
      <div style={{ height: headerPortalHeight }}>
        <div style={{ display: "flex" }}>
          <TextInput
            style={{ flex: 1 }}
            placeholder="Enter service name or category..."
            value={query}
            onChange={updateSearch}
          />
        </div>
      </div>
      <div
        style={{
          overflowY: "scroll",
          marginLeft: isMobile ? "-10px" : 0,
          marginRight: isMobile ? "-20px" : "-10px",
          maxHeight: isMobile
            ? `calc(100vh - 150px - ${headerPortalHeight} - ${bottomConfirm}`
            : `calc(100vh - 250px - ${headerPortalHeight} - ${bottomConfirm}`,
        }}
      >
        <div style={{ display: "flex" }}>
          <div style={{ flex: 1 }}>
            <div>
              {query === "" && (
                <List
                  campaigns={campaigns}
                  campaignsAreExpanded={campaignsAreExpanded}
                  categoryGroups={categoryGroups}
                  onClickCampaigns={() =>
                    setCampaignsAreExpanded(!campaignsAreExpanded)
                  }
                  onClickTopCategories={() =>
                    setTopCategoriesAreExpanded(!topCategoriesAreExpanded)
                  }
                  onClickCategoryGroup={toggleCategoryGroup}
                  topCategories={topCategories}
                  topCategoriesAreExpanded={topCategoriesAreExpanded}
                  toggleCampaign={toggleCampaign}
                  toggleCategory={toggleCategory}
                />
              )}
              {query !== "" && (
                <SearchResults
                  categories={categories}
                  onClickCategory={toggleCategory}
                  selectedCategories={selectedCategories}
                  query={query}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", justifyContent: "flex-end", marginTop: 10 }}
      >
        <Button onClick={clickClose}>Close</Button>
      </div>
    </div>
  )
}

CategoryContainer.propTypes = {
  categoryGroups: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  campaigns: PropTypes.array.isRequired,
  toggleCategory: PropTypes.func.isRequired,
  toggleCampaign: PropTypes.func.isRequired,
  closeAction: PropTypes.func.isRequired,
  selectedCampaigns: PropTypes.array.isRequired,
  selectedCategories: PropTypes.array.isRequired,
  topCategories: PropTypes.array.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    topCategories: topCategories(state),
    isMobile: browserIsMobile(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  toggleTopCategories: () => dispatch({ type: "TOGGLE_TOP_CATEGORIES" }),
  toggleCampaigns: () => dispatch({ type: "TOGGLE_CAMPAIGNS" }),
})

export default connect(mapStateToProps, mapDispatchToProps)(CategoryContainer)
