import PropTypes from "prop-types"
import ReactGA4 from "react-ga4"
import ReactPixel from "react-facebook-pixel"
import { useEffect } from "react"
import { connect } from "react-redux"
import { useLocation } from "react-router-dom"
import {
  isFacebookAnalyticsEnabled,
  isGoogleAnalyticsEnabled,
} from "../NavBar/selectors"
import { routerLocationChange } from "./actions"

function Analytics({
  children,
  dispatchLocationChange,
  isFacebookAnalyticsEnabled,
  isGoogleAnalyticsEnabled,
}) {
  useEffect(() => {
    if (isGoogleAnalyticsEnabled && !ReactGA4.isInitialized) {
      ReactGA4.initialize(process.env.GA4_SETTINGS)
    }
  }, [isGoogleAnalyticsEnabled])

  const location = useLocation()

  useEffect(() => {
    dispatchLocationChange(location)
  }, [location.pathname])

  useEffect(() => {
    if (isFacebookAnalyticsEnabled) {
      const advancedMatching = {} // optional, more info: https://developers.facebook.com/docs/facebook-pixel/advanced/advanced-matching
      const options = {
        autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
        debug: false, // enable logs
      }

      process.env.FACEBOOK_PIXEL_IDS.forEach((pixelId) => {
        ReactPixel.init(pixelId, advancedMatching, options)
      })
      ReactPixel.pageView()
    }
  }, [isFacebookAnalyticsEnabled])

  return children
}

Analytics.propTypes = {
  children: PropTypes.element,
  isFacebookAnalyticsEnabled: PropTypes.bool,
  isGoogleAnalyticsEnabled: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isFacebookAnalyticsEnabled: isFacebookAnalyticsEnabled(state),
  isGoogleAnalyticsEnabled: isGoogleAnalyticsEnabled(state),
})

const mapDispatchToProps = (dispatch) => ({
  dispatchLocationChange: (location) =>
    dispatch(routerLocationChange(location)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Analytics)
