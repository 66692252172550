import React from "react"
import PropTypes from "prop-types"
import CompanyAddressBuilder from "../../components/address/CompanyAddressBuilder"
import { CompanyLink } from "./components"
import { ArrowRightIcon } from "../../components/icons"
import { CompanyLogo } from "../../components/images/CompanyLogo"

function CompanyDetails({ company, isLarge = false }) {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        marginTop: isLarge ? 0 : 12,
        marginBottom: 20,
        padding: isLarge ? "0 14px" : "0",
      }}
    >
      <div style={{ flex: 1 }}>
        <h3
          style={{
            fontWeight: 500,
            fontSize: 20,
            margin: 0,
            padding: 0,
            marginBottom: -2,
          }}
        >
          {company.name}
        </h3>
        <div>
          <CompanyAddressBuilder company={company} addressOne district inline />
          <CompanyAddressBuilder company={company} postCode region inline />
        </div>
        <CompanyLink
          style={{
            fontSize: 14,
            marginTop: 8,
            display: "flex",
            alignItems: "center",
          }}
          to={`/company/${company.slug}`}
        >
          View salon information
          <ArrowRightIcon
            style={{
              marginLeft: "4px",
              height: 16,
              width: "auto",
              verticalAlign: "top",
            }}
          />
        </CompanyLink>
      </div>
      <CompanyLogo company={company} size={80} style={{ display: "block" }} />
    </div>
  )
}

CompanyDetails.propTypes = {
  company: PropTypes.object.isRequired,
  isLarge: PropTypes.bool,
}

export default CompanyDetails
