import "rxjs/add/operator/mergeMap"
import "rxjs/add/operator/map"
import "rxjs/add/operator/catch"
import { Observable } from "rxjs/Observable"

import { bookingCancelled, fetchBookingsFulfilled } from "./actions"
import {
  apiGetUserBookings,
  apiCancelUserBooking,
  handleXhrError,
} from "../../api/api"
import router from "../../config/router"
import { FETCH_BOOKINGS_ACTION, FETCH_BOOKINGS_ERROR_ACTION } from "./constants"

export const fetchBookingsEpic = (action$, { getState }) =>
  action$.ofType(FETCH_BOOKINGS_ACTION).mergeMap((action) =>
    apiGetUserBookings(
      getState().credentials.credentials,
      action.payload?.type === "past"
    )
      .map((response) => fetchBookingsFulfilled(response, action))
      .catch(
        (error) =>
          console.log(error) ||
          handleXhrError(error, FETCH_BOOKINGS_ERROR_ACTION)
      )
  )

export const cancelUserBookingEpic = (action$, { getState }) =>
  action$.ofType("CANCEL_USER_BOOKING").mergeMap((action) =>
    apiCancelUserBooking(
      getState().credentials.credentials,
      action.payload.bookingId
    )
      .flatMap((response) => {
        const { bookingId, shouldRedirect } = action.payload
        if (shouldRedirect) {
          router.navigate("/bookings/upcoming")
        }
        return Observable.of(bookingCancelled(response, bookingId))
      })
      .catch((error) => handleXhrError(error, "CANCEL_USER_BOOKING_ERROR"))
  )
