import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import ShadowBox from "../../components/ShadowBox"
import EmployeeCard from "./EmployeeCard"

import { EMPLOYEE_ID_ANY } from "../InventoryView/constants"
import { useCalendarBookingModuleContext } from "./CalendarBookingModuleContext"
import { isProfileVip } from "../Profile/selectors"
import { browserIsMobile } from "../../redux/browserSelector"
import EmployeeLink from "./EmployeeLink"

const sortEmployee = (a, b) => {
  if (a.pricing && b.pricing) {
    const order =
      parseFloat(a.pricing.default.price) - parseFloat(b.pricing.default.price)

    if (order !== 0) {
      return (
        parseFloat(a.pricing.default.price) -
        parseFloat(b.pricing.default.price)
      )
    }
  }

  if (a.first_name.toUpperCase() < b.first_name.toUpperCase()) return -1
  if (a.first_name.toUpperCase() > b.first_name.toUpperCase()) return 1

  return 0
}

function AvailableEmployeeList({ onClose, isMobile, isVip }) {
  const {
    inventoryItem: {
      description,
      employees,
      pricing,
      currency,
      hide_any_employee,
      company,
    },
  } = useCalendarBookingModuleContext()

  const selectOptions = employees.sort(sortEmployee).map((eachEmployee) => (
    <EmployeeLink key={eachEmployee.id} employeeSlug={eachEmployee.slug}>
      <EmployeeCard
        employee={eachEmployee}
        pricing={pricing}
        isMobile={isMobile}
        currency={currency}
        userIsVip={isVip}
        company={company}
      />
    </EmployeeLink>
  ))

  return (
    <ShadowBox
      header={`Select ${description.category.default_role}`}
      isFullscreen={isMobile}
      closeBox={onClose}
    >
      <div
        style={{
          overflowY: "scroll",
          marginLeft: isMobile ? "-10px" : 0,
          marginRight: isMobile ? "-20px" : "-10px",
          maxHeight: isMobile ? "calc(100vh - 150px)" : "calc(100vh - 250px)",
        }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          {!hide_any_employee && (
            <EmployeeLink key={EMPLOYEE_ID_ANY} employeeSlug={EMPLOYEE_ID_ANY}>
              Any Stylist
            </EmployeeLink>
          )}

          {selectOptions}
        </div>
      </div>
    </ShadowBox>
  )
}

AvailableEmployeeList.propTypes = {
  onClose: PropTypes.func,
  isMobile: PropTypes.bool,
  isVip: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  isVip: isProfileVip(state),
  isMobile: browserIsMobile(state),
})

export default connect(mapStateToProps)(AvailableEmployeeList)
