import { createSelector } from "reselect"

const navbarState = (state) => state.navBar

export const hasAcceptedCookies = createSelector(
  navbarState,
  (navbar) => navbar.cookieOk
)

export const isGoogleAnalyticsEnabled = createSelector(
  navbarState,
  (navbar) =>
    navbar.cookieOk &&
    Array.isArray(process.env.GA4_SETTINGS) &&
    process.env.GA4_SETTINGS.length > 0
)

export const isFacebookAnalyticsEnabled = createSelector(
  navbarState,
  (navbar) =>
    navbar.cookieOk &&
    Array.isArray(process.env.FACEBOOK_PIXEL_IDS) &&
    process.env.FACEBOOK_PIXEL_IDS.length > 0
)
