import React from "react"
import PropTypes from "prop-types"
import styled, { keyframes, css } from "styled-components"

import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"

import Button from "../button"

const { DEFAULT_PHONE_COUNTRY } = SITE_CONFIG

const StyledLabel = styled.label`
  font-size: 16px;
  text-align: left;
  display: block;
`

const baseInputStyles = css`
  font-size: ${(props) => (props.large ? "20px" : "16px")};
  padding: 10px 15px 10px 15px;
  -webkit-appearance: none;
  outline: none;
`

const textInputStyles = `
  ${baseInputStyles}
  font-size: ${(props) => (props.large ? "20px" : "16px")};
  background-color: #fff;
  border: 1px solid #ccc;
  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.primary};
  }
`

const StyledInput = styled.input`
  ${textInputStyles}
`

const StyledSubmit = styled.button`
  ${baseInputStyles}
  background: ${(props) => (props.disabled ? "#ccc !important" : "#EF609F")};
  cursor: ${(props) => (props.disabled ? "initial !important" : "pointer")};
  color: #fff;
  border: ${(props) => (props.active ? "1px solid #000" : "none")};
  font-weight: 700;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`

const spinnerAnimation = keyframes`
  to {transform: rotate(360deg);}
`

const Spinner = styled.div`
  transition: all 0.3s;
  content: " ";
  box-sizing: border-box;
  display: inline-block;
  visibility: visible;
  opacity: 1;
  top: 50%;
  left: 50%;
  width: 12px;
  height: 12px;
  margin-right: 12px;
  border-radius: 50%;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  animation: ${spinnerAnimation} 0.6s linear infinite;
`

export const InputLabel = styled.label`
  display: flex;
  flex-direction: column;
  font-weight: 500;
  width: 100%;
  span {
    margin-left: 15px; // to align with the rounded corners of the input
    margin-bottom: 5px;
  }
`

export function TextInput({ label, id, type = "text", error = "", ...rest }) {
  const input = (
    <>
      <StyledInput id={id} type={type} {...rest} />
      {error && <div style={{ color: "red", fontSize: 10 }}>{error}</div>}
    </>
  )

  if (label) {
    return (
      <>
        {/* eslint-disable */}
        <InputLabel htmlFor={id}>
          <span>{label}</span>
          {input}
        </InputLabel>
      </>
    )
  }
  return input
}

const StyledPhoneInputLabel = styled(InputLabel)`
  .react-tel-input {
    display: flex;

    input {
      order: 1;
      border-radius: unset;
      height: unset;
      width: unset;
      line-height: unset;
      flex-grow: 1;
      ${textInputStyles}
    }

    div.flag-dropdown {
      border-radius: unset;
      position: unset;
    }
  }
`

const StyledPhoneInput = styled(PhoneInput)`
  position: unset;
  width: unset;
  display: flex;

  input.form-control {
    order: 1;
    border-radius: unset;
    height: unset;
    width: unset;
    line-height: unset;
    flex-grow: 1;
    border-left: none !important;
    ${textInputStyles}
  }

  div.flag-dropdown {
    border-radius: unset;
    position: unset;
  }
`

export const PhoneNumberInputLabel = ({
  label,
  id,
  onChange,
  value,
  ...rest
}) => {
  return (
    <StyledPhoneInputLabel htmlFor={id}>
      <span>{label}</span>
      <PhoneNumberInput value={value} onChange={onChange} />
    </StyledPhoneInputLabel>
  )
}

export const PhoneNumberInput = ({
  label,
  id,
  onChange,
  value,
  style,
  ...rest
}) => {
  return (
    <StyledPhoneInput
      value={value}
      onChange={onChange}
      style={style}
      country={DEFAULT_PHONE_COUNTRY}
    />
  )
}

export const PasswordInput = (props) => <TextInput type="password" {...props} />

export const SubmitInput = ({ loading, value, ...rest }) => (
  <Button type="submit" {...rest}>
    <React.Fragment>
      {loading && <Spinner />}
      {value}
    </React.Fragment>
  </Button>
)

export const SimpleButton = ({ value, ...rest }) => (
  <StyledSubmit type="button" active={false} {...rest}>
    {value}
  </StyledSubmit>
)

export const InputLabelWrapper = ({
  id,
  label,
  children,
  isMobile,
  extraStyle,
}) => (
  <div
    style={{
      width: "100%",
      display: isMobile ? "block" : "flex",
      flexDirection: isMobile ? "column" : "row",
      alignItems: "center",
      marginBottom: 10,
      ...extraStyle,
    }}
  >
    <div
      style={{
        flex: 1,
        paddingLeft: isMobile ? 12 : 0,
        paddingBottom: isMobile ? 4 : 0,
      }}
    >
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
    </div>
    <div style={{}}>{children}</div>
  </div>
)

InputLabelWrapper.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.element,
  isMobile: PropTypes.bool,
  extraStyle: PropTypes.object,
}
