import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment-timezone"
import styled from "styled-components"
import InfiniteScroll from "react-infinite-scroll-component"

import { useNavigate, useOutletContext, useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import Animation from "../../components/Animation"
import PromoBanner from "../../components/banners/PromoBanner"
import CampaignBanner from "../../components/banners/CampaignBanner"
import WrapContainer from "../WrapContainer"
import WhatWhenWhere from "./WhatWhenWhere"
import CalendarContainer from "../InventoryFilter/CalendarContainer"
import CategoryContainer from "../InventoryFilter/CategoryContainer"
import CompanyFilterContainer from "../InventoryFilter/CompanyFilterContainer"
import SortContainer from "../InventoryFilter/SortContainer"
import ShadowBox from "../../components/ShadowBox"
import { urlToFilter, filterToUrl, broadenFilter } from "./urlFilter"
import { buildInventorySearchQueryOptions, instantBookToApiFilter } from "./lib"
import InfiniteScrollDate from "./InfiniteScrollDate"
import { browserIsMobile } from "../../redux/browserSelector"
import { setDefaultMetaData, setMetaData } from "../../../utils/metaData"
import { setInfiniteScrollPosition } from "./actions"
import { getInfiniteScrollPosition } from "./selector"
import AvedaNoSearchResultsSalonLocatorMessage from "../../components/CompanySelector/AvedaNoSearchResultsSalonLocatorMessage"
import InfiniteScrollEndMessage from "./InfiniteScrollEndMessage"

const InfiniteScrollWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2;
  max-width: ${({ theme }) => theme.instantBookMaxWidth};
  margin-left: auto;
  margin-right: auto;
  overflow: auto;
`

function NoResults() {
  const { t } = useTranslation()

  return (
    <div style={{ textAlign: "center" }}>
      <p>{t("search.no_results_broadened")}</p>
      <AvedaNoSearchResultsSalonLocatorMessage />
    </div>
  )
}

// This needs to occur outside the component so that it doesn't get redefined on every render and break the removeEventListener
const noScroll = () => {
  window.scrollTo(0, 0)
}

function InstantBookContainer({
  credentials,
  infiniteScrollPosition,
  isMobile,
  metaTitle,
  metaDescription,
  setInfiniteScrollPosition,
}) {
  const params = useParams()["*"] ?? ""

  const [currentSearchResultsPage, setCurrentSearchResultsPage] = useState(0)
  const [rawResults, setRawResults] = useState(null)
  // const [groupedInventoryItems, setGroupedInventoryItems] = useState([])
  const [isLoadingInstantBookData, setIsLoadingInstantBookData] =
    useState(false)
  const [hasMoreResults, setHasMoreResults] = useState(true)

  // const [showBroadenedMessage, setShowBroadenedMessage] = useState(
  //   lastFilterWasBroadened
  // )

  const groupedInventoryItems = groupByDay(rawResults)

  const [broadenSearchResults, setBroadenSearchResults] = useState(false)
  // const [showBroadenedMessage, setShowBroadenedMessage] = useState(false)

  const infiniteScrollRef = useRef(null)

  const defaultNumberOfInstantBookResultsPerPage = 20
  // On mount; If infiniteScrollPosition is set, and the key hasn't changed, scroll to the position
  const [
    numberOfInstantBookResultsPerPage,
    setNumberOfInstantBookResultsPerPage,
  ] = useState(
    infiniteScrollPosition.key === params
      ? infiniteScrollPosition.numberOfPages *
          defaultNumberOfInstantBookResultsPerPage
      : defaultNumberOfInstantBookResultsPerPage
  )

  const [initialInfiniteScrollPosition, setInitialInfiniteScrollPosition] =
    useState(null)
  useEffect(() => {
    if (infiniteScrollPosition.key === params) {
      setInitialInfiniteScrollPosition(infiniteScrollPosition.position)
    }
  }, [])

  const {
    campaigns,
    categoryData: { categories, groups: categoryGroups },
    companies,
    regions,
  } = useOutletContext()

  // Convert URL params into objects
  const filter = urlToFilter(params, campaigns, companies, categories, regions)
  const {
    selectedCampaigns,
    selectedCategories,
    selectedCompanies,
    selectedRegions,
    selectedDays,
    sortBy,
  } = filter

  const updateFilter = (newFilterParts, broadened = false) => {
    const newFilter = {
      ...deepCloneObject(filter),
      ...newFilterParts,
    }

    // If the filter had changed, reset and navigate
    if (filterToUrl(newFilter) !== filterToUrl(filter)) {
      setBroadenSearchResults(broadened)
      setRawResults(null)
      setCurrentSearchResultsPage(0)
      setHasMoreResults(true)
      closeModal()

      navigate(filterToUrl(newFilter))
    }
  }

  const navigate = useNavigate()

  const { data: instantBookData, isFetching: isFetchingInstantBookData } =
    useQuery(
      buildInventorySearchQueryOptions({
        credentials,
        enabled: currentSearchResultsPage !== null && hasMoreResults,
        filter: instantBookToApiFilter(
          filter,
          currentSearchResultsPage,
          numberOfInstantBookResultsPerPage
        ),
      })
    )

  useEffect(() => {
    if (instantBookData && typeof instantBookData === "object") {
      // If we got not results on the first try, broaden the filter
      if (
        (instantBookData.inventory ?? []).length === 0 &&
        rawResults === null
      ) {

        // If there's more results but this page is empty (🤦 this needs fixing, the API should not return empty pages)
        if ((rawResults?.length ?? 0) < instantBookData.count) {
            setCurrentSearchResultsPage(currentSearchResultsPage + 1)
        } else {
        // Set the "search was broadened" message to display on the next page when applicable
          const broadendFilter = broadenFilter(filter)
          updateFilter(broadendFilter, true)
        }
      } else {
        setRawResults((previousRawResults) => {
          const newRawResults = [
            ...(previousRawResults || []),
            ...(instantBookData?.inventory ?? []),
          ]

          if (newRawResults.length >= instantBookData.count) {
            setHasMoreResults(false)
          }

          setIsLoadingInstantBookData(false)

          return newRawResults
        })
      }
    }
  }, [instantBookData])

  const storeInfiniteScrollPosition = () => {
    if (infiniteScrollRef.current) {
      setInfiniteScrollPosition(
        infiniteScrollRef.current.lastScrollTop,
        params,
        currentSearchResultsPage + 1
      )
    }
  }

  // Handle scrolling to previous infinite scroll position
  useEffect(() => {
    // Wait for initial data to load
    if (groupedInventoryItems.length > 0) {
      // If our initial load was larger than the default it's because we were scrolling back to our previous position
      if (
        numberOfInstantBookResultsPerPage !==
        defaultNumberOfInstantBookResultsPerPage
      ) {
        // Fast forward to the page we were on
        setCurrentSearchResultsPage(
          Math.floor(
            numberOfInstantBookResultsPerPage /
              defaultNumberOfInstantBookResultsPerPage
          )
        )

        // Reset number of results per page to the default so the subsequent loads are the correct size
        setNumberOfInstantBookResultsPerPage(
          defaultNumberOfInstantBookResultsPerPage
        )
      }

      // Wait for items to load before restoring scroll position
      if (infiniteScrollRef.current && initialInfiniteScrollPosition !== null) {
        setInitialInfiniteScrollPosition(null)
        // Scroll to the position
        infiniteScrollRef.current.el.scrollTo(
          0,
          infiniteScrollPosition.position
        )
      }
    }
  }, [infiniteScrollRef, groupedInventoryItems])

  function groupByDay(items) {
    if (!items) {
      return []
    }

    const groupedItems = []

    // Use the last group in the array or create a new one of the array is empty
    let currentGroup = null

    items.forEach((eachItem) => {
      if (!currentGroup || currentGroup.date !== eachItem.date) {
        if (currentGroup) {
          groupedItems.push(currentGroup)
        }

        currentGroup = {
          date: eachItem.date,
          items: [],
        }
      }
      currentGroup.items.push(eachItem)
    })

    if (currentGroup) {
      groupedItems.push(currentGroup)
    }

    return groupedItems
  }

  const setSortOption = (newSortBy) => {
    if (sortBy !== newSortBy) {
      updateFilter({
        sortBy: newSortBy,
      })
    }
  }

  const clickCategory = (clickedCategory) =>
    updateFilter({
      selectedCategories: filter.selectedCategories.find(
        (eachFilterCategory) => eachFilterCategory.id === clickedCategory.id
      )
        ? deepCloneObject(filter.selectedCategories).filter(
            (eachFilterCategory) => eachFilterCategory.id !== clickedCategory.id
          )
        : [...deepCloneObject(filter.selectedCategories), clickedCategory],
    })

  const clickCampaign = (clickedCampaign) =>
    updateFilter({
      selectedCampaigns: filter.selectedCampaigns.find(
        (eachFilterCampaign) => eachFilterCampaign.id === clickedCampaign.id
      )
        ? deepCloneObject(filter.selectedCampaigns).filter(
            (eachFilterCampaign) => eachFilterCampaign.id !== clickedCampaign.id
          )
        : [...deepCloneObject(filter.selectedCampaigns), clickedCampaign],
    })

  const deepCloneObject = (object) => JSON.parse(JSON.stringify(object))

  const clickSelectAllRegion = (region) =>
    updateFilter({
      // De-select all companies in this region that might be indiviaully selected
      // It makes sense to do this regardless of whether the region is being selected or not
      selectedCompanies: deepCloneObject(
        filter.selectedCompanies.filter(
          (eachSelectedCompany) =>
            eachSelectedCompany.district.region.id !== region.id
        )
      ),
      selectedRegions: filter.selectedRegions.find(
        (eachSelectedRegion) => eachSelectedRegion.id === region.id
      )
        ? deepCloneObject(filter.selectedRegions).filter(
            (eachSelectedRegion) => eachSelectedRegion.id !== region.id
          )
        : [...deepCloneObject(filter.selectedRegions), region],
    })

  const clickRegion = (region) =>
    updateFilter({
      selectedRegions: filter.selectedRegions.find(
        (eachSelectedRegion) => eachSelectedRegion.id === region.id
      )
        ? // Remove if exists
          deepCloneObject(filter.selectedRegions).filter(
            (eachSelectedRegion) => eachSelectedRegion.id !== region.id
          )
        : // Add if not exists
          [...deepCloneObject(filter.selectedRegions), region],
    })

  const clearWhat = () => {
    updateFilter({
      selectedCampaigns: [],
      selectedCategories: [],
    })
  }

  const clearWhen = () => {
    updateFilter({
      selectedDays: [],
    })
  }

  const clearWhere = () => {
    updateFilter({
      selectedCompanies: [],
      selectedRegions: [],
    })
  }

  const clickCompany = (clickedCompany) => {
    const newFilter = {
      selectedCompanies: deepCloneObject(filter.selectedCompanies),
      selectedRegions: deepCloneObject(filter.selectedRegions),
    }

    // If the company belongs to a region that was explicitly selected
    if (
      selectedRegions.find(
        (eachSelectedRegion) =>
          eachSelectedRegion.id === clickedCompany.district.region.id
      )
    ) {
      // Unselect the region
      newFilter.selectedRegions = selectedRegions.filter(
        (eachSelectedRegion) =>
          eachSelectedRegion.id !== clickedCompany.district.region.id
      )

      // Individually select all other companies in the region
      newFilter.selectedCompanies = companies.filter(
        (eachCompany) =>
          eachCompany.id !== clickedCompany.id &&
          eachCompany.district.region.id === clickedCompany.district.region.id
      )
    }

    // Unselect this company specifically
    if (
      newFilter.selectedCompanies.find(
        (eachSelectedCompany) => clickedCompany.id === eachSelectedCompany.id
      )
    ) {
      newFilter.selectedCompanies = newFilter.selectedCompanies.filter(
        (eachSelectedCompany) => eachSelectedCompany.id !== clickedCompany.id
      )
    } else {
      newFilter.selectedCompanies.push(clickedCompany)
    }

    updateFilter(newFilter)
  }

  const clickDay = (day) => {
    const date = moment(day).format("YYYY-MM-DD")

    updateFilter({
      selectedDays: filter.selectedDays.includes(date)
        ? deepCloneObject(filter.selectedDays).filter((day) => day !== date)
        : [...deepCloneObject(filter.selectedDays), date],
    })
  }

  const [currentlyOpenModal, setCurrentlyOpenModal] = useState(null)

  const closeModal = () => {
    setCurrentlyOpenModal(() => {
      window.removeEventListener("scroll", noScroll)

      return null
    })
  }

  /* Lock window scrollbar in place when modals are open */
  useEffect(() => {
    if (currentlyOpenModal !== null) {
      window.addEventListener("scroll", noScroll)
    }
  }, [currentlyOpenModal])

  useEffect(() => {
    setMetaData({
      metaTitle,
      metaDescription,
      selectedCampaigns,
      selectedCategories,
      selectedCompanies,
      selectedRegions,
    })
    return () => setDefaultMetaData()
  }, [
    metaTitle,
    metaDescription,
    selectedCampaigns,
    selectedCategories,
    selectedCompanies,
    selectedRegions,
  ])

  return (
    <WrapContainer
      loginRequired={false}
      showNavBack={false}
      navTitle="Instant Book"
    >
      <PromoBanner />
      <WhatWhenWhere
        currentlyOpenModal={currentlyOpenModal}
        onClearFilters={(type) => {
          if (type === "what") {
            clearWhat()
          } else if (type === "when") {
            clearWhen()
          } else if (type === "where") {
            clearWhere()
          }
        }}
        onClick={(name) => {
          if (name === currentlyOpenModal) {
            closeModal()
          } else {
            setCurrentlyOpenModal(name)
          }
        }}
        selectedCampaigns={selectedCampaigns}
        selectedCategories={selectedCategories}
        selectedCompanies={selectedCompanies}
        selectedDays={selectedDays.map((eachSelectedDay) => ({
          name: moment(eachSelectedDay).format("ddd D MMM"),
        }))}
        selectedRegions={selectedRegions}
        selectCalendarDay={clickDay}
        selectCompany={clickCompany}
        toggleRegion={clickRegion}
        toggleCategory={clickCategory}
        toggleCampaign={clickCampaign}
      />
      <CampaignBanner
        campaigns={campaigns}
        companies={companies}
        categories={categories}
        regions={regions}
      />
      <div style={{ paddingTop: 0 }}>
        {currentlyOpenModal === "when" && (
          <ShadowBox
            header="When?"
            closeBox={closeModal}
            isFullscreen={isMobile}
          >
            <CalendarContainer
              clickDay={clickDay}
              closeAction={closeModal}
              selectedDays={selectedDays}
            />
          </ShadowBox>
        )}
        {currentlyOpenModal === "what" && (
          <ShadowBox
            header="What?"
            closeBox={closeModal}
            isFullscreen={isMobile}
          >
            <CategoryContainer
              campaigns={campaigns}
              categories={categories}
              categoryGroups={categoryGroups}
              selectedCampaigns={selectedCampaigns}
              selectedCategories={selectedCategories}
              toggleCategory={clickCategory}
              toggleCampaign={clickCampaign}
              closeAction={closeModal}
            />
          </ShadowBox>
        )}
        {currentlyOpenModal === "where" && (
          <ShadowBox
            header="Where?"
            closeBox={closeModal}
            isFullscreen={isMobile}
          >
            <CompanyFilterContainer
              companies={companies}
              regions={regions}
              selectCompany={clickCompany}
              selectRegion={clickRegion}
              selectAllCompaniesInRegion={clickSelectAllRegion}
              selectedCompanies={selectedCompanies}
              selectedRegions={selectedRegions}
              showRegionSelector
              closeAction={closeModal}
            />
          </ShadowBox>
        )}
        <SortContainer sortBy={sortBy} setSortOption={setSortOption} />
        {broadenSearchResults && <NoResults />}
        {currentSearchResultsPage === 0 && isFetchingInstantBookData ? (
          <Animation
            style={{ position: "absolute", width: "100%", left: 0 }}
            key={0}
          />
        ) : (
          <InfiniteScrollWrapper id="infinite-scroll-wrapper">
            <InfiniteScroll
              endMessage={<InfiniteScrollEndMessage />}
              ref={infiniteScrollRef}
              dataLength={rawResults?.length ?? 0}
              next={() => {
                if (!isLoadingInstantBookData) {
                  setCurrentSearchResultsPage(currentSearchResultsPage + 1)
                }
              }}
              hasMore={hasMoreResults}
              onScroll={() => storeInfiniteScrollPosition()}
            >
              {groupedInventoryItems.map(({ date, items }) => (
                <InfiniteScrollDate key={date} date={date} items={items} />
              ))}
            </InfiniteScroll>
          </InfiniteScrollWrapper>
        )}
      </div>
    </WrapContainer>
  )
}

InstantBookContainer.propTypes = {
  credentials: PropTypes.shape({
    token: PropTypes.string,
  }),
  infiniteScrollPosition: PropTypes.shape({
    position: PropTypes.number,
    key: PropTypes.string,
    numberOfPages: PropTypes.number,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  setInfiniteScrollPosition: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    credentials: state.credentials.credentials,
    infiniteScrollPosition: getInfiniteScrollPosition(state),
    isMobile: browserIsMobile(state),
  }
}

function mapDispatchToProps(dispatch) {
  return {
    setInfiniteScrollPosition: (position, key, numberOfPages) =>
      dispatch(setInfiniteScrollPosition(position, key, numberOfPages)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InstantBookContainer)
