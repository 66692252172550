import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "styled-components"

import {
  removeFromCart,
  addNotesToCart,
  removeNotesFromCart,
} from "../Cart/actions"

import { phoneNumberUpdate } from "./actions"

import { PhoneNumberInput } from "../../components/forms/Inputs"
import BookingItemCard from "../../components/cards/BookingItemCard"
import Button, { Link } from "../../components/button"
import { ArrowLeftIcon, CloseIcon } from "../../components/icons"

import BookingNotes from "./components/BookingNotes"

const OrderSummaryWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.tertiary};
  padding: 20px 24px 30px 24px;
  margin-bottom: 20px;
  @media only screen and (max-width: ${({ theme }) =>
      theme.responsive.mobile}px) {
    padding: 60px 24px 30px 24px;
  }
`

class BookingDetailsContainer extends Component {
  handleNotes(item, notes) {
    const { addNotesToCart, removeNotesFromCart } = this.props

    if (notes.length > 0) {
      addNotesToCart(item.identifier, notes)
    } else {
      removeNotesFromCart(item.identifier)
    }
  }

  renderBookingItem(item, extras) {
    const { removeFromCart, cart, isMobile } = this.props

    const logoWidth = 64

    const startTime = item.time.start_time

    return (
      <div key={item.id} style={{ marginTop: 20, marginBottom: 32 }}>
        <BookingItemCard
          booking={item}
          startTime={startTime}
          price={item.price}
          currency={cart.currency}
          isMobile={isMobile}
          duration={item.service.duration}
          extras={extras}
        />

        <div style={{ display: "flex", marginBottom: 20 }}>
          <div
            style={{
              width: isMobile ? 0 : logoWidth,
              marginRight: isMobile ? 0 : 20,
            }}
          />
          <div style={{ flex: 1 }}>
            <h4>Add notes</h4>
            <BookingNotes
              maxLength={220}
              defaultValue={item.notes ? item.notes : ""}
              onChange={(evt) => {
                this.handleNotes(item, evt.target.value)
              }}
            />
          </div>
        </div>

        <div style={{ display: "flex", fontWeight: 400 }}>
          <div
            style={{
              width: isMobile ? 0 : logoWidth,
              marginRight: isMobile ? 0 : 20,
            }}
          />
          <div style={{ flex: 1 }} />
          <div>
            <Button
              size="small"
              variant="secondary"
              onClick={() => {
                removeFromCart(item.identifier)
              }}
              icon={<CloseIcon style={{ height: 13 }} />}
            >
              Remove from cart
            </Button>
          </div>
        </div>
      </div>
    )
  }

  renderOrderHeader() {
    const { loggedIn, profile, isLarge } = this.props

    if (!loggedIn) {
      return (
        <div
          style={{
            fontSize: "23px",
            marginBottom: 15,
            textAlign: !isLarge ? "center" : "left",
          }}
        >
          Order Summary
        </div>
      )
    }

    return (
      <h2
        style={{
          marginBottom: 15,
          textAlign: !isLarge ? "center" : "left",
        }}
      >
        Order Summary for{" "}
        <span style={{ fontWeight: 500 }}>
          {profile.first_name} {profile.last_name}
        </span>
      </h2>
    )
  }

  render() {
    const { cart, purchase, phoneNumberUpdate, profile } = this.props

    const items = cart.items.filter((item) => item.type === "appointment")
    const extras = cart.items.filter((item) => item.type === "extra")
    return (
      <OrderSummaryWrapper>
        {this.renderOrderHeader()}

        <div style={{ borderBottom: "2px solid #c3c3c2" }}>
          {items.map((item) =>
            this.renderBookingItem(
              item,
              extras.filter(
                (extra) => extra.appointment_availability_id === item.id
              )
            )
          )}
        </div>

        {/*
        <div style={{ display: "flex", borderBottom: "2px solid #c3c3c2", padding: "38px 0px" }}>
          <div>TOTAL TO PAY</div>
          <div style={{ flex: 1, textAlign: "right" }}>
            <div>{cart.currency.symbol}{cart.total_gross}</div>
            <div style={{ textAlign: "right" }}>{cart.currency.code}</div>
          </div>
        </div>
        */}

        {!profile.phone_number && (
          <div
            style={{
              marginTop: "32px",
              paddingBottom: "32px",
              borderBottom: "2px solid #c3c3c2",
            }}
          >
            <div style={{ fontWeight: 500 }}>
              Your mobile number for the salon
            </div>
            <div style={{ marginTop: "18px" }}>
              <PhoneNumberInput
                onChange={(val) => {
                  phoneNumberUpdate(val)
                }}
                value={purchase.phoneNumber}
              />
            </div>
          </div>
        )}
        <div style={{ marginTop: "32px" }}>
          <Link
            href="/"
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ArrowLeftIcon style={{ width: "auto", height: "18px" }} />
            <span style={{ paddingLeft: "6px" }}>Continue shopping</span>
          </Link>
        </div>
      </OrderSummaryWrapper>
    )
  }
}

BookingDetailsContainer.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isLarge: PropTypes.bool.isRequired,
  loggedIn: PropTypes.bool.isRequired,
  purchase: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  removeFromCart: PropTypes.func.isRequired,
  addNotesToCart: PropTypes.func.isRequired,
  phoneNumberUpdate: PropTypes.func.isRequired,
  removeNotesFromCart: PropTypes.func,
  profile: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    isMobile: state.browser.lessThan.mobile,
    isLarge: state.browser.greaterThan.large,
    loggedIn: !!state.profile.profile.id,
    isVip: !!state.profile.profile.vip,
    profile: state.profile.profile,
    purchase: state.purchase,
    cart: state.cart.cart,
  }
}

const mapDispatchToProps = (dispatch) => ({
  removeFromCart: (identifier) => dispatch(removeFromCart(identifier)),
  removeNotesFromCart: (identifier) =>
    dispatch(removeNotesFromCart(identifier)),
  addNotesToCart: (identifier, notes) =>
    dispatch(addNotesToCart(identifier, notes)),
  phoneNumberUpdate: (number) => dispatch(phoneNumberUpdate(number)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BookingDetailsContainer)
