import React, { useRef, useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { useNavigate, useOutletContext, useParams } from "react-router-dom"
import styled from "styled-components"
import { RenderStarRatings } from "./components"
import { BackLink } from "../../components/nav/Links"
import { ServiceGallery } from "./ServiceGallery"
import FavouritesBtn from "../../components/favourites/FavouritesBtn"
import { userFavouriteService } from "../Favourites/actions"
import CompanyDetails from "./CompanyDetails"
import CalendarBookingModule from "../CalendarBookingModule"
import DescriptiveContent from "./DescriptiveContent"
import Pricing from "../../components/Pricing"
import { getUserHasFavouritedService } from "../Favourites/selectors"
import useInventoryNavigator from "../../hooks/useInventoryNavigator"

/* Main container for the two content columns */
const ServiceContainer = styled.div`
  display: grid;
  grid-column-gap: 40px;
  grid-template-columns: ${(props) =>
    props.isMobile ? "100%" : "calc(50% - 20px) calc(50% - 20px)"};
  max-width: 980px;
  margin: 0px auto;
  padding-top: ${(props) => (props.isMobile ? 0 : "20px")};
`

function Inventory({
  doUserFavouriteService,
  haveProfile,
  isFavourited,
  isLarge,
  isMedium,
  isVip,
}) {
  const navigate = useNavigate()

  const [selectedTab, setSelectedTab] = useState("about")

  const { date, employeeSlug, time } = useParams()

  const { inventoryItem } = useOutletContext()

  // if (!inventoryItem?.id) return null

  // console.log("Context", context)

  const reviewRef = useRef()

  /* Quick hack until there is time to refactor breakpoints properly */
  const isMobile = !isMedium

  const renderStarRatings = (item) => {
    if (item.rating_count === "0" && item.company.rating_count !== "0") {
      return (
        <RenderStarRatings
          clickStars={clickStars}
          rating={parseFloat(item.company.rating_customer)}
          ratingCount={parseInt(item.company.rating_count, 10)}
        />
      )
    }

    return (
      <RenderStarRatings
        clickStars={clickStars}
        rating={parseFloat(item.rating_customer)}
        ratingCount={parseInt(item.rating_count, 10)}
      />
    )
  }

  const scrollToReviews = () =>
    reviewRef.current &&
    window.scrollTo({
      top: reviewRef.current.offsetTop - 100,
      behavior: "smooth",
    })

  const clickTab = (tab) => {
    if (selectedTab !== tab) {
      setSelectedTab(tab)
    } else {
      setSelectedTab(null)
    }
  }

  const clickStars = () => {
    scrollToReviews()
    clickTab("reviews")
  }

  const {
    id: serviceId,
    company,
    pricing,
    currency,
    rating_count,
  } = inventoryItem

  const paymentMethods = company.payment_methods
  const oxiPayAvailable = paymentMethods.find(
    (method) => method.slug === "oxipay"
  )

  const inventoryNavigate = useInventoryNavigator()

  return (
    <ServiceContainer isMobile={!isLarge}>
      <div style={{ position: "relative" }}>
        <BackLink
          href="/"
          onClick={(e) => {
            e.preventDefault()
            navigate(-1)
          }}
          style={{ top: !isLarge ? 20 : 0, left: !isLarge ? 20 : -60 }}
        />

        <ServiceGallery
          style={{ height: isMobile ? 260 : 320 }}
          service={inventoryItem}
          showCompanyLogo={false}
        />

        <div
          style={{
            margin: isMobile ? "20px 0 0 0" : 0,
            padding: isMobile ? "0 10px" : 0,
          }}
        >
          <div
            style={{
              display: "flex",
              marginTop: 20,
              position: "relative",
            }}
          >
            {haveProfile && (
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  bottom: 4,
                }}
              >
                <FavouritesBtn
                  isMobile={isMobile}
                  isFavourited={isFavourited}
                  onClick={() =>
                    serviceId
                      ? doUserFavouriteService(serviceId, isFavourited)
                      : null
                  }
                />
              </div>
            )}
            <div style={{ flex: 1, marginTop: -8 }}>
              <Pricing
                style={{ textAlign: "left" }}
                hideTag
                prices={pricing}
                currency={currency}
                isMobile={isMobile}
                userIsVip={isVip}
              />
              {oxiPayAvailable && (
                <div>
                  <div style={{ display: "inline-block" }} />
                </div>
              )}
              {rating_count + company.rating_count > 0 && (
                <div style={{ textAlign: "left", marginTop: 6 }}>
                  <div style={{ display: "inline-block" }}>
                    {renderStarRatings(inventoryItem)}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div style={{ marginTop: 10, padding: isMobile ? "0 10px" : 0 }}>
          <div style={{ marginBottom: 20 }}>
            <DescriptiveContent
              clickTab={clickTab}
              item={inventoryItem}
              selectedTab={selectedTab}
            />
          </div>
        </div>
      </div>

      <div
        style={{
          position: "relative",
          padding: isMobile ? "0 10px" : 0,
        }}
      >
        <div style={{ marginTop: isMobile ? 20 : 0 }}>
          {!isMobile && <CompanyDetails company={company} isLarge={isLarge} />}
          <CalendarBookingModule
            date={date}
            time={time}
            employeeSlug={employeeSlug}
            inventoryItem={inventoryItem}
            onChange={(changeData) => {
              inventoryNavigate(changeData)
            }}
          />
        </div>
      </div>
    </ServiceContainer>
  )
}

Inventory.propTypes = {
  haveProfile: PropTypes.bool.isRequired,
  isFavourited: PropTypes.bool.isRequired,
  isLarge: PropTypes.bool.isRequired,
  isMedium: PropTypes.bool.isRequired,
  isVip: PropTypes.bool.isRequired,
  doUserFavouriteService: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  haveProfile: !!state.profile.profile.id,
  isFavourited: getUserHasFavouritedService(state),
  isLarge: state.browser.greaterThan.large,
  isMedium: state.browser.greaterThan.medium,
  isVip: !!state.profile.profile.vip,
})

const mapDispatchToProps = (dispatch) => ({
  doUserFavouriteService: (serviceId, isFavourited) =>
    dispatch(userFavouriteService(serviceId, isFavourited)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Inventory)
