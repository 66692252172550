import React from "react"
import PropTypes from "prop-types"
import { useCampaignWizardContext } from "./CampaignWizardContext"
import Button from "../../../components/button"

function CompanyButtons({ campaignCompanies }) {
  const { setSelectedStep } = useCampaignWizardContext()

  const canChangeSalon = campaignCompanies.length > 1

  return (
    <div
      style={{
        margin: "0px auto 20px auto",
        display: "flex",
        flexDirection: "horizontal",
        justifyContent: canChangeSalon ? "space-between" : "center",
        alignItems: "center",
        maxWidth: "280px",
      }}
    >
      {canChangeSalon && (
        <Button
          variant="secondary"
          onClick={() => {
            setSelectedStep(3)
          }}
        >
          Change Salon
        </Button>
      )}
      <Button
        variant="secondary"
        filled
        onClick={() => {
          setSelectedStep(5)
        }}
      >
        Next
      </Button>
    </div>
  )
}

CompanyButtons.propTypes = {
  campaignCompanies: PropTypes.array.isRequired,
}

export default CompanyButtons
