import React, { useEffect, useRef, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment"
import styled from "styled-components"
import { PromoTag, VipTag } from "../../../components/SaleTags"
import { useCalendarBookingModuleContext } from "../CalendarBookingModuleContext"
import { isProfileVip } from "../../Profile/selectors"

export const TimeSelectButton = styled.div`
  cursor: pointer;
  text-align: center;
  padding: 10px;
  background-color: blue;
  background-color: ${(props) =>
    props.selected
      ? props.theme.timeSelector.selectedBackground
      : "transparent"};
  color: ${(props) =>
    props.selected
      ? props.theme.timeSelector.selectedText
      : props.theme.timeSelector.defaultText};
  border-radius: 999px;
  border: 1px solid ${(props) => props.theme.timeSelector.selectedBackground};

  &:hover {
    background-color: ${(props) =>
      props.selected
        ? props.theme.timeSelector.selectedBackground
        : props.theme.timeSelector.hoverBackground};
    color: ${(props) =>
      props.selected
        ? props.theme.timeSelector.selectedText
        : props.theme.timeSelector.hoverText};
  }

  text-decoration: none;
`

function TimeSelectOption({ availability, isVip }) {
  const { discount_vip, sale, startTime, timezone } = availability

  const { onChange, selectedAvailability } = useCalendarBookingModuleContext()

  const time = moment(startTime).tz(timezone)

  const [selected, setSelected] = useState(false)

  useEffect(() => {
    setSelected(availability.id === selectedAvailability?.id)
  }, [selectedAvailability])

  // const [isVisible, setIsVisible] = useState(false)

  const vip = !!(isVip && discount_vip)

  const optionRef = useRef(null)

  useEffect(() => {
    // There is a bug Steve was getting that I couldn't recreate. Checking if the function exists before calling it *should* fix it, or it could cause
    // another bug further down the chain (i.e. the selected option not scrolling into view). We'll see.
    // https://app.shortcut.com/flossie-1/story/997/scrollintoviewifneeded-is-not-a-function-when-clicking-on-time
    if (
      selected &&
      typeof optionRef.current.scrollIntoViewIfNeeded === "function"
    ) {
      optionRef.current.scrollIntoViewIfNeeded()
    }
  }, [selected, optionRef.current])

  return (
    <TimeSelectButton
      ref={optionRef}
      data-availability-id={availability.id}
      data-selected={selected}
      selected={selected}
      onClick={() => onChange({ time: time.format("HHmm") })}
      replace
    >
      <div
        style={{
          whiteSpace: "nowrap",
          flex: 1,
        }}
      >
        {time.format("h:mm a")}
      </div>

      {!sale && vip && <VipTag style={{ marginTop: 13 }} />}
      {sale && !vip && <PromoTag style={{ marginTop: 13 }} />}
    </TimeSelectButton>
  )
}

TimeSelectOption.propTypes = {
  availability: PropTypes.shape({
    discount_vip: PropTypes.bool,
    id: PropTypes.string,
    sale: PropTypes.bool,
    startTime: PropTypes.object,
    time: PropTypes.string,
    timezone: PropTypes.string,
    vip: PropTypes.bool,
  }),
  isVip: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  haveProfile: !!state.profile.profile.id,
  isLarge: state.browser.greaterThan.large,
  isMedium: state.browser.greaterThan.medium,
  isVip: isProfileVip(state),
})

export default connect(mapStateToProps)(TimeSelectOption)
