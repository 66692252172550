import React from "react"
import PropTypes from "prop-types"
import CompaniesMap from "./CompaniesMap"
import { useCompanySelectorContext } from "./CompanySelectorContext"
import { filterCompanies } from "./lib"
import AvedaNoSearchResultsSalonLocatorMessage from "./AvedaNoSearchResultsSalonLocatorMessage"

function MapView({ companySearch }) {
  const { clickCompany, companies, isMobile } = useCompanySelectorContext()

  const [mapNeedsPan, setMapNeedsPan] = React.useState(true)
  const [mapZoom, setMapZoom] = React.useState(1)

  const filteredCompanies = filterCompanies(companies, companySearch)

  return filteredCompanies.length <= 0 ? (
    <div style={{ textAlign: "center" }}>
      Sorry, your search returned no results
      <AvedaNoSearchResultsSalonLocatorMessage />
    </div>
  ) : (
    <CompaniesMap
      companies={filteredCompanies}
      mapZoom={mapZoom}
      setMapZoom={setMapZoom}
      getPoints={() => filteredCompanies}
      onClick={clickCompany}
      mapNeedsPan={mapNeedsPan}
      setMapNeedsPan={setMapNeedsPan}
      height={isMobile ? "calc(100vh - 280px)" : "400px"}
    />
  )
}

MapView.propTypes = {
  companySearch: PropTypes.string,
}

export default MapView
