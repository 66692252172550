import styled from "styled-components"

export const StyledLabel = styled.div`
  display: flex;
  align-items: center;
  min-height: 45px;
  padding: 10px;
  border-radius: 999px;
  background-color: ${(props) => props.theme.timeSelector.selectedBackground};
  border: none;
  color: ${(props) => props.theme.timeSelector.selectedText};
  * {
    color: ${(props) => props.theme.timeSelector.selectedText};
  }
  margin: 10px;
`
