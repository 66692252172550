import { apiGetInstantBookServices, handleXhrError } from "../../api/api"
import { getInventoryFilterFromState } from "../InventoryFilter/lib"
import { filterToUrl } from "./urlFilter"
import { fetchInstantBookServices } from "./actions"
import {
  FETCH_INSTANT_BOOK_SERVICES_ACTION,
  RE_FETCH_INSTANT_BOOK_SERVICES_ACTION,
  SET_GEO_LOCATION_ACTION,
  SET_INVENTORY_FILTER_ACTION,
} from "./constants"
import router from "../../config/router"

const fetchInstantBookServicesFulfilled = (response, action) => ({
  type: "FETCH_INSTANT_BOOK_SERVICES_SUCCESS",
  payload: { response, action },
})

export const fetchInstantBookServicesEpic = (action$, { getState }) =>
  action$
    .ofType(
      FETCH_INSTANT_BOOK_SERVICES_ACTION,
      "FETCH_INSTANT_BOOK_SERVICES_AUTO_UNFAIL"
    )
    .mergeMap((action) =>
      apiGetInstantBookServices(
        getState().credentials.credentials,
        action.payload.filter
      )
        .map((response) => fetchInstantBookServicesFulfilled(response, action))
        .catch((error) =>
          handleXhrError(error, "FETCH_INSTANT_BOOK_SERVICES_ERROR")
        )
    )

export const filterChangeEpic = (action$, { getState }) =>
  action$
    .ofType(SET_GEO_LOCATION_ACTION, SET_INVENTORY_FILTER_ACTION)
    .filter(
      (action) =>
        (action.type === "SET_GEO_LOCATION" &&
          (JSON.stringify(action.payload.lat) !==
            JSON.stringify(getState().inventoryFilter.latitude) ||
            JSON.stringify(action.payload.lng) !==
              JSON.stringify(getState().inventoryFilter.longitude))) ||
        (action.type === "SET_INVENTORY_FILTER" &&
          (JSON.stringify(action.payload.filterState.selectedCampaigns) !==
            JSON.stringify(
              getState().inventoryFilter.lastFilterState.selectedCampaigns
            ) ||
            JSON.stringify(action.payload.filterState.selectedCategories) !==
              JSON.stringify(
                getState().inventoryFilter.lastFilterState.selectedCategories
              ) ||
            JSON.stringify(action.payload.filterState.selectedCompanies) !==
              JSON.stringify(
                getState().inventoryFilter.lastFilterState.selectedCompanies
              ) ||
            JSON.stringify(action.payload.filterState.selectedDays) !==
              JSON.stringify(
                getState().inventoryFilter.lastFilterState.selectedDays
              ) ||
            JSON.stringify(action.payload.filterState.selectedRegions) !==
              JSON.stringify(
                getState().inventoryFilter.lastFilterState.selectedRegions
              ) ||
            JSON.stringify(action.payload.filterState.sortBy) !==
              JSON.stringify(getState().inventoryFilter.lastFilterState.order)))
    )
    .concatMap(() => [
      { type: "CLEAR_INSTANT_BOOK_SERVICES" },
      fetchInstantBookServices(),
    ])

export const paginatedFetchEpic = (action$, { getState }) =>
  action$
    .ofType(RE_FETCH_INSTANT_BOOK_SERVICES_ACTION)
    .debounceTime(250) // dont spam api with autoscroll loading, helps prevent duplicate page loads
    .map((action) => ({
      type: FETCH_INSTANT_BOOK_SERVICES_ACTION,
      payload: {
        action,
        filter: getInventoryFilterFromState(getState().inventoryFilter),
      },
    }))

export const redirectToLastFilterOnOverFilter = (action$, { getState }) =>
  action$
    .ofType("FETCH_INSTANT_BOOK_SERVICES_AUTO_UNFAIL")
    .map(() =>
      router.navigate(filterToUrl(getState().inventoryFilter.lastFilterState))
    )

export const reSearchOnEmptyEpic = (action$, { getState }) =>
  action$.ofType("FETCH_INSTANT_BOOK_SERVICES_SUCCESS").map((action) => {
    if (action.payload.action.payload.filter.offset === 0) {
      // first page
      if (action.payload.response.count === 0) {
        // no results
        return {
          type: "FETCH_INSTANT_BOOK_SERVICES_AUTO_UNFAIL",
          payload: {
            action,
            filter: getState().inventoryFilter.lastFilter,
            failedSearch: true,
          },
        }
      }
    }
    return {
      type: "FETCH_INSTANT_BOOK_SERVICES_COMPLETE",
    }
  })
