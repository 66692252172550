import React, { useEffect } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"

import { clearCompany, fetchCompanyServices } from "./actions"
import CompanyService from "./CompanyService"

function CompanyServices({
  clearCompany,
  companyId,
  fetchCompanyServices,
  services,
}) {
  useEffect(() => {
    fetchCompanyServices(companyId)
    return () => {
      // reset the persisted services in companies redux store.
      clearCompany()
    }
  }, [])

  if (!services) return null
  return (
    <div>
      {services.map((service) => (
        <CompanyService key={service.id} service={service} />
      ))}
    </div>
  )
}

CompanyServices.propTypes = {
  fetchCompanyServices: PropTypes.func.isRequired,
  clearCompany: PropTypes.func.isRequired,
  services: PropTypes.array.isRequired,
  companyId: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    services: state.company.services,
    isMobile: state.browser.lessThan.mobile,
    isVip: !!state.profile.profile.vip,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCompanyServices: (id) => dispatch(fetchCompanyServices(id)),
  clearCompany: () => dispatch(clearCompany()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyServices)
