const {
  DEFAULT_TITLE,
  DEFAULT_TITLE_SUFFIX,
  DEFAULT_META_DESCRIPTION,
  ROOT_URL,
} = SITE_CONFIG

const addMetaFacebookTag = (property, content) => {
  const meta = document.createElement("meta")
  meta.setAttribute("property", property)
  meta.content = content

  document.getElementsByTagName("head")[0].appendChild(meta)
}

const setMetaFacebook = (property, content) => {
  const metaFacebook = document.querySelector(`meta[property="${property}"]`)

  if (!metaFacebook) {
    addMetaFacebookTag(property, content)

    return
  }

  metaFacebook.setAttribute("content", content)
}

const setTitle = (title) => {
  if (document.title !== title) {
    document.title = title
  }
}

const generateDefaultTitle = () => {
  return addTitleSuffix(DEFAULT_TITLE)
}

const addTitleSuffix = (title) => {
  return `${title} - ${DEFAULT_TITLE_SUFFIX}`
}

const removeCanonicalTag = () => {
  const canonicalTag = document.getElementById("canonical")
  if (canonicalTag) canonicalTag.parentNode.removeChild(canonicalTag)
}

const setMetaDescription = (description) => {
  const metaDesc = document
    .getElementsByTagName("meta")
    .namedItem("description")
  if (metaDesc) metaDesc.setAttribute("content", description)
}

const addCanonicalTag = (path) => {
  if (path) {
    const canonicalTag =
      document.getElementById("canonical") || document.createElement("link")
    canonicalTag.id = "canonical"
    canonicalTag.rel = "canonical"
    canonicalTag.href = `${ROOT_URL}${path}`
    document.head.appendChild(canonicalTag)
  } else {
    removeCanonicalTag()
  }
}

const removeMetaFacebook = (property) => {
  const metaFacebook = document.querySelector(`meta[property="${property}"]`)
  if (metaFacebook) metaFacebook.parentNode.removeChild(metaFacebook)
}

const generateMetaDescription = (description) => {
  if (!description) return DEFAULT_META_DESCRIPTION
  return description
}

const generateTitle = (title) => {
  if (!title) {
    return generateDefaultTitle()
  }

  return addTitleSuffix(title)
}

export function setMetaData({
  canonicalPath = null,
  metaTitle = null,
  metaDescription = null,
  ogTitle = null,
  ogDescription = null,
  ogUrl = null,
  ogImage = null,
  productBrand = null,
  productAvailability = null,
  productCondition = null,
  productPriceAmount = null,
  productPriceCurrency = null,
  productRetailerItemId = null,
}) {
  setTitle(generateTitle(metaTitle))
  setMetaDescription(generateMetaDescription(metaDescription))
  addCanonicalTag(canonicalPath)

  removeMetaFacebook("og:title")
  removeMetaFacebook("og:description")
  removeMetaFacebook("og:url")
  removeMetaFacebook("og:image")

  removeMetaFacebook("product:brand")
  removeMetaFacebook("product:availability")
  removeMetaFacebook("product:condition")
  removeMetaFacebook("product:price:amount")
  removeMetaFacebook("product:price:currency")
  removeMetaFacebook("product:retailer_item_id")

  if (ogTitle) {
    setMetaFacebook("og:title", ogTitle)
  }

  if (ogDescription) {
    setMetaFacebook("og:description", ogDescription)
  }

  if (ogUrl) {
    setMetaFacebook("og:url", ogUrl)
  }

  if (ogImage) {
    setMetaFacebook("og:image", ogImage)
  }

  if (productBrand) {
    setMetaFacebook("product:brand", productBrand)
  }

  if (productAvailability) {
    setMetaFacebook("product:availability", productAvailability)
  }

  if (productCondition) {
    setMetaFacebook("product:condition", productCondition)
  }

  if (productPriceAmount) {
    setMetaFacebook("product:price:amount", productPriceAmount)
  }

  if (productPriceCurrency) {
    setMetaFacebook("product:price:currency", productPriceCurrency)
  }

  if (productRetailerItemId) {
    setMetaFacebook("product:retailer_item_id", productRetailerItemId)
  }
}

export function setDefaultMetaData() {
  setTitle(generateDefaultTitle())
  setMetaDescription(DEFAULT_META_DESCRIPTION)
  removeCanonicalTag()
}
