import React from "react"
import { Navigate, useLocation } from "react-router-dom"
import { useAuth } from "../providers/AuthProvider"
import { connect } from "react-redux"
import { storeLastLocation } from "../containers/actions"

const ProtectedRoute = ({ loggingIn, storeLastLocation, children }) => {
  const auth = useAuth()
  const location = useLocation()

  if (!auth.user && !loggingIn) {
    storeLastLocation(location.pathname)
    return <Navigate to="/login" />
  }

  return children
}

const mapDispatchToProps = (dispatch) => ({
  storeLastLocation: (location) => dispatch(storeLastLocation(location)),
})

const mapStateToProps = (state) => ({
  loggingIn: state.credentials.loggingIn,
})

export default connect(mapStateToProps, mapDispatchToProps)(ProtectedRoute)
