import PropTypes from "prop-types"
import React, { useCallback } from "react"
import { useQuery } from "@tanstack/react-query"
import { useCampaignWizardContext } from "./CampaignWizardContext"
import { getCompany } from "../../../api/api"
import { Step, StepHeader, StepParent } from "./styledComponents"
import Company from "./Company"

function CampaignStep4({ similarCompanies }) {
  const { campaign, selectedCompany, selectedStep } = useCampaignWizardContext()

  const stepRef = useCallback((node) => {
    if (selectedStep === 4 && node !== null) {
      node.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  const { data: selectedCompanyData } = useQuery({
    queryKey: ["get-company", selectedCompany],
    enabled: selectedCompany !== null,
    retry: false,
    queryFn: async () => getCompany(selectedCompany.id),
  })

  if (!selectedCompanyData) {
    return null
  }

  return (
    <div ref={stepRef} style={{ marginBottom: "20px" }}>
      <StepParent>
        <Step id="step-4">Step 4 of 5</Step>
        <StepHeader>{campaign.title_company_confirm}</StepHeader>
      </StepParent>
      <div style={{ minHeight: "500px" }}>
        {selectedCompany.id && (
          <Company
            companyData={selectedCompanyData}
            campaignCompanies={similarCompanies}
          />
        )}
      </div>
    </div>
  )
}

CampaignStep4.propTypes = {
  similarCompanies: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default CampaignStep4
