import { FETCH_ORDER_ACTION } from "./constants"

export const fetchOrder = (id) => ({
  type: FETCH_ORDER_ACTION,
  payload: { id },
})

export const addToCalendarTrack = () => ({
  type: "TRACK_ADD_TO_CALENDAR",
})
