import PropTypes from "prop-types"
import React, { useState } from "react"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"
import Button from "../../components/button"
import ErrorBar from "../../components/ErrorBar"
import {
  resetFormProps,
  setResetPasswordToken,
  submitUpdatePassword,
} from "./actions"
import { PasswordInput, SubmitInput } from "../../components/forms/Inputs"

const StyledParentDiv = styled.div`
  margin-left: auto;
  flex: 1;
  margin-right: auto;
  max-width: 600px;
`
const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.isMobile ? "column-reverse" : "row")};
  text-align: ${(props) => (props.isMobile ? "center" : "left")};
`

const StyledSpan = styled.span`
  flex: 1;
  font-weight: 700;
  font-size: 16px;
  line-height: 40px;
  margin: 0;
`

const StyledInputDiv = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  flex-direction: ${(props) => (props.isMobile ? "column-reverse" : "row")};
`

const StyledPasswordDiv = styled.div`
  margin-bottom: 14px;
  display: flex;
`

function ResetPasswordForm({
  hasError,
  isMobile,
  lastError,
  lastErrorCode,
  passwordUpdated,
  submitUpdatePassword,
  token = "",
}) {
  const [password, setPassword] = useState("")
  const [passwordAgain, setPasswordAgain] = useState("")

  const navigate = useNavigate()

  const navToPreviousLocation = (e) => {
    const { localStorage } = window

    const location = localStorage.getItem("REDIRECT_TO")
      ? localStorage.getItem("REDIRECT_TO")
      : "/"

    e.preventDefault()

    localStorage.removeItem("REDIRECT_TO")

    navigate(location)
  }

  const successMessage = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <h2 style={{ textAlign: "center" }}>
          Success. Your Password has been updated
        </h2>
        <Button
          onClick={navToPreviousLocation}
          style={{
            textAlign: "center",
            marginTop: 10,
          }}
        >
          Continue
        </Button>
      </div>
    )
  }

  const passwordMatch = () => {
    if (
      !password &&
      !password.length &&
      !passwordAgain &&
      !passwordAgain.length
    ) {
      return false
    }

    return password === passwordAgain
  }

  const passwordMismatchMessage = () => {
    let errorMsg = null
    // don't show error if one textbox is empty.
    if (
      password &&
      password.length > 0 &&
      passwordAgain &&
      passwordAgain.length > 0 &&
      !passwordMatch()
    ) {
      errorMsg = <ErrorBar message="Passwords do not match" />
    }
    return errorMsg
  }

  const onSubmit = (e) => {
    e.preventDefault()
    submitUpdatePassword(password, passwordAgain, token)
  }

  // hide the form when password updated.
  if (passwordUpdated && !hasError) {
    return successMessage()
  }
  if (!token && !passwordUpdated && hasError) {
    return (
      <div>
        <p style={{ margin: 0, textAlign: "center" }}>Token not found.</p>
      </div>
    )
  }

  const errorMessageBar = () => {
    let errorMsg = null
    if (hasError && lastErrorCode !== 412) {
      errorMsg = (
        // THIS SHOULD BE A CONSTANT - OFTEN RE-USED TEXT
        <ErrorBar message="Something went wrong! Please try again later." />
      )
    }

    if (hasError && lastErrorCode === 412) {
      errorMsg = <ErrorBar message={lastError} />
    }
    return errorMsg
  }

  return (
    <form onSubmit={onSubmit}>
      <StyledParentDiv>
        <StyledDiv isMobile={isMobile}>
          <StyledSpan>Confirm new password</StyledSpan>
        </StyledDiv>
        {errorMessageBar()}
        {passwordMismatchMessage()}
        <StyledPasswordDiv>
          <PasswordInput
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            placeholder="New Password"
            style={{ flexGrow: 1 }}
          />
        </StyledPasswordDiv>
        <StyledPasswordDiv>
          <PasswordInput
            onChange={(e) => setPasswordAgain(e.target.value)}
            value={passwordAgain}
            placeholder="New Password Confirm"
            style={{ flexGrow: 1 }}
          />
        </StyledPasswordDiv>
        <StyledInputDiv isMobile={isMobile}>
          <div style={{ flex: 1 }} />
          <SubmitInput
            value="Update Password"
            disabled={!passwordMatch()}
            style={{ marginBottom: 10 }}
          />
        </StyledInputDiv>
      </StyledParentDiv>
    </form>
  )
}

ResetPasswordForm.propTypes = {
  isMobile: PropTypes.bool,
  passwordUpdated: PropTypes.bool,
  submitUpdatePassword: PropTypes.func.isRequired,
  hasError: PropTypes.bool.isRequired,
  lastError: PropTypes.string,
  lastErrorCode: PropTypes.number,
  token: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    passwordUpdated: state.resetpassword.passwordUpdated,
    hasError: state.resetpassword.hasError,
    lastError: state.resetpassword.lastError,
    lastErrorCode: state.resetpassword.lastErrorCode,
    token: state.resetpassword.resetPasswordToken,
    isMobile: state.browser.lessThan.mobile,
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetFormProps: () => dispatch(resetFormProps()),
  setResetPasswordToken: (token) => dispatch(setResetPasswordToken(token)),
  submitUpdatePassword: (password, passwordAgain, token) =>
    dispatch(submitUpdatePassword(password, passwordAgain, token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordForm)
