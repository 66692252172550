import {
  SET_INVENTORY_FILTER_ACTION,
  FETCH_INSTANT_BOOK_SERVICES_ACTION,
  RE_FETCH_INSTANT_BOOK_SERVICES_ACTION,
  SET_INFINITE_SCROLL_POSITION,
} from "./constants"
import {
  FETCH_PROFILE_SUCCESS_ACTION,
  LOG_OUT_ACTION,
} from "../Login/constants"

export const initialState = {
  infiniteScrollPosition: 0,
  infiniteScrollPositionKey: null,
  infiniteScrollPositionNumberOfPages: 0,
  instantbookservices: {},
  instantbookservicecount: 0,

  isFetching: false,
  isScrolling: false,
  lastFilterWasBroadened: false,
  readyToFetch: false,
  anyMorePages: true,
  noResults: false,
  reloadResults: true,

  whatOpen: false,
  whenOpen: false,
  whereOpen: false,

  mapOpen: false,
  mapZoom: 13,
  bbox: null,
  mapCenter: {
    lat: -36.8465517,
    lng: 174.7657366,
  },

  mapCompanies: [],
}

const anyMorePages = (action) => {
  if (
    action.payload.response.count &&
    action.payload.response.count > action.payload.action.payload.filter.offset
  ) {
    return true
  }
  return false
}

const mergeServiceArray = (serviceStore, services, offset) => ({
  ...serviceStore,
  [offset]: services,
})

const handleWsMessage = (state, action) => {
  // Pusher packet carrying availability results from scrape
  if (action.payload.type === "result") {
    const availabilities = action.payload.res
    const scrapeDate = action.payload.start_date

    // If we have at least one availability we should see if it needs to be inserted
    // into existing instant book service records on the homepage
    if (availabilities.length > 0) {
      const { instantbookservices } = state

      const serviceId = availabilities[0].service.id

      const keys = Object.keys(instantbookservices)

      keys.forEach((key) => {
        const services = instantbookservices[key]

        services.forEach((service, i) => {
          // Pusher data matches for both service and date
          if (service.id === serviceId && service.date === scrapeDate) {
            // Don't overwrite existing availabilities supplied by either API or a previous pusher packet
            if ("availabilities" in service) {
              return
            }

            instantbookservices[key][i].availabilities = availabilities
          }
        })
      })

      return {
        ...state,
        instantbookservices,
      }
    }
  }

  return state
}

export default function myBookingsReducer(state = initialState, action) {
  switch (action.type) {
    case SET_INFINITE_SCROLL_POSITION:
      const {
        position: infiniteScrollPosition,
        key: infiniteScrollPositionKey,
        numberOfPages: infiniteScrollPositionNumberOfPages,
      } = action.payload
      return {
        ...state,
        infiniteScrollPosition,
        infiniteScrollPositionKey,
        infiniteScrollPositionNumberOfPages,
      }
    case "HOME_CLICKED":
      return {
        ...state,
        whatOpen: false,
        whenOpen: false,
        whereOpen: false,
      }
    case "INSTANT_BOOK_MAP_SET_CENTER":
      return {
        ...state,
        mapCenter: action.payload.center,
      }
    case "INSTANT_BOOK_MAP_SHOW":
      return {
        ...state,
        mapOpen: true,
      }
    case "INSTANT_BOOK_MAP_HIDE":
      return {
        ...state,
        mapOpen: false,
      }
    case FETCH_PROFILE_SUCCESS_ACTION:
    case "FETCH_PROFILE_FAILURE":
    case "NO_STORED_CREDENTIALS":
      return {
        ...state,
        readyToFetch: true,
      }
    case "SCRAPE_WS_MESSAGE":
      return handleWsMessage(state, action)
    case "FETCH_INSTANT_BOOK_SERVICES_SUCCESS":
      return {
        ...state,
        instantbookservices: mergeServiceArray(
          state.instantbookservices,
          action.payload.response.inventory,
          action.payload.action.payload.filter.offset
        ),
        instantbookservicecount: action.payload.response.count,
        anyMorePages: anyMorePages(action),
        noResults: action.payload.response.count === 0,
        mapCompanies: action.payload.response.companies || [],
      }
    case "CLEAR_INSTANT_BOOK_SERVICES":
      return {
        ...state,
        noResults: false,
        instantbookservices: {},
      }
    case FETCH_INSTANT_BOOK_SERVICES_ACTION:
      return {
        ...state,
        noResults: false,
        instantbookservices: action.payload.clear
          ? {}
          : state.instantbookservices,
      }
    case RE_FETCH_INSTANT_BOOK_SERVICES_ACTION:
      return {
        ...state,
        isScrolling: true,
      }
    case SET_INVENTORY_FILTER_ACTION:
      return {
        ...state,
        isScrolling: false,
      }
    case "CLICK_WHEN_MENU":
      return {
        ...state,
        whenOpen: !state.whenOpen,
        whatOpen: false,
        whereOpen: false,
      }
    case "CLICK_WHAT_MENU":
      return {
        ...state,
        whatOpen: !state.whatOpen,
        whenOpen: false,
        whereOpen: false,
      }
    case "CLICK_WHERE_MENU":
      return {
        ...state,
        whereOpen: !state.whereOpen,
        whatOpen: false,
        whenOpen: false,
      }
    case LOG_OUT_ACTION:
      return {
        ...initialState,
        readyToFetch: true,
      }
    default:
      return state
  }
}
