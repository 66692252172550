import styled from "styled-components"

/* Container for individual category row. eg Manicure, Pedicure */
export const StyledCategoryItem = styled.div`
  min-height: 42px;
  margin: 0px;
  font-size: 14px;
  font-weight: 300;
  color: #000;
  display: flex;
  padding: 10px 14px;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightHover};
  }
`
