import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import Carousel from "react-multi-carousel"

import { useLocation, useNavigate } from "react-router-dom"
import { TagIcon } from "../icons"

import {
  urlToFilter,
  filterToUrl,
} from "../../containers/InstantBook/urlFilter"

const Announcement = styled.div`
  cursor: pointer;
  min-height: 30px;
  padding: 10px;
  background-color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    color: ${({ theme }) => theme.colors.primaryAccent};
  }
`

const AnnouncementTag = styled(TagIcon)`
  fill: ${({ theme }) => theme.colors.primaryAccent};
  margin-right: 10px;
`

function CampaignBanner({
  className = "",
  campaigns,
  companies,
  categories,
  regions,
}) {
  if (campaigns.length === 0) {
    return null
  }

  const location = useLocation()

  const navigate = useNavigate()

  const clickCampaign = (campaign) => {
    const filter = urlToFilter(
      location.pathname,
      campaigns,
      companies,
      categories,
      regions
    )
    if (filter.selectedCampaigns.includes(campaign)) {
      filter.selectedCampaigns = filter.selectedCampaigns.filter(
        (r) => r.slug !== campaign.slug
      )
    } else {
      filter.selectedCampaigns.push(campaign)
    }

    navigate(filterToUrl(filter))
  }

  const renderCampaign = (campaign) => (
    <Announcement
      key={`campaign-${campaign.slug}`}
      onClick={() => {
        clickCampaign(campaign)
      }}
    >
      <AnnouncementTag style={{}} />
      <span>{campaign.banner}</span>
    </Announcement>
  )

  return (
    <Carousel
      className={`campaign-banner ${className}`}
      responsive={{
        desktop: {
          breakpoint: { max: 3500, min: 1024 },
          items: 1,
        },
        tablet: {
          breakpoint: { max: 1024, min: 768 },
          items: 1,
        },
        mobile: {
          breakpoint: { max: 768, min: 0 },
          items: 1,
        },
      }}
      arrows={false}
      showDots={false}
      autoPlay={campaigns.length > 1}
      autoPlaySpeed={2500}
      infinite={campaigns.length > 1}
    >
      {campaigns.map(renderCampaign)}
    </Carousel>
  )
}

CampaignBanner.propTypes = {
  loggedIn: PropTypes.bool,
  className: PropTypes.string,
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  companies: PropTypes.arrayOf(PropTypes.object).isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  regions: PropTypes.arrayOf(PropTypes.object).isRequired,
}

const mapStateToProps = (state) => ({
  loggedIn: !!state.profile.profile.id,
})

export default connect(mapStateToProps)(CampaignBanner)
