import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment-timezone"
import styled, { withTheme } from "styled-components"
import { Trans } from "react-i18next"

import { Link, useMatches } from "react-router-dom"
import { logOut, preLogOut } from "../Profile/actions"

import {
  AnchorLinkMenu,
  StyledLink as BaseStyledLink,
} from "../../components/nav/Links"

import PoweredByFlossie from "../../components/PoweredByFlossie"

import * as faqSelectors from "../Faqs/selectors"

const { RETURN_LINK_TITLE, RETURN_LINK_URL, SHOW_PBF_SIDE_MENU } = SITE_CONFIG

const StyledLink = styled(BaseStyledLink)`
  text-transform: uppercase;
  padding: 0 10px;

  @media ${({ theme }) => theme.responsive.large} {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1.2px solid #aaa;
  }
`

const SpanLinkMenu = styled.span`
  font-size: 15px;
  color: ${({ theme, active }) =>
    active ? theme.sideNav.activeLinks : theme.sideNav.links};
  font-weight: 600;
  cursor: pointer;
  padding-bottom: 10px;
  border-bottom: 1.2px solid #aaa;
  text-align: right;
  text-transform: uppercase;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-appearance: none;
`

const SideMenuDiv = styled.div`
  position: fixed;
  top: ${({ theme }) => theme.sideNav.top};
  z-index: 008;
  height: 100%;
  min-height: 680px;
  background-color: ${({ theme }) => theme.sideNav.background};
  padding: 30px;
  display: flex;
  flex-direction: column;
  transition: right 0.4s;
  transition: left 0.4s;
  @media ${({ theme }) => theme.responsive.large} {
    width: 200px;
  }
`

const StyledBottomContainer = styled.div`
  position: absolute;
  bottom: 225px;
  width: calc(100% - 60px);
`

const Copyright = styled.p`
  text-align: right;
  font-size: 10px;
  line-height: 14px;
  font-weight: 400;
  color: ${({ theme }) => theme.sideNav.legalLinks};
`

const StyledShowMenuParent = styled.div`
  z-index: 6;
  position: "fixed";
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.overlayColor};
`

const LegalLink = styled(Link)`
  color: ${({ theme }) => theme.sideNav.legalLinks};
  font-size: 10px;
  display: block;
`

const StyledPrivacyContainer = styled.div`
  margin-top: 20;
  font-size: 10;
  color: ${({ theme }) => theme.sideNav.legalLinks};
  text-align: right;
`

const MenuLink = styled(SpanLinkMenu)`
  margin-bottom: 10px;
  width: 200px;
`

const MenuPoweredByFlossie = styled(PoweredByFlossie)`
  position: static;
  display: inline-block;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;

  a {
    text-transform: none !important;
  }
`

function NavBarLink({ children, closeMenu, to, $isActive }) {
  return (
    <StyledLink
      to={to}
      $isActive={$isActive}
      onClick={() => typeof closeMenu === "function" && closeMenu()}
    >
      {children}
    </StyledLink>
  )
}

NavBarLink.propTypes = {
  children: PropTypes.node.isRequired,
  closeMenu: PropTypes.func,
  to: PropTypes.string.isRequired,
  $isActive: PropTypes.bool.isRequired,
}

function NavBarMenu({
  className = "",
  closeMenu,
  menuOpen,
  loggedIn,
  isMobile,
  showFooter = true,
  hasFaqs = false,
  theme,
  id = "menu",
  preLogOut,
  logOut,
}) {
  const routeMatches = useMatches()
  let menuSection = null
  if (Array.isArray(routeMatches) && routeMatches.length > 0) {
    menuSection = routeMatches[0].handle?.menuSection
  }

  const clickLogOut = (e) => {
    e.preventDefault()
    preLogOut()
    logOut()
  }

  const sideMenuStyle = {}

  if (theme.sideNav.alignment === "left") {
    sideMenuStyle.left = menuOpen ? "0px" : "-260px"
  } else {
    sideMenuStyle.right = menuOpen ? "0px" : "-260px"
  }

  return (
    <div
      className={className}
      style={{
        margin: "0 auto",
        maxWidth: theme.pageMaxWidth,
        position: "relative",
      }}
    >
      {menuOpen && <StyledShowMenuParent />}
      <SideMenuDiv id={id} style={sideMenuStyle} menuOpen={menuOpen}>
        <NavBarLink
          closeMenu={closeMenu}
          to="/"
          $isActive={menuSection === "home"}
        >
          <Trans i18nKey="sidenav_home" />
        </NavBarLink>

        {loggedIn && (
          <NavBarLink
            closeMenu={closeMenu}
            to="/bookings"
            $isActive={menuSection === "bookings"}
          >
            <Trans i18nKey="sidenav_bookings" />
          </NavBarLink>
        )}
        {loggedIn && (
          <NavBarLink
            closeMenu={closeMenu}
            $isActive={menuSection === "favourites"}
            to="/favourites"
          >
            <Trans i18nKey="sidenav_favourites" />
          </NavBarLink>
        )}
        {loggedIn && (
          <NavBarLink
            closeMenu={closeMenu}
            $isActive={menuSection === "account"}
            to="/account"
          >
            <Trans i18nKey="sidenav_profile" />
          </NavBarLink>
        )}
        {SITE_CONFIG.SHOW_INSPIRATION && (
          <NavBarLink
            closeMenu={closeMenu}
            $isActive={menuSection === "articles"}
            i18n="sidenav_inspiration"
            to="/articles"
          >
            <Trans i18nKey="sidenav_inspiration" />
          </NavBarLink>
        )}
        {SITE_CONFIG.SHOW_YOUR_HEALTH && (
          <NavBarLink
            closeMenu={closeMenu}
            $isActive={menuSection === "health"}
            to="/your-health"
          >
            <Trans i18nKey="sidenav_your_health" />
          </NavBarLink>
        )}
        {hasFaqs && (
          <NavBarLink
            closeMenu={closeMenu}
            to="/help"
            $isActive={menuSection === "help"}
          >
            <Trans i18nKey="sidenav_help" />
          </NavBarLink>
        )}
        {!loggedIn && (
          <NavBarLink
            closeMenu={closeMenu}
            $isActive={menuSection === "login" || menuSection === "register"}
            to="/login"
          >
            <Trans i18nKey="sidenav_login" />
          </NavBarLink>
        )}
        {!loggedIn && isMobile && (
          <NavBarLink
            closeMenu={closeMenu}
            $isActive={menuSection === "register"}
            to="/register"
          >
            <Trans i18nKey="sidenav_register" />
          </NavBarLink>
        )}

        {loggedIn && (
          <MenuLink
            $isActive
            style={{ borderBottom: "none", marginTop: 20 }}
            onClick={clickLogOut}
          >
            <Trans i18nKey="sidenav_logout" />
          </MenuLink>
        )}

        {showFooter && (
          <StyledBottomContainer isMobile={isMobile}>
            <StyledPrivacyContainer>
              <LegalLink onClick={closeMenu} to="/privacy">
                Cookie &amp; Privacy Policy
              </LegalLink>
              <LegalLink onClick={closeMenu} to="/terms/customer">
                Booking Terms &amp; Conditions
              </LegalLink>
              {SHOW_PBF_SIDE_MENU && <MenuPoweredByFlossie />}
              <Copyright>
                © Flossie.com Limited {moment().format("YYYY")}
                <br />
                All Rights Reserved
              </Copyright>
            </StyledPrivacyContainer>
            {RETURN_LINK_URL && RETURN_LINK_TITLE && (
              <AnchorLinkMenu
                onClick={closeMenu}
                style={{
                  width: 200,
                  marginBottom: 10,
                  fontSize: "13px",
                  marginTop: 20,
                  paddingBottom: 0,
                  display: "block",
                  textAlign: "right",
                  borderBottom: "none",
                }}
                to={RETURN_LINK_URL}
              >
                {RETURN_LINK_TITLE}
              </AnchorLinkMenu>
            )}
          </StyledBottomContainer>
        )}
      </SideMenuDiv>
    </div>
  )
}

NavBarMenu.propTypes = {
  closeMenu: PropTypes.func,
  loggedIn: PropTypes.bool.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  logOut: PropTypes.func.isRequired,
  preLogOut: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  hasFaqs: PropTypes.bool,
  theme: PropTypes.object.isRequired,
  className: PropTypes.string,
  id: PropTypes.string,
  showFooter: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  loggedIn: !!state.profile.profile.id,
  menuOpen: state.navBar.menuOpen,
  userId: state.profile.profile.id,
  userEmail: state.profile.profile.username,
  isMobile: state.browser.lessThan.mobile,
  hasFaqs: faqSelectors.getShowFaqs(state),
})

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(logOut()),
  preLogOut: () => dispatch(preLogOut()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(NavBarMenu))
