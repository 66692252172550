import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Countries from "./Countries"
import TopCompanies from "./TopCompanies"
import SearchResults from "./SearchResults"

export function ListView({
  companySearch,
  isSearchingCompanies,
  topCompanies,
}) {
  if (isSearchingCompanies) {
    return <div style={{ textAlign: "center" }}>Searching</div>
  }

  const containerRef = React.useRef(null)

  useEffect(() => {
    if (containerRef.current !== null) {
      const firstSelectedCompany = document.querySelector(
        "[data-filter-company-selected]"
      )
      if (firstSelectedCompany) {
        firstSelectedCompany.scrollIntoView({
          behavior: "smooth",
          block: "center",
        })
      }
    }
  }, [containerRef])

  return (
    <div ref={containerRef}>
      {topCompanies.length > 0 && companySearch === "" && (
        <TopCompanies topCompanies={topCompanies} />
      )}
      {companySearch === "" ? (
        <Countries />
      ) : (
        <SearchResults query={companySearch} />
      )}
    </div>
  )
}

ListView.propTypes = {
  companySearch: PropTypes.string.isRequired,
  isSearchingCompanies: PropTypes.bool.isRequired,
  topCompanies: PropTypes.array,
}
