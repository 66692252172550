import React from "react"

const { FRANCHISE_ID, RETURN_LINK_URL } = SITE_CONFIG

function AvedaNoSearchResultsSalonLocatorMessage() {
  if (!["11", "12"].includes(FRANCHISE_ID)) {
    return null
  }

  return (
    <p>
      Looking for another Aveda salon location near you? View the full list
      &nbsp;<a href={`${RETURN_LINK_URL}/locations`}>here</a>
    </p>
  )
}

export default AvedaNoSearchResultsSalonLocatorMessage
