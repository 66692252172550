import "rxjs/add/operator/mergeMap"
import "rxjs/add/operator/map"
import "rxjs/add/operator/catch"
import {
  resetPassword,
  apiUpdateUserPassword,
  handleXhrError
} from "../../api/api"
import { storeCredentials, submitLoginFulfulled } from "../Login/epics"

const clearPreviousLocation = () => {
  const { localStorage } = window
  localStorage.removeItem("REDIRECT_TO")
}

const resetPasswordFulfilled = response => ({
  type: "RESET_PASSWORD_SUCCESS",
  payload: { response }
})

const updatePasswordFulfilled = response => {
  return { type: "UPDATE_PASSWORD_SUCCESS", payload: { response } }
}

export const resetPasswordEpic = (action$) => action$.ofType("SUBMIT_RESET_PASSWORD").exhaustMap(action => resetPassword(action.payload.email, action.payload.googleRecaptchaToken)
  .map(response => resetPasswordFulfilled(response, action))
  .catch(error => handleXhrError(error, "RESET_PASSWORD_FAILURE")))

export const updateUserPasswordEpic = (action$) => action$.ofType("SUBMIT_UPDATE_PASSWORD").mergeMap(action => apiUpdateUserPassword(action.payload)
  .map(response => updatePasswordFulfilled(response, action))
  .catch(error => handleXhrError(error, "UPDATE_PASSWORD_FAILURE")))

export const updatePasswordFullfilledEpic = (action$) => action$.ofType("UPDATE_PASSWORD_SUCCESS")
  .filter(action => action.payload.response)
  .mergeMap(action => ([
    submitLoginFulfulled(
      storeCredentials(action.payload.response.token),
      true
    )]
  ))