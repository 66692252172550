import React from "react"
import PropTypes from "prop-types"
import { PromoTag } from "../../../components/SaleTags"
import { ThemedSpan } from "../../InventoryView/components"
import { useCalendarBookingModuleContext } from "../CalendarBookingModuleContext"
import SelectedEmployeeCard from "./SelectedEmployeeCard"
import { StyledLabel } from "./styledComponents"
import AnyStylistLabel from "./AnyStylistLabel"

export function EmployeeSelectButton({ role }) {
  const { employee, inventoryItem } = useCalendarBookingModuleContext()

  const employeeOnSale = (e) =>
    e.pricing.lowest.price !== e.pricing.default.price && e.pricing.lowest.sale

  const { currency, employees, hide_any_employee, pricing } = inventoryItem

  const onSale = employees.some(employeeOnSale)

  /* Calculate price range to be displayed when nothing is yet selected */
  let priceRange = currency.symbol + pricing.lowest.price

  if (pricing.highest.price !== pricing.lowest.price) {
    priceRange = `${priceRange} - ${currency.symbol}${pricing.highest.price}`
  }

  if (!employees) return null

  // Can't show a card if there's no employee AND we're hiding the any employee card
  // (This should never happen by the way)
  if (!employee && hide_any_employee) return null

  if (employee !== null && typeof employee === "object") {
    return <SelectedEmployeeCard employee={employee} onSale={onSale} />
  }

  if (!hide_any_employee) {
    return <AnyStylistLabel role={role} onSale={onSale} />
  }

  return (
    <StyledLabel>
      <ThemedSpan highlighted>{`Select your ${role
        .trim()
        .toLowerCase()}:`}</ThemedSpan>
      {priceRange && (
        <span style={{ paddingLeft: 8, fontWeight: 500 }}>{priceRange}</span>
      )}
      {onSale && (
        <PromoTag style={{ padding: "6px 0px 0px 4px" }} align="left" />
      )}
    </StyledLabel>
  )
}

EmployeeSelectButton.propTypes = {
  role: PropTypes.string,
}
