import {
  FETCH_PROFILE_SUCCESS_ACTION,
  LOG_OUT_ACTION,
} from "../Login/constants"
import {
  AUTHENTICATION_FORM_INIT_ACTION,
  PROFILE_FORM_INIT_ACTION,
} from "./constants"

export const initialState = {
  profile: {
    id: "",
    existing_card: false,
    vip: false,
    phone_number: "",
  },
  profileForm: {},
  authenticationForm: {},
  failed: false,
  saving: false,
  saved: false,
  error: "",
}

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case "SAVE_PROFILE":
      return {
        ...state,
        saving: true,
      }
    case "SAVE_AUTHENTICATION":
      return {
        ...state,
        saving: true,
      }
    case "SAVE_PROFILE_SUCCESS":
      return {
        ...state,
        profile: {
          ...state.profile,
          first_name: state.profileForm.first_name,
          last_name: state.profileForm.last_name,
          // username: state.authenticationForm.username,
          phone_number: state.profileForm.phone_number,
        },
        saved: true,
        saving: false,
        failed: false,
      }
    case "SAVE_AUTHENTICATION_SUCCESS":
      return {
        ...state,
        profile: {
          ...state.profile,
          username: state.authenticationForm.username,
        },
        saved: true,
        saving: false,
        failed: false,
      }
    case "SAVE_PROFILE_FAILURE":
    case "SAVE_AUTHENTICATION_FAILURE":
      let error = ""

      if (
        action.payload.error_response.response.status === 412 &&
        action.payload.error_response.response.data.message
      ) {
        error = action.payload.error_response.response.data.message
      }
      return {
        ...state,
        saved: false,
        saving: false,
        failed: true,
        error,
      }
    case "AUTHENTICATION_FORM_USERNAME_UPDATE":
      return {
        ...state,
        saved: false,
        authenticationForm: {
          ...state.authenticationForm,
          username: action.payload,
        },
      }
    case "PROFILE_FORM_PHONE_UPDATE":
      return {
        ...state,
        saved: false,
        failed: false,
        profileForm: {
          ...state.profileForm,
          phone_number: action.payload,
        },
      }
    case "PROFILE_FORM_FIRST_NAME_UPDATE":
      return {
        ...state,
        saved: false,
        failed: false,
        profileForm: {
          ...state.profileForm,
          first_name: action.payload,
        },
      }
    case "PROFILE_FORM_LAST_NAME_UPDATE":
      return {
        ...state,
        saved: false,
        failed: false,
        profileForm: {
          ...state.profileForm,
          last_name: action.payload,
        },
      }
    case "AUTHENTICATION_FORM_PASSWORD_UPDATE":
      return {
        ...state,
        saved: false,
        failed: false,
        authenticationForm: {
          ...state.authenticationForm,
          password: action.payload,
        },
      }
    case "AUTHENTICATION_FORM_CURRENT_PASSWORD_UPDATE":
      return {
        ...state,
        saved: false,
        failed: false,
        authenticationForm: {
          ...state.authenticationForm,
          current_password: action.payload,
        },
      }
    case PROFILE_FORM_INIT_ACTION:
      return {
        ...state,
        profileForm: {
          id: state.profile.id,
          first_name: state.profile.first_name,
          last_name: state.profile.last_name,
          phone_number: state.profile.phone_number,
        },
        failed: false,
        saved: false,
      }

    case AUTHENTICATION_FORM_INIT_ACTION:
      return {
        ...state,
        authenticationForm: {
          username: state.profile.username,
          current_password: "",
          password: "",
        },
        failed: false,
        saved: false,
      }
    case "FETCH_PROFILE_FAILURE":
      return {
        ...state,
        failed: true,
      }
    case FETCH_PROFILE_SUCCESS_ACTION:
      return {
        ...state,
        profile: action.payload.response,
      }
    case LOG_OUT_ACTION:
      return initialState
    default:
      return state
  }
}
