import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const StyledP = styled.div`
  font-weight: bold;
  color: ${({ color, theme }) => color || theme.colors.primary};
  margin: 0;
  text-decoration: ${(props) =>
    props.strikethrough ? "line-through" : "none"};
  font-size: 19px;
  flex: 1;
  text-align: right;
  display: flex;
  flex-direction: column;
`

const CurrencyCode = styled.small`
  color: ${(props) => props.theme.colors.secondary};
  font-weight: 100;
`

export function PriceBlock({
  price,
  currency,
  strikethrough = false,
  color = null,
  style,
  showCode,
}) {
  return (
    <StyledP strikethrough={strikethrough} color={color} style={style}>
      <div>
        {currency.symbol}
        {price}
      </div>
      <CurrencyCode>{showCode && ` ${currency.code}`}</CurrencyCode>
    </StyledP>
  )
}

PriceBlock.propTypes = {
  price: PropTypes.any.isRequired, // TODO, Should be consistant not either number or string
  currency: PropTypes.object.isRequired,
  strikethrough: PropTypes.bool,
  color: PropTypes.string,
  style: PropTypes.object,
  showCode: PropTypes.bool,
}
