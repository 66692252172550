import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "styled-components"
import moment from "moment-timezone"
import { useMatches, useNavigate, useParams } from "react-router-dom"
import { fetchOrder, addToCalendarTrack } from "./actions"
import { cancelBooking } from "../MyBookings/actions"

import BookingItemCard from "../../components/cards/BookingItemCard"
import { AccordianHeader, AccordianContent } from "../../components/accordians"

import { SubmitInput } from "../../components/forms/Inputs"
import ParagraphText from "../../components/ParagraphText"
import WrapContainer from "../WrapContainer"
import ShadowBox from "../../components/ShadowBox"
import { CompanyMap } from "../../components/datarenderers/CompanyMap"
import { BackLink } from "../../components/nav/Links"
import Seperator from "../../components/Seperator"
import { AddToCalendarButton } from "../../components/datarenderers"
import useInventoryNavigator from "../../hooks/useInventoryNavigator"
import { FETCH_PROFILE_ACTION } from "./constants"

const InformationText = styled.p`
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
`

const Header = styled.h2`
  color: ${(props) => props.theme.colors.secondary};
  margin: 0px 46px 20px 46px;
  font-size: 19px;
  font-weight: bold;
  display: inline-block;
  max-width: ${(props) => (props.isMobile ? "260px" : "none")};
`

function OrderViewContainer({
  cancelBooking,
  credentials,
  fetchOrder,
  fetchProfile,
  isFetchingOrder,
  isMedium,
  isMobile,
  isLarge,
  order,
  profile,
}) {
  const [accordionTab, setAccordionTab] = useState("")

  const params = useParams()

  const {
    handle: { confirmation },
  } = useMatches()[2]

  const navigate = useNavigate()

  const inventoryNavigate = useInventoryNavigator()

  useEffect(() => {
    window.scrollTo(0, 0)
    fetchOrder(params.id)
    if (profile.id && confirmation === true) {
      fetchProfile()
    }
  }, [])

  const navToRebook = (booking) => {
    inventoryNavigate({
      serviceSlug: booking.service.slug,
      date: moment().format("YYYY-MM-DD"),
    })
  }

  const closePoupUp = (e) => {
    e.preventDefault()
    navigate(`/bookings/${params.id}`)
  }

  const handleCancelBooking = (bookingId) => {
    const shouldRedirect = order.bookings.length === 1
    // if we have only one booking and we are cancel it we tell the action to redirect after is done
    cancelBooking(bookingId, shouldRedirect)
  }

  const renderConfirmationPopup = () => {
    if (!confirmation || isFetchingOrder) return null

    const multipleBookings = order.bookings.length > 1
    return (
      <ShadowBox
        containerStyle={{
          minWidth: 0,
          minHeight: 0,
          maxWidth: 400,
          marginLeft: 10,
          marginRight: 10,
          textAlign: "left",
        }}
        isFullscreen={false}
        header={
          multipleBookings
            ? "Your appointments are confirmed!"
            : "Your appointment is confirmed!"
        }
        closeBox={closePoupUp}
      >
        <InformationText>
          You will receive an email confirmation shortly
        </InformationText>

        <div
          style={{
            textAlign: "center",
            margin: "15px auto",
            marginBottom: 0,
            display: "block",
          }}
        >
          <AddToCalendarButton orderId={order.id} credentials={credentials} />
        </div>
      </ShadowBox>
    )
  }

  const renderBooking = (booking) => {
    if (isFetchingOrder) return null
    if (!booking) return null

    const {
      id,
      service,
      company,
      appointment_time,
      duration,
      price,
      products,
    } = booking

    const { currency, description } = service

    const { terms_and_conditions, preparation, about } = description

    return (
      <div
        key={id}
        style={{
          margin: "0 auto",
          marginBottom: 60,
          maxWidth: 960,
          padding: "0 15px",
          display: isMedium ? "block" : "flex",
          textAlign: isMedium ? "center" : "left",
        }}
      >
        <div style={{ width: isMedium ? "100%" : "50%", margin: "0 auto" }}>
          <BookingItemCard
            booking={booking}
            isMobile={isMobile}
            price={price}
            currency={currency}
            startTime={appointment_time}
            duration={duration}
            extras={products}
            onCancel={handleCancelBooking}
            credentials={credentials}
            onRebook={(e) => {
              e.preventDefault()
              e.stopPropagation()
              navToRebook(booking)
            }}
          />

          <div style={{ flex: 1, padding: 0 }}>
            <Seperator />
            <AccordianHeader
              title="About This Service"
              open={accordionTab === "about"}
              onClick={() => setAccordionTab("about")}
            />
            <AccordianContent open={accordionTab === "about"}>
              <ParagraphText text={about} />
            </AccordianContent>

            <Seperator />
            {preparation && (
              <div>
                <AccordianHeader
                  title="How to Prepare"
                  open={accordionTab === "prepare"}
                  onClick={() => setAccordionTab("prepare")}
                />
                <AccordianContent open={accordionTab === "prepare"}>
                  <ParagraphText text={preparation} />
                </AccordianContent>

                <Seperator />
              </div>
            )}

            {terms_and_conditions && (
              <div>
                <AccordianHeader
                  title="Booking Terms"
                  open={accordionTab === "terms"}
                  onClick={() => setAccordionTab("terms")}
                />
                <AccordianContent open={accordionTab === "terms"}>
                  <ParagraphText text={terms_and_conditions} />
                </AccordianContent>
                <Seperator />
              </div>
            )}

            {booking.notes && (
              <div>
                <AccordianHeader
                  title="Booking Notes"
                  open={accordionTab === "bookingNotes"}
                  onClick={() => setAccordionTab("bookingNotes")}
                />
                <AccordianContent open={accordionTab === "bookingNotes"}>
                  <ParagraphText text={booking.notes} />
                </AccordianContent>
                <Seperator />
              </div>
            )}
          </div>
        </div>

        <div
          style={{
            width: isMedium ? "100%" : "50%",
            margin: "0 auto",
            padding: isMedium ? "15px 0" : "15px 0 0 30px",
          }}
        >
          <CompanyMap company={company} />
        </div>
      </div>
    )
  }

  const renderBookings = () => {
    if (isFetchingOrder || !order.bookings || order.bookings.length === 0)
      return null
    const multipleBookings = order.bookings.length > 1
    const appointmentTime = order.bookings
      .map((booking) => booking.appointment_time)
      .reduce((latestTime, booking) => {
        if (!latestTime) {
          return booking.appointment_time
        }

        if (moment(booking.appointment_time).isAfter(latestTime)) {
          return booking.appointment_time
        }

        return latestTime
      })

    return (
      <>
        <div
          style={{
            margin: "20px auto",
            maxWidth: 960,
            textAlign: "center",
            position: "relative",
          }}
        >
          <BackLink
            href="/"
            onClick={(e) => {
              e.preventDefault()
              navigate("/bookings")
            }}
            style={{ top: 0, left: !isLarge ? 20 : -60 }}
          />
          <Header isMobile={isMobile}>
            {moment(appointmentTime).isBefore()
              ? multipleBookings
                ? "We hope you enjoyed these appointments!"
                : "We hope you enjoyed this appointment!"
              : multipleBookings
              ? "Your appointments are confirmed!"
              : "Your appointment is confirmed!"}
          </Header>
          <InformationText style={{ maxWidth: "360px" }}>
            {moment(appointmentTime).isBefore()
              ? ""
              : `We've emailed you confirming your appointment${
                  multipleBookings ? "s" : ""
                }`}
          </InformationText>
        </div>
        <div>{order.bookings.map((booking) => renderBooking(booking))}</div>
      </>
    )
  }

  return (
    <WrapContainer loginRequired>
      {order && renderBookings()}
      {order && renderConfirmationPopup()}
      {!isFetchingOrder && !order && (
        <div
          style={{ display: "block", margin: "0 auto", textAlign: "center" }}
        >
          <div style={{ margin: "30px 0 15px 0" }}>Order not found</div>
          <SubmitInput
            onClick={() => {
              navigate("/bookings")
            }}
            value="Go to Bookings"
          />
        </div>
      )}
    </WrapContainer>
  )
}

OrderViewContainer.propTypes = {
  fetchOrder: PropTypes.func.isRequired,
  isFetchingOrder: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isLarge: PropTypes.bool.isRequired,
  isMedium: PropTypes.bool.isRequired,
  order: PropTypes.object,
  profile: PropTypes.shape({
    id: PropTypes.number,
  }),
  fetchProfile: PropTypes.func.isRequired,
  credentials: PropTypes.object.isRequired,
  cancelBooking: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    order: state.bookings.order,
    isFetchingOrder: state.bookings.isFetchingOrder,
    credentials: state.credentials.credentials,
    isMobile: state.browser.lessThan.mobile,
    isMedium: state.browser.lessThan.medium,
    isLarge: state.browser.greaterThan.large,
    lastError: state.bookings.lastError,
    profile: state?.profile?.profile,
    hasError: state.bookings.hasError,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchOrder: (id) => dispatch(fetchOrder(id)),
  addToCalendarTrack: () => dispatch(addToCalendarTrack()),
  fetchProfile: () => dispatch({ type: FETCH_PROFILE_ACTION }),
  cancelBooking: (bookingId, shouldRedirect) =>
    dispatch(cancelBooking(bookingId, shouldRedirect)),
})

export default connect(mapStateToProps, mapDispatchToProps)(OrderViewContainer)
