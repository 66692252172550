import React from "react"
import PropTypes from "prop-types"
import Category from "./Category"

function SearchResults({
  categories: allCategories,
  onClickCategory,
  selectedCategories,
  query,
}) {
  const categories = allCategories
    .filter((eachCategory) =>
      query
        .toLowerCase()
        .split(" ")
        .every(
          (searchToken) =>
            eachCategory.name.toLowerCase().includes(searchToken) ||
            eachCategory.keywords.toLowerCase().includes(searchToken)
        )
    )
    .map((eachCategory) => ({
      ...eachCategory,
      selected: Boolean(
        selectedCategories.find(
          (eachSelectedCategory) => eachSelectedCategory.id === eachCategory.id
        )
      ),
    }))

  return (
    <div>
      <div>Search Results</div>
      {categories.map((eachCategory) => (
        <Category
          key={eachCategory.id}
          category={eachCategory}
          onClick={onClickCategory}
        />
      ))}
    </div>
  )
}

SearchResults.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickCategory: PropTypes.func.isRequired,
  selectedCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  query: PropTypes.string.isRequired,
}

export default SearchResults
