import {
  INIT_INVENTORY_FILTER_ACTION,
  SET_GEO_LOCATION_ACTION,
  SET_INVENTORY_FILTER_ACTION,
} from "../InstantBook/constants"
import { LOG_OUT_ACTION } from "../Login/constants"
import { FETCH_INSTANT_BOOK_SERVICES_ACTION } from "./constants"
import { getInventoryFilterFromState } from "./lib"

const { DEFAULT_SORT_ORDER } = SITE_CONFIG

export const initialState = {
  selectedDays: [],
  selectedCategoryGroups: [],
  selectedCategories: [],
  selectedCampaigns: [],
  selectedCompanies: [],
  selectedRegions: [],
  selectedCountries: [],

  lastFilter: null,
  lastFilterState: {
    selectedDays: [],
    selectedCategories: [],
    selectedCampaigns: [],
    selectedCompanies: [],
    selectedRegions: [],
    sortBy: null,
  },
  lastFilterAddedName: null,
  lastFilterMessageShow: 0,

  currentPage: 0,
  everFetched: false,
  showTopCategories: false,
  showTopCompanies: false,
  showCampaigns: false,

  sortBy: null,
  vipOnly: false,
  saleOnly: false,
  bookmarksOnly: false,

  latitude: null,
  longitude: null,
  mapZoom: 14,
  bbox: null,

  catGroups: [],
  catCategories: [],

  regions: [],
  companies: [],
  campaigns: [],

  companySearch: "",
  isSearchingCompanies: false,
}

const getLastFilterState = (state) => ({
  selectedCampaigns: state.selectedCampaigns,
  selectedCategories: state.selectedCategories,
  selectedCompanies: state.selectedCompanies,
  selectedDays: state.selectedDays,
  selectedRegions: state.selectedRegions,
  sortBy: state.sortBy,
})

const toggleArray = (selected, id) => {
  if (selected.includes(id)) {
    return selected.filter((c) => c !== id)
  }
  return [...selected, id]
}

export default function inventoryFilter(state = initialState, action) {
  switch (action.type) {
    case INIT_INVENTORY_FILTER_ACTION:
    case SET_INVENTORY_FILTER_ACTION:
      return {
        ...state,
        lastFilter: getInventoryFilterFromState(state, true),
        lastFilterState: getLastFilterState(state),
        ...action.payload.filterState,
        lastFilterMessageShow: state.lastFilterMessageShow === 2 ? 1 : 0,
        currentPage: 0,
      }
    case "FETCH_INSTANT_BOOK_SERVICES_AUTO_UNFAIL":
      return {
        ...state,
        ...state.lastFilterState,
        lastFilterMessageShow: 2,
      }
    case FETCH_INSTANT_BOOK_SERVICES_ACTION:
      return {
        ...state,
        currentPage: action.payload.clear ? 0 : state.currentPage,
        everFetched: true,
      }
    case "FETCH_INSTANT_BOOK_SERVICES_SUCCESS":
      return {
        ...state,
        currentPage: state.everFetched
          ? state.currentPage + 1
          : state.currentPage,
        everFetched: true,
      }
    case "TOGGLE_CATEGORY_GROUP":
      return {
        ...state,
        selectedCategoryGroups: toggleArray(
          state.selectedCategoryGroups,
          action.payload
        ),
      }
    case "FETCH_CATEGORIES_SUCCESS":
      return {
        ...state,
        catGroups: action.payload.response.groups,
        catCategories: action.payload.response.categories,
      }
    case "FETCH_REGIONS_SUCCESS":
      return {
        ...state,
        regions: action.payload.response,
      }
    case "TOGGLE_CAMPAIGNS":
      return {
        ...state,
        showCampaigns: !state.showCampaigns,
      }
    case "TOGGLE_TOP_CATEGORIES":
      return {
        ...state,
        showTopCategories: !state.showTopCategories,
      }
    case "TOGGLE_TOP_COMPANIES":
      return {
        ...state,
        showTopCompanies: !state.showTopCompanies,
      }
    case SET_GEO_LOCATION_ACTION:
      return {
        ...state,
        latitude: action.payload.lat,
        longitude: action.payload.lng,
      }
    case "COMPANY_SEARCH_UPDATE":
      return {
        ...state,
        companySearch: action.payload,
        companies: [],
        isSearchingCompanies: true,
      }
    case "COMPANY_SEARCH_FAILURE":
      return {
        ...state,
        companies: [],
        isSearchingCompanies: false,
      }
    case "COMPANY_SEARCH_SUCCESS":
      return {
        ...state,
        companies: action.payload.response,
        isSearchingCompanies: false,
        // selectedCompanies: removeMissingSelected(action.payload.response, state.selectedCompanies),
      }
    case "SET_MAP_ZOOM":
      return {
        ...state,
        mapZoom: action.payload.zoom,
      }
    case "CLEAR_FILTERS":
      return {
        ...state,
        selectedDays: [],
        selectedCategoryGroups: [],
        selectedCategories: [],
        selectedCompanies: [],
        selectedCampaigns: [],
      }
    case LOG_OUT_ACTION:
      return {
        ...state,
        currentPage: 0,
        everFetched: false,
      }
    case "@@router/LOCATION_CHANGE":
      if (action.payload.pathname === "/") {
        return {
          ...state,
          everFetched: false,
        }
      }
      return state
    default:
      return state
  }
}
