import React from "react"
import { connect } from "react-redux"
import styled from "styled-components"
import PropTypes from "prop-types"
import { browserIsMobile } from "../../../redux/browserSelector"
import { useCampaignWizardContext } from "./CampaignWizardContext"
import { thumborizeFitIn } from "../../../components/images/thumborize"

const TextContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  color: ${({ colour }) => colour};
  @media (max-width: 570px) {
    position: relative;
  }
`

const InnerTextContainer = styled.div`
  @media (max-width: 570px) {
    position: absolute;
    padding-top: 10px;
    top: 0;
  }
  color: inherit;
`

const Headline = styled.h1`
  text-transform: uppercase;
  font-weight: bolder;
  line-height: 30px;
  color: inherit;
  font-size: ${(props) => (props.isMobile ? "24px" : "32px")};
  margin: 0 0 0 ${(props) => (props.isMobile ? "20px" : "34px")};
  width: ${(props) => (props.isMobile ? "50%" : "66%")};
`

const Description = styled.div`
  color: inherit;
  font-size: ${(props) => (props.isMobile ? "16px" : "25px")};
  margin-top: ${(props) => (props.isMobile ? "10px" : "34px")};
  margin-left: ${(props) => (props.isMobile ? "20px" : "34px")};
  width: ${(props) => (props.isMobile ? "50%" : "66%")};
`

function CampaignHeader({ isMobile }) {
  const { campaign } = useCampaignWizardContext()

  const campaignPlacement = isMobile ? "main" : "main_desktop"
  const campaignImage = campaign.images.find(
    (i) => i.placement === campaignPlacement
  )

  if (!campaignImage) {
    return null
  }

  return (
    <div
      title={campaign.headline}
      style={{
        width: "100%",
        minHeight: 200,
        display: "block",
        position: "relative",
      }}
    >
      <div style={{ position: "absolute", width: "100%", height: "100%" }}>
        <TextContainer colour={campaign.headline_color}>
          <InnerTextContainer>
            <Headline isMobile={isMobile}>{campaign.headline}</Headline>
            <Description isMobile={isMobile}>
              {campaign.description}
            </Description>
          </InnerTextContainer>
        </TextContainer>
      </div>
      <img
        alt="campaign"
        src={thumborizeFitIn(encodeURIComponent(campaignImage.url), 1080, 1080)}
        style={{ width: "100%", display: "block" }}
      />
    </div>
  )
}

CampaignHeader.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    isMobile: browserIsMobile(state),
  }
}

export default connect(mapStateToProps)(CampaignHeader)
