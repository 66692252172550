import React from "react"
import PropTypes from "prop-types"
import { CompanyDiv, CompanyName, CompanyRow } from "./styledComponents"
import CompanyAddressBuilder from "../address/CompanyAddressBuilder"
import { FlossieRadioButton } from "../../containers/Purchase/helpers"
import buttonize from "../buttonize"
import { useCompanySelectorContext } from "./CompanySelectorContext"
// eslint-disable-next-line import/no-named-as-default
import { thumborize } from "../images/thumborize"
import { RoundImage } from "../images/RoundImage"
import { RadioButtonIcon } from "../icons"

function Company({ company }) {
  const { allowMultipleSelection, isMobile, clickCompany } =
    useCompanySelectorContext()

  return (
    <CompanyDiv
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonize(() => clickCompany(company))}
      selected={company.selected}
      data-filter-company-selected={company.selected ? "true" : null}
      key={`company-${company.slug}`}
    >
      {company.images && company.images.find((i) => i.placement === "logo") && (
        <RoundImage
          src={thumborize(
            company.images.find((i) => i.placement === "logo").url,
            40 * 4,
            40 * 4
          )}
          size={40}
        />
      )}

      <CompanyRow isMobile={isMobile}>
        <div style={{ flex: 1, textAlign: "left" }}>
          <CompanyName>{company.name}</CompanyName>
          <div>
            <CompanyAddressBuilder
              company={company}
              addressOne
              addressTwo
              district
              region
              postCode
              inline
            />
          </div>
        </div>

        {/* Optional price range used on campaign page company selector */}
        {/* TODO: Fix this - broken since API rebuild
          {c.service && c.service.pricing && (
          <div style={{ display: "flex", margin: isMobile ? "5px 0 0 0" : "0 5px" }}>
            <PriceRange
              lowestPrice={c.service.pricing.lowest.price}
              highestPrice={c.service.pricing.highest.price}
              currency={currency}
              showCode={false}
            />
          </div>
        )} */}
      </CompanyRow>

      {!allowMultipleSelection && (
        <FlossieRadioButton
          selected={company.selected}
          onClick={() => clickCompany(company)}
        />
      )}
      {allowMultipleSelection && (
        <RadioButtonIcon selected={company.selected} />
      )}
    </CompanyDiv>
  )
}

Company.propTypes = {
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    selected: PropTypes.bool.isRequired,
    slug: PropTypes.string.isRequired,
    images: PropTypes.array.isRequired,
  }).isRequired,
  scrollIntoView: PropTypes.bool,
}

export default Company
