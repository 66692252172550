import moment from "moment-timezone"
import { EMPLOYEE_ID_ANY } from "../app/containers/InventoryView/constants"
import { DATE_TO_STRING_FORMAT } from "./momentHelpers"

export const buildInventoryLink = (currentParams, newParams) => {
  const serviceSlug = newParams.serviceSlug || currentParams.serviceSlug
  if (!serviceSlug) {
    throw new Error("No service slug")
  }

  let path = `/services/${serviceSlug}`

  // console.log({ newDate: newParams.date, f: moment().format("YYYY-MM-DD") })
  // let newDate = newParams.date
  // if (moment.isMoment(newDate)) {
  //   newDate = newDate.format("YYYY-MM-DD")
  // }

  const dateValue = newParams.date || currentParams.date
  if (dateValue) {
    path = `${path}/date/${
      moment.isMoment(dateValue)
        ? dateValue.format(DATE_TO_STRING_FORMAT)
        : dateValue
    }`

    // Time must follow date
    const timeValue = newParams.time || currentParams.time
    if (timeValue) {
      path = `${path}/time/${timeValue}`
    }
  }

  if (
    newParams.employeeSlug !== EMPLOYEE_ID_ANY &&
    (newParams.employeeSlug || currentParams.employeeSlug)
  ) {
    const employeeSlugValue =
      newParams.employeeSlug || currentParams.employeeSlug

    path = `${path}/employee/${employeeSlugValue}`
  }

  return path
}
