import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "styled-components"

import {
  TextInput,
  PasswordInput,
  PhoneNumberInput,
  SubmitInput,
  InputLabelWrapper,
} from "../../components/forms/Inputs"

import {
  updateUsername,
  updatePassword,
  updateCurrentPassword,
  updateFirstName,
  updateLastName,
  updatePhone,
  initProfileForm,
  initAuthenticationForm,
  saveProfile,
  saveAuthentication,
} from "./actions"

import profileFormValidator from "../../../utils/profileFormValidator"
import { useAuth } from "../../providers/AuthProvider"

const StyledErrorDiv = styled.div`
  background-color: #e2e2e2;
  margin-bottom: 20px;
  text-align: center;
  padding: 10px 0px;
`

function ProfileFormContainer(props) {
  const {
    authenticationForm,
    isMobile,
    error,
    failed,
    initProfileForm,
    initAuthenticationForm,
    profile,
    profileForm,
    saved,
    saveProfile,
    saveAuthentication,
    saving,
    updateCurrentPassword,
    updateFirstName,
    updateLastName,
    updatePassword,
    updatePhone,
    updateUsername,
  } = props

  const [formErrors, setFormErrors] = useState({})
  const [hasErrors, setHasErrors] = useState(false)

  useEffect(() => {
    setHasErrors(Object.keys(formErrors).length > 0)
  }, [formErrors])

  useEffect(() => {
    if (profile.id) {
      initProfileForm()
      initAuthenticationForm()
    }
  }, [profile])

  const updateFieldValues = (value, type, objectLabel, formFeedbackLabel) => {
    if (!type) return

    const error = profileFormValidator(value, type, formFeedbackLabel)

    // if there is an error, add it to the state.
    if (error) {
      setFormErrors((prev) => ({
        ...prev,
        [`${objectLabel || type}`]: error,
      }))
    } else {
      // otherwise there is no error, delete the object from the state.
      setFormErrors((prev) => {
        delete prev[`${objectLabel || type}`]

        return { ...prev }
      })
    }
  }

  const doUpdateUsername = (e) => {
    updateFieldValues(e.target.value, "email")
    updateUsername(e.target.value)
  }

  const doUpdateFirstName = (e) => {
    updateFieldValues(e.target.value, "name", "firstName", "First Name")
    updateFirstName(e.target.value)
  }

  const doUpdateLastName = (e) => {
    updateFieldValues(e.target.value, "name", "lastName", "Last Name")
    updateLastName(e.target.value)
  }

  const doUpdatePassword = (e) => {
    updatePassword(e.target.value)
  }

  const doUpdateCurrentPassword = (e) => {
    e.preventDefault()
    updateCurrentPassword(e.target.value)
  }

  const doUpdatePhone = (value) => updatePhone(value)

  const renderSaved = () => {
    return (
      <StyledErrorDiv>
        <p style={{ margin: 0 }}>Profile Updated!</p>
      </StyledErrorDiv>
    )
  }

  const renderSaveFailed = () => {
    return (
      <StyledErrorDiv>
        <p style={{ margin: 0 }}>
          {error.length
            ? error
            : "Something went wrong! Please try again later."}
        </p>
      </StyledErrorDiv>
    )
  }

  const renderErrorMessages = () => {
    const globalFormErrors = Object.entries(formErrors)
      .filter(
        ([key]) => !["firstName", "lastName", "phone", "password"].includes(key)
      )
      .map((error) => error[1])

    return (
      globalFormErrors.length > 0 && (
        <StyledErrorDiv>
          {globalFormErrors.map((error, i) => (
            <p key={i} style={{ margin: 0 }}>
              {error}
            </p>
          ))}
        </StyledErrorDiv>
      )
    )
  }

  const renderSaving = () => {
    return (
      <div>
        <p>Saving ... </p>
      </div>
    )
  }

  const renderLoading = () => {
    return (
      <div>
        <p>Loading</p>
      </div>
    )
  }

  if (!profile.id || !Object.keys(authenticationForm).length)
    return renderLoading()

  const inputStyle = isMobile ? { width: "calc(100% - 32px)" } : { width: 217 }

  return (
    <div>
      {renderErrorMessages()}
      {saved && renderSaved()}
      {saving && renderSaving()}
      {failed && renderSaveFailed()}

      <div style={{ marginBottom: 50 }}>
        <InputLabelWrapper id="username" label="Email*" isMobile={isMobile}>
          <TextInput
            id="username"
            onChange={doUpdateUsername}
            error={formErrors.email}
            value={authenticationForm.username}
            type="username"
            style={inputStyle}
          />
        </InputLabelWrapper>

        <InputLabelWrapper
          id="password"
          label="New Password"
          isMobile={isMobile}
        >
          <PasswordInput
            id="password"
            onChange={doUpdatePassword}
            value={authenticationForm.password}
            style={inputStyle}
          />
        </InputLabelWrapper>

        <InputLabelWrapper
          id="current_password"
          label="Current Password*"
          isMobile={isMobile}
        >
          <PasswordInput
            id="current_password"
            onChange={doUpdateCurrentPassword}
            value={authenticationForm.current_password}
            style={inputStyle}
          />
        </InputLabelWrapper>

        <InputLabelWrapper id="save_authentication" label="">
          <SubmitInput
            id="save_authentication"
            value="Update"
            onClick={saveAuthentication}
            disabled={hasErrors ? "disabled" : false}
          />
        </InputLabelWrapper>
      </div>

      <div>
        <InputLabelWrapper
          id="first_name"
          label="First Name*"
          isMobile={isMobile}
        >
          <TextInput
            id="first_name"
            onChange={doUpdateFirstName}
            error={formErrors.firstName}
            value={profileForm.first_name}
            style={inputStyle}
            required
          />
        </InputLabelWrapper>
        <InputLabelWrapper
          id="last_name"
          label="Last Name*"
          isMobile={isMobile}
        >
          <TextInput
            id="last_name"
            onChange={doUpdateLastName}
            value={profileForm.last_name}
            error={formErrors.lastName}
            style={inputStyle}
          />
        </InputLabelWrapper>

        <InputLabelWrapper id="phone" label="Phone*" isMobile={isMobile}>
          <PhoneNumberInput
            label="Phone Number*"
            id="phone-input"
            onChange={doUpdatePhone}
            value={profileForm.phone_number}
          />
        </InputLabelWrapper>

        <InputLabelWrapper id="save_profile" label="">
          <SubmitInput
            id="save_profile"
            value="Save Profile"
            onClick={saveProfile}
            disabled={hasErrors ? "disabled" : false}
          />
        </InputLabelWrapper>
      </div>
    </div>
  )
}

ProfileFormContainer.propTypes = {
  profile: PropTypes.object.isRequired,
  profileForm: PropTypes.object.isRequired,
  authenticationForm: PropTypes.object.isRequired,
  updateUsername: PropTypes.func.isRequired,
  updateFirstName: PropTypes.func.isRequired,
  updateLastName: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  updateCurrentPassword: PropTypes.func.isRequired,
  updatePhone: PropTypes.func.isRequired,
  saveProfile: PropTypes.func.isRequired,
  saveAuthentication: PropTypes.func.isRequired,
  initProfileForm: PropTypes.func.isRequired,
  initAuthenticationForm: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  saved: PropTypes.bool,
  saving: PropTypes.bool,
  failed: PropTypes.bool,
  error: PropTypes.string,
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
    profileForm: state.profile.profileForm,
    authenticationForm: state.profile.authenticationForm,
    saving: state.profile.saving,
    saved: state.profile.saved,
    failed: state.profile.failed,
    error: state.profile.error,
    isMobile: state.browser.lessThan.mobile,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateUsername: (val) => dispatch(updateUsername(val)),
  updateFirstName: (val) => dispatch(updateFirstName(val)),
  updateLastName: (val) => dispatch(updateLastName(val)),
  updatePassword: (val) => dispatch(updatePassword(val)),
  updateCurrentPassword: (val) => dispatch(updateCurrentPassword(val)),
  updatePhone: (val) => dispatch(updatePhone(val)),
  initProfileForm: () => dispatch(initProfileForm()),
  initAuthenticationForm: () => dispatch(initAuthenticationForm()),
  saveProfile: () => dispatch(saveProfile()),
  saveAuthentication: () => dispatch(saveAuthentication()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProfileFormContainer)
