import {
  handleXhrError,
  apiFetchCampaign,
  apiGetSimilarServices,
  apiGetRelatedServices,
} from "../../api/api"

import { navToFourOhFour } from "../FourOhFour/actions"

export const fetchInventoryErrorEpic = (action$) =>
  action$
    .ofType("FETCH_CAMPAIGN_ERROR", "FETCH_COMPANY_FAILURE")
    .map((response) => navToFourOhFour(response.type))

const fetchSimilarServicesFulfilled = (response, action) => ({
  type: "FETCH_SIMILAR_SERVICES_SUCCESS",
  payload: { response, action },
})

const fetchRelatedServicesFulfilled = (response, action) => ({
  type: "FETCH_RELATED_SERVICES_SUCCESS",
  payload: { response, action },
})

export const fetchServicesByServiceDescriptionIdEpic = (action$) =>
  action$.ofType("SELECT_SERVICE_DESCRIPTION_ID").mergeMap((action) =>
    apiGetSimilarServices(
      action.payload.serviceDescriptionId,
      action.payload.campaignId
    )
      .map((response) => fetchSimilarServicesFulfilled(response, action))
      .catch((error) => handleXhrError(error, "FETCH_SIMILAR_SERVICES_ERROR"))
  )

export const fetchRelatedServicesEpic = (action$, { getState }) =>
  action$.ofType("SELECT_SERVICE_DESCRIPTION_ID").mergeMap((action) =>
    apiGetRelatedServices(
      getState().credentials.credentials,
      action.payload.serviceDescriptionId,
      action.payload.campaignId
    )
      .map((response) => fetchRelatedServicesFulfilled(response, action))
      .catch((error) => handleXhrError(error, "FETCH_RELATED_SERVICES_ERROR"))
  )
