import React, { Component } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import FranchiseSpecificNavigationLinks from "./RegionSpecificNavigationLinks"

const Navigation = styled.ul`
  display: block;
  list-style-type: none;
  padding: 0 0 0 18px;
  margin: 20px auto 15px auto;
  font-size: 16px;
  line-height: 1;
  text-align: center;

  & > li {
    display: inline-block;
    font-size: 16px;
    line-height: 1;
    cursor: pointer;
    margin-right: 28px;
    position: relative;
  }

  & > li > a {
    text-decoration: none;
    outline: none;
    font-size: 14px;
    font-weight: 700;
    line-height: 14px;
    color: ${({ theme }) => theme.colors.headers};
  }

  @media ${({ theme }) => theme.responsive.large} {
    display: none;
  }
`

const SubNavLinkDiv = styled.li`
  font-weight: ${({ isHeading }) => (isHeading === true ? "bold" : "normal")};
  margin-left: ${({ isHeading, isBelowHeading }) =>
    isHeading === true || isBelowHeading === false ? "0px" : "16px"};
`

const SubNavigationHeader = styled.a`
  transition: color 0.1s ease;
  color: ${(props) => (props.active ? "#a39e9d !important" : "inherit")};

  &:hover {
    color: #a39e9d;
  }
`

const SubNavigation = styled.div`
  position: absolute;
  top: 31px;
  left: 0;
  z-index: 5;
  background-color: #fff;
  text-align: left;

  a {
    text-decoration: none;
    font-size: 14px;
    white-space: nowrap;
  }

  a:hover {
    font-weight: 700;
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  li {
    line-height: 1.3em;
  }

  & > ul {
    display: flex;
    padding: 15px 10px;
  }

  & > ul > li {
    display: inline-block;
    padding: 0 15px;
  }

  & > ul > li > a {
    font-weight: 700;
    cursor: default;
  }
`

const SubNavLinks = ({ links, subNavKey }) => {
  let isBelowHeading = false
  return links.map((subnavSectionLink, i) => {
    const isHeading = subnavSectionLink.heading

    const element = (
      <SubNavLinkDiv
        isHeading={isHeading}
        isBelowHeading={isBelowHeading}
        key={`${subNavKey}-link-${i}`}
      >
        <a target="_blank" href={subnavSectionLink.href} rel="noreferrer">
          {subnavSectionLink.title}
        </a>
      </SubNavLinkDiv>
    )

    if (isHeading) {
      isBelowHeading = subnavSectionLink.heading
    }

    return element
  })
}

class AvedaNavigation extends Component {
  renderSubNavigation(subNav) {
    const { key: subNavKey, title, href, sections } = subNav

    const { openSubNav = null, onOpenSubNav, onCloseSubNav } = this.props

    if (typeof sections === "undefined") {
      return (
        <li key={subNavKey}>
          <SubNavigationHeader
            onFocus={onCloseSubNav}
            onMouseOver={onCloseSubNav}
            href={href}
          >
            {title}
          </SubNavigationHeader>
        </li>
      )
    }

    return (
      <li
        key={subNavKey}
        onFocus={() => {
          onOpenSubNav(subNavKey)
        }}
        onMouseOver={() => {
          onOpenSubNav(subNavKey)
        }}
      >
        <SubNavigationHeader
          active={openSubNav === subNavKey}
          href={href}
          onClick={(e) => {
            e.preventDefault()
            if (openSubNav === subNavKey) {
              onCloseSubNav()
            } else {
              onOpenSubNav(subNavKey)
            }
          }}
        >
          {title}
        </SubNavigationHeader>
        <SubNavigation
          style={{
            display: openSubNav && openSubNav === subNavKey ? "flex" : "none",
          }}
        >
          {sections.map((subnavSection, i) => (
            <ul key={`${subNavKey}-section-${i}`}>
              <li>
                {subnavSection.title && (
                  <a
                    href="#"
                    onClick={(e) => {
                      e.preventDefault()
                    }}
                  >
                    {subnavSection.title}
                  </a>
                )}
                <ul>
                  <SubNavLinks
                    links={subnavSection.links}
                    subNavKey={subNavKey}
                  />
                </ul>
              </li>
            </ul>
          ))}
        </SubNavigation>
      </li>
    )
  }

  render() {
    const { website } = this.props

    return (
      <Navigation>
        {FranchiseSpecificNavigationLinks(website).map((nav) =>
          this.renderSubNavigation(nav)
        )}
      </Navigation>
    )
  }
}

AvedaNavigation.propTypes = {
  openSubNav: PropTypes.string,
  website: PropTypes.string.isRequired,
  onOpenSubNav: PropTypes.func.isRequired,
  onCloseSubNav: PropTypes.func.isRequired,
}

export default AvedaNavigation
