import styled from "styled-components"

export const ServiceDescription = styled.div`
  @media only screen and (max-width: ${({ theme }) =>
      theme.responsive.mobile}px) {
    padding: 10px;
    background-color: ${({ theme }) => theme.colors.tertiary};
  }
`

export const StepParent = styled.div`
  padding: 20px 0px 12px 0px;
  margin: 20px auto 15px auto;
  border-top: 1px solid #ececec;
`

export const Step = styled.small`
  display: block;
  text-align: center;
`

export const StepHeader = styled.h2`
  text-align: center;
  font-weight: bold;
`
