import React from "react"
import PropTypes from "prop-types"

import { thumborize } from "../../components/images/thumborize"
import { PromoTag } from "../../components/SaleTags"
import Pricing from "../../components/Pricing"
import { getCompanyLogo } from "../InventoryView/lib"
import { useCalendarBookingModuleContext } from "./CalendarBookingModuleContext"

function EmployeeCard({ employee, isMobile, userIsVip, hidePrice, hideTag }) {
  const {
    inventoryItem: { company, currency, pricing },
  } = useCalendarBookingModuleContext()

  const prices =
    pricing && !hidePrice
      ? {
          default: pricing.employees.find((p) => !p.employee_id),
          lowest: pricing.employees.find((p) => employee.id === p.employee_id),
        }
      : false

  const getProfileImage = () => {
    const image = employee.images.find(
      (i) => i.placement === "profile" && i.position === "0"
    )
    return image && thumborize(encodeURIComponent(image.url), 240, 240)
  }
  return (
    <div style={{ display: "flex", flex: 1, alignItems: "center" }}>
      <div style={{ paddingRight: 12 }}>
        <img
          src={getProfileImage() || getCompanyLogo(company)}
          alt=""
          style={{
            width: 45,
            height: 45,
            borderRadius: 30,
            display: "block",
          }}
        />
      </div>
      <div style={{ flex: 2, textAlign: "left" }}>
        <span
          style={{
            display: "block",
            fontSize: isMobile ? 15 : 17,
            fontWeight: 500,
          }}
        >
          {employee.first_name}
        </span>
        <span
          style={{
            display: "block",
            fontSize: 14,
            fontWeight: 400,
          }}
        >
          {employee.role}
        </span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          paddingRight: "1em",
        }}
      >
        {!hideTag &&
          prices &&
          prices.lowest &&
          prices.lowest.sale &&
          prices.lowest.price_original && (
            <div
              style={{
                textAlign: "right",
                marginBottom: 12,
                marginLeft: 4,
              }}
            >
              <PromoTag />
            </div>
          )}
        {!hidePrice && prices && (
          <Pricing
            style={{ marginLeft: 10 }}
            prices={prices}
            currency={currency}
            userIsVip={userIsVip}
            hideTag
          />
        )}
      </div>
    </div>
  )
}

EmployeeCard.propTypes = {
  employee: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  userIsVip: PropTypes.bool,
  hidePrice: PropTypes.bool,
  hideTag: PropTypes.bool,
}

export default EmployeeCard
