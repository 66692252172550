import { SEARCH_FAQS_ACTION } from "./constants"

export const loadAllFaqs = () => ({
  type: "LOAD_ALL_FAQS"
})

export const searchFaqs = (query) => ({
  type: SEARCH_FAQS_ACTION,
  payload: { query }
})
