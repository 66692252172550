import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"

import { submitResetPassword, updateEmail, resetState } from "./actions"

import { TextInput, SubmitInput } from "../../components/forms/Inputs"
import NavBarContainer from "../NavBar/NavBarContainer"
import HugeTitle from "../../components/HugeTitle"
import { Link, useNavigate } from "react-router-dom"

const { GOOGLE_RECAPTCHA_SITE_ID } = SITE_CONFIG

const ResetPasswordForm = ({
  email,
  isMobile,
  onUpdate,
  onSubmit,
  hasError,
  lastError,
  passwordIsReset,
}) => {
  if (passwordIsReset) return null

  return (
    <form style={{ padding: "20px" }} onSubmit={onSubmit}>
      {GOOGLE_RECAPTCHA_SITE_ID && (
        <GoogleReCaptcha onVerify={handleReCaptchaVerify} />
      )}
      <TextInput
        onChange={onUpdate}
        value={email}
        placeholder="Email address"
        style={{ marginRight: isMobile ? 0 : 20 }}
      />
      <SubmitInput
        onClick={onSubmit}
        value="Reset Password"
        style={{ marginTop: 20 }}
      />
      {hasError && <p style={{ color: "red" }}>{lastError}</p>}
    </form>
  )
}

const ResetPasswordContainer = ({
  resetState,
  updateEmail,
  submitResetPassword,
  resetpassword,
  isMobile,
}) => {
  const [googleRecaptchaToken, setGoogleRecaptchaToken] = useState(false)

  useEffect(() => {
    resetState()
  }, [])

  const navigate = useNavigate()

  const handleReCaptchaVerify = (token) => {
    setGoogleRecaptchaToken(token)
  }

  const doUpdateEmail = (e) => {
    e.preventDefault()
    updateEmail(e.target.value)
  }

  const doSubmitResetPassword = (e) => {
    e.preventDefault()
    submitResetPassword(resetpassword.email, googleRecaptchaToken)
  }

  const CheckYourEmailMessage = () => {
    if (!resetpassword.passwordIsReset) return null
    return (
      <div style={{ padding: "20px" }}>
        <p>
          If you have an account with us, we have sent you a link to reset your
          password.
          <br />
          If you haven't received an email, you will not have an account with us{" "}
          <Link to="/register">create one here</Link>.
        </p>
      </div>
    )
  }

  return (
    <div style={{ textAlign: "center", paddingTop: 40 }}>
      <NavBarContainer />
      <HugeTitle>Reset Password</HugeTitle>
      <ResetPasswordForm
        email={resetpassword.email}
        isMobile={isMobile}
        onSubmit={doSubmitResetPassword}
        onUpdate={doUpdateEmail}
        hasError={resetpassword.hasError}
        lastError={resetpassword.lastError}
        passwordIsReset={resetpassword.passwordIsReset}
      />
      <CheckYourEmailMessage />
    </div>
  )
}

ResetPasswordContainer.propTypes = {
  submitResetPassword: PropTypes.func.isRequired,
  updateEmail: PropTypes.func.isRequired,
  resetState: PropTypes.func.isRequired,
  resetpassword: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    resetpassword: state.resetpassword,
    isMobile: state.browser.lessThan.mobile,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateEmail: (email) => dispatch(updateEmail(email)),
  submitResetPassword: (email, googleRecaptchaToken) =>
    dispatch(submitResetPassword(email, googleRecaptchaToken)),
  resetState: () => dispatch(resetState()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ResetPasswordContainer)
