import React from "react"
import Button, { SelectButton } from "../../../components/button"
import { useCampaignWizardContext } from "./CampaignWizardContext"

function ServiceButtons() {
  const {
    campaign,
    clickTab,
    setSelectedStep,
    selectService,
    selectedService,
    selectedCategory,
  } = useCampaignWizardContext()

  const options = campaign.similar
    .filter(
      (service) => selectedCategory.id === service.description.category.id
    )
    .sort((a, b) => {
      if (a.description.name === b.description.name) {
        return 0
      }

      return a.description.name < b.description.name ? -1 : 1
    })
    .map((option) => ({
      label: option.description.name,
      value: option.description.id,
    }))

  return (
    <div
      style={{
        margin: "30px auto",
        marginTop: 0,
        display: "flex",
        flexDirection: "horizontal",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "300px",
      }}
    >
      <div style={{ paddingRight: 10 }}>
        <SelectButton
          variant="secondary"
          options={options}
          selectedValue={selectedService.description.id}
          defaultValue={options.find(
            (option) => option.value === selectedService.description.id
          )}
          isSearchable={false}
          controlShouldRenderValue={false}
          placeholder="Change service"
          onChange={(option) => {
            setSelectedStep(2)
            // // first we close the select service tab
            clickTab("select-service")

            const service = campaign.similar.find(
              (s) => s.description.id === option.value
            )
            if (service) {
              selectService(service)
            }

            // // then we dispatch action to select service
            // selectServiceDescription(option.value)
          }}
          minWidth="150px"
          menuWidth="380px"
        />
      </div>

      <div style={{ textAlign: "right" }}>
        <Button
          filled
          variant="secondary"
          onClick={() => {
            setSelectedStep(3)
          }}
        >
          Next
        </Button>
      </div>
    </div>
  )
}

export default ServiceButtons
