import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"

import Animation from "../../components/Animation"

import WrapContainer from "../WrapContainer"

import { setDefaultMetaData, setMetaData } from "../../../utils/metaData"
import LoginForm from "./LoginForm"

function LoginContainer({ noWrap, toggleRegistrationHandler = null }) {
  const [fbLoggingIn, setFbLoggingIn] = useState(false)

  useEffect(() => {
    setMetaData({
      metaTitle: "Login",
    })
    return () => setDefaultMetaData()
  }, [])

  if (fbLoggingIn) return <Animation />

  return noWrap ? (
    <LoginForm
      setFbLoggingIn={setFbLoggingIn}
      toggleRegistrationHandler={toggleRegistrationHandler}
    />
  ) : (
    <WrapContainer loginRequired={false}>
      <div style={{ marginTop: 50 }}>
        <LoginForm
          setFbLoggingIn={setFbLoggingIn}
          toggleRegistrationHandler={toggleRegistrationHandler}
        />
      </div>
    </WrapContainer>
  )
}

LoginContainer.propTypes = {
  noWrap: PropTypes.bool,
  toggleRegistrationHandler: PropTypes.func,
}

export default LoginContainer
