import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "styled-components"
import { PrismicRichText } from "@prismicio/react"

import { useNavigate } from "react-router-dom"
import WrapContainer from "../WrapContainer"
import { setDefaultMetaData, setMetaData } from "../../../utils/metaData"
import { BackLink } from "../../components/nav/Links"
import { getPageContent } from "./selectors"

const ParentDiv = styled.div`
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  position: relative;

  div > h1 + div {
    text-align: center;
  }
  a {
    color: ${({ theme }) => theme.colors.primary};
  }
`

function HealthContainer({ isLarge, pageContent }) {
  const navigate = useNavigate()

  useEffect(() => {
    setMetaData({
      metaTitle: "Your Health",
      metaDescription:
        "It’s now even more important to maintain a high standard of hygiene and safety.",
    })
    return () => setDefaultMetaData()
  })
  return (
    <WrapContainer loginRequired={false}>
      <ParentDiv>
        <BackLink
          href="/"
          onClick={(e) => {
            e.preventDefault()
            navigate(-1)
          }}
          style={{ left: isLarge ? 0 : 20, top: 16 }}
        />

        <div>
          <div
            style={{
              margin: 0,
              padding: 0,
              textAlign: "center",
            }}
          >
            <PrismicRichText field={pageContent.title} />
          </div>
          <div style={{ padding: !isLarge ? "0 20px" : 0, textAlign: "left" }}>
            <PrismicRichText field={pageContent.textContent} />
          </div>
        </div>
      </ParentDiv>
    </WrapContainer>
  )
}

HealthContainer.propTypes = {
  isLarge: PropTypes.bool.isRequired,
  pageContent: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    isLarge: state.browser.greaterThan.large,
    pageContent: getPageContent(),
  }
}

export default connect(mapStateToProps)(HealthContainer)
