import { ADD_ITEM_TO_CART_AND_REDIRECT_ACTION } from "./constants"

export const clearCartError = () => ({ type: "CLEAR_CART_ERROR" })

export const fetchCart = () => ({
  type: "FETCH_CART",
})

export const addAvailabilityToCart = (id, campaignId = false) => {
  const action = {
    type: "ADD_ITEM_TO_CART",
    payload: {
      type: "appointment",
      appointment_availability_id: id,
    },
  }

  if (campaignId) {
    action.payload.campaign_id = campaignId
  }

  return action
}

export const addPromotionCodeToCart = (id) => ({
  type: "ADD_ITEM_TO_CART",
  payload: { type: "code", promotion_code_id: id },
})

export const addAvailabilityToCartAndRedirect = (
  availability,
  inventoryItem = null
) => {
  const action = {
    type: ADD_ITEM_TO_CART_AND_REDIRECT_ACTION,
    payload: {
      type: "appointment",
      availability,
    },
  }

  if (inventoryItem) {
    action.payload.inventoryItem = inventoryItem
  }

  return action
}

export const removeFromCart = (identifier) => ({
  type: "REMOVE_ITEM_FROM_CART",
  payload: { identifier },
})

export const addNotesToCart = (identifier, notes) => ({
  type: "ADD_NOTES_TO_CART",
  payload: { identifier, notes },
})

export const removeNotesFromCart = (identifier) => ({
  type: "REMOVE_NOTES_FROM_CART",
  payload: { identifier },
})

export const enableBalanceInCart = () => ({
  type: "ENABLE_BALANCE_IN_CART",
})

export const disableBalanceInCart = () => ({
  type: "DISABLE_BALANCE_IN_CART",
})
