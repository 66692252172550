import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import buttonize from "./buttonize"
import CloseStripe from "./CloseStripe"

const commonStyle = {
  backgroundColor: "#fff",
  display: "inline-block",
  position: "relative",
}

const normalStyle = {
  ...commonStyle,
  minWidth: 600,
  minHeight: "50%",
  borderRadius: 16,
  marginTop: 40,
  padding: "20px 30px",
}
const fullscreenStyle = {
  ...commonStyle,
  borderRadius: 0,
  width: "calc(100% - 40px)",
  maxHeight: "calc(100vh - 90px)",
  marginTop: 0,
  padding: "0px 20px",
  paddingTop: "20px",
}

const ShadowWrapper = styled.div`
  position: fixed;
  z-index: 300;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: ${({ theme }) => theme.colors.overlayColor};
`

class ShadowBox extends React.Component {
  render() {
    const {
      children,
      closeBox,
      isFullscreen = false,
      headerPortalHeight = "0px",
      header = "",
      containerStyle = {},
    } = this.props

    const containerStyleToApply = isFullscreen
      ? { ...fullscreenStyle, ...containerStyle }
      : { ...normalStyle, ...containerStyle }

    return (
      <ShadowWrapper
        {...buttonize(closeBox)}
        onClick={(e) => {
          e.preventDefault()
          closeBox(e)
        }}
      >
        <div
          style={{
            position: "absolute",
            top: isFullscreen ? 0 : 70,
            right: 0,
            left: 0,
            height: isFullscreen ? "100vh" : "calc(100vh - 70px)",
            width: "100%",
            textAlign: "center",
            backgroundColor: isFullscreen ? "#fff" : "transparent",
          }}
        >
          <div
            onClick={(e) => {
              e.stopPropagation()
              e.cancelBubble = true
            }}
            style={containerStyleToApply}
          >
            <CloseStripe
              style={{ marginBottom: 20 }}
              header={header}
              clickClose={(e) => {
                e.preventDefault()
                closeBox(e)
              }}
            />
            <div>{children}</div>
          </div>
        </div>
      </ShadowWrapper>
    )
  }
}

ShadowBox.propTypes = {
  closeBox: PropTypes.func.isRequired,
  isFullscreen: PropTypes.bool,
  headerPortalHeight: PropTypes.string,
  header: PropTypes.string,
  containerStyle: PropTypes.object,
  children: PropTypes.element,
}

export default ShadowBox
