import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { withTheme } from "styled-components"
import { connect } from "react-redux"
import moment from "moment"
import { useNavigate } from "react-router-dom"
import { fetchBookings, toggleHistory, cancelBooking } from "./actions"
import WrapContainer from "../WrapContainer"
import { Booking } from "./Booking"
import { SubmitInput } from "../../components/forms/Inputs"
import HugeTitle from "../../components/HugeTitle"
import Button from "../../components/button"
import useInventoryNavigator from "../../hooks/useInventoryNavigator"
import { useAuth } from "../../providers/AuthProvider"

function MyBookingsContainer({
  cancelBooking,
  toggleHistory,
  fetchBookings,
  isFetchingBookings,
  isMobile,
  bookings,
  credentials,
  theme,
  type = "upcoming",
}) {
  const navigate = useNavigate()
  const { user } = useAuth()
  const [bookingType, setBookingType] = useState(type)

  useEffect(() => {
    if (user) {
      fetchBookings(bookingType)
    }
  }, [bookingType])

  const inventoryNavigate = useInventoryNavigator()

  const clickToggleHistory = (e) => {
    e.preventDefault()
    setBookingType(bookingType === "upcoming" ? "past" : "upcoming")
  }

  const handleCancel = (bookingId) => {
    cancelBooking(bookingId)
  }

  const navToRebook = (booking) => {
    inventoryNavigate({
      serviceSlug: booking.service.slug,
      date: moment().format("YYYY-MM-DD"),
    })
  }

  return (
    <WrapContainer loginRequired showNavBack={false}>
      <div
        style={{
          backgroundColor: "#f7f7f7",
          borderBottom: `3px solid ${theme.colors.primary}`,
        }}
      >
        <HugeTitle
          style={{
            padding: "20px 0px",
            fontWeight: 600,
          }}
        >
          My Appointments
        </HugeTitle>
      </div>
      <div
        style={{
          maxWidth: 760,
          margin: isMobile ? "0px 10px 20px 10px" : "0px auto",
          paddingTop: 10,
        }}
      >
        <div
          style={{
            display: "flex",
            margin: "20px auto",
          }}
        >
          <div
            style={{
              flex: 1,
              marginRight: 5,
            }}
          >
            <Button
              variant="secondary"
              onClick={() => setBookingType("upcoming")}
              filled={bookingType === "upcoming"}
              style={{ width: "100%" }}
            >
              Upcoming
            </Button>
          </div>
          <div
            style={{
              flex: 1,
              marginLeft: 5,
            }}
          >
            <Button
              style={{ width: "100%" }}
              variant="secondary"
              onClick={() => setBookingType("past")}
              filled={bookingType === "past"}
            >
              Past
            </Button>
          </div>
        </div>
        {bookings.bookings &&
          bookings.bookings
            .filter((booking) => !!booking.service)
            .map((booking) => (
              <Booking
                appointmentSynced={booking.appointment_synced}
                appointmentTime={booking.appointment_time}
                company={booking.company}
                credentials={credentials}
                duration={booking.duration}
                id={booking.id}
                isMobile={isMobile}
                key={booking.id}
                onCancel={handleCancel}
                onClick={() => navigate(`/bookings/${booking.order.id}`)}
                onRebook={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  navToRebook(booking)
                }}
                service={booking.service}
              />
            ))}
        {bookings.bookings &&
          bookings.bookings.length === 0 &&
          bookingType === "upcoming" && (
            <div>
              <p style={{ textAlign: "center" }}>
                You have no upcoming appointments.
              </p>
              <p style={{ textAlign: "center" }}>
                <SubmitInput
                  value="Find an appointment"
                  onClick={() => {
                    navigate("/")
                  }}
                />
              </p>
            </div>
          )}
        {!isFetchingBookings &&
          bookings.bookings &&
          bookings.bookings.length === 0 &&
          bookingType === "past" && (
            <div>
              <p style={{ textAlign: "center" }}>
                You have no past appointments.
              </p>
            </div>
          )}
      </div>
    </WrapContainer>
  )
}

MyBookingsContainer.propTypes = {
  fetchBookings: PropTypes.func.isRequired,
  toggleHistory: PropTypes.func.isRequired,
  bookings: PropTypes.object.isRequired,
  isFetchingBookings: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  cancelBooking: PropTypes.func.isRequired,
  credentials: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired, // injected by "withTheme" from styled-components
}

function mapStateToProps(state) {
  return {
    bookings: state.bookings,
    isMobile: state.browser.lessThan.mobile,
    credentials: state.credentials.credentials,
    isFetchingBookings: state.bookings.isFetchingBookings,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchBookings: (type) => dispatch(fetchBookings(type)),
  toggleHistory: () => dispatch(toggleHistory()),
  cancelBooking: (bookingId) => dispatch(cancelBooking(bookingId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(MyBookingsContainer))
