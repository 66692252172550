import { hasAcceptedCookies } from "../NavBar/selectors"
import {
  FETCH_INVENTORY_SUCCESS,
  TRACK_PURCHASE_START,
} from "../InventoryView/constants"
import { REGISTRATION_SUCCESS } from "../Registration/constants"

const trackItem = (inventoryItem) => {
  if (!inventoryItem) return {}

  return {
    content_type: "product",
    content_ids: [inventoryItem.id],
    contents: [
      {
        id: inventoryItem.id,
        quantity: 1,
      },
    ],
    currency: inventoryItem.currency.code,
    num_items: 1,
  }
}

const validFbActions = [
  REGISTRATION_SUCCESS,
  TRACK_PURCHASE_START,
  FETCH_INVENTORY_SUCCESS,
]

export const fbTrackerActionsEpic = (action$, { getState }) => {
  return action$
    .ofType(...validFbActions)
    .do((action) => {
      const isFacebookAnalyticsEnabled =
        hasAcceptedCookies(getState()) &&
        Array.isArray(process.env.FACEBOOK_PIXEL_IDS) &&
        process.env.FACEBOOK_PIXEL_IDS.length > 0

      if (typeof fbq === "function" && isFacebookAnalyticsEnabled) {
        switch (action.type) {
          case TRACK_PURCHASE_START:
            fbq("track", "AddToCart", trackItem(action.payload.item))
            break
          case FETCH_INVENTORY_SUCCESS:
            fbq("track", "ViewContent", trackItem(action.payload.item))
            break
          case REGISTRATION_SUCCESS:
            fbq("track", "CompleteRegistration")
            break
          default:
          // Technically it's impossible to hit this case
        }
      }
    })
    .ignoreElements()
}
