import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { useCampaignWizardContext } from "./CampaignWizardContext"
import { Step, StepHeader, StepParent } from "./styledComponents"
import Calendar from "./Calendar"

function CampaignStep5({ isMobile }) {
  const { campaign, selectedStep } = useCampaignWizardContext()

  const stepRef = useCallback((node) => {
    if (selectedStep === 5 && node !== null) {
      node.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  return (
    <div ref={stepRef} style={{ marginBottom: "20px" }}>
      <StepParent>
        <Step id="step-5">Step 5 of 5</Step>
        <StepHeader>{campaign.title_time_select}</StepHeader>
      </StepParent>

      <div style={{ textAlign: "center" }}>
        <div
          style={{
            display: "inline-block",
            textAlign: "left",
            minHeight: 520,
            width: isMobile ? "100%" : "auto",
            minWidth: isMobile ? 0 : 500,
          }}
        >
          <Calendar />
        </div>
      </div>
    </div>
  )
}

CampaignStep5.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

export default CampaignStep5
