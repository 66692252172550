import React, { useEffect } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment-timezone"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import WrapContainer from "../WrapContainer"
import InstantBookService from "../InstantBook/InstantBookService"
import { getListUserFavourites, getIsFetchingFavourites } from "./selectors"
import { fetchFavourites } from "./actions"
import Button from "../../components/button"
import { HeartIcon } from "../../components/icons"
import useInventoryLink from "../../hooks/useInventoryLink"
import { useAuth } from "../../providers/AuthProvider"
import useInventoryNavigator from "../../hooks/useInventoryNavigator"

const Heading = styled.h2`
  font-size: 20px;
  font-weight: 700;
  color: ${(props) => props.theme.colors.secondary};
  padding: 20px 0px 20px 0px;
  margin-top: 0px;
  margin-left: 8px;
  margin-right: 8px;
  margin-bottom: 0px;
  border-bottom-length: 80px;
  text-align: center;
`

const FavouritesWrapper = styled.div`
  margin: auto;
  padding: 0 10 0 10;
`

const NoFavsWrapperDiv = styled.div`
  text-align: center;
`
const NoFavsStyled = styled.p`
  color: #444;
  font-weight: 400;
  font-size: 18px;
`
const NoFavsStyledCTA = styled.p`
  color: #444;
  font-weight: 400;
  font-size: 18px;
`

function NoFavourites({ userFavourites, userPastBookings }) {
  const navigate = useNavigate()

  const favoritesCount = userFavourites?.length ?? 0
  const userPastBookingsCount = userPastBookings?.length ?? 0
  const bookingCount = favoritesCount + userPastBookingsCount

  const { t } = useTranslation()

  return bookingCount === 0 ? (
    <NoFavsWrapperDiv>
      <NoFavsStyled>{t("favourites_none")}</NoFavsStyled>
      <NoFavsStyledCTA>
        Either make a booking or tap the{" "}
        <HeartIcon $filled onClick={() => null} /> icon on any service and we'll
        save them for you here!
      </NoFavsStyledCTA>
      <Button onClick={() => navigate("/")}>Find an Appointment</Button>
    </NoFavsWrapperDiv>
  ) : null
}

function UserFavourites({ userFavourites, isMobile, isVip, heading = "" }) {
  if (!userFavourites?.length) return null

  const serviceContent = []

  const inventoryLinkFactory = useInventoryLink()
  const inventoryNavigate = useInventoryNavigator()

  userFavourites.forEach((favourite) => {
    const today = moment().format("YYYY-MM-DD")
    const service = (favourite && favourite.service) || {}
    const rating =
      (favourite &&
        favourite.service &&
        favourite.service.company &&
        favourite.service.company.rating_customer) ||
      false
    const ratingCount =
      (favourite &&
        favourite.service &&
        favourite.service.company &&
        favourite.service.company.rating_count) ||
      false

    const showRating = rating && ratingCount

    const slug =
      (favourite && favourite.service && favourite.service.slug) || ""

    const linkUrl =
      favourite.service.date === today
        ? inventoryLinkFactory({ serviceSlug: slug })
        : inventoryLinkFactory({ serviceSlug: slug, date: today })

    const serviceId =
      (favourite && favourite.service && favourite.service.id) ||
      Math.round(Math.random() * 10)

    serviceContent.push(
      <InstantBookService
        key={`service-${serviceId}`}
        aLink={linkUrl}
        clickAction={() =>
          inventoryNavigate({ serviceSlug: slug, date: today })
        }
        date={today}
        isMobile={isMobile}
        onClickRating={() =>
          inventoryNavigate({ serviceSlug: slug, date: today })
        }
        service={service}
        showRating={showRating}
        userIsVip={isVip}
        rating={rating}
        ratingCount={ratingCount}
      />
    )
  })

  return (
    <>
      <Heading>{heading}</Heading>
      {serviceContent}
    </>
  )
}

function FavouritesContainer({
  userPastBookings = [],
  userFavourites = [],
  fetchFavourites = null,
  isMobile = false,
  isFetchFavourites = true,
  isVip = false,
}) {
  const { t } = useTranslation()

  const auth = useAuth()

  useEffect(() => {
    if (auth.user) {
      fetchFavourites()
    }
  }, [auth])

  return (
    <div>
      <WrapContainer loginRequired>
        {!isFetchFavourites && (
          <FavouritesWrapper>
            <div>
              <UserFavourites
                userFavourites={userFavourites}
                isMobile={isMobile}
                heading={t("book_your_favourite_service")}
                isVip={isVip}
              />
            </div>
            <div>
              <UserFavourites
                userFavourites={userPastBookings}
                isMobile={isMobile}
                heading="Rebook a past appointment"
                isVip={isVip}
              />
            </div>
            <div>
              <NoFavourites
                userFavourites={userFavourites}
                userPastBookings={userPastBookings}
                isMobile={isMobile}
              />
            </div>
          </FavouritesWrapper>
        )}
      </WrapContainer>
    </div>
  )
}

FavouritesContainer.propTypes = {
  userFavourites: PropTypes.array,
  userPastBookings: PropTypes.array,
  fetchFavourites: PropTypes.func,
  isVip: PropTypes.bool,
  isMobile: PropTypes.bool,
  isFetchFavourites: PropTypes.bool,
}

function mapStateToProps(state) {
  return {
    userFavourites: getListUserFavourites(state),
    userPastBookings: getListUserFavourites(state, 1),
    isFetchFavourites: getIsFetchingFavourites(state),
    // this should be in selector since it is used everywhere
    isVip: state.profile.profile.vip,
    isMobile: state.browser.lessThan.mobile,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchFavourites: () => dispatch(fetchFavourites()),
})

export default connect(mapStateToProps, mapDispatchToProps)(FavouritesContainer)
