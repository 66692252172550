import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import * as selectors from "../InventoryView/selectors"
import { SubmitInput } from "../../components/forms/Inputs"
import { getIsAddingItemToCart } from "../Cart/selectors"
import { useCalendarBookingModuleContext } from "./CalendarBookingModuleContext"

function BookButton({ findBestPriceError, isAddingItemToCart, onClick }) {
  const { employee, isLoading, selectedAvailability } =
    useCalendarBookingModuleContext()

  let label = "Select Time"
  if (isLoading) {
    // only show this if we started the scrape and there is an employee selected
    label = "Finding Availability..."
  }

  if (
    !isLoading &&
    selectedAvailability &&
    employee?.id !== null &&
    findBestPriceError
  ) {
    label = "Select time"
  }

  return (
    <SubmitInput
      id="book-now-button"
      disabled={!selectedAvailability || isAddingItemToCart}
      onClick={onClick}
      value={label}
      loading={isAddingItemToCart}
      style={{
        flex: 1,
        margin: "10px 0px",
      }}
    />
  )
}

BookButton.propTypes = {
  findBestPriceError: PropTypes.bool,
  isAddingItemToCart: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
  findBestPriceError: selectors.getFindBestPriceError(state),
  isAddingItemToCart: getIsAddingItemToCart(state),
})

export default connect(mapStateToProps)(BookButton)
