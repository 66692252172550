import React, { useEffect, useMemo, useState } from "react"

import { useParams } from "react-router-dom"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { useQuery } from "@tanstack/react-query"
import Animation from "../../../components/Animation"

import PromoBanner from "../../../components/banners/PromoBanner"

import WrapContainer from "../../WrapContainer"

import { CampaignWizardContext } from "./CampaignWizardContext"
import { browserIsMobile } from "../../../redux/browserSelector"
import { fetchCampaign, getSimilarServices } from "../../../api/api"
import CampaignHeader from "./CampaignHeader"
import CampaignStep1 from "./CampaignStep1"
import CampaignStep2 from "./CampaignStep2"
import CampaignStep3 from "./CampaignStep3"
import CampaignStep4 from "./CampaignStep4"
import CampaignStep5 from "./CampaignStep5"
import { buildCoreDataQueryOptions } from "../../InstantBook/lib"

function CampaignWizard({ isMobile }) {
  const [selectedServiceTab, setSelectedServiceTab] = useState("love-it")
  const [selectedService, setSelectedService] = useState(null)
  const [selectedServiceDescription, setSelectedServiceDescription] =
    useState(null)
  const [selectedCategory, setSelectedCategory] = useState(null)
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [selectedStep, setSelectedStep] = useState(1)
  const [regions, setRegions] = useState(null)

  const { isLoading: isLoadingCoreData, data: coreData } = useQuery(
    buildCoreDataQueryOptions()
  )

  const params = useParams()

  const { data: campaign } = useQuery({
    queryKey: ["get-campaign", params.slug],
    enabled: Boolean(params.slug),
    queryFn: async () => fetchCampaign(params.slug),
  })

  useEffect(() => {
    if (coreData) {
      setRegions(coreData.regions)
    }
  }, [coreData])

  const { data: similarCompanies, isLoading: isLoadingSimilarCompanies } =
    useQuery({
      queryKey: ["similar", campaign?.id, selectedService],
      enabled: selectedService !== null,
      retry: false,
      queryFn: async () =>
        getSimilarServices(selectedService.description.id, campaign?.id).then(
          (json) => json.companies
        ),
    })

  const selectCompany = (company) => {
    setSelectedCompany(company)
    setSelectedServiceTab("love-salon")
  }

  const clickTab = (tab) =>
    setSelectedServiceTab(selectedServiceTab !== tab ? tab : null)

  const selectService = (service) => {
    setSelectedService({
      company: service.company,
      currency: service.currency,
      description: service.description,
      extras: service.extras,
      images: service.images,
    })

    setSelectedStep(2)
  }

  const selectCategory = (category) => {
    /* Reset service selection */
    setSelectedService(null)
    setSelectedServiceDescription(null)
    setSelectedCompany(null)
    setSelectedCategory(category)

    // Find the first matching service, we'll use it's details to populate the next step
    const selectedService = campaign.similar.find(
      (service) => category.id === service.description.category.id
    )

    selectService(selectedService)
  }

  const contextValue = useMemo(
    () => ({
      campaign,
      clickTab,
      selectCategory,
      selectCompany,
      selectService,
      selectedCompany,
      selectedCategory,
      selectedService,
      selectedServiceDescription,
      selectedStep,
      selectedServiceTab,
      setSelectedCompany,
      setSelectedServiceTab,
      setSelectedServiceDescription,
      setSelectedStep,
    }),
    [
      campaign,
      selectedCategory,
      selectedCompany,
      selectedService,
      selectedStep,
      selectedServiceTab,
    ]
  )

  if (!campaign || isLoadingCoreData) return <Animation />

  return (
    <WrapContainer loginRequired={false} showNavBack navTitle="Promo Detail">
      <PromoBanner />
      <CampaignWizardContext.Provider value={contextValue}>
        <div
          style={{
            maxWidth: 1078,
            minWidth: isMobile ? 0 : 1078,
            marginLeft: "auto",
            marginRight: "auto",
            position: "relative",
          }}
        >
          <CampaignHeader />

          <div style={{ padding: isMobile ? "0 14px" : 0, minHeight: 300 }}>
            <CampaignStep1 campaign={campaign} />

            {isLoadingSimilarCompanies ? (
              <Animation />
            ) : (
              <>
                {selectedStep > 1 && <CampaignStep2 />}

                {selectedStep > 2 && (
                  <CampaignStep3
                    regions={regions}
                    similarCompanies={similarCompanies}
                  />
                )}

                {selectedStep > 3 && (
                  <CampaignStep4 similarCompanies={similarCompanies} />
                )}

                {selectedStep > 4 && <CampaignStep5 isMobile={isMobile} />}
              </>
            )}
          </div>
        </div>
      </CampaignWizardContext.Provider>
    </WrapContainer>
  )
}

CampaignWizard.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

function mapStateToProps(state) {
  return {
    isMobile: browserIsMobile(state),
  }
}

export default connect(mapStateToProps)(CampaignWizard)
