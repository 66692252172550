import router from "../config/router"

export const HTTP_REDIRECT_ACTION = "HTTP_REDIRECT"

export const redirectAction = (url) => ({
  type: HTTP_REDIRECT_ACTION,
  payload: {
    url,
  },
})

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    if (action.type === HTTP_REDIRECT_ACTION) {
      router.navigate(action.payload.url)
    }
    return next(action)
  }
