import React from "react"
import { createBrowserRouter, defer, Navigate, Outlet } from "react-router-dom"
// eslint-disable-next-line no-unused-vars
import { element } from "prop-types"
import NotFound from "../components/NotFound"
import Faqs from "../containers/Faqs"
import MyBookingsContainer from "../containers/MyBookings/MyBookingsContainer"
import ProfileContainer from "../containers/Profile/ProfileContainer"
import FavouritesContainer from "../containers/Favourites/FavouritesContainer"
import InventoryViewContainer from "../containers/InventoryView/InventoryViewContainer"
import LoginContainer from "../containers/Login/LoginContainer"
import RegistrationContainer from "../containers/Registration/RegistrationContainer"
import PurchaseContainer from "../containers/Purchase/PurchaseContainer"
import ChangePasswordContainer from "../containers/ResetPassword/ChangePasswordContainer"
import CampaignWizard from "../containers/InventoryView/CampaignWizard"
import HealthContainer from "../containers/Health"
import TermsConditions from "../containers/Terms"
import { PRISMIC_TYPES } from "../constants"
import CompanyViewContainer from "../containers/Companies/CompanyViewContainer"
import FaqsTopic from "../containers/Faqs/FaqsTopic"
import FaqSearchResults from "../containers/Faqs/FaqSearchResults"
import Answer from "../containers/Faqs/Answer"
import OrderViewContainer from "../containers/Order/OrderViewContainer"
import ResetPasswordContainer from "../containers/ResetPassword/ResetPasswordContainer"
import ProtectedRoute from "../components/ProtectedRoute"
import { fetchCampaign, fetchService } from "../api/api"
import InventoryViewParentRoute from "../containers/InventoryView/InventoryViewParentRoute"
import InstantBook from "../containers/InstantBook"
import InstantBookLoadingContainer from "../containers/InstantBook/InstantBookLoadingContainer"
import Analytics from "../containers/Analytics"
import ErrorBoundary from "../components/ErrorBoundary"

export const PATH_BOOKINGS = "bookings"

/* I left this here on purpose, I found it handy when things were navigating around and I couldn't work out where the call to do it
was coming from. To use it, uncomment the function and then call createDebugRouter instead of createBrowserRouter at the bottom of the file */
// function createDebugRouter(routes, opts) {
//   const router = createBrowserRouter(routes, opts)

//   // Store the original navigator function
//   const originalNavigate = router.navigate

//   // Log initial state
//   console.log("Initial router state:", {
//     basename: router.basename,
//     state: router.state,
//     routes: router.routes,
//   })

//   // Override the navigate function to add logging
//   router.navigate = (...args) => {
//     console.log("Navigation requested:", {
//       args,
//       currentLocation: router.state.location,
//       currentAction: router.state.navigation.action,
//     })
//     return originalNavigate.apply(router, args)
//   }

//   // Add listener for all location changes
//   router.subscribe((state) => {
//     console.log("Router state updated:", {
//       location: state.location,
//       action: state.navigation.action,
//       state: state.navigation.state,
//       error: state.navigation.error,
//     })
//   })

//   return router
// }

const routes = [
  {
    path: "/",
    errorElement: process.env.NODE_ENV !== "development" ? (<ErrorBoundary />) : null,
    element: (
      <Analytics>
        <Outlet />
      </Analytics>
    ),
    // errorElement: <ErrorBoundary />,
    children: [
      {
        path: "*",
        element: <NotFound />,
      },
      {
        handle: {
          menuSection: "account",
        },
        path: "account",
        element: (
          <ProtectedRoute>
            <ProfileContainer />
          </ProtectedRoute>
        ),
      },
      {
        handle: {
          menuSection: "bookings",
        },
        path: "bookings",
        // element: <MyBookingsContainer />,
        element: (
          <ProtectedRoute>
            <Outlet />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="/bookings/upcoming" />,
          },
          {
            handle: {
              menuSection: "bookings",
            },
            path: "upcoming",
            element: <MyBookingsContainer type="upcoming" />,
          },
          {
            handle: {
              menuSection: "bookings",
            },
            path: "past",
            element: <MyBookingsContainer type="past" />,
          },
          {
            handle: {
              menuSection: "bookings",
            },
            path: ":id",
            element: <OrderViewContainer />,
          },
          {
            handle: {
              menuSection: "bookings",
              confirmation: true,
            },
            path: ":id/confirmation",
            element: <OrderViewContainer />,
          },
        ],
      },
      {
        handle: {
          menuSection: "companyView",
        },
        path: "company/:id",
        element: <CompanyViewContainer />,
      },
      {
        handle: {
          menuSection: "favourites",
        },
        path: "favourites",
        element: (
          <ProtectedRoute>
            <FavouritesContainer />
          </ProtectedRoute>
        ),
      },
      {
        handle: {
          menuSection: "forgotPassword",
        },
        path: "forgot-password",
        element: <ResetPasswordContainer />,
      },
      {
        handle: {
          menuSection: "health",
        },
        path: "/your-health",
        element: <HealthContainer />,
      },
      {
        id: "home",
        handle: {
          menuSection: "home",
        },
        element: <InstantBookLoadingContainer />,
        path: "/",
        children: [
          {
            index: true,
            element: (
              <InstantBook
                metaTitle={SITE_CONFIG.HOME_META_TITLE}
                metaDescription={SITE_CONFIG.HOME_META_DESCRIPTION}
              />
            ),
          },
          {
            path: "browse/*",
            element: <InstantBook />,
          },
        ],
      },
      {
        path: "help",
        handle: {
          menuSection: "help",
        },
        element: <Faqs />,
      },
      {
        path: "help-search",
        handle: {
          menuSection: "help",
        },
        element: <FaqSearchResults />,
      },
      {
        path: "help/:faqTopic",
        handle: {
          menuSection: "help",
        },
        element: <FaqsTopic />,
      },
      {
        path: "help/:faqTopic/:faq",
        handle: {
          menuSection: "help",
        },
        element: <Answer />,
      },
      {
        path: "login",
        handle: {
          menuSection: "login",
        },
        element: <LoginContainer />,
      },
      {
        path: "offer/:slug",
        element: <CampaignWizard />,
        loader: async ({ params }) =>
          defer({
            campaign: fetchCampaign(params.slug),
          }),
      },
      {
        path: "privacy",
        handle: {
          menuSection: "privacy",
        },
        element: (
          <TermsConditions
            metaTitle={SITE_CONFIG.PRIVACY_META_TITLE}
            metaDescription={SITE_CONFIG.PRIVACY_META_DESCRIPTION}
            documentType={PRISMIC_TYPES.PRIVACY_POLICY}
          />
        ),
      },
      {
        path: "purchase",
        element: <PurchaseContainer />,
      },
      {
        handle: {
          menuSection: "register",
        },
        path: "register",
        element: <RegistrationContainer />,
      },
      {
        path: "reset-password/:required?/:id",
        element: <ChangePasswordContainer />,
      },
      {
        id: "servicesRoot",
        path: "/services/:serviceSlug",
        element: <InventoryViewParentRoute />,
        loader: async ({ params }) =>
          defer({
            inventoryItem: fetchService(params.serviceSlug),
          }),
        handle: {
          menuSection: "services",
        },
        children: [
          {
            index: true,
            element: <InventoryViewContainer />,
          },
          ...[
            "employee/:employeeSlug",
            "date/:date",
            "date/:date/time/:time",
            "date/:date/employee/:employeeSlug",
            "date/:date/time/:time/employee/:employeeSlug",
          ].map((path) => ({
            handle: {
              menuSection: "services",
            },
            path,
            element: <InventoryViewContainer />,
          })),
        ],
      },
      {
        path: "terms/customer",
        handle: {
          menuSection: "customer-terms",
        },
        element: (
          <TermsConditions
            metaTitle={SITE_CONFIG.TERMS_META_TITLE}
            metaDescription={SITE_CONFIG.TERMS_META_DESCRIPTION}
            documentType={PRISMIC_TYPES.CUSTOMER_TERMS}
          />
        ),
      },
    ],
  },
]

export default createBrowserRouter(routes)
