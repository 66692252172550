import { useMatches, useParams } from "react-router-dom"
import { buildInventoryLink } from "../../utils/inventoryLink"

/**
 * This function handles the optional parameters of the Inventory Item Page
 * @param currentParams { id, date, employeeSlug }: Object with all the params present in the current url.
 * Id: Service id.
 * Date: Date for the booking, which will be used in the calendar booking module.
 * EmployeeSlug: Slug of the employee.
 * @param newParams { id, date, employeeSlug }: Object with all the params that will be added/updated to the current url.
 * @param replaceInHistory: Boolean to determine if the browser history should be replaced or not.
 */
export default () => {
  const routeMatches = useMatches()

  const urlParams = useParams()

  return (newParams = {}) => {
    const firstMatchingRouteWithMenuSection = routeMatches.find(
      (matches) => matches.handle?.menuSection
    )

    if (!firstMatchingRouteWithMenuSection) {
      throw new Error(
        "useInventoryLink can only be used in the context of a route with a menuSection"
      )
    }

    const { menuSection } = firstMatchingRouteWithMenuSection.handle

    let currentParams = {}
    // If we're currently on an inventory item page
    if (menuSection === "services") {
      currentParams = urlParams
    }

    return buildInventoryLink(currentParams, newParams)
  }
}
