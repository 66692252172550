import React, { useEffect } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styled from "styled-components"

import { useNavigate } from "react-router-dom"
import { PRISMIC_TYPES } from "../../constants"

import WrapContainer from "../WrapContainer"

import { BackLink } from "../../components/nav/Links"

import { getPartnerTerms, getCustomerTerms, getPrivacyTerms } from "./selectors"

import Chapter from "./Chapter"

import { loadLegalsContent } from "./actions"
import { setMetaData } from "../../../utils/metaData"

const ParentDiv = styled.div`
  max-width: 980px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
  position: relative;

  div > h1 + div {
    text-align: center;
  }
`

function TermsConditions({
  customerTerms,
  documentType,
  isMobile,
  loadLegalsContent,
  metaDescription,
  metaTitle,
  partnerTerms,
  privacyTerms,
}) {
  const navigate = useNavigate()

  useEffect(() => {
    loadLegalsContent()

    window.scrollTo(0, 0)
  }, [])

  const getTermsContent = () => {
    switch (documentType) {
      case PRISMIC_TYPES.PRIVACY_POLICY:
        return privacyTerms
      case PRISMIC_TYPES.PARTNER_TERMS:
        return partnerTerms
      case PRISMIC_TYPES.CUSTOMER_TERMS:
        return customerTerms
      default:
        return {}
    }
  }

  const termsContent = getTermsContent()

  if (!termsContent) {
    navigate("/404-page-not-found")
  }

  setMetaData({
    metaTitle: metaTitle || termsContent?.title,
    metaDescription,
  })

  if (!termsContent?.title) return null

  return (
    <WrapContainer loginRequired={false}>
      <ParentDiv>
        <BackLink
          to="/"
          onClick={(e) => {
            e.preventDefault()
            navigate(-1)
          }}
          style={{ left: isMobile ? 20 : -60, top: 20 }}
        />
        <div style={{ paddingTop: isMobile ? 60 : 20 }}>
          <h1
            style={{
              margin: 0,
              padding: 0,
              textAlign: "center",
              fontStyle: "italic",
            }}
          >
            {termsContent.title}
          </h1>
          <div style={{ padding: isMobile ? "0 8px" : 0, textAlign: "left" }}>
            {termsContent.body.map((section, i) => (
              <Chapter
                key={`section${i}`}
                title={section.primary.title}
                items={section.items}
              />
            ))}
          </div>
        </div>
      </ParentDiv>
    </WrapContainer>
  )
}

TermsConditions.propTypes = {
  documentType: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  metaTitle: PropTypes.string,
  metaDescription: PropTypes.string,
  loadLegalsContent: PropTypes.func.isRequired,
  privacyTerms: PropTypes.object,
  partnerTerms: PropTypes.object,
  customerTerms: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    isMobile: state.browser.lessThan.mobile,
    partnerTerms: getPartnerTerms(state),
    privacyTerms: getPrivacyTerms(state),
    customerTerms: getCustomerTerms(state),
  }
}

const mapDispatchToProps = (dispatch) => ({
  loadLegalsContent: () => dispatch(loadLegalsContent()),
})

export default connect(mapStateToProps, mapDispatchToProps)(TermsConditions)
