import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import styled from "styled-components"

import { CompanyLogo } from "../images/CompanyLogo"
import CompanyAddressBuilder from "../address/CompanyAddressBuilder"
import {
  ListAppointmentTime,
  ListDuration,
  ListTopHeading,
  PriceRange,
  AddToCalendarLink,
  RebookLink,
} from "../datarenderers"
import ProductCard from "./ProductCard"
import { Phone } from "../datarenderers/Phone"
import CancelBookingLink from "../../containers/MyBookings/CancelBookingLink"

const DetailRow = styled.div`
  text-align: left;
  margin-top: 15px;
`

const BookingButtons = styled.div`
  text-align: right;
  margin-top: 15px;
`

function BookingItemCard({
  booking,
  price,
  currency,
  isMobile,
  startTime,
  duration,
  extras,
  onCancel,
  onRebook,
  credentials,
}) {
  const {
    id,
    service,
    company,
    employee,
    appointment_time,
    appointment_synced,
  } = booking

  const { timezone, phone_number } = company

  const needsReview = parseInt(booking.needs_review, 0) > 0

  // If employee is selected use singular cart price, otherwise use price range from service
  const highestPrice = employee ? price : service.pricing.highest.price
  const lowestPrice = employee ? undefined : service.pricing.lowest.price

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          position: isMobile ? "static" : "absolute",
          top: 0,
          left: 0,
          textAlign: "center",
        }}
      >
        <CompanyLogo company={company} size={64} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginLeft: isMobile ? 0 : 82,
          marginBottom: 10,
          paddingTop: 10,
        }}
      >
        <ListTopHeading
          style={{
            textAlign: "left",
            fontWeight: "bold",
            flex: 1,
            fontSize: isMobile ? 16 : 18,
          }}
        >
          {service.description.name}
          <DetailRow style={{ marginTop: 0 }}>
            {employee && (
              <p style={{ margin: 0 }}>
                with your stylist: {employee.first_name}
              </p>
            )}

            {!employee && <p style={{ margin: 0 }}>with Any Stylist</p>}
          </DetailRow>
        </ListTopHeading>
        <div style={{ flex: 0, marginLeft: 10 }}>
          <PriceRange
            lowestPrice={lowestPrice}
            highestPrice={highestPrice}
            currency={currency}
            showCode
            toPay
          />
        </div>
      </div>

      <div
        style={{
          marginBottom: 25,
          paddingLeft: isMobile ? "0px" : "82px",
        }}
      >
        <div>
          {!employee && (
            <p>
              If you book with{" "}
              <span style={{ fontWeight: "bold" }}>Any Stylist</span> you will
              be charged dependent on stylist availability and level. You will
              receive an email confirming your stylist and price after
              completing your booking.
            </p>
          )}

          <DetailRow style={{ marginBottom: 10 }}>
            <CompanyAddressBuilder company={company} inline />
          </DetailRow>

          <DetailRow>
            <ListAppointmentTime
              appointmentTime={startTime}
              timezone={timezone}
            >
              <ListDuration duration={duration} />
            </ListAppointmentTime>
          </DetailRow>

          {/* Only render this section when showing a booking that has been completed and associated with an order */}
          {typeof booking.order !== "undefined" && (
            <>
              {phone_number && (
                <DetailRow style={{ marginTop: 10 }}>
                  <Phone number={phone_number} />
                </DetailRow>
              )}
              <CancelBookingLink
                style={{ marginTop: 10, textAlign: "left" }}
                onCancel={onCancel}
                bookingId={id}
                appointmentTime={appointment_time}
                appointmentSynced={appointment_synced}
                company={company}
              />

              {moment(booking.appointment_time).isAfter() && (
                <BookingButtons>
                  <AddToCalendarLink
                    bookingId={booking.id}
                    credentials={credentials}
                  />
                </BookingButtons>
              )}

              {moment(booking.appointment_time).isBefore() && (
                <BookingButtons>
                  <RebookLink onRebook={onRebook} />
                </BookingButtons>
              )}
            </>
          )}
        </div>
      </div>

      {extras &&
        extras.length > 0 &&
        extras.map((extra) => (
          <div
            key={extra.product.id}
            style={{
              display: "flex",
              alignItems: isMobile ? "center" : "flex-start",
              flexDirection: isMobile ? "column" : "row",
              marginBottom: 15,
            }}
          >
            <ProductCard product={extra.product || extra} isMobile={isMobile} />
            <div />
          </div>
        ))}

      <div>
        <div>
          {moment(startTime).isBefore() && needsReview && (
            <SubmitInput
              style={{ marginBottom: 20 }}
              onClick={() => {
                resetUserReview()
                navToReview(order.id, booking.id)
              }}
              value="Review"
            />
          )}
        </div>
      </div>
    </div>
  )
}

BookingItemCard.propTypes = {
  booking: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  extras: PropTypes.array,
  price: PropTypes.number,
  currency: PropTypes.object,
  startTime: PropTypes.string,
  duration: PropTypes.string,

  /* Required for post-purchase view of booking */
  credentials: PropTypes.object,
  onCancel: PropTypes.func,
  onRebook: PropTypes.func,
}

export default BookingItemCard
