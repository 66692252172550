import Thumbor from "./thumbor"

const { THUMBOR_KEY, THUMBOR_URL } = SITE_CONFIG

const thumbor = new Thumbor(THUMBOR_KEY, THUMBOR_URL)

export const thumborize = (src, width, height) => {
  if (!src) return ""
  return thumbor
    .filter("format(webp)")
    .setImagePath(src)
    .resize(width, height)
    .buildUrl()
}

export const thumborizeFitIn = (src, width, height) => {
  if (!src) return ""
  return thumbor
    .filter("format(webp)")
    .setImagePath(src)
    .fitIn(width, height)
    .buildUrl()
}
