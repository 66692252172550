import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const BodyMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
  padding: 0px 20px;
  paddingtop: 1.5em;
  height: 240px;
  p {
    padding: 0;
    margin: 0;
    text-align: center;
    font-weight: 500;
    font-size: 15px;
  }
  a {
    color: ${(props) => props.theme.colors.primary};
    font-weight: bold;
    cursor: pointer;
  }
  button {
    margin-top: 10px;
  }
`

const Title = styled.span`
  display: inline-block;
  font-weight: bold;
  margin-bottom: 10px;
`

function CalendarMessage({ children, title }) {
  return (
    <BodyMessage>
      {title && <Title>{title}</Title>}
      {children}
    </BodyMessage>
  )
}

CalendarMessage.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
  title: PropTypes.string,
}

export default CalendarMessage
