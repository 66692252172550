import { COOKIE_DECLINE, COOKIE_OK } from "../Analytics/constants"

export const initialState = {
  menuOpen: false,
  cookieOk: false,
  cookieDecline: false,
}

export default function navBarReducer(state = initialState, action) {
  switch (action.type) {
    case COOKIE_OK:
      return {
        ...state,
        cookieOk: true,
      }
    case COOKIE_DECLINE:
      return {
        ...state,
        cookieDecline: true,
      }
    case "HOME_CLICKED":
      return {
        ...state,
        menuOpen: false,
      }
    case "CLOSE_MENU":
      return {
        ...state,
        menuOpen: false,
      }
    case "TOGGLE_MENU":
      return {
        ...state,
        menuOpen: !state.menuOpen,
      }
    case "@@router/LOCATION_CHANGE":
      return {
        ...state,
        menuOpen: false,
      }
    default:
      return state
  }
}
