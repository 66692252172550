import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
import InstantBookService from "./InstantBookService"
import { browserIsMobile } from "../../redux/browserSelector"
import useInventoryNavigator from "../../hooks/useInventoryNavigator"
import { isProfileVip } from "../Profile/selectors"

function InfiniteScrollService({ isMobile, isVip, service }) {
  const inventoryNavigate = useInventoryNavigator()

  const clickService = (slug, date) =>
    inventoryNavigate({ serviceSlug: slug, date })

  const today = moment().format("YYYY-MM-DD")

  const linkUrl =
    service.date === today
      ? `/services/${service.slug}`
      : `/services/${service.slug}/date/${service.date}`

  return (
    <InstantBookService
      key={`service-${service.id}-${service.date}`}
      aLink={linkUrl}
      clickAction={() => clickService(service.slug, service.date)}
      isMobile={isMobile}
      service={service}
      userIsVip={isVip}
    />
  )
}

InfiniteScrollService.propTypes = {
  service: PropTypes.shape({
    id: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
  }).isRequired,
  isMobile: PropTypes.bool.isRequired,
  isVip: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  isMobile: browserIsMobile(state),
  isVip: isProfileVip(state),
})

export default connect(mapStateToProps)(InfiniteScrollService)
