import {
  PAYMENT_CANCEL,
  PAYMENT_CONFIRM,
  PAYMENT_TOKEN,
  PURCHASE_PHONE_UPDATE,
} from "./constants"

export const loadTerms = () => ({ type: "LOAD_TERMS_AND_CONDITIONS" })

export const phoneNumberUpdate = (number) => ({
  type: PURCHASE_PHONE_UPDATE,
  payload: { number },
})
export const toggleFlossieDollars = () => ({ type: "TOGGLE_FLOSSIE_DOLLARS" })
export const clearCart = () => ({ type: "CLEAR_CART" })

export const toggleRegistration = () => ({ type: "TOGGLE_SIGNUP_VIEW" })

export const savePromotionCode = (code) => ({
  type: "SAVE_PROMOTION_CODE",
  payload: { code },
})

export const clearPromotionCode = () => ({
  type: "CLEAR_PROMOTION_CODE",
})

export const enterPromotionCode = (code) => ({
  type: "ENTER_PROMOTION_CODE",
  payload: { code },
})

export const selectPaymentMethod = (method) => ({
  type: "SELECT_PAYMENT_METHOD",
  payload: method,
})

export const selectPaymentMethodOption = (option) => ({
  type: "SELECT_PAYMENT_METHOD_OPTION",
  payload: option,
})

export const selectPaymentBtn = (method) => ({
  type: "SELECT_PURCHASE_BTN",
  payload: method,
})

export const handleCardNameChange = (name) => ({
  type: "CARD_NAME_CHANGE",
  name,
})
export const handleCardNumberChange = (num) => ({
  type: "CARD_NUMBER_CHANGE",
  num,
})
export const handleCardExpiryChange = (exp) => ({
  type: "CARD_EXPIRY_CHANGE",
  exp,
})
export const handleCardCvcChange = (cvc) => ({ type: "CARD_CVC_CHANGE", cvc })

export const clearPurchaseError = () => ({ type: "CLEAR_PURCHASE_ERROR" })

export const handleSalonNoteChange = (note) => ({
  type: "NOTES_FOR_SALON_CHANGE",
  payload: note,
})

export const paymentCancel = () => ({ type: PAYMENT_CANCEL })

export const paymentToken = (token) => ({ type: PAYMENT_TOKEN, payload: token })

export const paymentConfirm = (phoneNumber) => {
  const urlParts = window.location.href.split("/")
  const urlPrefix = `${urlParts[0]}//${urlParts[2]}`
  const payload = {
    phone_number: phoneNumber,
    complete_url: `${urlPrefix}/bookings/{ORDER_ID}/confirmation`,
    cancel_url: `${urlPrefix}/purchase#cancel`,
  }

  return {
    type: PAYMENT_CONFIRM,
    payload,
  }
}
