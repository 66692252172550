import React from "react"
import PropTypes from "prop-types"
import {
  AccordianContent,
  AccordianHeader,
} from "../../../components/accordians"
import ParagraphText from "../../../components/ParagraphText"

function InfoAccordion({ content, onClick = null, isOpen, title, tabName }) {
  return (
    <div>
      {content && (
        <AccordianHeader
          title={title}
          open={isOpen}
          onClick={() => typeof onClick === "function" && onClick(tabName)}
        />
      )}
      {content && (
        <AccordianContent open={isOpen} style={{ marginBottom: 20 }}>
          <ParagraphText text={content} />
        </AccordianContent>
      )}
    </div>
  )
}

InfoAccordion.propTypes = {
  content: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isOpen: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  tabName: PropTypes.string.isRequired,
}

export default InfoAccordion
