import React from "react"
import PropTypes from "prop-types"
import Button from "../button"

export function RebookLink({ onRebook }) {
  return (
    <Button variant="primary" onClick={onRebook}>
      Rebook
    </Button>
  )
}

const propTypes = {
  onRebook: PropTypes.func,
}

RebookLink.propTypes = propTypes
