import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { ChevronDownIcon, PlusIcon } from "../../../components/icons"
import buttonize from "../../../components/buttonize"
import Category from "./Category"

const StyledCategoryGroup = styled.div`
  color: ${(props) => (props.selected ? "#f60" : "#000")};
`

const categoryGroupAccordianStyle = {
  display: "block",
  height: 20,
  marginTop: 11,
}

const categoryGroupNameStyle = (isSubCategory) => ({
  flex: 1,
  height: "100%",
  lineHeight: "42px",
  textTransform: isSubCategory ? "none" : "uppercase",
  fontStyle: isSubCategory ? "italic" : "normal",
})

/* Container for individual group row. eg Nails, Hair */
const CategoryHeading = styled.div`
  margin: 0px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  text-align: left;
  border-top: ${(props) =>
    props.isSubCategory ? "1px dashed #ccc" : "1px solid #ccc"};
  height: 42px;
  padding: 0px 14px;
`

function CategoryGroup({
  groupHierachy = [],
  categoryGroup,
  onClick,
  onClickCategory,
  isSubCategory = false,
}) {
  groupHierachy.push(categoryGroup)

  return (
    <StyledCategoryGroup
      key={`catgroup-${categoryGroup.id}`}
      selected={categoryGroup.selected}
    >
      <CategoryHeading
        isSubCategory={isSubCategory}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonize(() => onClick(categoryGroup))}
      >
        <div style={categoryGroupNameStyle(false)}>{categoryGroup.name}</div>
        {categoryGroup.selected ? (
          <ChevronDownIcon style={categoryGroupAccordianStyle} />
        ) : (
          <PlusIcon style={categoryGroupAccordianStyle} />
        )}
      </CategoryHeading>
      {categoryGroup.selected && (
        <>
          {categoryGroup.groups.map((eachSubCategoryGroup) => (
            <CategoryGroup
              key={eachSubCategoryGroup.id}
              categoryGroup={eachSubCategoryGroup}
              onClick={onClick}
              onClickCategory={onClickCategory}
              isSubCategory
            />
          ))}
          {categoryGroup.categories.map((eachCategory) => (
            <Category
              key={eachCategory.id}
              category={eachCategory}
              onClick={() => onClickCategory(eachCategory)}
            />
          ))}
        </>
      )}
    </StyledCategoryGroup>
  )
}

CategoryGroup.propTypes = {
  groupHierachy: PropTypes.arrayOf(PropTypes.string),
  onClick: PropTypes.func.isRequired,
  onClickCategory: PropTypes.func.isRequired,
  categoryGroup: PropTypes.object.isRequired,
  isSubCategory: PropTypes.bool,
}

export default CategoryGroup
