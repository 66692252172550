import React from "react"
import styled from "styled-components"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { useNavigate } from "react-router-dom"
import { SubmitInput } from "../forms/Inputs"

import { cookieOk, cookieDecline } from "../../containers/NavBar/actions"

const StyledCookieBanner = styled.div`
  z-index: 999;
  display: block;
  position: fixed;
  bottom: 0;
  font-size: 12;
  width: calc(100% - 20px);
  color: #fff;
  padding: 20px 10px;
  background-color: ${(props) => props.theme.cookieBgColor};

  div {
    color: #fff;
  }
`

const AcceptButton = styled(SubmitInput)`
  background-color: ${({ theme }) => theme.colors.primary};
`

const DeclineButton = styled(SubmitInput)`
  background-color: #777;
`

const StyledCookieBannerPrivacy = styled.div``

function CookieBanner({
  className = "",
  cookieOk,
  cookieDecline,
  cookiesAllowed,
  cookiesDeclined,
}) {
  const navigate = useNavigate()

  /* Is cookie banner already complete? */
  if (cookiesAllowed || cookiesDeclined) {
    return null
  }

  const handleCookieOk = () => {
    document.cookie = "cookiesok=true;path=/;Secure"

    cookieOk()
  }

  const handleCookieDecline = () => {
    document.cookie = "cookiesok=false;path=/;Secure"

    cookieDecline()
  }

  const buttonStyle = {
    padding: "4px 8px",
    fontSize: 12,
    fontWeight: "bold",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    minWidth: "0px",
    letterSpacing: "0px",
  }

  return (
    <StyledCookieBanner className={className}>
      <StyledCookieBannerPrivacy>
        <div style={{ fontSize: "20px", marginBottom: 10 }}>
          This website uses cookies
        </div>
        <div>
          We use third party cookies to personalise content and ads, to provide
          social media features and to analyse our traffic. We also share
          information about your use of our site with our social media,
          advertising and analytics partners who may combine it with other
          information that you've provided to them or that they've collected
          from your use of their service.
        </div>
        <div style={{ marginTop: 8 }}>
          For more information please refer to our{" "}
          <a
            href="/privacy"
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()

              navigate("/privacy")
            }}
            style={{ color: "#fff" }}
          >
            Privacy Policy
          </a>
        </div>

        <div style={{ display: "flex", marginTop: 20 }}>
          <div style={{ flex: 1 }} />

          <DeclineButton
            onClick={handleCookieDecline}
            value="DECLINE"
            variant="secondary"
            filled
            style={buttonStyle}
          />

          <div style={{ width: 10 }} />

          <AcceptButton
            onClick={handleCookieOk}
            value="ACCEPT COOKIES"
            style={buttonStyle}
          />

          <div style={{ flex: 1 }} />
        </div>
      </StyledCookieBannerPrivacy>
    </StyledCookieBanner>
  )
}

CookieBanner.propTypes = {
  className: PropTypes.string,
  cookieOk: PropTypes.func.isRequired,
  cookieDecline: PropTypes.func.isRequired,
  cookiesAllowed: PropTypes.bool,
  cookiesDeclined: PropTypes.bool,
}

const mapStateToProps = (state) => ({
  cookiesAllowed: state.navBar.cookieOk,
  cookiesDeclined: state.navBar.cookieDecline,
})

const mapDispatchToProps = (dispatch) => ({
  cookieOk: () => dispatch(cookieOk()),
  cookieDecline: () => dispatch(cookieDecline()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CookieBanner)
