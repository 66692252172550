import router from "../config/router"
import { REGISTRATION_SUCCESS } from "../containers/Registration/constants"

const loggedInActions = [
  "SUBMIT_LOGIN_FORM_SUCCESS",
  REGISTRATION_SUCCESS,
  // 'AUTO_LOGIN_SUCCESS',
]

// insert the new dispatched actions after, by calling next() first
export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const returnValue = next(action)
    if (action.type) {
      if (loggedInActions.includes(action.type)) {
        // dispatch(fetchBookings()) // fetch profile?
        const { lastLocation } = getState().login
        if (!action.payload.noRedirect) {
          if (lastLocation) {
            router.navigate(lastLocation)
            dispatch({ type: "CLEAR_LAST_LOCATION" })
          } else {
            router.navigate("/")
          }
        }
      }

      if (action.type === "LOGIN_REQUIRED") {
        router.navigate("/login")
      }
    }
    return returnValue
  }
