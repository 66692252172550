import { LOG_OUT_ACTION, PRE_LOG_OUT_ACTION } from "../Login/constants"
import {
  AUTHENTICATION_FORM_INIT_ACTION,
  PROFILE_FORM_INIT_ACTION,
} from "./constants"

export const initProfileForm = () => ({ type: PROFILE_FORM_INIT_ACTION })
export const initAuthenticationForm = () => ({
  type: AUTHENTICATION_FORM_INIT_ACTION,
})

export const updateUsername = (username) => ({
  type: "AUTHENTICATION_FORM_USERNAME_UPDATE",
  payload: username,
})
export const updatePassword = (password) => ({
  type: "AUTHENTICATION_FORM_PASSWORD_UPDATE",
  payload: password,
})
export const updateCurrentPassword = (password) => ({
  type: "AUTHENTICATION_FORM_CURRENT_PASSWORD_UPDATE",
  payload: password,
})

export const updateFirstName = (firstName) => ({
  type: "PROFILE_FORM_FIRST_NAME_UPDATE",
  payload: firstName,
})
export const updateLastName = (lastName) => ({
  type: "PROFILE_FORM_LAST_NAME_UPDATE",
  payload: lastName,
})
export const updatePhone = (phone) => ({
  type: "PROFILE_FORM_PHONE_UPDATE",
  payload: phone,
})

export const saveProfile = () => ({ type: "SAVE_PROFILE" })
export const saveAuthentication = () => ({ type: "SAVE_AUTHENTICATION" })
export const preLogOut = () => ({ type: PRE_LOG_OUT_ACTION })
export const logOut = () => ({ type: LOG_OUT_ACTION })
