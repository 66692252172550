import Pusher from "pusher-js"

import { getPusherAuthEndpointUrl } from "../api/api"
import {
  FETCH_PROFILE_SUCCESS_ACTION,
  PRE_LOG_OUT_ACTION,
} from "../containers/Login/constants"

const { FRANCHISE_ID } = SITE_CONFIG

// Pusher on Login
const initSocket = (username) => {
  if (typeof window.pusherPrivateSocket === "undefined") {
    window.pusherPrivateSocket = new Pusher(process.env.PUSHER_APP_KEY, {
      cluster: process.env.PUSHER_CLUSTER,
      authEndpoint: getPusherAuthEndpointUrl(),
      auth: {
        params: {
          api_user: username,
        },
        headers: {
          "X-Franchise-ID": FRANCHISE_ID,
        },
      },
    })
  }
}

const startSocket = (dispatch, username, userId) => {
  initSocket(username)

  const presenceChannel = `private-presence-${userId}`
  const chatChannel = `private-chat-${userId}`

  /* Explicitly unbind all channel events in case this gets called more than once */
  if (window.pusherPrivateSocket.channel(presenceChannel)) {
    window.pusherPrivateSocket.channel(presenceChannel).unbind()
  }

  if (window.pusherPrivateSocket.channel(chatChannel)) {
    window.pusherPrivateSocket.channel(chatChannel).unbind()
  }

  window.pusherPrivateSocket
    .subscribe(presenceChannel)
    .bind_global((name, data) => {
      dispatch({ type: "WS_MESSAGE", payload: { name, data } })
    })

  window.pusherPrivateSocket
    .subscribe(chatChannel)
    .bind_global((name, data) => {
      dispatch({ type: "WS_MESSAGE", payload: { name, data } })
    })
}

const unsubscribePusher = (profile) => {
  if (typeof window.pusherPrivateSocket !== "undefined") {
    if (profile) {
      const userId = profile.id
      const channel = `private-presence-${userId}`
      const chatChannel = `private-chat-${userId}`
      window.pusherPrivateSocket.unsubscribe(channel)
      window.pusherPrivateSocket.unsubscribe(chatChannel)
      window.pusherPrivateSocket.disconnect()
      delete window.pusherPrivateSocket
    }
  }
}

// insert the new dispatched actions after, by calling next() first
export default ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    const returnValue = next(action)

    const { type, payload } = action

    if (type) {
      if (type === FETCH_PROFILE_SUCCESS_ACTION) {
        startSocket(dispatch, payload.response.username, payload.response.id)
      }
      if (type === PRE_LOG_OUT_ACTION) {
        const { profile } = getState().profile
        unsubscribePusher(profile)
      }
    }
    return returnValue
  }
