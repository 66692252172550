import React from "react"
import PropTypes from "prop-types"
import buttonize from "../../../components/buttonize"
import { RoundImage } from "../../../components/images/RoundImage"
import { thumborize } from "../../../components/images/thumborize"
import { CheckBoxIcon } from "../../../components/icons"
import { StyledCategoryItem } from "./styledComponents"

function Category({ category, groupName, onClick }) {
  const { descriptions, name, selected } = category

  const images = descriptions && descriptions[0] && descriptions[0].images

  return (
    <StyledCategoryItem
      selected={selected}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonize(() => onClick(category))}
    >
      {images && images.find((i) => i.placement === "gallery") && (
        <RoundImage
          src={thumborize(
            images.find((i) => i.placement === "gallery").url,
            40 * 4,
            40 * 4
          )}
          size={40}
          style={{ marginRight: 10 }}
        />
      )}

      <div style={{ flex: 1, textAlign: "left" }}>
        <div style={{ fontSize: "14px", color: "#000", fontWeight: 500 }}>
          {name}
        </div>
        {groupName && <div>{groupName}</div>}
      </div>

      <CheckBoxIcon checked={selected} />
    </StyledCategoryItem>
  )
}

Category.propTypes = {
  category: PropTypes.object.isRequired,
  groupName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
}

export default Category
