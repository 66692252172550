import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled, { withTheme } from "styled-components"

import { useTranslation } from "react-i18next"

import "react-multi-carousel/lib/styles.css"
import WhatWhenWhereInput from "./WhatWhereWhenInput"
import { browserIsMobile } from "../../../redux/browserSelector"

const WrapContainer = styled.div`
  min-height: 280px;
  background-size: ${(props) => props.theme.wwwBgImageSize};
  background-position: ${(props) => props.theme.wwwBgImagePosition};
  background-image: ${(props) =>
    props.theme.wwwBgImage ? `url('${props.theme.wwwBgImage}')` : ""};
  background-color: ${(props) => props.theme.wwwBgColor};
  background-blend-mode: ${(props) => props.theme.wwwBgImageBlendMode};
  padding: 20px 10px;

  @media ${({ theme }) => theme.responsive.extraSmall} {
    background-position: ${(props) => props.theme.wwwBgImagePositionMobile};
    min-height: 0px;
  }
`

function WhatWhenWhereContainer({
  currentlyOpenModal = null,
  onClearFilters,
  isMobile,
  onClick,
  selectedCampaigns,
  selectedCategories,
  selectedCompanies,
  selectedDays,
  selectedRegions,
  theme,
}) {
  const { t } = useTranslation()

  const www = [
    {
      label: "What",
      name: "what",
      plural: "",
      tags: [
        ...selectedCategories,
        ...selectedCampaigns.map((eachCampaign) => ({
          ...eachCampaign,
          name:
            eachCampaign.headline.charAt(0).toUpperCase() +
            eachCampaign.headline.slice(1),
        })),
      ],
      showBorder: true,
    },
    {
      label: "When",
      name: "when",
      plural: "Dates",
      tags: selectedDays,
      showBorder: true,
    },
    {
      label: "Where",
      name: "where",
      plural: "",
      tags: [...selectedRegions, ...selectedCompanies],
    },
  ]

  return (
    <WrapContainer isMobile={isMobile}>
      <div style={{ maxWidth: 800, marginLeft: "auto", marginRight: "auto" }}>
        <div
          style={{
            color: theme.topNav.whatWhenWhereText,
            fontSize: 20,
            margin: isMobile ? "0px 10px 10px 15px" : "30px 10px 10px 15px",
            fontWeight: 600,
          }}
        >
          <span className="preserve-line-breaks">
            {t("book_appointment_header")}
          </span>
        </div>
        <div>
          {www.map(({ label, name, plural, tags, tagToggle }) => (
            <WhatWhenWhereInput
              clickAction={() => onClick(name)}
              onClear={() => onClearFilters(name)}
              label={label}
              open={currentlyOpenModal === name}
              plural={plural}
              tags={tags}
              tagToggle={tagToggle}
              key={name}
            />
          ))}
        </div>
      </div>
    </WrapContainer>
  )
}

WhatWhenWhereContainer.propTypes = {
  currentlyOpenModal: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onClearFilters: PropTypes.func.isRequired,
  selectedCampaigns: PropTypes.array.isRequired,
  selectedCategories: PropTypes.array.isRequired,
  selectedCompanies: PropTypes.array.isRequired,
  selectedDays: PropTypes.array.isRequired,
  selectedRegions: PropTypes.array.isRequired,
  theme: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    isMobile: browserIsMobile(state),
  }
}

export default connect(mapStateToProps)(withTheme(WhatWhenWhereContainer))
