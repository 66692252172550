import React from "react"
import { WrapperDiv } from "../CalendarBookingModule/DateAndTimeSelector/styledComponents"

function InfiniteScrollEndMessage() {
  return (
    <WrapperDiv
      style={{ paddingTop: "10px", paddingBottom: "10px", textAlign: "center" }}
    >
      <h2>No more results</h2>
      <p>To find more appointments please try adjusting your filters</p>
    </WrapperDiv>
  )
}

export default InfiniteScrollEndMessage
