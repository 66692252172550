import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import SearchInput from "./SearchInput"
import ContainerWithSideBar from "./ContainerWithSideBar"
import Header from "./Header"
import { QuestionLink } from "./FaqLink"
import FaqsContentHoc from "./FaqsContentHoc"
import { useParams } from "react-router-dom"

const Title = styled.h1`
  text-align: center;
  flex: 1;
`

const FaqsTopic = ({
  faqTopics,
  navigateTo,
  navToFourOhFour,
  isMobile,
}) => {
  const params = useParams()

  const faqTopic = params?.faqTopic

  if (!faqTopic) {
    navigateTo("/help")
  }

  const topic = faqTopics.find((t) => t.slug === faqTopic)
  const faqsOfTopic = topic.faqs

  if (!topic) {
    return navToFourOhFour(`Unable to find topic for: ${faqTopic}`)
  }

  return (
    <div>
      <Header>
        <Title>{`${topic.label} FAQs`}</Title>
      </Header>
      <SearchInput navigateTo={navigateTo} />
      <ContainerWithSideBar
        isMobile={isMobile}
        topics={faqTopics}
        navigateTo={navigateTo}
      >
        <div>
          {faqsOfTopic && <span>{`${faqsOfTopic.length} Questions`}</span>}
          {faqsOfTopic &&
            faqsOfTopic.map((f) => (
              <QuestionLink
                key={f.id}
                size="h3"
                topic={topic}
                faq={f}
                navigateTo={navigateTo}
              />
            ))}
        </div>
      </ContainerWithSideBar>
    </div>
  )
}

FaqsTopic.propTypes = {
  navigateTo: PropTypes.func.isRequired,
  faqTopics: PropTypes.array.isRequired,
  navToFourOhFour: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
}

export default FaqsContentHoc(FaqsTopic)
