import { combineEpics } from "redux-observable"

import {
  submitLoginEpic,
  submitLoginFacebook,
  fetchProfileEpic,
  fetchProfileAfterPurchaseEpic,
  handleAuthFailureEpic,
  logOutEpic,
  automaticLoginEpic,
  postDeviceEpic,
  rayGunIdentifyEpic,
  submitLoginAndReloadCartEpic,
} from "../containers/Login/epics"
import {
  resetPasswordEpic,
  updateUserPasswordEpic,
  updatePasswordFullfilledEpic,
} from "../containers/ResetPassword/epics"
import {
  registrationEpic,
  registrationCodeRequestEpic,
  registrationAndReloadCartEpic,
} from "../containers/Registration/epics"
import {
  fetchBookingsEpic,
  cancelUserBookingEpic,
} from "../containers/MyBookings/epics"
import { fetchOrderEpic } from "../containers/Order/epics"
import {
  autoCheckPromotionCodeEpic,
  validatePromotionCodeEpic,
  savePromotionCodeEpic,
  savePromotionCodeAndReloadCartEpic,
  paymentConfirmEpic,
  paymentConfirmCheckEpic,
  paymentConfirmMonitorEpic,
  paymentConfirmAvailableEpic,
  paymentConfirmUnavailableEpic,
  paymentRemoveUnavailableEpic,
  paymentAuthorizeEpic,
  paymentAuthorizeRedirectEpic,
  paymentAuthorizeStripeEpic,
  redirectOnPaymentSuccessEpic,
} from "../containers/Purchase/epics"
import {
  fetchCartEpic,
  addItemToCartEpic,
  addItemToCartAndRedirectEpic,
  removeFromCartEpic,
  removeNotesFromCartEpic,
  addNotesToCartEpic,
  enableBalanceInCartEpic,
  disableBalanceInCartEpic,
  selectCartPaymentMethodEpic,
  clearCartEpic,
} from "../containers/Cart/epics"
import {
  fetchServicesByServiceDescriptionIdEpic,
  fetchRelatedServicesEpic,
} from "../containers/InventoryView/epics"
import {
  fetchCompanyEpic,
  fetchCompanyServicesEpic,
  getCompanyProfileReviewsEpic,
} from "../containers/Companies/epics"
import { fetchEmployeeEpic } from "../containers/Employees/epics"
import {
  profileSaveEpic,
  authenticationSaveEpic,
} from "../containers/Profile/epics"
import { fbTrackerActionsEpic } from "../containers/Analytics/epics"

import {
  fetchInstantBookServicesEpic,
  filterChangeEpic,
  paginatedFetchEpic,
  reSearchOnEmptyEpic,
  redirectToLastFilterOnOverFilter,
} from "../containers/InstantBook/epics"

import {
  fetchCategoriesEpic,
  fetchRegionsEpic,
  companySearchEpic,
} from "../containers/InventoryFilter/epics"

import {
  fetchFavouritesEpic,
  userToggleFavouriteEpic,
} from "../containers/Favourites/epics"

export default combineEpics(
  logOutEpic,
  handleAuthFailureEpic,
  submitLoginEpic,
  submitLoginFacebook,
  submitLoginAndReloadCartEpic,
  automaticLoginEpic,
  fetchProfileEpic,
  fetchProfileAfterPurchaseEpic,
  rayGunIdentifyEpic,
  resetPasswordEpic,
  updateUserPasswordEpic,
  registrationEpic,
  registrationCodeRequestEpic,
  registrationAndReloadCartEpic,
  fetchBookingsEpic,
  cancelUserBookingEpic,
  fetchOrderEpic,
  fetchInstantBookServicesEpic,
  filterChangeEpic,
  fetchCategoriesEpic,
  fetchRegionsEpic,
  redirectToLastFilterOnOverFilter,
  companySearchEpic,
  paginatedFetchEpic,
  fetchServicesByServiceDescriptionIdEpic,
  fetchRelatedServicesEpic,
  profileSaveEpic,
  authenticationSaveEpic,
  reSearchOnEmptyEpic,
  postDeviceEpic,
  paymentConfirmEpic,
  paymentConfirmCheckEpic,
  paymentConfirmMonitorEpic,
  paymentConfirmAvailableEpic,
  paymentConfirmUnavailableEpic,
  paymentRemoveUnavailableEpic,
  paymentAuthorizeEpic,
  paymentAuthorizeRedirectEpic,
  paymentAuthorizeStripeEpic,
  fetchCompanyEpic,
  fetchCompanyServicesEpic,
  getCompanyProfileReviewsEpic,
  fetchEmployeeEpic,
  fbTrackerActionsEpic,
  fetchFavouritesEpic,
  userToggleFavouriteEpic,
  fetchCartEpic,
  clearCartEpic,
  addItemToCartEpic,
  addItemToCartAndRedirectEpic,
  redirectOnPaymentSuccessEpic,
  removeFromCartEpic,
  removeNotesFromCartEpic,
  addNotesToCartEpic,
  enableBalanceInCartEpic,
  disableBalanceInCartEpic,
  selectCartPaymentMethodEpic,
  autoCheckPromotionCodeEpic,
  validatePromotionCodeEpic,
  savePromotionCodeEpic,
  savePromotionCodeAndReloadCartEpic,
  updatePasswordFullfilledEpic
)
