import { createSelector } from "reselect"

const profileState = (state) => state.profile

export const isProfileVip = createSelector(
  profileState,
  (profile) => profile.vip || false
)

export const topCategories = createSelector(profileState, (profile) =>
  Array.isArray(profile?.top?.categories) ? profile.top.categories : []
)

export const isLoggedIn = createSelector(
  profileState,
  (profile) => !!profile.profile.id
)
