import React from "react"
import { Outlet } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import Animation from "../../components/Animation"
import WrapContainer from "../WrapContainer"
import { buildCoreDataQueryOptions } from "./lib"

function Loading() {
  return (
    <WrapContainer
      loginRequired={false}
      showNavBack={false}
      navTitle="Instant Book"
    >
      <Animation />
    </WrapContainer>
  )
}

function InstantBookLoadingContainer() {
  const { isLoading: isLoadingCoreData, data: coreData } = useQuery(
    buildCoreDataQueryOptions()
  )

  if (isLoadingCoreData) return <Loading />

  return <Outlet context={coreData} />
}

export default InstantBookLoadingContainer
