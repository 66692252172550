import { INVENTORY_ITEM_SCRAPED, ROUTER_LOCATION_CHANGE } from "./constants"

export const routerLocationChange = (location) => ({
  type: ROUTER_LOCATION_CHANGE,
  payload: {
    pathname: location.pathname,
  },
})

export const inventoryItemScraped = (inventoryItem, date) => ({
  type: INVENTORY_ITEM_SCRAPED,
  payload: {
    inventoryItem,
    date,
  },
})
