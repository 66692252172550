import {
  FETCH_BOOKINGS_ACTION,
  FETCH_BOOKINGS_SUCCESS_ACTION,
  TOGGLE_BOOKINGS_HISTORY_ACTION,
} from "./constants"

export const fetchBookingsFulfilled = (response) => ({
  type: FETCH_BOOKINGS_SUCCESS_ACTION,
  payload: { response },
})

export const fetchBookings = (type = "upcoming") => ({
  type: FETCH_BOOKINGS_ACTION,
  payload: {
    type,
  },
})

export const toggleHistory = () => ({
  type: TOGGLE_BOOKINGS_HISTORY_ACTION,
})

/**
 * @param bookingId id of the booking to cancel
 * @param shouldRedirect sometimes after the booking is cancelled we want to redirect to another page
 * redirect logic it's on './epic.js'
 * */
export const cancelBooking = (bookingId, shouldRedirect = false) => ({
  type: "CANCEL_USER_BOOKING",
  payload: { bookingId, shouldRedirect },
})

export const bookingCancelled = (response, bookingId) => ({
  type: "CANCEL_USER_BOOKING_SUCCESS",
  payload: { response, bookingId },
})
