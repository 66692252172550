import React, { Component, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled, { withTheme } from "styled-components"
import { GoogleReCaptcha } from "react-google-recaptcha-v3"

import { Link, useNavigate } from "react-router-dom"
import {
  updateFirstName,
  updateLastName,
  updateEmail,
  updatePhone,
  updatePassword,
  submitRegistration,
  submitRegistrationCodeRequest,
  updateError,
  updateCode,
} from "./actions"
import { submitFacebookLogin, clickedFacebookLogin } from "../Login/actions"
import Animation from "../../components/Animation"

import {
  InputLabel,
  TextInput,
  PhoneNumberInput,
  PasswordInput,
  SubmitInput,
  PhoneNumberInputLabel,
} from "../../components/forms/Inputs"
import { SpanLinkFacebook } from "../../components/nav/Links"

import WrapContainer from "../WrapContainer"
import NavBarContainer from "../NavBar/NavBarContainer"
import ErrorBar from "../../components/ErrorBar"
import { GoToButton } from "../../components/button"
import { FacebookIcon, ShieldIcon } from "../../components/icons"

import NewsletterSignup from "./components/NewsletterSignup"
import AdditionalSignup from "./components/AdditionalSignup"
import { setMetaData } from "../../../utils/metaData"

const { FACEBOOK_ENABLED, GOOGLE_RECAPTCHA_SITE_ID } = SITE_CONFIG

const FormContainer = styled.div`
  margin-left: auto;
  flex: 1;
  margin-right: auto;
  max-width: 600px;
`

const LegalLink = styled(Link)`
  color: #333;
  font-weight: 700;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`

function RegistrationContainer({
  hideVerificationCodeForm,
  isMobile,
  noWrap,
  registration,
  submitRegistration,
  submitRegistrationCodeRequest,
  theme,
  toggleRegistrationHandler,
  updateError,
  updateCode,
  updateLastName,
  updateFirstName,
  updateEmail,
  updatePhone,
  updatePassword,
}) {
  const {
    enterCode,
    firstName,
    lastName,
    phone,
    email,
    password,
    code,
    isProcessing,
  } = registration

  const [fbLoggingIn, setFbLoggingIn] = useState(false)
  const [googleRecaptchaToken, setGoogleRecaptchaToken] = useState(false)
  const navigate = useNavigate()

  const doUpdateFirstName = (e) => {
    e.preventDefault()
    updateFirstName(e.target.value)
  }

  const doUpdateLastName = (e) => {
    e.preventDefault()
    updateLastName(e.target.value)
  }

  const doUpdateEmail = (e) => {
    e.preventDefault()
    updateEmail(e.target.value)
  }

  const doUpdatePhone = (value) => {
    updatePhone(value)
  }

  const doUpdatePassword = (e) => {
    e.preventDefault()
    updatePassword(e.target.value)
  }

  const doUpdateCode = (e) => {
    e.preventDefault()
    updateCode(e.target.value)
  }

  const handleReCaptchaVerify = (token) => {
    setGoogleRecaptchaToken(token)
  }

  /* Some franchises have additional newsletter field for marketing signup */
  const getNewsletter = () =>
    document.getElementById("newsletter") &&
    document.getElementById("newsletter").checked

  const validateForm = () => {
    if (!firstName.length) {
      updateError("Please enter your first name")

      return false
    }

    if (!lastName.length) {
      updateError("Please enter your last name")

      return false
    }

    if (!email.length) {
      updateError("Please enter your email address")

      return false
    }

    if (!phone.length) {
      updateError("Please enter your phone number")

      return false
    }

    if (!password.length) {
      updateError("Please enter your password")

      return false
    }

    if (password.length < 8) {
      updateError("Your password must be at least 8 characters long")

      return false
    }

    let complexity = 0

    if (password.match(/[A-Z]/)) {
      complexity += 1
    }

    if (password.match(/[a-z]/)) {
      complexity += 1
    }

    if (password.match(/[0-9]/)) {
      complexity += 1
    }

    if (password.match(/[^\w\s]/)) {
      complexity += 1
    }

    if (complexity < 2) {
      updateError(
        "Please enter a password using a combination of lowercase and uppercase letters, numbers or symbols"
      )

      return false
    }

    return true
  }

  const doSubmitRegistration = (e) => {
    e.preventDefault()

    const newsletter = getNewsletter()

    if (validateForm() && !isProcessing) {
      submitRegistration(
        code,
        firstName,
        lastName,
        phone,
        email,
        password,
        noWrap,
        newsletter
      )
    }
  }

  const doSubmitRegistrationCodeRequest = (e) => {
    e.preventDefault()

    if (validateForm()) {
      submitRegistrationCodeRequest(
        firstName,
        lastName,
        phone,
        email,
        password,
        googleRecaptchaToken
      )
    }
  }

  const fbComponentClicked = () => {
    setFbLoggingIn(true)
    const newsletter = getNewsletter()

    FB.login((response) => {
      if (response.authResponse) {
        const { submitFacebookLogin, noWrap } = props
        submitFacebookLogin(
          response.authResponse.accessToken,
          googleRecaptchaToken,
          noWrap,
          newsletter
        )
      } else {
        setFbLoggingIn(false)
        console.log("User cancelled login or did not fully authorize.")
      }
    })
  }

  const navToLogin = (e) => {
    if (typeof toggleRegistrationHandler === "function") {
      toggleRegistrationHandler(e)
    } else {
      navigate("/login")
    }
  }

  const navToTerms = (e) => {
    e.preventDefault()
    navigate("/terms/customer")
  }

  const navToPrivacyPolicy = (e) => {
    e.preventDefault()
    navigate("/privacy")
  }

  const verificationForm = () => {
    const { hasError } = registration

    return (
      <form onSubmit={doSubmitRegistration}>
        <FormContainer>
          {renderFormHeader(false)}
          {hasError && <ErrorBar message={registration.lastError} />}

          <div>
            <div style={{ marginBottom: 20 }}>
              We&apos;ve emailed a verification code to{" "}
              <span style={{ fontWeight: "bold" }}>{registration.email}</span>{" "}
              which you can enter below to complete your sign up.
            </div>

            <TextInput
              onChange={doUpdateCode}
              value={registration.code}
              style={{ display: "block", margin: "0 auto" }}
            />

            <div style={{ marginTop: 20, marginBottom: 20 }}>
              If you don&apos;t see the email in your inbox please check your
              junk folder or{" "}
              <Link href="mailto:help@flossie.com">contact us</Link> for
              assistance.
            </div>
          </div>

          <div
            style={{
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <SubmitInput
              style={{ maxWidth: 90, color: "#000", backgroundColor: "#fff" }}
              onClick={(e) => {
                e.preventDefault()
                hideVerificationCodeForm()
              }}
              value="Back"
            />
            <div style={{ flex: 1 }} />
            <SubmitInput
              variant="secondary"
              style={{ maxWidth: 110 }}
              onClick={doSubmitRegistration}
              value="Complete"
            />
          </div>
        </FormContainer>
      </form>
    )
  }

  const registrationForm = () => {
    const { hasError } = registration
    if (fbLoggingIn) return <Animation />

    const formContainerStyle = isMobile
      ? { display: "block" }
      : {
          display: "grid",
          gridTemplateColumns: "290px 290px",
          gridTemplateRows: "auto",
          gridColumnGap: "20px",
          gridRowGap: "14px",
        }
    const formInputStyle = isMobile
      ? { display: "block", width: "calc(100% - 32px)", marginBottom: 14 }
      : { display: "inline-block" }

    return (
      <form onSubmit={doSubmitRegistrationCodeRequest}>
        {GOOGLE_RECAPTCHA_SITE_ID && (
          <GoogleReCaptcha onVerify={handleReCaptchaVerify} />
        )}
        <FormContainer>
          {renderFormHeader(true)}

          {hasError && <ErrorBar message={registration.lastError} />}

          <div style={formContainerStyle}>
            <TextInput
              onChange={doUpdateFirstName}
              value={registration.firstName}
              style={formInputStyle}
              label="First Name*"
            />
            <TextInput
              onChange={doUpdateLastName}
              value={registration.lastName}
              style={formInputStyle}
              label="Last Name*"
            />
            <TextInput
              onChange={doUpdateEmail}
              value={registration.email}
              type="email"
              style={formInputStyle}
              label="Email Address*"
            />
            <InputLabel htmlFor="phone-input">
              <span>Phone Number*</span>
              <PhoneNumberInput
                id="phone-input"
                onChange={doUpdatePhone}
                value={registration.phone}
                style={{ marginBottom: isMobile ? 14 : 0 }}
              />
            </InputLabel>
            <div>
              <PasswordInput
                onChange={doUpdatePassword}
                value={registration.password}
                style={{ ...formInputStyle, width: "calc(100% - 30px)" }}
                label="Password*"
              />
            </div>

            <div>
              <div style={{ display: "flex", fontSize: 12, fontWeight: 200 }}>
                <div>
                  <ShieldIcon />
                </div>
                <div style={{ marginLeft: 8 }}>
                  {renderSignupNotice(formInputStyle)}
                </div>
              </div>
            </div>
          </div>

          <NewsletterSignup isMobile={isMobile} />

          <div
            style={{
              marginTop: 20,
              display: "flex",
              alignItems: "center",
              flexDirection: isMobile ? "column-reverse" : "row",
            }}
          >
            {FACEBOOK_ENABLED && (
              <SpanLinkFacebook
                style={{
                  marginTop: isMobile ? 40 : 0,
                  marginBottom: isMobile ? 40 : 0,
                }}
                onClick={fbComponentClicked}
              >
                <FacebookIcon variant="secondary" fill="#FFF" />
                Sign Up with Facebook
              </SpanLinkFacebook>
            )}
            <div style={{ flex: 1 }} />
            <SubmitInput
              variant="primary"
              style={{ maxWidth: 100 }}
              onClick={doSubmitRegistrationCodeRequest}
              value="Sign Up"
            />
          </div>
        </FormContainer>
      </form>
    )
  }

  const renderSignupNotice = (formInputStyle) => {
    if (theme.signupNotice) {
      return theme.signupNotice(formInputStyle, navToTerms, navToPrivacyPolicy)
    }

    return (
      <>
        <AdditionalSignup />

        <p
          style={{
            ...formInputStyle,
            fontSize: 12,
            fontWeight: 200,
            margin: 0,
            lineHeight: "16px",
          }}
        >
          {"By continuing, you are agreeing to Flossie's"}{" "}
          <LegalLink to="/terms/customer">Terms of Service</LegalLink> and{" "}
          <LegalLink to="/privacy">Privacy Policy</LegalLink>
        </p>
      </>
    )
  }

  const renderFormHeader = (showLogin) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: isMobile ? "column-reverse" : "row",
          textAlign: isMobile ? "center" : "left",
          marginBottom: "20px",
        }}
      >
        <span
          style={{
            flex: 1,
            fontWeight: 700,
            fontSize: "22px",
            margin: 0,
            marginTop: isMobile ? "20px" : "0px",
          }}
        >
          {enterCode ? "Verify your email address" : "Create an Account"}
        </span>

        {showLogin && (
          <GoToButton
            variant="secondary"
            style={{ marginBottom: isMobile ? "20px" : "0" }}
            aLink="/login"
            onClick={navToLogin}
          >
            Have an account? Log In here
          </GoToButton>
        )}
      </div>
    )
  }

  const renderForms = () => {
    return (
      <>
        <div style={{ display: enterCode ? "none" : "block" }}>
          {registrationForm()}
        </div>
        {enterCode && <div>{verificationForm()}</div>}
      </>
    )
  }

  setMetaData({
    metaTitle: "Sign Up",
  })

  if (noWrap) return renderForms()
  return (
    <WrapContainer>
      <div style={{ marginTop: 50 }}>{renderForms()}</div>
    </WrapContainer>
  )
}

RegistrationContainer.propTypes = {
  submitRegistration: PropTypes.func.isRequired,
  submitRegistrationCodeRequest: PropTypes.func.isRequired,
  updateFirstName: PropTypes.func.isRequired,
  updateLastName: PropTypes.func.isRequired,
  updateEmail: PropTypes.func.isRequired,
  updatePhone: PropTypes.func.isRequired,
  updatePassword: PropTypes.func.isRequired,
  updateCode: PropTypes.func.isRequired,
  updateError: PropTypes.func.isRequired,
  hideVerificationCodeForm: PropTypes.func.isRequired,
  registration: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  submitFacebookLogin: PropTypes.func.isRequired,
  noWrap: PropTypes.bool,
  toggleRegistrationHandler: PropTypes.func,
  theme: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    registration: state.registration,
    isMobile: state.browser.lessThan.mobile,
  }
}

const mapDispatchToProps = (dispatch) => ({
  updateFirstName: (firstName) => dispatch(updateFirstName(firstName)),
  updateLastName: (lastName) => dispatch(updateLastName(lastName)),
  updatePhone: (phone) => dispatch(updatePhone(phone)),
  updateEmail: (email) => dispatch(updateEmail(email)),
  updateCode: (code) => dispatch(updateCode(code)),
  submitFacebookLogin: (token, googleRecaptchaToken, noWrap, newsletter) =>
    dispatch(
      submitFacebookLogin(token, googleRecaptchaToken, noWrap, newsletter)
    ),
  clickedFacebookLogin: () => dispatch(clickedFacebookLogin()),
  updatePassword: (password) => dispatch(updatePassword(password)),
  updateError: (error) => dispatch(updateError(error)),
  submitRegistration: (
    code,
    firstName,
    lastName,
    phone,
    email,
    password,
    nowrap,
    newsletter
  ) =>
    dispatch(
      submitRegistration(
        code,
        firstName,
        lastName,
        phone,
        email,
        password,
        nowrap,
        newsletter
      )
    ),
  submitRegistrationCodeRequest: (
    firstName,
    lastName,
    phone,
    email,
    password,
    googleRecaptchaToken
  ) =>
    dispatch(
      submitRegistrationCodeRequest(
        firstName,
        lastName,
        phone,
        email,
        password,
        googleRecaptchaToken
      )
    ),
  hideVerificationCodeForm: () => dispatch({ type: "REGISTRATION_CODE_HIDE" }),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(RegistrationContainer))
