import React, { useContext, useMemo } from "react"
import { connect } from "react-redux"

const AuthContext = React.createContext()

const AuthProvider = ({ profile, children }) => {
  const value = useMemo(
    () => ({
      user: profile.id ? profile : null,
    }),
    [profile]
  )

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
}

const mapStateToProps = (state) => ({
  profile: state.profile?.profile,
})

export default connect(mapStateToProps)(AuthProvider)

export const useAuth = () => {
  return useContext(AuthContext)
}
