import React, { useState } from "react"
import styled from "styled-components"

import { TextInput, SubmitInput } from "../../components/forms/Inputs"
import { useNavigate, useSearchParams } from "react-router-dom"

const SearchBox = styled.form`
  width: 100%;
  display: flex;
  margin: 0px 0px 30px 0px;
  input[type="text"] {
    width: 100%;
    margin-right: 15px;
  }
`

const SearchInput = () => {
  const [searchParams] = useSearchParams()

  const navigate = useNavigate()

  // If query is undefined (as is the case when loading /help) then the default value
  // for the input must be a string or React assumes the input is uncontrolled and errors
  // when the value changes later
  const defaultValue = searchParams.get("q")?.trim() ?? ""

  const [searchValue, setSearchValue] = useState(defaultValue)

  const handleSubmit = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (searchValue && searchValue.trim() !== "") {
      const query = new URLSearchParams({
        q: searchValue.trim(),
      })

      navigate(`/help-search?${query.toString()}`)
    } else {
      navigate("/help")
    }
  }

  return (
    <SearchBox onSubmit={handleSubmit}>
      <TextInput
        placeholder="Type keywords to find answers"
        value={searchValue}
        onChange={(e) => setSearchValue(e.target.value)}
      />
      <SubmitInput onClick={handleSubmit} value="Search" />
    </SearchBox>
  )
}

export default SearchInput
