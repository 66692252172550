import React from "react"
import PropTypes from "prop-types"
import { DaySelectDiv } from "./styledComponents"

function DaySelect({ day, disabled, onClick, selected }) {
  return (
    <DaySelectDiv
      selected={selected}
      onClick={disabled ? undefined : () => onClick(day)}
      disabled={disabled}
      sale={day.sale}
      key={day.format("YYYY-MM-DD")}
    >
      <div
        style={{
          flex: 1,
          marginTop: 1,
          textAlign: "center",
          padding: 3,
          fontSize: 12,
          paddingBottom: 8,
        }}
      >
        {day.format("ddd")}
      </div>
      <div
        style={{
          flex: 1,
          marginTop: -12,
          textAlign: "center",
          padding: 3,
          paddingTop: 0,
          paddingBottom: 7,
        }}
      >
        {day.format("D")}
      </div>
    </DaySelectDiv>
  )
}

DaySelect.propTypes = {
  day: PropTypes.object.isRequired,
  disabled: PropTypes.bool.isRequired,
  selected: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default DaySelect
