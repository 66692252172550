import React, { useEffect, useState } from "react"
import moment from "moment"
import { useQuery } from "@tanstack/react-query"
import { useCampaignWizardContext } from "./CampaignWizardContext"
import { fetchService } from "../../../api/api"
import CalendarBookingModule from "../../CalendarBookingModule"
import { DATE_TO_STRING_FORMAT } from "../../../../utils/momentHelpers"

function Calendar() {
  const { selectedCompany, selectedService } = useCampaignWizardContext()

  const [date, setDate] = useState(moment().format(DATE_TO_STRING_FORMAT))
  const [time, setTime] = useState(null)
  const [employeeSlug, setEmployeeSlug] = useState(null)

  const { data: companyService } = useQuery({
    queryKey: ["get-service", selectedCompany.service?.slug],
    enabled: selectedService !== null,
    queryFn: async () => fetchService(selectedCompany.service?.slug),
  })

  // Select a default employee if the company is set to hide any employee
  useEffect(() => {
    if (
      companyService &&
      employeeSlug === null &&
      companyService.hide_any_employee
    ) {
      setEmployeeSlug(companyService.employees[0].slug)
    }
  }, [companyService])

  return companyService ? (
    <div style={{ flex: 1 }}>
      <CalendarBookingModule
        inventoryItem={companyService}
        date={date}
        time={time}
        employeeSlug={employeeSlug}
        onChange={({ date, time, employeeSlug }) => {
          if (date !== undefined) {
            setDate(date)
          }

          if (time !== undefined) {
            setTime(time)
          }

          if (employeeSlug !== undefined) {
            setEmployeeSlug(employeeSlug)
          }
        }}
      />
    </div>
  ) : null
}

export default Calendar
