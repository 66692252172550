import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"

const NotesTextarea = styled.textarea`
  font-size: 14px;
  background-color: #fff;
  padding: 6px 10px;
  outline: none;
  border: 1px solid #ccc;
  width: calc(100% - 20px);
  margin-top: 8px;
  min-height: 100px;
`

function BookingNotes({ onChange, defaultValue = "", maxLength }) {
  const { t } = useTranslation()

  return (
    <NotesTextarea
      maxLength={maxLength}
      placeholder={t("booking_notes_placeholder")}
      defaultValue={defaultValue}
      onChange={onChange}
    />
  )
}

BookingNotes.propTypes = {
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.string,
  maxLength: PropTypes.number,
}

export default BookingNotes
