import React from "react"
import PropTypes from "prop-types"
import { StyledCategoryItem } from "./styledComponents"
import buttonize from "../../../components/buttonize"
import { CheckBoxIcon } from "../../../components/icons"

function Campaign({ campaign, onClick }) {
  const { description, headline, selected } = campaign
  return (
    <StyledCategoryItem
      selected={selected}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonize(() => onClick(campaign))}
    >
      <div style={{ flex: 1, textAlign: "left" }}>
        <div style={{ fontSize: "14px", color: "#000", fontWeight: 500 }}>
          {headline}
        </div>
        <div
          style={{
            fontSize: 12,
            color: "#000",
            fontWeight: 400,
            paddingRight: 12,
            maxWidth: 518,
            marginTop: 4,
          }}
        >
          {description}
        </div>
      </div>
      <CheckBoxIcon checked={selected} />
    </StyledCategoryItem>
  )
}

Campaign.propTypes = {
  campaign: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
}

export default Campaign
