import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import {
  updatePassword,
  setResetPasswordToken,
  updatePasswordAgain,
  resetFormProps,
} from "./actions"

import NavBarContainer from "../NavBar/NavBarContainer"
import ResetPasswordForm from "./ResetPasswordForm"

function ChangePasswordContainer({
  match = {},
  resetFormProps,
  setResetPasswordToken,
}) {
  useEffect(() => {
    const token = (match && match.params && match.params.id) || null
    // reset any persisted form errors
    resetFormProps()
    // set the token from the url
    setResetPasswordToken(token)
  }, [])

  return (
    <div style={{ paddingTop: 30 }}>
      <NavBarContainer />

      <div style={{ margin: 15 }}>
        <ResetPasswordForm />
      </div>
    </div>
  )
}

ChangePasswordContainer.propTypes = {
  setResetPasswordToken: PropTypes.func.isRequired,
  resetFormProps: PropTypes.func.isRequired,
  match: PropTypes.object,
}

function mapStateToProps(state) {
  return {
    password: state.resetpassword.password,
    passwordAgain: state.resetpassword.passwordAgain,
    passwordUpdated: state.resetpassword.passwordUpdated,
    token: state.resetpassword.resetPasswordToken,
    lastErrorCode: state.resetpassword.lastErrorCode,
    lastError: state.resetpassword.lastError,
    hasError: state.resetpassword.hasError,
    isMobile: state.browser.lessThan.mobile,
  }
}

const mapDispatchToProps = (dispatch) => ({
  resetFormProps: () => dispatch(resetFormProps()),
  setResetPasswordToken: (token) => dispatch(setResetPasswordToken(token)),
  updatePassword: (password) => dispatch(updatePassword(password)),
  updatePasswordAgain: (password) => dispatch(updatePasswordAgain(password)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangePasswordContainer)
