import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { connect } from "react-redux"
import { EmployeeSelectButton } from "./EmployeeSelectButton"
import { QuestionMarkInCircleIcon, UserIcon } from "../../components/icons"
import ArrowDownIcon from "../../components/icons/chevron-down.svg"
import { useCalendarBookingModuleContext } from "./CalendarBookingModuleContext"

const BookAppointmentHeading = styled.div`
  display: flex;
  padding-bottom: 0.8vh;
  align-items: center;
  font-weight: bolder;
  > div {
    cursor: pointer;
  }
  > * {
    padding: 0.2vh;
  }
`
const EmployeeSelectContainer = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  background-color: ${(props) =>
    props.white ? "" : props.theme.colors.tertiary};

  > .employee-select-heading {
    text-align: center;
    padding: 1.7vh;
    display: flex;
    position: relative;
    flex-wrap: nowrap;
  }

  > .employee-select-heading svg {
    height: 1.5vh;
  }

  > .employee-select-heading .down-arrow {
    margin-left: auto;
    order: 2;
  }

  > .employee-select-heading .heading-text {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    left: 50%;
    transform: translateX(-50%);
    font-weight: bold;
  }
`

const ThemedArrowDownIcon = styled(ArrowDownIcon)`
  width: 20px;
  margin-left: 5px;
  fill: ${({ theme }) => theme.colors.primary};
`

const ToolTipPopup = styled.div`
  display: ${(props) => (props.show ? "block" : "none")};
  position: absolute;
  top: 10px;
  left: 10px;
  width: 20vh;
  background: #d1d2bf;
  z-index: 10;
  border: 1px solid #848575;
  font-size: 0.8em;
  padding: 1vh;
  border-radius: 1vh;
`

function EmployeeSelector({ isMobile }) {
  const { inventoryItem, setEmployeeSelectOpen } =
    useCalendarBookingModuleContext()

  const [bookingTooltipTriggerMethod, setBookingTooltipTriggerMethod] =
    useState(null)

  useEffect(() => {
    if (bookingTooltipTriggerMethod === "click") {
      setTimeout(() => setShowBookingToolTipTriggerMethod(null), 5000)
    }
  }, [bookingTooltipTriggerMethod])

  const setShowBookingToolTipTriggerMethod = (displayTrigger) => {
    setBookingTooltipTriggerMethod(displayTrigger)
  }

  const clickShowToolTip = () => {
    setShowBookingToolTipTriggerMethod(
      bookingTooltipTriggerMethod === "click" ? null : "click"
    )
  }

  if (!inventoryItem) return null

  const { employees, description } = inventoryItem

  const defaultRole = description.category.default_role

  // hide Employee Select Button if there's nothing to choose from
  if (!employees || employees.length === 0) {
    return null
  }

  return (
    <>
      <BookAppointmentHeading
        onMouseEnter={() => setShowBookingToolTipTriggerMethod("hover")}
        onMouseLeave={() => setShowBookingToolTipTriggerMethod(null)}
      >
        <div>Book an appointment</div>
        <div
          role="button"
          tabIndex={0}
          style={{
            position: "relative",
            display: "grid",
            alignItems: "center",
          }}
          onKeyDown={clickShowToolTip}
          onClick={clickShowToolTip}
        >
          <QuestionMarkInCircleIcon />
          <ToolTipPopup show={bookingTooltipTriggerMethod !== null}>
            Select the arrow below to view stylists and rates. Payment will be
            processed at the salon, based on the stylist level. If you prefer a
            particular stylist, pick one to view their schedule.
          </ToolTipPopup>
        </div>
      </BookAppointmentHeading>
      <EmployeeSelectContainer onClick={() => setEmployeeSelectOpen(true)}>
        <div className="employee-select-heading">
          <div className="down-arrow">
            <ThemedArrowDownIcon />
          </div>
          <div className="heading-text">
            <UserIcon />
            &nbsp;Select&nbsp;
            {defaultRole}
          </div>
        </div>
        <EmployeeSelectButton isMobile={isMobile} role={defaultRole} />
      </EmployeeSelectContainer>
    </>
  )
}

EmployeeSelector.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  isMobile: state.browser.lessThan.mobile,
})

export default connect(mapStateToProps)(EmployeeSelector)
