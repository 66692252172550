import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import moment from "moment-timezone"
import { withTheme } from "styled-components"

import { logOut, preLogOut } from "./actions"
import HugeTitle from "../../components/HugeTitle"

import WrapContainer from "../../containers/WrapContainer"
import ProfileFormContainer from "./ProfileFormContainer"

import { InputLabelWrapper } from "../../components/forms/Inputs"
import { SpanLinkPlain } from "../../components/nav/Links"

const ProfileContainer = ({ preLogOut, logOut, profile, theme }) => {
  const clickLogOut = (e) => {
    e.preventDefault()
    preLogOut()
    logOut()
  }

  const vipExpiry = () => {
    if (!profile.vip_expiry) return null
    const expDate = moment(profile.vip_expiry).format("D MMM YYYY")
    return <p>Make a booking by {expDate} to stay a VIP!</p>
  }

  if (!profile.id) return null

  const { first_name, vip } = profile

  return (
    <WrapContainer showNavBack={false} navTitle="My Account">
      <div
        style={{
          backgroundColor: "#f7f7f7",
          borderBottom: `3px solid ${theme.colors.primary}`,
        }}
      >
        <HugeTitle
          style={{
            color: theme.colors.secondary,
            padding: "20px 0px",
            fontWeight: 600,
          }}
        >
          My Account
        </HugeTitle>
      </div>
      {vip && (
        <div
          style={{
            backgroundColor: theme.colors.primary,
            color: "#fff",
            textAlign: "center",
            padding: "4px 0px",
          }}
        >
          VIP
        </div>
      )}
      <div
        style={{
          maxWidth: 760,
          marginLeft: "auto",
          marginRight: "auto",
          padding: 15,
        }}
      >
        <div style={{ fontSize: 25, fontWeight: 600, marginBottom: 10 }}>
          {`Hi ${first_name}`}
        </div>
        {vipExpiry()}

        <ProfileFormContainer />

        <div style={{ marginTop: 30 }}>
          <InputLabelWrapper label="" id="log_out">
            <SpanLinkPlain onClick={clickLogOut}>Log Out</SpanLinkPlain>
          </InputLabelWrapper>
        </div>
      </div>
    </WrapContainer>
  )
}

ProfileContainer.propTypes = {
  profile: PropTypes.object.isRequired,
  preLogOut: PropTypes.func.isRequired,
  logOut: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
}

function mapStateToProps(state) {
  return {
    profile: state.profile.profile,
  }
}

const mapDispatchToProps = (dispatch) => ({
  logOut: () => dispatch(logOut()),
  preLogOut: () => dispatch(preLogOut()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(ProfileContainer))
