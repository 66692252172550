export const fetchFavourites = () => ({
  type: "FETCH_USER_FAVORITES"
})

export const userFavouriteService = (service_id, isFavourited) => {
  return {
    type: "USER_FAVORITE_SERVICE",
    payload: { service_id, isFavourited }
  }
}

export const setUserFavouriteServiceId = service_id => ({
  type: "USER_FAVORITES_SERVICE_ID",
  payload: service_id
})