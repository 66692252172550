import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { withTheme } from "styled-components"

import { useNavigate } from "react-router-dom"
import { homeClicked, toggleMenu } from "./actions"
import { logOut, preLogOut } from "../Profile/actions"

import PoweredByFlossie from "../../components/PoweredByFlossie"
import CartContainer from "../Cart/CartContainer"
import NavBarMenu from "./NavBarMenu"
import AvedaHeader from "../../franchises/aveda/components/Header"

import { MenuIcon } from "../../components/icons"
import { FixedDiv } from "./components"

function NavBarContainer({
  closeMenu,
  homeClicked,
  menuOpen,
  toggleMenu,
  theme,
  browser,
}) {
  const navigate = useNavigate()

  const navToHome = (e) => {
    e.stopPropagation()
    e.preventDefault()
    homeClicked()

    navigate("/")
  }

  const toggleBurger = (e) => {
    e.preventDefault()
    toggleMenu()
  }

  useEffect(() => {
    /* Quick hack so if menu is open and click is neither the menu toggle button
       or within the menu itself, then close the menu */
    document.addEventListener("click", (e) => {
      const isBurger = e.target.closest("#burger") !== null
      const isMenu = e.target.closest("#menu") !== null

      if (menuOpen && !isBurger && !isMenu) {
        closeMenu()
      }
    })
  }, [])

  function Header() {
    // This abomination is explain within the Aveda theme configuration
    if (theme.avedaHeaderHack) {
      return (
        <AvedaHeader
          browser={browser}
          onHomeClick={navToHome}
          onLoginClick={() => navigate("/login")}
          onBurgerClick={toggleBurger}
          menuOpen={menuOpen}
        />
      )
    }

    return (
      <div style={{ height: 70 }}>
        <FixedDiv>
          {theme.logo(theme.logoImage, theme.logoSize, navToHome)}

          <div style={{ flex: 1 }} />

          <CartContainer />

          <a
            style={{ display: "inline-block", marginRight: 24 }}
            id="burger"
            onClick={toggleBurger}
            href="/"
          >
            <MenuIcon />
          </a>
        </FixedDiv>
      </div>
    )
  }

  return (
    <>
      {/* Render powered by flossie top tab */}
      {SITE_CONFIG.SHOW_PBF_TOP && <PoweredByFlossie />}

      {/* Render side nav */}
      <NavBarMenu closeMenu={closeMenu} />

      {/* Render site header */}
      <Header />
    </>
  )
}

NavBarContainer.propTypes = {
  homeClicked: PropTypes.func.isRequired,
  menuOpen: PropTypes.bool.isRequired,
  logOut: PropTypes.func.isRequired,
  preLogOut: PropTypes.func.isRequired,
  closeMenu: PropTypes.func.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  browser: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  displayName: `${state.profile.profile.first_name} ${state.profile.profile.last_name}`,
  loggedIn: !!state.profile.profile.id,
  menuOpen: state.navBar.menuOpen,
  userId: state.profile.profile.id,
  userEmail: state.profile.profile.username,
  isMobile: state.browser.lessThan.mobile,
  browser: state.browser,
})

const mapDispatchToProps = (dispatch) => ({
  homeClicked: () => dispatch(homeClicked()),
  toggleMenu: () => dispatch(toggleMenu()),
  closeMenu: () => dispatch({ type: "CLOSE_MENU" }),
  logOut: () => dispatch(logOut()),
  preLogOut: () => dispatch(preLogOut()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(NavBarContainer))
