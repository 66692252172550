import React from "react"
import PropTypes from "prop-types"

import { ThemedButton, ThemedButtonLink, VARIANT } from "./ThemedButton"

function GoToButton({
  aLink,
  style,
  className = "",
  children = "Select",
  variant = VARIANT.PRIMARY,
  filled = false,
  onClick = null,
  ...rest
}) {
  /**
   * A button className is generated using variant and filled props.
   * Below are all possible names:
   * go-to-button-primary
   * go-to-button-primary-filled
   * go-to-button-secondary
   * go-to-button-secondary-filled
   * In the franchise theme file we can redefine the style for each class.
   */
  const buttonClassName = `go-to-button-${variant}${filled ? "-filled" : ""}`

  return (
    <div className={`${className} ${buttonClassName}`} style={style}>
      {typeof onClick === "function" ? (
        <ThemedButton
          className={variant}
          variant={variant}
          onClick={onClick}
          {...rest}
        >
          {children}
        </ThemedButton>
      ) : (
        <ThemedButtonLink
          className={variant}
          variant={variant}
          to={aLink}
          {...rest}
        >
          {children}
        </ThemedButtonLink>
      )}
    </div>
  )
}

GoToButton.propTypes = {
  aLink: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  children: PropTypes.string,
  filled: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf([
    VARIANT.PRIMARY,
    VARIANT.SECONDARY,
    VARIANT.TERTIARY,
  ]),
}

export default GoToButton
