/* eslint-disable no-undef */
import axios from "axios"
import qs from "qs"
import alphabetize from "alphabetize-object-keys"
import { Observable } from "rxjs/Observable"
import "rxjs/add/observable/of"

const { API_BASE, FRANCHISE_ID, ROOT_URL } = SITE_CONFIG

export const ApiException = (message) => ({ message })

function getEndpointUrl(endpoint) {
  return `${API_BASE}/${endpoint}`
}

export function getPusherAuthEndpointUrl() {
  return getEndpointUrl("support/pusher/auth")
}

function getFranchiseHeaders() {
  return {
    "X-Franchise-ID": FRANCHISE_ID,
  }
}

function get(credentials, endpoint, data) {
  const d = {
    ...data,
    source: "web",
  }

  const url = `${getEndpointUrl(endpoint)}?${qs.stringify(alphabetize(d))}`

  const headers = getFranchiseHeaders()

  if (credentials) {
    headers.Authorization = `Bearer ${credentials.token}`
  }

  return Observable.create((observer) => {
    axios({
      method: "get",
      url,
      headers,
      validateStatus: (status) => status === 412 || status === 200,
    })
      .then((response) => {
        observer.next(response.data)
        observer.complete()
      })
      .catch((error) => {
        observer.error(error)
      })
  })
}

function getArticleBySlug(slug, endpoint, data) {
  const d = {
    ...data,
    source: "web",
  }
  const url = `${getEndpointUrl(endpoint)}?${qs.stringify(alphabetize(d))}`
  const headers = getFranchiseHeaders()

  return Observable.create((observer) => {
    axios
      .get(url, { headers })
      .then((response) => {
        observer.next(response.data)
        observer.complete()
      })
      .catch((error) => {
        observer.error(error)
      })
  })
}

export function iCalBookingLink(credentials, { id, order_id }) {
  const endpoint = "user/booking/calendar"
  // when order_id is set means we are booking more than one service at the same time
  // thus we sent the order id to the back end to create the iCal file with all the bookings
  // Otherwise we just sent the param 'id' which is the single booking id

  const d = { token: credentials.token, franchise_id: FRANCHISE_ID }

  if (order_id) {
    d.order_id = order_id
  } else {
    d.id = id
  }

  return `${getEndpointUrl(endpoint)}?${qs.stringify(alphabetize(d))}`
}

function post(credentials, endpoint, params, status412Valid = true) {
  const postParams = {
    ...params,
    source: "web",
  }

  const url = getEndpointUrl(endpoint)

  const headers = getFranchiseHeaders()

  if (credentials) {
    headers.Authorization = `Bearer ${credentials.token}`
  }

  return Observable.create((observer) => {
    axios({
      method: "post",
      url,
      data: qs.stringify(alphabetize(postParams)),
      headers,
      validateStatus: (status) =>
        status412Valid ? status === 200 || status === 412 : status === 200,
    })
      .then((response) => {
        observer.next(response.data)
        observer.complete()
      })
      .catch((error) => {
        observer.error(error)
      })
  })
}

export async function fetchGet(credentials, endpoint, params) {
  const url = new URL(getEndpointUrl(endpoint))
  if (typeof params === "object" || Array.isArray(params)) {
    url.search = new URLSearchParams(alphabetize(params))
  }

  return fetch(url, {
    method: "get",
    headers: {
      ...getFranchiseHeaders(),
      ...(credentials !== null && typeof credentials === "object"
        ? { Authorization: `Bearer ${credentials.token}` }
        : {}),
    },
  }).then((response) => response.json())
}

export async function fetchPost(endpoint, params, credentials = null) {
  const formData = new FormData()
  Object.entries(params).forEach(([key, value]) => {
    formData.append(key, value)
  })

  return fetch(getEndpointUrl(endpoint), {
    method: "post",
    headers: {
      ...getFranchiseHeaders(),
      ...(credentials ? { Authorization: `Bearer ${credentials.token}` } : {}),
    },
    body: formData,
  }).then((response) => {
    return response
  })
}

function anonPost(endpoint, params, status412Valid = true) {
  const postParams = {
    ...params,
    source: "web",
  }

  const headers = getFranchiseHeaders()

  return Observable.create((observer) => {
    axios
      .post(getEndpointUrl(endpoint), qs.stringify(alphabetize(postParams)), {
        headers,
        validateStatus: (status) =>
          status412Valid ? status === 200 || status === 412 : status === 200,
      })
      .then((response) => {
        observer.next(response.data)
        observer.complete()
      })
      .catch((error) => {
        observer.error(error)
      })
  })
}

export function login(email, password, googleRecaptchaToken) {
  const data = {
    username: email,
    password,
    source: "web",
  }

  if (googleRecaptchaToken) {
    data.google_recaptcha_token = googleRecaptchaToken
  }

  const headers = getFranchiseHeaders()

  return Observable.create((observer) => {
    axios
      .post(getEndpointUrl("authentication/login"), data, { headers })
      .then((response) => {
        observer.next(response.data)
        observer.complete()
      })
      .catch((error) => {
        observer.error(error)
      })
  })
}

export function facebookLogin(token, googleRecaptchaToken, newsletter) {
  const data = new FormData()
  data.append("token", token)
  data.append("newsletter", newsletter ? 1 : 0)
  data.append("source", "web")

  if (googleRecaptchaToken) {
    data.append("google_recaptcha_token", googleRecaptchaToken)
  }

  const headers = getFranchiseHeaders()

  return Observable.create((observer) => {
    axios
      .post(getEndpointUrl("authentication/facebook"), data, { headers })
      .then((response) => {
        observer.next(response.data)
        observer.complete()
      })
      .catch((error) => {
        observer.error(error)
      })
  })
}

export function registration(
  code,
  firstName,
  lastName,
  phone,
  email,
  password,
  newsletter
) {
  const data = new FormData()
  data.append("code", code)
  data.append("first_name", firstName)
  data.append("last_name", lastName)
  data.append("phone_number", phone)
  data.append("username", email)
  data.append("password", password)
  data.append("newsletter", newsletter ? 1 : 0)
  data.append("source", "web")

  const headers = getFranchiseHeaders()

  return Observable.create((observer) => {
    axios
      .post(getEndpointUrl("authentication/register"), data, { headers })
      .then((response) => {
        observer.next(response.data)
        observer.complete()
      })
      .catch((error) => {
        observer.error(error)
      })
  })
}

export function registrationCodeRequest(
  firstName,
  lastName,
  phone,
  email,
  password,
  googleRecaptchaToken
) {
  const data = new FormData()
  data.append("first_name", firstName)
  data.append("last_name", lastName)
  data.append("phone_number", phone)
  data.append("username", email)
  data.append("password", password)
  data.append("source", "web")

  if (googleRecaptchaToken) {
    data.append("google_recaptcha_token", googleRecaptchaToken)
  }

  const headers = getFranchiseHeaders()

  return Observable.create((observer) => {
    axios
      .post(getEndpointUrl("authentication/code"), data, { headers })
      .then((response) => {
        observer.next(response.data)
        observer.complete()
      })
      .catch((error) => {
        observer.error(error)
      })
  })
}

export function resetPassword(email, googleRecaptchaToken) {
  const data = new FormData()
  data.append("email", email.trim())
  data.append("reset_password_url", `${ROOT_URL}/reset-password/{unique_key}`)
  data.append("source", "web")

  if (googleRecaptchaToken) {
    data.append("google_recaptcha_token", googleRecaptchaToken)
  }

  const headers = getFranchiseHeaders()

  return Observable.create((observer) => {
    axios
      .post(getEndpointUrl("authentication/forgot-password"), data, { headers })
      .then((response) => {
        observer.next(response.data)
        observer.complete()
      })
      .catch((error) => {
        observer.error(error)
      })
  })
}

export function apiGetUserBookings(credentials, showHistory) {
  let type = "upcoming"
  if (showHistory) type = "history"

  return get(credentials, "user/booking/search", { type })
}

export function apiGetUserBooking(credentials, id) {
  return get(credentials, "user/booking/get", { id })
}

export function apiGetUserOrder(credentials, id) {
  return get(credentials, "user/order/get", { id })
}

export function apiCancelUserBooking(credentials, bookingId) {
  return post(credentials, "user/booking/cancel", { id: bookingId })
}

/**
 * @deprecated Use getCompany instead
 */
export function apiGetCompany(id) {
  if (id.match(/^[0-9]+$/)) {
    return get(false, "company/get", { id })
  }
  return get(false, "company/get", { slug: id })
}

export function getCompany(id) {
  if (id.match(/^[0-9]+$/)) {
    return fetchGet(null, "company/get", { id })
  }
  return fetchGet(false, "company/get", { slug: id })
}

export function apiGetCompaniesForService(serviceId) {
  return get(false, "service/similar", { service_id: serviceId })
}

export function apiGetCampaignsForService(campaignId, companyId) {
  return get(false, "service/search", {
    campaign_id: campaignId,
    company_id: companyId,
  })
}

export function apiGetRelatedServices(
  credentials,
  serviceDescriptionId,
  campaignId
) {
  const params = {
    campaign_id: campaignId,
    service_description_id: serviceDescriptionId,
  }

  return get(
    Object.keys(credentials).length > 0 ? credentials : false,
    "service/related",
    params
  )
}

export function apiLoadTermsAndConditions() {
  return get(false, "content/terms-and-conditions", {})
}

export function fetchCampaign(slug) {
  return fetchGet(null, "campaign/get", { slug })
}

export function fetchCampaigns() {
  return fetchGet(false, "campaign/all", { homepage_only: 1 })
}

/*
 * @deprecated use fetchCampaigns
 */
export function apiFetchCampaigns(homepageOnly) {
  return get(false, "campaign/all", { homepage_only: homepageOnly ? 1 : 0 })
}

export function apiGetCompanyServices(id) {
  return get(false, "service/search", { company_id: id })
}

export function apiGetUserProfile(credentials) {
  return get(credentials, "user/get", {})
}

export function apiGetCartVouchers(credentials, appointmentAvailabilityId) {
  // appointment_availability_id
  // include_inapplicable
  // service_id
  const reqParams = {
    include_inapplicable: 1,
    appointment_availability_id: appointmentAvailabilityId,
  }
  return get(credentials, "cart/promotion-codes", reqParams)
}

export function apiSaveProfile(credentials, params) {
  return post(credentials, "user/set", params, false)
}

export function apiPostDevice(credentials, params) {
  return post(credentials, "support/device", params)
}

export function fetchInstantBookServices(credentials, filter) {
  return fetchGet(
    credentials?.token ? credentials : false,
    "inventory/search",
    filter
  )
}

/**
 * @deprecated Use fetchInstantBookServices
 */
export function apiGetInstantBookServices(credentials, filter) {
  return get(
    credentials?.token ? credentials : false,
    "inventory/search",
    filter
  )
}

export function apiFindBestPrice(credentials, token, serviceId, date) {
  return post(credentials, "inventory/price", {
    token,
    service_id: serviceId,
    start_time: date,
  })
}

export function findNextAvailability(
  token,
  date,
  serviceId,
  employeeId = null
) {
  const params = {
    start_date: date,
    token,
    service_id: serviceId,
  }
  if (employeeId) params.employee_id = employeeId
  return fetchPost("inventory/next", params)
}

// Fetch version of find best price
export function findBestPrice(token, serviceId, date, credentials) {
  return fetchPost(
    "inventory/price",
    {
      token,
      service_id: serviceId,
      start_time: date,
    },
    credentials
  )
}

export function fetchService(id) {
  return fetchGet(
    null,
    "service/get",
    id.match(/^[0-9]+$/) ? { id } : { slug: id }
  )
}

/**
 * @deprecated Use getSimilarServices instead
 */
export function apiGetSimilarServices(serviceDescriptionId, campaignId) {
  return get(false, "service/similar", {
    service_description_id: serviceDescriptionId,
    campaign_id: campaignId,
  })
}

export function getSimilarServices(serviceDescriptionId, campaignId) {
  return fetchGet(false, "service/similar", {
    service_description_id: serviceDescriptionId,
    campaign_id: campaignId,
  })
}

export function apiFetchService(unusedcredentials, id) {
  if (id.match(/^[0-9]+$/)) {
    return get(false, "service/get", { id })
  }
  return get(false, "service/get", { slug: id })
}

export function apiPaymentConfirm(credentials, id, params) {
  return post(credentials, "payment/confirm", { cart_id: id, ...params })
}

export function apiPaymentAuthorize(credentials, params) {
  return post(credentials, "payment/authorize", params)
}

export function apiValidatePromotionCode(credentials, code) {
  return get(credentials, "user/promotion-code/validate", {
    voucher_code: code,
  })
}

export function apiSavePromotionCode(credentials, code) {
  return post(credentials, "user/promotion-code/save", {
    voucher_code: code,
  })
}

export function scrapeAvailability(
  credentials,
  serviceId,
  date,
  token,
  employeeId,
  force = false
) {
  const params = {
    start_date: date,
    token,
    service_id: serviceId,
  }

  if (employeeId) params.employee_id = employeeId

  if (force) {
    params.force = 1
  } else {
    params.include_results = 1
  }

  return fetchPost("inventory/scrape", params)
}

/**
 * @deprecated Use scrapeAvailability
 */
export function apiScrapeAvailability(
  credentials,
  serviceId,
  date,
  token,
  employeeId,
  force = false
) {
  const params = {
    start_date: date,
    token,
    service_id: serviceId,
  }
  if (employeeId) params.employee_id = employeeId
  if (force) {
    params.force = 1
  } else {
    params.include_results = 1
  }

  if (Object.keys(credentials).length > 0) {
    return post(credentials, "inventory/scrape", params)
  }

  return anonPost("inventory/scrape", params)
}

export function apiNextAvailability(serviceId, date, token, employeeId) {
  const params = {
    start_date: date,
    token,
    service_id: serviceId,
  }
  if (employeeId) params.employee_id = employeeId
  return anonPost("inventory/next", params)
}

export function franchiseSignup(name, email, salon, software) {
  const params = {
    name,
    email,
    salon,
    software,
  }
  return anonPost("franchise/signup", params)
}

export function apiDistrictSearch(filter) {
  return get(false, "district/search", filter)
}

/**
 * @deprecated Use companySearch instead
 */
export function apiCompanySearch(filter) {
  return get(false, "company/search", filter)
}

export function companySearch(filter = {}) {
  return fetchGet(false, "company/search", filter)
}

export function apiGetCart(credentials, id) {
  const params = id ? { id } : {}

  if (Object.keys(credentials).length > 0) {
    return get(credentials, "cart/get", params)
  }

  return get(false, "cart/get", params)
}

export function apiAddItemToCart(credentials, id, params) {
  if (Object.keys(credentials).length > 0) {
    return post(credentials, "cart/add", { id, ...params })
  }

  return anonPost("cart/add", { id, ...params })
}

export function apiRemoveFromCart(credentials, id, params) {
  if (Object.keys(credentials).length > 0) {
    return post(credentials, "cart/remove", { id, ...params })
  }

  return anonPost("cart/remove", { id, ...params })
}

export function apiRemoveNotesFromCart(credentials, id, params) {
  if (Object.keys(credentials).length > 0) {
    return post(credentials, "cart/remove/notes", { id, ...params })
  }

  return anonPost("cart/remove/notes", { id, ...params })
}

export function apiAddNotesToCart(credentials, id, params) {
  if (Object.keys(credentials).length > 0) {
    return post(credentials, "cart/add/notes", { id, ...params })
  }

  return anonPost("cart/add/notes", { id, ...params })
}

export function apiDisableBalanceInCart(credentials, id) {
  return post(credentials, "cart/disable/balance", { id })
}

export function apiEnableBalanceInCart(credentials, id) {
  return post(credentials, "cart/enable/balance", { id })
}

export function apiSelectCartPaymentMethod(credentials, id, params) {
  if (Object.keys(credentials).length > 0) {
    return post(credentials, "cart/payment-method", { id, ...params })
  }

  return anonPost("cart/payment-method", { id, ...params })
}

export function fetchCategoryData() {
  return fetchGet(false, "category/all", {})
}

/**
 * @deprecated use fetchCategoryData
 */
export function apiGetCategories() {
  return get(false, "category/all", {})
}

export function fetchRegions() {
  return fetchGet(false, "region/all", {})
}

/**
 * @deprecated use fetchRegions
 */
export function apiGetRegions() {
  return get(false, "region/all", {})
}

export function apiGetEmployee(id) {
  return get(false, "employee/get", { id })
}

export const handleXhrError = (error, type) => {
  if (error.xhr && error.xhr.response && error.xhr.response.errors) {
    return Observable.of(
      ...error.xhr.response.errors.map((e) => ({
        // doesn't catch 404, 500 etc errros.
        type,
        payload: e,
        error: true,
      }))
    )
  }
  return Observable.of({
    type,
    payload: {
      error: true,
      error_response: error,
    },
  })
}

// categories = category/all

// unused below

export function apiCompanies(credentials, includeServices) {
  return get(credentials, "user/company/all", {
    include_services: includeServices ? "1" : "0",
  })
}

export function getProfileData(credentials) {
  return axios
    .all([userProfile(credentials), apiCompanies(credentials, false)])
    .then(
      axios.spread((userData, companyData) => ({
        userData,
        salonsData: companyData,
      }))
    )
}

export function apiSaveBooking(credentials, id) {
  return post(credentials, "company/booking/save", { id })
}

export function apiCancelBooking(credentials, id) {
  return post(credentials, "company/booking/cancel", { id })
}

export function apiRescheduleBooking(credentials, params) {
  return post(credentials, "company/booking/reschedule", params)
}

export function apiCreateContact(credentials, params) {
  return post(credentials, "company/add/contact", params)
}

export function apiSaveContact(credentials, params) {
  return post(credentials, "company/edit/contact", params)
}

export function apiSaveEmployee(credentials, params) {
  return post(credentials, "company/edit/employee", params)
}

export function apiDeleteContact(credentials, params) {
  return post(credentials, "company/remove/contact", params)
}

export function apiDeleteEmployee(credentials, params) {
  return post(credentials, "company/remove/employee", params)
}

export function apiDeleteAvailability(credentials, params) {
  return post(credentials, "company/availability/cancel", params)
}

export function apiSaveAvailability(credentials, params) {
  return post(credentials, "company/availability/edit", params)
}

export function apiSaveService(credentials, params) {
  return post(credentials, "service/edit", params)
}

export function apiSaveCompanyHours(credentials, params) {
  return post(credentials, "company/edit/hours", params)
}

export function apiSaveCompanyAvailability(credentials, params) {
  return post(credentials, "company/availability/set", params)
}

export function apiSaveCompany(credentials, params) {
  return post(credentials, "company/edit", params)
}

export function apiSendReport(credentials, params) {
  return post(credentials, "company/report/payment", params)
}

export function apiReviewBooking(credentials, params) {
  return post(credentials, "review/create", params)
}

export function apiUpdateUserPassword({
  password,
  password_again,
  unique_key,
}) {
  return anonPost(
    "authentication/reset-password",
    {
      password,
      password_again,
      unique_key,
    },
    false
  )
}

export function apiUserFavouriteService(
  credentials,
  { service_id, isFavourited }
) {
  let path = "user/bookmark/delete"
  if (!isFavourited) path = "user/bookmark/save"
  return post(credentials, path, { service_id })
}

//
// export function apiGetEmployees(credentials) {
//   return get(credentials, 'company/employee/all', {})
// }

export function apiGetBookings(credentials) {
  return get(credentials, "company/booking/all", {})
}

export function apiGetAvailabilities(credentials, params) {
  return get(credentials, "company/availability/all", params)
}

export function apiGetAvailabilitiesDates(credentials, params) {
  return get(credentials, "company/availability/dates", params)
}

export function apiGetStats(credentials) {
  return get(credentials, "user/statistics", {})
}

export function apiGetCompanyStats(credentials, params) {
  return get(credentials, "company/statistics", params)
}

export function apiGetCompanyReviews(params) {
  return get(false, "review/search", params)
}

export function apiGetArticles(params) {
  return get(false, "article/search", params)
}

export function apiGetArticle(slug) {
  return getArticleBySlug(false, "article/get", { slug })
}

export function apiGetFavourites(credentials) {
  return get(credentials, "user/bookmark/all", {})
}
