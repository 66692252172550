import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { ChevronDownIcon, PlusIcon } from "../../../components/icons"
import buttonize from "../../../components/buttonize"
import Category from "./Category"

const StyledTopCategoriesGroup = styled.div`
  color: "#f60";
`

const TopCategoriesHeading = styled.div`
  margin: 0px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  text-align: left;
  border-top: ${(props) =>
    props.isSubCategory ? "1px dashed #ccc" : "1px solid #ccc"};
  height: 42px;
  padding: 0px 14px;
`

const nameStyle = { flex: 1, height: "100%", lineHeight: "42px" }

const accordianStyle = { display: "block", height: 20, marginTop: 11 }

function TopCategories({
  onClick,
  onClickCategory,
  topCategories,
  topCategoriesAreExpanded,
}) {
  const selected =
    Boolean(
      topCategories.find((eachTopCategory) => eachTopCategory.selected)
    ) || topCategoriesAreExpanded

  return (
    <StyledTopCategoriesGroup>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <TopCategoriesHeading {...buttonize(onClick)}>
        <div style={nameStyle}>Your Top Categories</div>
        {selected ? (
          <ChevronDownIcon style={accordianStyle} />
        ) : (
          <PlusIcon style={accordianStyle} />
        )}
      </TopCategoriesHeading>
      {selected &&
        topCategories.map((eachTopCategory) => (
          <Category
            key={eachTopCategory.id}
            category={eachTopCategory}
            onClick={onClickCategory}
          />
        ))}
    </StyledTopCategoriesGroup>
  )
}

TopCategories.propTypes = {
  onClick: PropTypes.func.isRequired,
  onClickCategory: PropTypes.func.isRequired,
  topCategories: PropTypes.arrayOf(PropTypes.object).isRequired,
  topCategoriesAreExpanded: PropTypes.bool.isRequired,
}

export default TopCategories
