import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import {
  Navigate,
  useLocation,
  useNavigate,
  useOutletContext,
  useParams,
} from "react-router-dom"
import { setMetaData, setDefaultMetaData } from "../../../utils/metaData"
import ShadowBox from "../../components/ShadowBox"
import ErrorBar from "../../components/ErrorBar"
import { SubmitInput } from "../../components/forms/Inputs"
import { getServiceImage } from "../../components/images/lib"

import { clearCartError } from "../Cart/actions"

import {
  fetchFavourites,
  setUserFavouriteServiceId,
} from "../Favourites/actions"

import { redirectToLogin, storeLastLocation } from "../actions"

import Inventory from "./Inventory"
import { getSelectedEmployee } from "./lib"
import useInventoryLink from "../../hooks/useInventoryLink"
import {
  companyToday,
  DATE_TO_STRING_FORMAT,
  dayIsInThePast,
} from "../../../utils/momentHelpers"

function InventoryViewContainer({
  cartError,
  cartErrorMessage,
  clearCartError,
  isMobile,
  fetchFavourites,
  fourOhFour,
  setUserFavouriteServiceId,
  storeLastLocation,
}) {
  const navigate = useNavigate()
  const inventoryLinkFactory = useInventoryLink()

  const { inventoryItem } = useOutletContext()

  const { employeeSlug, date } = useParams()

  const employee = getSelectedEmployee(inventoryItem, employeeSlug)

  const location = useLocation()

  useEffect(() => {
    if (location?.pathname) {
      storeLastLocation(location.pathname)
    }
  }, [location.pathname])

  useEffect(() => {
    fetchFavourites()
  }, [])

  useEffect(() => {
    setMetaData({
      canonicalPath: `/services/${inventoryItem.slug}`,
      metaTitle: `${inventoryItem.company.name} - ${inventoryItem.description.name}`,
      metaDescription: inventoryItem.description.about,
      ogTitle: `${inventoryItem.company.name} - ${inventoryItem.description.name}`,
      ogDescription: inventoryItem.description.about,
      ogUrl: window.location.href,
      ogImage: getServiceImage(inventoryItem.images, inventoryItem.description),
      productBrand: inventoryItem?.company?.name,
      productAvailability: inventoryItem && "in stock",
      productCondition: inventoryItem && "new",
      productPriceAmount:
        inventoryItem.pricing.lowest.price &&
        `${inventoryItem.pricing.lowest.price}`,
      productPriceCurrency: inventoryItem.currency.code,
      productRetailerItemId: inventoryItem.id,
    })

    return () => setDefaultMetaData()
  }, [inventoryItem])

  useEffect(() => {
    if (inventoryItem) {
      setUserFavouriteServiceId(inventoryItem.id)
    }
  }, [inventoryItem])

  useEffect(() => {
    if (fourOhFour) {
      navigate("/404-page-not-found")
    }
  }, [fourOhFour])

  if (date && dayIsInThePast(date, inventoryItem.company.timezone)) {
    return (
      <Navigate
        to={inventoryLinkFactory({
          date: companyToday(inventoryItem.company).format(
            DATE_TO_STRING_FORMAT
          ),
        })}
        replace
      />
    )
  }

  // If the user has selected an employee and the service does not allow any stylist, redirect to the first employee
  if (!employee && inventoryItem.hide_any_employee) {
    return (
      <Navigate
        to={inventoryLinkFactory({
          employeeSlug: inventoryItem.employees[0].slug,
        })}
        replace
      />
    )
  }

  return (
    <>
      <Inventory />

      {cartError ? (
        <ShadowBox
          header="Whoops!"
          isFullscreen={isMobile}
          closeBox={clearCartError}
        >
          <div style={{ textAlign: "center" }}>
            <ErrorBar message={cartErrorMessage} />
            <p>
              <SubmitInput
                onClick={clearCartError}
                value="Please click here to try again"
              />
            </p>
          </div>
        </ShadowBox>
      ) : null}
    </>
  )
}

InventoryViewContainer.propTypes = {
  cartError: PropTypes.bool,
  cartErrorMessage: PropTypes.string,
  clearCartError: PropTypes.func.isRequired,
  fetchFavourites: PropTypes.func.isRequired,
  fourOhFour: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
  setUserFavouriteServiceId: PropTypes.func.isRequired,
  storeLastLocation: PropTypes.func.isRequired,
}

function mapStateToProps(state) {
  return {
    isMobile: state.browser.lessThan.mobile,
    credentials: state.credentials.credentials,
    inventoryDate: state.inventoryView.inventoryDate,
    loggingIn: state.credentials.loggingIn,
    fourOhFour: state.inventoryView.fourOhFour,
    cartError: state.cart.cartError,
    cartErrorMessage: state.cart.cartErrorMessage,
  }
}

const mapDispatchToProps = (dispatch) => ({
  redirectToLogin: () => dispatch(redirectToLogin()),
  storeLastLocation: (location) => dispatch(storeLastLocation(location)),
  fetchFavourites: () => dispatch(fetchFavourites()),
  clearCartError: () => dispatch(clearCartError()),
  setUserFavouriteServiceId: (serviceId) =>
    dispatch(setUserFavouriteServiceId(serviceId)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InventoryViewContainer)
