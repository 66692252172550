import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"

import { Link, useNavigate } from "react-router-dom"
import { fetchCart } from "./actions"

import { TrolleyIcon } from "../../components/icons"

const ItemCount = styled.span`
  display: block;
  position: absolute;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.primaryAccent};
  border-radius: 50%;
  padding: 1px;
  width: 14px;
  height: 14px;
  font-size: 10px;
  text-align: center;
  bottom: -6px;
  left: -5px;
  line-height: 13px;
`

function CartContainer({ cart, theme, icon = null }) {
  const navigate = useNavigate()

  const itemCount = cart.cart.items
    ? cart.cart.items.filter((item) => item.type === "appointment").length
    : 0

  // Cart only has relevance if there's something in it, so don't render if empty
  if (itemCount === 0) {
    return null
  }

  if (theme.cart) {
    return theme.cart((e) => {
      e.preventDefault()
      navigate("/purchase")
    }, itemCount)
  }

  return (
    <div
      style={{
        display: "inline-block",
        position: "relative",
        marginRight: 26,
      }}
    >
      <Link to="/purchase">
        {icon || <TrolleyIcon />}
        {itemCount > 0 && <ItemCount>{itemCount}</ItemCount>}
      </Link>
    </div>
  )
}

CartContainer.propTypes = {
  cart: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
  icon: PropTypes.node,
}

function mapStateToProps(state) {
  return {
    cart: state.cart,
  }
}

const mapDispatchToProps = (dispatch) => ({
  fetchCart: () => dispatch(fetchCart()),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTheme(CartContainer))
