import React from "react"
import PropTypes from "prop-types"
import { withTheme } from "styled-components"
import {
  useJsApiLoader,
  GoogleMap,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api"

import styledMap from "../../containers/InventoryFilter/mapTheme"

const { GOOGLE_MAPS_API_KEY } = SITE_CONFIG

function MyMapComponent(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAPS_API_KEY,
  })

  const [map, setMap] = React.useState(null)

  const onLoad = React.useCallback((map) => {
    setMap(map)

    fitBounds()
    // Disabled by request: askUserLocation()
  }, [])

  const onUnmount = React.useCallback(() => {
    setMap(null)
  }, [])

  const fitBounds = () => {
    if (map === null) {
      return
    }

    const { getPoints, mapNeedsPan, setMapNeedsPan } = props

    const newPoints = getPoints()

    if (mapNeedsPan && newPoints.length > 0) {
      const bounds = new google.maps.LatLngBounds()

      newPoints.map((p) =>
        bounds.extend(
          new google.maps.LatLng(
            parseFloat(p.latitude),
            parseFloat(p.longitude)
          )
        )
      )
      setMapNeedsPan(false)

      map.fitBounds(bounds)
    }
  }

  const onMapChange = () => {
    if (map === null) {
      return
    }

    const { setMapZoom } = props
    const d = {}

    d.zoom = map.getZoom()
    setMapZoom(d.zoom)
    fitBounds()
  }

  const getCenter = () => {
    const { points, mapNeedsPan } = props

    // Only recenter map on all points when explicitly told to
    const bounds = new google.maps.LatLngBounds()

    points.forEach((point) => {
      bounds.extend(
        new google.maps.LatLng(
          parseFloat(point.latitude),
          parseFloat(point.longitude)
        )
      )
    })

    return mapNeedsPan
      ? { lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng() }
      : null
  }

  const { mapZoom, markers, height, theme } = props

  return isLoaded ? (
    <GoogleMap
      onLoad={onLoad}
      onUnmount={onUnmount}
      mapContainerStyle={{
        width: "100%",
        height,
      }}
      zoom={mapZoom}
      onBoundsChanged={onMapChange}
      onZoomChanged={onMapChange}
      center={getCenter()}
      className="themed-map"
      options={{
        styles: styledMap,
        gestureHandling: "greedy",
        maxZoom: 17,
        zoomControl: true,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
        streetViewControlOptions: {
          position: google.maps.ControlPosition.LEFT_BOTTOM,
        },
      }}
    >
      {markers && (
        <MarkerClusterer
          averageCenter
          enableRetinaIcons
          gridSize={60}
          clusterClass="themed-cluster"
          styles={[
            {
              url: "/circle.svg",
              height: 48,
              width: 48,
              filter: "grayscale(1)",
              anchor: [24, 24],
              textColor: theme.colors.primaryAccent,
              textSize: 14,
            },
          ]}
        >
          {(clusterer) =>
            markers.map((marker) => {
              const markerPosition = new google.maps.LatLng(
                parseFloat(marker.latitude),
                parseFloat(marker.longitude)
              )
              return (
                <Marker
                  key={`marker${marker.id}`}
                  onClick={marker.onClick}
                  label={{
                    text: marker.name,
                    className: "themed-label",
                  }}
                  position={markerPosition}
                  clusterer={clusterer}
                  icon={{
                    url: "/location-pin.svg",
                    scaledSize: new google.maps.Size(40, 40),
                    labelOrigin: new google.maps.Point(20, 40),
                  }}
                />
              )
            })
          }
        </MarkerClusterer>
      )}
    </GoogleMap>
  ) : null
}

MyMapComponent.propTypes = {
  setMapZoom: PropTypes.func,
  mapZoom: PropTypes.number,
  height: PropTypes.string,
  getPoints: PropTypes.func, // get points which will be converted in markers on map
  mapNeedsPan: PropTypes.bool,
  markers: PropTypes.array, // array of markers to show in map
  setMapNeedsPan: PropTypes.func,
  points: PropTypes.array, // array with {latitude, longitude} points to show in the map, we use it to zoom out the map till all fit
  theme: PropTypes.object.isRequired, // theme injected by withTheme from styled-components
}

export default withTheme(MyMapComponent)
