import React, { useContext, useRef } from "react"
import FindBestPrice from "./FindBestPrice"
import { TimeSelectorContainer } from "./styledComponents"
import TimeSelectOption from "./TimeSelectOption"
import { useCalendarBookingModuleContext } from "../CalendarBookingModuleContext"

function CalendarBody() {
  const { availabilities, inventoryItem } = useCalendarBookingModuleContext()

  const scrollContainer = useRef(null)

  return (
    <div
      ref={scrollContainer}
      style={{
        height: "240px",
        position: "relative",
        overflow: "auto",
      }}
    >
      <div
        style={{
          position: "relative",
          height: "auto",
        }}
      >
        <TimeSelectorContainer>
          {Array.isArray(availabilities) &&
            availabilities.map((time) => (
              <TimeSelectOption availability={time} key={time.id} />
            ))}
        </TimeSelectorContainer>
        {inventoryItem && <FindBestPrice />}
      </div>
    </div>
  )
}

export default CalendarBody
