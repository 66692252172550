import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

import { Await } from "react-router-dom"
import { CompanyLogo } from "../../components/images/CompanyLogo"
import { getServiceImage } from "../../components/images/lib"

const ServiceGalleryImage = styled.div`
  width: 100%;
  position: relative;
  height: 300px;
  background-image: url("${(props) => props.galleryImageUrl}");
  background-size: cover;
  background-position: center;
  background-color: #fafafa;
`

const ServiceName = styled.div`
  position: absolute;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.5) 25%,
    rgba(0, 0, 0, 1) 90%
  );
  bottom: 0;
  color: #fff;
  width: ${(props) =>
    props.hasLogo ? "calc(100% - 100px)" : "calc(100% - 40px)"};
  height: auto;
  padding: ${(props) => (props.hasLogo ? "20px 80px 20px 20px" : "20px")};
  font-size: 22px;
`

export function ServiceGallery({
  service: { company, description, images },
  style = {},
  showCompanyLogo = true,
}) {
  const galleryImageUrl = getServiceImage(images, description, {
    type: "fitin",
  })

  const hasLogo = typeof company !== "undefined"

  return (
    <React.Suspense fallback={null}>
      <Await resolve={galleryImageUrl}>
        {(galleryImageUrl) => (
          <ServiceGalleryImage
            galleryImageUrl={galleryImageUrl}
            style={{ ...style }}
          >
            {company && (
              <CompanyLogo
                company={company}
                size={40}
                style={{
                  display: "block",
                  position: "absolute",
                  bottom: 10,
                  right: 20,
                  zIndex: 2,
                }}
              />
            )}
            <ServiceName hasLogo={showCompanyLogo && hasLogo}>
              {description.name}
            </ServiceName>
          </ServiceGalleryImage>
        )}
      </Await>
    </React.Suspense>
  )
}

ServiceGallery.propTypes = {
  service: PropTypes.object.isRequired,
  showCompanyLogo: PropTypes.bool,
  style: PropTypes.object,
}
