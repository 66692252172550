/*
    phone number will accept following formats as valid:
    NOTE: there is no length validation yet (excluding 0 char lengths)
    (022) 702 9183
    +64 (022) 702 9183
    +64 (022)-702-9183
    (022)7029183
    0227029183
    +640227029183
  */

const profileFormValidator = (value, type, fieldName) => {
  let fieldError = ""

  if (!value.length) {
    fieldError = "This field cannot be empty."
    return fieldError
  }

  switch (type) {
    case "name":
      if (!/^[A-Za-z\s'.-]+$/.test(value)) {
        return `${fieldName} is invalid.`
      }
      break
    case "email":
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        return "Email address is invalid."
      }
      break
    case "phone":
      if (!/^[0-9* \()+-]+$/.test(value)) {
        return "Phone number is invalid."
      }
      break
    default:
      return null
  }

  return null
}

export default profileFormValidator
