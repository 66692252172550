import React from "react"
import PropTypes from "prop-types"
import { ScrapeStatus, ScrapeStatusParent } from "./styledComponents"
import Animation from "../../../components/Animation"

function LoadingAnimation({ children = null }) {
  return (
    <div style={{ flex: 1, position: "relative" }}>
      {children}
      <Animation height={230} />
    </div>
  )
}

LoadingAnimation.propTypes = {
  children: PropTypes.node,
}

export function Scraping({ status }) {
  return (
    <LoadingAnimation>
      {status && (
        <ScrapeStatusParent>
          <ScrapeStatus>{status}</ScrapeStatus>
        </ScrapeStatusParent>
      )}
    </LoadingAnimation>
  )
}

Scraping.propTypes = {
  status: PropTypes.string,
}
