import React from "react"
import PropTypes from "prop-types"
import Company from "./Company"
import { useCompanySelectorContext } from "./CompanySelectorContext"
import { filterCompanies } from "./lib"
import AvedaNoSearchResultsSalonLocatorMessage from "./AvedaNoSearchResultsSalonLocatorMessage"

function SearchResults({ query }) {
  const { companies } = useCompanySelectorContext()

  if (!query) {
    return null
  }

  const results = filterCompanies(companies, query)

  return (
    <div style={{ paddingBottom: 20 }}>
      {results.length > 0 &&
        results.map((company) => (
          <Company key={company.id} company={company} />
        ))}
      {results.length === 0 && (
        <div style={{ textAlign: "center" }}>
          Sorry, your search returned no results
          <AvedaNoSearchResultsSalonLocatorMessage />
        </div>
      )}
    </div>
  )
}

SearchResults.propTypes = {
  query: PropTypes.string.isRequired,
}

export default SearchResults
