import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import CompanyDetails from "./CompanyDetails"
import Seperator from "../../components/Seperator"
import { AccordianContent, AccordianHeader } from "../../components/accordians"
import ParagraphText from "../../components/ParagraphText"
import ProductDescription from "../../components/ProductDescription"

function Campaign({ campaign }) {
  return (
    <div key={`campaign-${campaign.id}`}>
      <h4 style={{ marginBottom: 8 }}>{campaign.headline}</h4>
      <div style={{ display: "flex", alignItems: "top", marginBottom: 20 }}>
        <p style={{ margin: 0 }}>{campaign.description}</p>
      </div>
    </div>
  )
}

Campaign.propTypes = {
  campaign: PropTypes.shape({
    id: PropTypes.string,
    headline: PropTypes.string,
    description: PropTypes.string,
  }),
}

function DescriptiveContent({ clickTab, item, isLarge, selectedTab = null }) {
  return (
    <>
      {/* TODO: Make sure this works properly with new API before reinstating
        {(item.extras || item.campaigns) && (
          <div style={{ display: "flex", flexWrap: "wrap", marginBottom: 8 }}>
            {item.extras && (<ExtraTag />)}
            {item.campaigns && item.campaigns.map(c => (<CampaignTag campaign={c} />))}
          </div>
        )} */}

      {item.extras &&
        item.extras.map((serviceExtra) => (
          <ProductDescription
            key={`product-${serviceExtra.id}`}
            product={serviceExtra}
          />
        ))}
      {item.campaigns &&
        item.campaigns.map((campaign) => <Campaign campaign={campaign} />)}

      {!isLarge && <CompanyDetails company={item.company} />}

      <Seperator />
      <AccordianHeader
        title="About This Service"
        open={selectedTab === "about"}
        onClick={() => clickTab("about")}
      />
      <AccordianContent open={selectedTab === "about"}>
        <ParagraphText
          text={item.description.about}
          style={{ margin: "0 0 8px 0", fontSize: 16, color: "#000" }}
        />

        <div style={{ marginBottom: 20 }}>
          <span style={{ fontWeight: 500 }}>Duration:</span> {item.duration}{" "}
          Mins
        </div>
      </AccordianContent>

      {item.description.product_info && (
        <>
          <Seperator />
          <AccordianHeader
            title="Products We Use"
            open={selectedTab === "product"}
            onClick={() => clickTab("product")}
          />
          <AccordianContent open={selectedTab === "product"}>
            <ParagraphText
              text={item.description.product_info}
              style={{ marginBottom: 20 }}
            />
          </AccordianContent>
        </>
      )}

      {item.description.preparation && (
        <>
          <Seperator />
          <AccordianHeader
            title="How to Prepare"
            open={selectedTab === "prepare"}
            onClick={() => clickTab("prepare")}
          />
          <AccordianContent open={selectedTab === "prepare"}>
            <ParagraphText
              text={item.description.preparation}
              style={{ marginBottom: 20 }}
            />
          </AccordianContent>
        </>
      )}

      {item.description.terms_and_conditions && (
        <>
          <Seperator />
          <AccordianHeader
            title="Booking Terms"
            open={selectedTab === "terms"}
            onClick={() => clickTab("terms")}
          />
          <AccordianContent open={selectedTab === "terms"}>
            <ParagraphText
              text={item.description.terms_and_conditions}
              style={{ marginBottom: 20 }}
            />
          </AccordianContent>
        </>
      )}
    </>
  )
}

DescriptiveContent.propTypes = {
  clickTab: PropTypes.func.isRequired,
  item: PropTypes.object.isRequired,
  isLarge: PropTypes.bool.isRequired,
  selectedTab: PropTypes.string,
}

const mapStateToProps = (state) => {
  return {
    isLarge: state.browser.greaterThan.large,
  }
}

export default connect(mapStateToProps)(DescriptiveContent)
