import { Observable } from "rxjs"

import {
  apiGetCart,
  apiAddItemToCart,
  apiRemoveFromCart,
  apiRemoveNotesFromCart,
  apiAddNotesToCart,
  apiDisableBalanceInCart,
  apiEnableBalanceInCart,
  apiSelectCartPaymentMethod,
  handleXhrError,
} from "../../api/api"
import { fetchCart } from "./actions"
import { ADD_ITEM_TO_CART_AND_REDIRECT_ACTION } from "./constants"
import { LOG_OUT_ACTION } from "../Login/constants"
import { redirectAction } from "../../redux/redirectMiddleware"
import { trackPurchaseStart } from "../InventoryView/actions"

const fetchCartFulfilled = (response) => ({
  type: "FETCH_CART_SUCCESS",
  payload: { response },
})

const addItemToCartFulfilled = (response, action) => ({
  type: "ADD_ITEM_TO_CART_SUCCESS",
  payload: { response, action },
})

const addItemToCartFailure = (response, action) => ({
  type: "ADD_ITEM_TO_CART_FAILURE",
  payload: { response, action },
})

const storeCartIdentifier = (response) => {
  const { localStorage } = window

  if (!response.id) {
    return response
  }

  localStorage.setItem("FLOSSIE_USER_CART_ID", response.id)

  return response
}

const clearCartIdentifier = () => {
  const { localStorage } = window
  localStorage.removeItem("FLOSSIE_USER_CART_ID")
}

export const fetchCartEpic = (action$, { getState }) =>
  action$.ofType("FETCH_CART").mergeMap(() => {
    const { cart } = getState()
    const { localStorage } = window

    // This action happens very early in application life-cycle so pull these values from local storage rather than redux
    const id = localStorage.getItem("FLOSSIE_USER_CART_ID")
      ? localStorage.getItem("FLOSSIE_USER_CART_ID")
      : cart.id
    const token = localStorage.getItem("FLOSSIE_USER_API_TOKEN")

    const credentials = token ? { token } : {}

    return apiGetCart(credentials, id)
      .map((response) => fetchCartFulfilled(storeCartIdentifier(response)))
      .catch((error) => handleXhrError(error, "FETCH_CART_FAILURE"))
  })

export const addItemToCartEpic = (action$, { getState }) =>
  action$.ofType("ADD_ITEM_TO_CART").mergeMap((action) => {
    const { cart, credentials } = getState()

    return apiAddItemToCart(credentials.credentials, cart.id, action.payload)
      .map((response) => {
        if (!response.success) {
          return addItemToCartFailure(response, action$)
        }

        return addItemToCartFulfilled(response, action$)
      })
      .catch((error) => handleXhrError(error, "ADD_ITEM_TO_CART_FAILURE"))
  })

export const addItemToCartAndRedirectEpic = (action$, { getState }) =>
  action$.ofType(ADD_ITEM_TO_CART_AND_REDIRECT_ACTION).switchMap((action) => {
    const { cart, credentials } = getState()

    const { availability, inventoryItem, type } = action.payload

    const apiParams = {
      type,
      appointment_availability_id: availability.id,
    }
    if (inventoryItem) {
      apiParams.inventory_item_id = inventoryItem.id
    }

    return apiAddItemToCart(credentials.credentials, cart.id, apiParams)
      .concatMap((response) => {
        if (!response.success) {
          return Observable.of(addItemToCartFailure(response, action$))
        }

        return Observable.of(
          trackPurchaseStart(inventoryItem),
          addItemToCartFulfilled(response, action$),
          redirectAction("/purchase")
        )
      })
      .catch((error) => handleXhrError(error, "ADD_ITEM_TO_CART_FAILURE"))
  })

const removeFromCartFulfilled = (response, action) => ({
  type: "REMOVE_ITEM_FROM_CART_SUCCESS",
  payload: { response, action },
})

export const removeFromCartEpic = (action$, { getState }) =>
  action$.ofType("REMOVE_ITEM_FROM_CART").concatMap((action) => {
    const { cart, credentials } = getState()

    return apiRemoveFromCart(credentials.credentials, cart.id, action.payload)
      .map((response) => removeFromCartFulfilled(response))
      .catch((error) => handleXhrError(error, "REMOVE_ITEM_FROM_CART_FAILURE"))
  })

const removeNotesFromCartFulfilled = (response, action) => ({
  type: "REMOVE_NOTES_FROM_CART_SUCCESS",
  payload: { response, action },
})

const removeNotesFromCartFailure = (response, action) => ({
  type: "REMOVE_NOTES_FROM_CART_FAILURE",
  payload: { response, action },
})

export const removeNotesFromCartEpic = (action$, { getState }) =>
  action$
    .ofType("REMOVE_NOTES_FROM_CART")
    .debounceTime(500)
    .concatMap((action) => {
      const { cart, credentials } = getState()

      return apiRemoveNotesFromCart(
        credentials.credentials,
        cart.id,
        action.payload
      )
        .concatMap((response) => {
          if (!response.success) {
            return Observable.of(
              removeNotesFromCartFailure(response, action$),
              fetchCart()
            )
          }

          return Observable.of(removeNotesFromCartFulfilled(response))
        })
        .catch((error) =>
          handleXhrError(error, "REMOVE_NOTES_FROM_CART_FAILURE")
        )
    })

const addNotesToCartFulfilled = (response, action) => ({
  type: "ADD_NOTES_TO_CART_SUCCESS",
  payload: { response, action },
})

const addNotesToCartFailure = (response, action) => ({
  type: "ADD_NOTES_TO_CART_FAILURE",
  payload: { response, action },
})

export const addNotesToCartEpic = (action$, { getState }) =>
  action$
    .ofType("ADD_NOTES_TO_CART")
    .debounceTime(500)
    .concatMap((action) => {
      const { cart, credentials } = getState()

      return apiAddNotesToCart(credentials.credentials, cart.id, action.payload)
        .concatMap((response) => {
          if (!response.success) {
            return Observable.of(
              addNotesToCartFailure(response, action$),
              fetchCart()
            )
          }

          return Observable.of(addNotesToCartFulfilled(response))
        })
        .catch((error) => handleXhrError(error, "ADD_NOTES_TO_CART_FAILURE"))
    })

const disableBalanceInCartFulfilled = (response, action) => ({
  type: "DISABLE_BALANCE_IN_CART_SUCCESS",
  payload: { response, action },
})

export const disableBalanceInCartEpic = (action$, { getState }) =>
  action$.ofType("DISABLE_BALANCE_IN_CART").mergeMap(() => {
    const { cart, credentials } = getState()

    return apiDisableBalanceInCart(credentials.credentials, cart.id)
      .map((response) => disableBalanceInCartFulfilled(response))
      .catch((error) =>
        handleXhrError(error, "DISABLE_BALANCE_IN_CART_FAILURE")
      )
  })

const enableBalanceInCartFulfilled = (response, action) => ({
  type: "ENABLE_BALANCE_IN_CART_SUCCESS",
  payload: { response, action },
})

export const enableBalanceInCartEpic = (action$, { getState }) =>
  action$.ofType("ENABLE_BALANCE_IN_CART").mergeMap(() => {
    const { cart, credentials } = getState()

    return apiEnableBalanceInCart(credentials.credentials, cart.id)
      .map((response) => enableBalanceInCartFulfilled(response))
      .catch((error) => handleXhrError(error, "ENABLE_BALANCE_IN_CART_FAILURE"))
  })

const selectCartPaymentMethodFulfilled = (response, action) => ({
  type: "SELECT_PAYMENT_METHOD_SUCCESS",
  payload: { response, action },
})

const selectCartPaymentMethodFailure = (response, action) => ({
  type: "SELECT_PAYMENT_METHOD_FAILURE",
  payload: { response, action },
})

export const selectCartPaymentMethodEpic = (action$, { getState }) =>
  action$.ofType("SELECT_PAYMENT_METHOD").mergeMap((action) => {
    const { cart, credentials } = getState()

    return apiSelectCartPaymentMethod(credentials.credentials, cart.id, {
      payment_method: action.payload.slug,
    })
      .concatMap((response) => {
        if (!response.success) {
          return Observable.of(
            selectCartPaymentMethodFailure(response, action$),
            fetchCart()
          )
        }

        return Observable.of(
          selectCartPaymentMethodFulfilled(response, action$)
        )
      })
      .catch((error) => handleXhrError(error, "SELECT_PAYMENT_METHOD_FAILURE"))
  })

export const clearCartEpic = (action$) =>
  action$.ofType(LOG_OUT_ACTION, "PAYMENT_AUTHORIZE_SUCCESS").mergeMap(() => {
    clearCartIdentifier()

    return [fetchCart()]
  })
