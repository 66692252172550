export const SET_INVENTORY_DATE_ACTION = "SET_INVENTORY_DATE"
export const SELECT_AVAILABILITY_ACTION = "SELECT_AVAILABILITY"
export const SET_BEST_PRICE_DATA_ACTION = "SET_BEST_PRICE_DATA"
export const SET_BEST_PRICE_ERROR_ACTION = "SET_BEST_PRICE_DATA"

// Value that represents insufficient information currently available to render employee selector
export const EMPLOYEE_ID_LOADING = null

// Value that represents an employee selection of 'Any'
export const EMPLOYEE_ID_ANY = "0"

export const TRACK_PURCHASE_START = "TRACK_PURCHASE_START"

export const FETCH_INVENTORY_SUCCESS = "FETCH_INVENTORY_SUCCESS"
