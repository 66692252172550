export const sortByName = (a, b) => {
  if (a.name > b.name) return 1
  if (a.name < b.name) return -1
  return 0
}

const matchCompanyValue = (company, attribute, searchValue) => {
  const attributeArray = Array.isArray(attribute) ? attribute : [attribute]

  let attributeValueCursor = company
  for (let i = 0; i < attributeArray.length; i += 1) {
    if (Object.keys(attributeValueCursor).includes(attributeArray[i])) {
      if (typeof attributeValueCursor[attributeArray[i]] === "object") {
        attributeValueCursor = attributeValueCursor[attributeArray[i]]
      } else {
        return attributeValueCursor[attributeArray[i]]
          .toLowerCase()
          .includes(searchValue.toLowerCase())
      }
    }
  }

  return false
}

export const filterCompanies = (companies, searchQuery) => {
  const searchTokens = searchQuery.split(" ")
  if (searchTokens.length === 0) {
    return companies
  }

  return companies.filter((eachCompany) => {
    const searchableAttributes = [
      "name",
      "address_one",
      "address_two",
      "post_code",
      ["district", "name"],
      ["district", "region", "name"],
    ]

    const found = searchTokens.find((eachToken) =>
      searchableAttributes.find((eachSearchableAttribute) =>
        matchCompanyValue(eachCompany, eachSearchableAttribute, eachToken)
      )
    )

    return found !== undefined
  })
}
