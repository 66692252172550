import React from "react"
import {
  CountryDiv,
  ThemedArrowDownIcon,
  ThemedPlusIcon,
} from "./styledComponents"
import buttonize from "../buttonize"
import { useCompanySelectorContext } from "./CompanySelectorContext"
import Region from "./Region"

function Countries() {
  const {
    clickCountry,
    clickRegion,
    companies,
    regions,
    selectedRegions,
    countries,
  } = useCompanySelectorContext()

  const availableRegions = regions.filter((eachRegion) =>
    companies.find(
      (eachCompany) => eachCompany.district.region.id === eachRegion.id
    )
  )

  return countries.map((eachAvailableCountry) => (
    <React.Fragment key={`country-${eachAvailableCountry.id}`}>
      <CountryDiv
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...buttonize(() => clickCountry(eachAvailableCountry))}
      >
        <div style={{ flex: 1, height: "100%", lineHeight: "42px" }}>
          {eachAvailableCountry.name}
        </div>
        {eachAvailableCountry.expanded ? (
          <ThemedArrowDownIcon />
        ) : (
          <ThemedPlusIcon />
        )}
      </CountryDiv>
      {eachAvailableCountry.expanded &&
        availableRegions
          .filter(
            (eachAvailableRegion) =>
              eachAvailableRegion.country.name === eachAvailableCountry.name
          )
          .map((eachAvailableRegion) => {
            const isSelected =
              typeof selectedRegions.find(
                (eachSelectedRegion) =>
                  eachSelectedRegion.id === eachAvailableRegion.id
              ) !== "undefined"

            return (
              <Region
                key={`region-${eachAvailableRegion.id}`}
                companies={companies}
                region={eachAvailableRegion}
                isSelected={isSelected}
                clickRegion={clickRegion}
              />
            )
          })}
    </React.Fragment>
  ))
}

export default Countries
