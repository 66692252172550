import styled from "styled-components"
import React, { useRef, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { useNavigate } from "react-router-dom"
import { BackLink } from "../../components/nav/Links"
import { CompanyGallery } from "../../components/images/CompanyGallery"
import { CompanyHours, CompanyMap, Phone } from "../../components/datarenderers"
import { RenderStarRatings, Review } from "../InventoryView/components"
import CompanyAddressBuilder from "../../components/address/CompanyAddressBuilder"
import SocialMediaLinks from "./SocialMediaLinks"
import Seperator from "../../components/Seperator"
import { AccordianContent, AccordianHeader } from "../../components/accordians"
import ParagraphText from "../../components/ParagraphText"
import CompanyServices from "./CompanyServices"

/* Main container for the two content columns */
const CompanyContainer = styled.div`
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: ${(props) =>
    props.isMedium ? "100%" : "calc(50% - 10px) calc(50% - 10px)"};
  max-width: 980px;
  margin: 0px auto;
  padding-top: ${(props) => (props.isMedium ? 0 : "20px")};
`

function Company({
  company,
  loadingCompany,
  isMobile,
  isMedium,
  isLarge,
  reviews,
}) {
  const navigate = useNavigate()

  const [openTabs, setOpenTabs] = useState(
    isMobile ? [] : ["findus", "hours", "loveus"]
  )

  const reviewRef = useRef()

  const tabIsOpen = (tab) => {
    return openTabs.includes(tab)
  }

  const clickStars = () => {
    if (!tabIsOpen("reviews")) clickTab("reviews")
    scrollToReviews()
  }

  const scrollToReviews = () =>
    reviewRef.current &&
    window.scrollTo({
      top: reviewRef.current.offsetTop - 100,
      behavior: "smooth",
    })

  const clickTab = (tab) => {
    if (tabIsOpen(tab)) {
      setOpenTabs(openTabs.filter((t) => t !== tab))
    } else {
      setOpenTabs([...openTabs, tab])
    }
  }

  const renderHowToFindUs = () => {
    return (
      <>
        <AccordianHeader
          title="How To Find Us"
          open={tabIsOpen("findus")}
          onClick={() => clickTab("findus")}
        />
        <AccordianContent open={tabIsOpen("findus")}>
          <div style={{ height: 200, marginBottom: 20 }}>
            <CompanyMap company={company} height={200} />
          </div>
        </AccordianContent>
      </>
    )
  }

  if (loadingCompany || !company) return null

  return (
    <>
      <CompanyContainer isMedium={isMedium}>
        <div style={{ position: "relative" }}>
          <BackLink
            href="/"
            onClick={(e) => {
              e.preventDefault()
              navigate(-1)
            }}
            style={{ top: !isLarge ? 10 : 0, left: !isLarge ? 10 : -60 }}
          />

          <CompanyGallery company={company} />

          {!isMedium && (
            <div
              style={{
                backgroundColor: "#ececec",
                marginTop: 20,
              }}
            >
              <CompanyMap company={company} height={300} />
            </div>
          )}
        </div>

        <div style={{ padding: isMedium ? 10 : 0 }}>
          {company.rating_count > 0 && (
            <div
              style={{
                textAlign: "left",
              }}
            >
              <div style={{ display: "inline-block" }}>
                <RenderStarRatings
                  clickStars={clickStars}
                  rating={company.rating_customer}
                  ratingCount={company.rating_count}
                />
              </div>
            </div>
          )}

          <div style={{ margin: "15px 0" }}>
            <CompanyAddressBuilder
              company={company}
              addressOne
              addressTwo
              district
              region
              postCode
              inline
              showIcon
            />
            <div style={{ margin: "2px 0 20px 0" }}>
              <Phone number={company.phone_number} />
            </div>
            <SocialMediaLinks company={company} isMedium={isMedium} />
          </div>

          <Seperator />

          <AccordianHeader
            title="Current Opening Hours"
            open={tabIsOpen("hours")}
            onClick={() => clickTab("hours")}
          />
          <AccordianContent open={tabIsOpen("hours")}>
            <CompanyHours hours={company.hours} />
            <div style={{ marginBottom: 20 }} />
          </AccordianContent>

          {isMedium && (
            <>
              <Seperator />
              {renderHowToFindUs()}
            </>
          )}

          <Seperator />

          <AccordianHeader
            title="About This Salon"
            open={tabIsOpen("loveus")}
            onClick={() => clickTab("loveus")}
          />

          <AccordianContent open={tabIsOpen("loveus")}>
            <ParagraphText text={company.description} />
            <div style={{ marginBottom: 20 }} />
          </AccordianContent>

          <Seperator />

          {reviews.length > 0 && (
            <>
              <span ref={reviewRef} />
              <Seperator />
              <AccordianHeader
                title="Reviews"
                open={tabIsOpen("reviews")}
                onClick={() => clickTab("reviews")}
              />
              <AccordianContent open={tabIsOpen("reviews")}>
                <div style={{ maxHeight: 400, overflow: "scroll" }}>
                  {reviews.map((r) => (
                    <Review
                      style={{ marginBottom: 14 }}
                      key={`review-${r.id}`}
                      review={r}
                    />
                  ))}
                </div>
              </AccordianContent>
            </>
          )}
        </div>
      </CompanyContainer>

      <div
        style={{
          maxWidth: "980px",
          margin: "0px auto",
          padding: isMedium ? 10 : 0,
        }}
      >
        <div style={{ marginTop: 40, paddingBottom: 40 }}>
          <h2 style={{ textAlign: "center", marginBottom: 20 }}>
            Our Services
          </h2>

          <Seperator />
          <CompanyServices companyId={company.id} />
        </div>
      </div>
    </>
  )
}

Company.propTypes = {
  company: PropTypes.object.isRequired,
  loadingCompany: PropTypes.bool.isRequired,
  isMedium: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  isLarge: PropTypes.bool,
  reviews: PropTypes.array.isRequired,
}

function mapStateToProps(state) {
  return {
    company: state.company.company,
    isLarge: state.browser.greaterThan.large,
    isMobile: state.browser.lessThan.mobile,
    isMedium: state.browser.lessThan.medium,
    reviews: state.company.reviews,
    services: state.company.services,
  }
}

export default connect(mapStateToProps)(Company)
