import { SET_GEO_LOCATION_ACTION } from "../InstantBook/constants"

export const setGeo = (lat, lng) => ({
  type: SET_GEO_LOCATION_ACTION,
  payload: { lat, lng }
})
export const setMapZoom = zoom => ({ type: "SET_MAP_ZOOM", payload: { zoom } })
export const toggleCategoryGroup = groupId => ({
  type: "TOGGLE_CATEGORY_GROUP",
  payload: groupId
})

export const companySearchUpdate = search => ({
  type: "COMPANY_SEARCH_UPDATE",
  payload: search
})

export const fetchCompanies = () => ({ type: "FETCH_COMPANIES" })
