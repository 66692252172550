import PropTypes from "prop-types"
import React, { useState } from "react"
import {
  CountryDiv,
  ThemedArrowDownIcon,
  ThemedPlusIcon,
} from "./styledComponents"
import Company from "./Company"
import buttonize from "../buttonize"
import { sortByName } from "./lib"

function TopCompanies({ topCompanies = [] }) {
  const [showTopCompanies, setShowTopCompanies] = useState(false)

  const toggleTopCompanies = () => {
    if (topCompanies.length === 0) {
      return
    }
    setShowTopCompanies(!showTopCompanies)
  }

  const nameStyle = { flex: 1, height: "100%", lineHeight: "42px" }

  return (
    <React.Fragment key="your-companies">
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <CountryDiv {...buttonize(toggleTopCompanies)}>
        <div style={nameStyle}>Your Top Salons</div>
        {showTopCompanies ? <ThemedArrowDownIcon /> : <ThemedPlusIcon />}
      </CountryDiv>
      {showTopCompanies &&
        topCompanies
          .sort(sortByName)
          .map((company) => (
            <Company key={`top-company-${company.id}`} company={company} />
          ))}
    </React.Fragment>
  )
}

TopCompanies.propTypes = {
  topCompanies: PropTypes.array,
}

export default TopCompanies
