import { createSelector } from "reselect"

export const getInstantbook = (state) =>
  Object.prototype.hasOwnProperty.call(state, "instantbook")
    ? state.instantbook
    : state

export const getInstantbookReload = createSelector(
  getInstantbook,
  (instantbook) => instantbook.reloadResults
)

export const getInstantReadyToFetch = createSelector(
  getInstantbook,
  (instantbook) => instantbook.readyToFetch
)

export const getInfiniteScrollPosition = createSelector(
  getInstantbook,
  (instantbook) => ({
    position: instantbook.infiniteScrollPosition,
    key: instantbook.infiniteScrollPositionKey,
    numberOfPages: instantbook.infiniteScrollPositionNumberOfPages,
  })
)
