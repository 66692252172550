import React, { useCallback } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import {
  ServiceDescription,
  Step,
  StepHeader,
  StepParent,
} from "./styledComponents"
import { useCampaignWizardContext } from "./CampaignWizardContext"
import HighlightedBorderArea from "../../../components/sections/HighlightedBorderArea"
import ServiceButtons from "./ServiceButtons"
import { ServiceGallery } from "../ServiceGallery"
import { ArrowRightIcon } from "../../../components/icons"
import ProductDescription from "../../../components/ProductDescription"
import Seperator from "../../../components/Seperator"
import InfoAccordion from "./InfoAccordion"
import { browserIsMobile } from "../../../redux/browserSelector"

function CampaignStep2({ isMobile }) {
  const {
    campaign,
    selectedCategory,
    selectedService,
    selectedStep,
    selectedServiceTab,
    setSelectedServiceTab,
  } = useCampaignWizardContext()

  const stepRef = useCallback((node) => {
    if (selectedStep === 2 && node !== null) {
      node.scrollIntoView({ behavior: "smooth" })
    }
  }, [])

  if (!selectedCategory) {
    return null
  }

  return (
    <div ref={stepRef} style={{ marginBottom: "20px" }}>
      <StepParent>
        <Step id="step-2">Step 2 of 5</Step>
        <StepHeader>{campaign.title_service_confirm}</StepHeader>
      </StepParent>

      {selectedService && (
        <div>
          <ServiceButtons />

          <HighlightedBorderArea
            style={{ marginBottom: isMobile ? 30 : 0 }}
            showBorder={isMobile}
          >
            <div
              style={{
                display: isMobile ? "block" : "grid",
                gridGap: "20px",
                gridTemplateRows: isMobile ? "repeat(2, auto)" : "none",
                gridTemplateColumns: isMobile
                  ? "none"
                  : "repeat(2, calc(50% - 10px))",
              }}
            >
              <div>
                <ServiceGallery service={selectedService} />
              </div>

              <ServiceDescription>
                <div>
                  {campaign.related_url && (
                    <div>
                      <Link
                        href={campaign.related_url}
                        style={{
                          display: "flex",
                          position: "relative",
                        }}
                      >
                        More services you may like{" "}
                        <ArrowRightIcon style={{ marginLeft: 5 }} />
                      </Link>
                    </div>
                  )}

                  {selectedService.extras &&
                    selectedService.extras.map((serviceExtra) => (
                      <ProductDescription product={serviceExtra} />
                    ))}
                </div>

                <Seperator />

                <InfoAccordion
                  content={selectedService.description.about}
                  isOpen={selectedServiceTab === "love-it"}
                  onClick={setSelectedServiceTab}
                  title="About This Service"
                  tabName="love-it"
                />
                {selectedService.description.about && <Seperator />}
                <InfoAccordion
                  content={selectedService.description.preparation}
                  isOpen={selectedServiceTab === "prepare"}
                  onClick={setSelectedServiceTab}
                  title="How you can prepare"
                  tabName="prepare"
                />
                {selectedService.description.preparation && <Seperator />}
                <InfoAccordion
                  content={selectedService.description.terms_and_conditions}
                  isOpen={selectedServiceTab === "terms"}
                  onClick={setSelectedServiceTab}
                  title="Booking Terms"
                  tabName="terms"
                />
                {selectedService.description.terms_and_conditions && (
                  <Seperator />
                )}
              </ServiceDescription>
            </div>
          </HighlightedBorderArea>

          {isMobile && <ServiceButtons />}
        </div>
      )}
    </div>
  )
}

CampaignStep2.propTypes = {
  isMobile: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  isMobile: browserIsMobile(state),
})

export default connect(mapStateToProps)(CampaignStep2)
