import React from "react"
import PropTypes from "prop-types"
import Button from "../../../components/button"
import CalendarMessage from "./CalendarMessage"
import { useCalendarBookingModuleContext } from "../CalendarBookingModuleContext"

export function NoAvailability({ showFindNextDate }) {
  const { inventoryItem, fetchNextAvailability } =
    useCalendarBookingModuleContext()

  const role = inventoryItem?.description?.category.default_role
    .trim()
    .toLowerCase()

  return (
    <CalendarMessage title="Sorry!">
      <div>
        <p>
          {`The ${role} you have selected is`}
          <b> booked out today.</b>
        </p>
        <p>{`Select next available date or choose another ${role}.`}</p>
      </div>
      {showFindNextDate && (
        <Button onClick={() => fetchNextAvailability()}>
          Find next available date
        </Button>
      )}
    </CalendarMessage>
  )
}

NoAvailability.propTypes = {
  showFindNextDate: PropTypes.bool.isRequired,
}
