import { createSelector } from "reselect"

export const getInventoryView = (state) =>
  Object.prototype.hasOwnProperty.call(state, "inventoryView")
    ? state.inventoryView
    : state

export const getInventoryItem = createSelector(
  getInventoryView,
  (inventoryView) => inventoryView.inventoryItem || {}
)

export const getCompany = createSelector(
  getInventoryItem,
  (inventoryItem) => inventoryItem.inventoryView.company
)

export const getServiceSlug = createSelector(
  getInventoryItem,
  (inventoryItem) => inventoryItem.slug
)
export const getServicePrice = createSelector(
  getInventoryItem,
  (inventoryItem) => inventoryItem.pricing
)

export const getBestPriceData = createSelector(
  getInventoryView,
  (inventoryView) => inventoryView.bestPriceData
)

export const getFindBestPriceError = createSelector(
  getInventoryView,
  (inventoryView) => inventoryView.findBestPriceError
)
