import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import ServiceCard from "../../../components/cards/ServiceCard"
import { isProfileVip } from "../../Profile/selectors"
import { browserIsMobile } from "../../../redux/browserSelector"
import { useCampaignWizardContext } from "./CampaignWizardContext"

function CategorySelector({ category, isMobile, isVip }) {
  const { selectCategory, selectedCategory } = useCampaignWizardContext()
  /* Is the currently selected category of similar services */
  const isCurrent = category.id === selectedCategory?.id

  const buttonContent = isCurrent ? "Selected" : "See more"
  return (
    <ServiceCard
      highlight={isCurrent}
      buttonContent={buttonContent}
      key={`category-card-${category.id}`}
      onClick={() => selectCategory(category)}
      showPrice={false}
      isMobile={isMobile}
      isVip={isVip}
      name={category.name}
      description={category.description}
      serviceImages={category.images}
      centeredContent
    />
  )
}

CategorySelector.propTypes = {
  category: PropTypes.object.isRequired,
  isVip: PropTypes.bool.isRequired,
  isMobile: PropTypes.bool.isRequired,
}

const mapStateToProps = (state) => ({
  isVip: isProfileVip(state),
  isMobile: browserIsMobile(state),
})

export default connect(mapStateToProps)(CategorySelector)
