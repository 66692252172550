import PropTypes from "prop-types"
import React, { useMemo, useRef } from "react"
import Company from "./Company"
import { sortByName } from "./lib"
import {
  CompanyDiv,
  CompanyName,
  RegionDiv,
  ThemedArrowDownIcon,
  ThemedPlusIcon,
} from "./styledComponents"
import buttonize from "../buttonize"
import { CheckBoxIcon } from "../icons"
import { useCompanySelectorContext } from "./CompanySelectorContext"

function Region({ companies: allCompanies, region, isSelected, clickRegion }) {
  const { id: regionId, name: regionName } = region

  const {
    showSelectAllRegions: showSelectAll,
    onSelectAllCompaniesInRegion,
    selectedCompanies,
  } = useCompanySelectorContext()

  const firstSelectedCompany = useRef(null)

  const companies = useMemo(
    () =>
      allCompanies
        .filter((eachCompany) => eachCompany.district.region.id === regionId)
        .map((eachCompany) => {
          const selected =
            isSelected ||
            Boolean(
              selectedCompanies.find(
                (eachSelectedCompany) =>
                  eachSelectedCompany.id === eachCompany.id
              )
            )

          if (selected && firstSelectedCompany.current === null) {
            firstSelectedCompany.current = eachCompany
          }

          return {
            ...eachCompany,
            selected,
          }
        })
        .sort(sortByName),
    [allCompanies]
  )

  return (
    <>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <RegionDiv {...buttonize(() => clickRegion(region))}>
        <div
          style={{
            flex: 1,
            height: "100%",
            lineHeight: "42px",
            fontStyle: "italic",
            textTransform: "none",
          }}
        >
          {regionName}
        </div>
        {region.expanded ? <ThemedArrowDownIcon /> : <ThemedPlusIcon />}
      </RegionDiv>
      {(isSelected || region.expanded) && (
        <>
          {showSelectAll && companies.length > 1 && (
            <CompanyDiv
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...buttonize(() => onSelectAllCompaniesInRegion(region))}
              selected={isSelected}
              key={`region-${region.id}`}
            >
              <div style={{ flex: 1, textAlign: "left", marginLeft: 10 }}>
                <CompanyName>All of {region.name}</CompanyName>
              </div>
              <CheckBoxIcon checked={isSelected} />
            </CompanyDiv>
          )}
          {companies.map((eachCompany) => (
            <Company company={eachCompany} key={eachCompany.id} />
          ))}
        </>
      )}
    </>
  )
}

Region.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      selected: PropTypes.bool.isRequired,
    })
  ).isRequired,
  isSelected: PropTypes.bool.isRequired,
  region: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    expanded: PropTypes.bool.isRequired,
  }).isRequired,
  clickRegion: PropTypes.func.isRequired,
}

export default Region
