import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { ChevronDownIcon, PlusIcon, TagIcon } from "../../../components/icons"
import Campaign from "./Campaign"

const TagWrapper = styled.div`
  flex: 1;
  height: 100%;
  line-height: 42px;
  display: flex;
  align-items: center;
  svg {
    margin-left: 15px;
  }
`
const StyledCampaignGroup = styled.div`
  color: "#f60";
`

const CampaignGroupHeading = styled.div`
  margin: 0px;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  text-align: left;
  border-top: ${(props) =>
    props.isSubCategory ? "1px dashed #ccc" : "1px solid #ccc"};
  height: 42px;
  padding: 0px 14px;
`

const accordianStyle = { display: "block", height: 20, marginTop: 11 }

function Campaigns({
  campaigns,
  campaignsAreExpanded,
  onClick,
  onClickCampaign,
}) {
  const selected =
    Boolean(campaigns.find((eachCampaign) => eachCampaign.selected)) ||
    campaignsAreExpanded

  return (
    <StyledCampaignGroup>
      <CampaignGroupHeading onClick={onClick}>
        <TagWrapper>
          Special Offers
          <TagIcon />
        </TagWrapper>
        {selected ? (
          <ChevronDownIcon style={accordianStyle} />
        ) : (
          <PlusIcon style={accordianStyle} />
        )}
      </CampaignGroupHeading>
      {selected &&
        campaigns.map((eachCampaign) => (
          <Campaign
            key={eachCampaign.slug}
            campaign={eachCampaign}
            onClick={onClickCampaign}
          />
        ))}
    </StyledCampaignGroup>
  )
}

Campaigns.propTypes = {
  campaigns: PropTypes.arrayOf(PropTypes.object).isRequired,
  campaignsAreExpanded: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  onClickCampaign: PropTypes.func.isRequired,
}

export default Campaigns
