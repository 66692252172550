import React, { useEffect } from "react"
import { Await, Outlet, useLoaderData } from "react-router-dom"
import { connect } from "react-redux"
import { Loading } from "../../components/nav/Loading"
import WrapContainer from "../WrapContainer"
import { fetchInventorySuccess } from "./actions"

function InventoryViewParentRoute({ fetchInventorySuccess }) {
  const { inventoryItem } = useLoaderData()

  return (
    <React.Suspense fallback={<Loading />}>
      <Await resolve={inventoryItem}>
        {(inventoryItem) => {
          useEffect(() => {
            if (typeof inventoryItem === "object") {
              fetchInventorySuccess(inventoryItem)
            }
          }, [inventoryItem])
          return (
            <WrapContainer loginRequired={false} showNavBack>
              <Outlet context={{ inventoryItem }} />
            </WrapContainer>
          )
        }}
      </Await>
    </React.Suspense>
  )
}

const mapDispatchToProps = (dispatch) => {
  return {
    fetchInventorySuccess: (inventoryItem) =>
      dispatch(fetchInventorySuccess(inventoryItem)),
  }
}

export default connect(null, mapDispatchToProps)(InventoryViewParentRoute)
