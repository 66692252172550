import {
  RE_FETCH_INSTANT_BOOK_SERVICES_ACTION,
  SET_INFINITE_SCROLL_POSITION,
} from "./constants"

export const fetchInstantBookServices = () => ({
  type: RE_FETCH_INSTANT_BOOK_SERVICES_ACTION,
})

export const readyToFetchServices = () => ({
  type: "READY_TO_FETCH_INSTANT_BOOK_SERVICES",
})

export const fetchCatgories = () => ({
  type: "FETCH_CATEGORIES",
})

export const clickWhat = () => ({
  type: "CLICK_WHAT_MENU",
})

export const clickWhen = () => ({
  type: "CLICK_WHEN_MENU",
})

export const clickWhere = () => ({
  type: "CLICK_WHERE_MENU",
})

export const setInfiniteScrollPosition = (position, key, numberOfPages) => ({
  type: SET_INFINITE_SCROLL_POSITION,
  payload: {
    position,
    key,
    numberOfPages,
  },
})
