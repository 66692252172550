import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import moment from "moment"
import { browserIsMobile } from "../../redux/browserSelector"
import { isProfileVip } from "../Profile/selectors"
import { getServiceImage } from "../../components/images/lib"
import buttonize from "../../components/buttonize"
import useInventoryNavigator from "../../hooks/useInventoryNavigator"
import { RoundImage } from "../../components/images/RoundImage"
import ParagraphText from "../../components/ParagraphText"
import Pricing from "../../components/Pricing"
import { GoToButton } from "../../components/button"

function Service({ isMobile, isVip, service }) {
  const inventoryNavigate = useInventoryNavigator()
  const imageSize = isMobile ? 60 : 80

  const image = getServiceImage(service.images, service.description, {
    type: "resize",
    width: imageSize * 4,
    height: imageSize * 4,
  })

  return (
    <div
      key={`service-${service.id}`}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...buttonize(
        () =>
          inventoryNavigate({
            serviceSlug: service.slug,
            date: moment().format("YYYY-MM-DD"),
          }),
        {
          display: "flex",
          borderBottom: "1px dashed #ccc",
          padding: "10px 0px",
          alignItems: "stretch",
        }
      )}
    >
      {image && (
        <div style={{ marginRight: 14 }}>
          <RoundImage src={image} size={imageSize} />
        </div>
      )}
      <div style={{ flex: 1 }}>
        <h3
          style={{
            margin: "2px auto 8px auto",
            fontSize: 16,
            fontWeight: 500,
          }}
        >
          {service.description.name}
        </h3>

        <ParagraphText
          style={{
            margin: 0,
            fontSize: isMobile ? 14 : 16,
            paddingRight: 10,
          }}
          text={service.description.about}
          maxLength={isMobile ? 140 : 320}
        />
      </div>
      <div
        style={{
          textAlign: "right",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end",
          justifyContent: "space-between",
        }}
      >
        <div style={{ textAlign: "right", marginBottom: 8 }}>
          {service.price && service.price.default && (
            <Pricing
              prices={service.price}
              currency={service.currency}
              isMobile={isMobile}
              userIsVip={isVip}
            />
          )}
        </div>
        <div
          style={{
            textAlign: "right",
            display: "inline-block",
            marginTop: 8,
            marginBottom: 8,
          }}
        >
          <GoToButton variant="secondary" aLink={`/services/${service.slug}`} />
        </div>
      </div>
    </div>
  )
}

Service.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isVip: PropTypes.bool.isRequired,
  service: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  isMobile: browserIsMobile(state),
  isVip: isProfileVip(state),
})

export default connect(mapStateToProps)(Service)
