import { useNavigate } from "react-router-dom"
import useInventoryLink from "./useInventoryLink"

export default () => {
  const inventoryLinkFactory = useInventoryLink()

  const navigate = useNavigate()

  return (params, navigateOptions = {}) =>
    navigate(inventoryLinkFactory(params), navigateOptions)
}
