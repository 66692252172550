import React from "react"
import PropTypes from "prop-types"
import styled, { withTheme } from "styled-components"
import LoadingBar from "react-redux-loading-bar"
import NavBarContainer from "./NavBar/NavBarContainer"

import CookieBanner from "../components/banners/CookieBanner"

const Wrap = styled.div`
  padding: 0px;
  margin: 0px auto;
  max-width: ${(props) => props.theme.pageMaxWidth};
`

function WrapContainer({ children, theme }) {
  return (
    <>
      <CookieBanner />
      <Wrap id="outer-container">
        <NavBarContainer />
        <main id="page-wrap" style={{ margin: 0, padding: 0 }}>
          <LoadingBar style={{ backgroundColor: theme.colors.primary }} />
          {children}
        </main>
      </Wrap>
    </>
  )
}

WrapContainer.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.object.isRequired, // injected by "withTheme" from styled-components
}

export default withTheme(WrapContainer)
