import React from "react"
import PropTypes from "prop-types"

import { RoundImage } from "./RoundImage"
import { thumborize } from "./thumborize"

const ProductImage = ({ product }) => {

  const mainImage = product.images.find(i => i.placement === "main")?.url;

  if (!mainImage) return null

  return <RoundImage
    style={{ backgroundColor: "#fff", objectFit: "contain" }}
    src={thumborize(
      mainImage,
      92,
      92
    )}
    alt={product.name}
    size={92}
  />
}

ProductImage.propTypes = {
  product: PropTypes.object
}

export default ProductImage
