import PropTypes from "prop-types"
import React from "react"
import { Step, StepHeader, StepParent } from "./styledComponents"
import CategoryOptions from "./CategoryOptions"

function CampaignStep1({ campaign }) {
  return (
    <>
      <StepParent style={{ marginTop: 0, border: "none" }}>
        <Step id="step-1">Step 1 of 5</Step>
        <StepHeader>{campaign.title_service_select}</StepHeader>
      </StepParent>

      <div style={{ marginBottom: "20px" }}>
        <CategoryOptions services={campaign.similar} />
      </div>
    </>
  )
}

CampaignStep1.propTypes = {
  campaign: PropTypes.shape({
    title_service_select: PropTypes.string.isRequired,
    similar: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        description: PropTypes.object,
        image: PropTypes.string,
      })
    ),
  }).isRequired,
}

export default CampaignStep1
