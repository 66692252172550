import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "styled-components"

import { CardElement, ElementsConsumer } from "@stripe/react-stripe-js"

import { Link, useLocation, useNavigate } from "react-router-dom"
import {
  paymentCancel,
  paymentConfirm,
  toggleFlossieDollars,
  clearPurchaseError,
  handleSalonNoteChange,
} from "./actions"
import { PURCHASE_ERROR_UNAVAILABLE } from "./reducer"

import { getRequiredPatchTestItems } from "../Cart/selectors"

import { removeFromCart } from "../Cart/actions"

import { SubmitInput } from "../../components/forms/Inputs"
import { BackLink } from "../../components/nav/Links"
import Animation from "../../components/Animation"
import ErrorBar from "../../components/ErrorBar"
import ShadowBox from "../../components/ShadowBox"
import PaymentContainer from "./PaymentContainer"
import BookingDetailsContainer from "./BookingDetailsContainer"
import WrapContainer from "../WrapContainer"
import LoginContainer from "../Login/LoginContainer"
import RegistrationContainer from "../Registration/RegistrationContainer"
import FlossieCheckBox from "../../components/FlossieCheckBox"
import PatchTestingAlert from "./PatchTestingAlert"

import { PurchaseButton } from "./helpers"
import useInventoryNavigator from "../../hooks/useInventoryNavigator"
import { isLoggedIn } from "../Profile/selectors"
import { storeLastLocation } from "../actions"

const ThemedLink = styled(Link)`
  color: ${({ theme }) => theme.colors.primary};
`

const LoggedOutDiv = styled.div`
  max-width: 860px;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
`

const StyleErrorDiv = styled.div`
  flex: 1;
  padding: 0;
  position: relative;
`
const LoadingLine = styled.div`
  height: 4px;
  background-color: ${({ theme }) => theme.colors.primary};
`
const LoadingText = styled.h2`
  font-size: 16px;
  text-align: center;
  font-weight: 500;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.secondary};
`

function PurchaseContainer({
  cardName,
  cardCvc,
  cardNumber,
  cardExpiry,
  cart,
  clearPurchaseError,
  elements,
  isLarge,
  isMobile,
  loggedIn,
  paymentCancel,
  paymentConfirm,
  paymentMethods,
  phoneNumberUpdate,
  profile,
  purchase,
  requiredPatchTestItems,
  selectedPaymentMethodOption,
  selectedPaymentMethod,
  selectedPurchaseMethod,
  storeLastLocation,
  stripe,
}) {
  const [showPatchRequiredItems, setShowPatchRequiredItems] = useState(false)

  const LOGGED_OUT_SIGN_IN_OPTION_LOGIN = "login"
  const LOGGED_OUT_SIGN_IN_OPTION_REGISTER = "register"

  const [loggedOutSignInOption, setLoggedOutSignInOption] = useState(
    LOGGED_OUT_SIGN_IN_OPTION_LOGIN
  )

  const closePatchTestingRequired = () => {
    setShowPatchRequiredItems(false)
  }

  const [readBookingTerms, setReadBookingTerms] = useState(true)

  const navigate = useNavigate()

  const inventoryNavigate = useInventoryNavigator()

  const location = useLocation()

  useEffect(() => {
    if (location?.pathname) {
      storeLastLocation(location.pathname)
    }
  }, [location.pathname])

  const [purchaseProcessHasStarted, setPurchaseProcessHasStarted] =
    useState(false)

  /*
  This deserves some explain'n: We need to record that purchase has started so we can keep the progress bar up long enough to 
  prevent a screen flicker while the browser is waiting to navigate to the purchase complete page.
  This is a temporary measure until we take redux/rxjs side effects out of the equation.
  */
  useEffect(() => {
    if (purchase.purchaseError) {
      setPurchaseProcessHasStarted(false)
    } else if (purchase.inProgress) {
      setPurchaseProcessHasStarted(true)
    }
  }, [purchase])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    /* Check URL for tag indicating the user bailed out of the payment process and cancel the transaction if so */
    const urlParts = document.URL.split("#")

    if (urlParts.length === 2 && urlParts[1] === "cancel") {
      paymentCancel()
      navigate("/navigate")
    }
  }, [document.URL])

  const renderPaymentBtnText = (cart, method) => {
    let btnText = "Buy Now"
    if (!method || !cart) return btnText
    const paymentRequired = `${cart.currency.symbol}${cart.total} ${cart.currency.code}`
    btnText = `Buy Now ${paymentRequired}`
    if (method === "Part Pay") btnText = `Complete Purchase ${paymentRequired}`
    if (method === "Pay In Salon") btnText = "Complete Booking!"
    return btnText
  }

  const newCreditCardValid = () => {
    if (selectedPaymentMethodOption !== "cc_new") return true
    if (cardName === "") {
      alert("Please enter your Cardholder Name")
      return false
    }
    if (cardCvc === "") {
      alert("Please enter your Card Security (CVC) code")
      return false
    }
    if (cardNumber === "") {
      alert("Please enter your Card Number")
      return false
    }
    if (cardExpiry === "") {
      alert("Please enter your Card Number")
      return false
    }
    return true
  }

  const phoneNumberValid = (phoneNumber) => {
    if (phoneNumber !== "") return true
    alert("Please enter your phone number for the Salon")
    return false
  }

  const makePurchase = ({ patchTestConfirmed }) => {
    const phoneNumber = profile.phone_number
      ? profile.phone_number
      : purchase.phoneNumber

    if (!phoneNumberValid(phoneNumber)) {
      // Error message handled by function
      return
    }

    if (
      !paymentMethods.filter((p) => p.slug === selectedPaymentMethod.slug)
        .length
      // make sure at least one VALID payment method is selected
    ) {
      alert("Please select a payment method")

      return
    }

    const displayPatchTestConfirmationModal =
      !patchTestConfirmed && (requiredPatchTestItems?.length ?? 0 > 0)

    if (displayPatchTestConfirmationModal) {
      setShowPatchRequiredItems(true)
      return
    }

    if (selectedPaymentMethod.slug === "stripe") {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        alert("Stripe library has not finished loading")

        return
      }

      window.stripePayment = {
        stripe,
        elements,
        cardElement: elements.getElement(CardElement),
      }
    }

    closePatchTestingRequired()
    paymentConfirm(phoneNumber)
  }

  const isUnavailable = () => false

  const renderError = () => {
    if (purchase.purchaseError) {
      let buttonText = "Please click here to try again"

      if (purchase.purchaseErrorType === PURCHASE_ERROR_UNAVAILABLE) {
        buttonText = "Click here to find a new appointment"
      }

      const handleClick = () => {
        clearPurchaseError()

        // If unavailable error send them back where they came from to try find another time
        if (purchase.purchaseErrorType === PURCHASE_ERROR_UNAVAILABLE) {
          inventoryNavigate({
            serviceSlug: purchase.purchaseErrorData.items[0].service.slug,
          })
        }
      }

      return (
        <ShadowBox isFullscreen={isMobile} closeBox={handleClick}>
          <div style={{ textAlign: "center" }}>
            <ErrorBar message={purchase.purchaseErrorMessage} />
            <p>
              <SubmitInput onClick={handleClick} value={buttonText} />
            </p>
          </div>
        </ShadowBox>
      )
    }

    return null
  }

  const renderNoPaymentMethodError = () => {
    /* See the note at the top of the file about keeping the progress bar open long enough to prevent screen flicker on navigation */
    if (!purchase?.inProgress && purchaseProcessHasStarted) return null

    return (
      <div>
        <div style={{ textAlign: "center" }}>
          <p>No Payment method available.</p>
          <p>
            <SubmitInput
              onClick={() => navigate(-1)}
              value="Continue Shopping"
            />
          </p>
        </div>
      </div>
    )
  }

  const renderProgress = () => {
    const { inProgress, paymentConfirmationSteps, paymentAuthorizeSteps } =
      purchase

    /* See the note at the top of the file about keeping the progress bar open long enough to prevent screen flicker on navigation */
    if (!inProgress && !purchaseProcessHasStarted) return null

    const items = (cart?.items ?? []).filter(
      (item) => item.type === "appointment"
    )

    let confirmWidth = 0
    let purchaseWidth = 0

    /* See the note at the top of the file about keeping the progress bar open long enough to prevent screen flicker on navigation */
    if (purchaseProcessHasStarted && !items.length) {
      confirmWidth = 100
      purchaseWidth = 100
    } else {
      const confirmPercent = []
      for (let i = 0; i < items.length; i += 1) {
        const item = items[i]

        if (!paymentConfirmationSteps[item.id]) {
          confirmPercent[i] = 0
        } else {
          const progress = paymentConfirmationSteps[item.id]

          if (!progress.current || !progress.current) {
            confirmPercent[i] = 0
          } else {
            confirmPercent[i] = (progress.current / progress.steps) * 100
          }
        }
      }
      /* Get average percentage value then halve it as confirmation represents 50%
       of total progress bar width */
      for (let i = 0; i < confirmPercent.length; i += 1) {
        confirmWidth += confirmPercent[i]
      }

      confirmWidth = confirmWidth / confirmPercent.length / 2

      if (paymentAuthorizeSteps.current)
        purchaseWidth =
          (50 / paymentAuthorizeSteps.steps) * paymentAuthorizeSteps.current
      if (purchase.confirmedIsPurchased) purchaseWidth = 50
    }

    const fullWidth = Math.round(confirmWidth) + Math.round(purchaseWidth)

    return (
      <div style={{ margin: 0, position: "relative", width: "100%" }}>
        <Animation height={500} />
        <div
          style={{
            position: "absolute",
            top: 200,
            left: 0,
            right: 0,
            padding: 10,
          }}
        >
          <LoadingText>
            {purchaseWidth > 1 ? "Processing" : "Confirming Booking"}
          </LoadingText>
          <LoadingLine style={{ width: `${fullWidth}%` }} />
          <p style={{ textAlign: "center", color: "#ccc", fontSize: 11 }}>
            {fullWidth}%
          </p>
        </div>
      </div>
    )
  }

  const processingPurchase = () => {
    return purchase.inProgress
  }

  const showLoading = () => processingPurchase()

  const toggleRegistration = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setLoggedOutSignInOption(
      loggedOutSignInOption === LOGGED_OUT_SIGN_IN_OPTION_LOGIN
        ? LOGGED_OUT_SIGN_IN_OPTION_REGISTER
        : LOGGED_OUT_SIGN_IN_OPTION_LOGIN
    )
  }

  const renderLoggedOutPanel = () => {
    if (loggedOutSignInOption === LOGGED_OUT_SIGN_IN_OPTION_REGISTER) {
      return (
        <div style={{ padding: 15 }}>
          <div style={{ padding: "0px 5px", marginTop: 20 }}>
            <RegistrationContainer
              noWrap
              toggleRegistrationHandler={toggleRegistration}
            />
          </div>
        </div>
      )
    }

    return (
      <div style={{ padding: 15 }}>
        <div style={{ padding: "0px 5px", marginTop: 20 }}>
          <LoginContainer
            noWrap
            toggleRegistrationHandler={toggleRegistration}
          />
        </div>
      </div>
    )
  }

  const renderEmptyCart = () => {
    return (
      <WrapContainer loginRequired={false}>
        <StyleErrorDiv>{renderError()}</StyleErrorDiv>
        <div style={{ position: "relative" }}>
          <BackLink
            href="/"
            onClick={(e) => {
              e.preventDefault()
              navigate(-1)
            }}
            style={{ top: isMobile ? 20 : 0, left: isMobile ? 20 : -60 }}
          />
          <div style={{ padding: 40, textAlign: "center" }}>
            You have no items in your cart
          </div>
          <div style={{ textAlign: "center" }}>
            <SubmitInput
              onClick={() => {
                navigate("/")
              }}
              value="Begin shopping"
            />
          </div>
        </div>
      </WrapContainer>
    )
  }

  const toggleReadBookingTerms = () => {
    setReadBookingTerms(!readBookingTerms)
  }

  const renderBookingTerms = () => {
    return (
      <div style={{ paddingLeft: 4, paddingTop: 4 }}>
        I have read the{" "}
        <ThemedLink to="/terms/customer">booking terms</ThemedLink> and
        understand the cancellation policy.
        <div style={{ marginTop: 10 }}>
          For any upcoming appointments I give consent for the salon to contact
          me to about patch testing and an appointment deposit if required.
        </div>
      </div>
    )
  }

  /* See the note at the top of the file about keeping the progress bar open long enough to prevent screen flicker on navigation
  No items in cart so no need to proceed any further */
  if (
    !purchaseProcessHasStarted &&
    (typeof cart === "undefined" ||
      typeof cart.items === "undefined" ||
      !cart.items.length)
  ) {
    return renderEmptyCart()
  }

  /* See: calculateCheckoutPrice */
  const bookingPrice = {
    defaultPrice: cart.total,
    basePrice: cart.total,
    partPayPrice: cart.total,
    buyNowPrice: cart.total,
    paymentRequired: true,
    currency: cart.currency,
  }

  return (
    <WrapContainer loginRequired={false}>
      <div
        style={{
          position: "relative",
          maxWidth: 980,
          marginLeft: "auto",
          marginRight: "auto",
          display: "block",
          height: 0,
        }}
      >
        {!showLoading() && (
          <BackLink
            href="/"
            onClick={(e) => {
              e.preventDefault()
              navigate(-1)
            }}
            style={{ top: 20, left: !isLarge ? 20 : -60 }}
          />
        )}
      </div>

      {showPatchRequiredItems && (
        <PatchTestingAlert
          onConfirm={() => makePurchase({ patchTestConfirmed: true })}
          onClose={closePatchTestingRequired}
          isMobile={!isLarge}
          requiredPatchTestItems={requiredPatchTestItems}
        />
      )}

      <LoggedOutDiv>
        {!processingPurchase() && (
          <div style={{ flex: 1, padding: 0 }}>
            {!loggedIn && renderLoggedOutPanel()}
          </div>
        )}
      </LoggedOutDiv>

      <StyleErrorDiv>{renderError()}</StyleErrorDiv>

      {!paymentMethods || paymentMethods.length === 0 ? (
        renderNoPaymentMethodError()
      ) : (
        <div
          style={{
            position: "relative",
            maxWidth: 980,
            marginLeft: "auto",
            marginRight: "auto",
            paddingTop: !isLarge ? 0 : 20,
            display: showLoading() ? "none" : "block",
          }}
        >
          {!loggedIn && (
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: 1,
                top: 0,
                right: 0,
                backgroundColor: "rgb(255, 255, 255, 0.7)",
              }}
            />
          )}

          <div
            style={{
              justifyContent: "center",
              display: !isLarge ? "block" : "grid",
              gridGap: "20px",
              gridTemplateRows: !isLarge ? "repeat(2, auto)" : "none",
              gridTemplateColumns: !isLarge
                ? "none"
                : "repeat(2, calc(50% - 10px))",
            }}
          >
            <div>
              <BookingDetailsContainer />
            </div>
            <div>
              <PaymentContainer
                bookingPrice={bookingPrice}
                paymentRequired={bookingPrice.paymentRequired}
                purchaseMethod={selectedPurchaseMethod}
              />

              <div style={{ marginTop: 10, padding: "0px 6px 0px 10px" }}>
                <FlossieCheckBox
                  selected={readBookingTerms}
                  onClick={toggleReadBookingTerms}
                  labelContent={renderBookingTerms()}
                  extraStyle={{ fontSize: 14 }}
                  buttonStyle={{ alignItems: "top" }}
                />
              </div>

              <PurchaseButton
                disabled={!readBookingTerms}
                makePurchase={makePurchase}
                btnText={renderPaymentBtnText(cart, selectedPurchaseMethod)}
              />
            </div>
          </div>
        </div>
      )}
      {renderProgress()}
    </WrapContainer>
  )
}

PurchaseContainer.propTypes = {
  paymentConfirm: PropTypes.func.isRequired,
  isMobile: PropTypes.bool.isRequired,
  isLarge: PropTypes.bool.isRequired,
  paymentCancel: PropTypes.func.isRequired,
  profile: PropTypes.object.isRequired,
  purchase: PropTypes.object.isRequired,
  cart: PropTypes.object.isRequired,
  selectedPaymentMethodOption: PropTypes.string,
  cardName: PropTypes.string,
  cardCvc: PropTypes.string,
  cardNumber: PropTypes.string,
  cardExpiry: PropTypes.string,
  paymentMethods: PropTypes.array,
  selectedPaymentMethod: PropTypes.object,
  requiredPatchTestItems: PropTypes.array,
  clearPurchaseError: PropTypes.func,
  loggedIn: PropTypes.bool,
  selectedPurchaseMethod: PropTypes.string,
  phoneNumberUpdate: PropTypes.func,
  storeLastLocation: PropTypes.func,
  stripe: PropTypes.object,
  elements: PropTypes.object,
}

const mapStateToProps = (state) => {
  return {
    isMobile: state.browser.lessThan.mobile,
    isLarge: state.browser.greaterThan.large,
    loggedIn: isLoggedIn(state),
    isVip: !!state.profile.profile.vip,
    profile: state.profile.profile,

    noAvailabilityResults: state.inventoryView.noAvailabilityResults,

    paymentMethods: state.cart.cart.payment_methods,

    cart: state.cart.cart,
    requiredPatchTestItems: getRequiredPatchTestItems(state),

    purchase: state.purchase,
    salonNote: state.purchase.salonNote,
    flossieDollarsBalance: parseInt(state.profile.profile.total_balance, 10),
    useFlossieDollars: state.purchase.useFlossieDollars,
    cardNumber: state.purchase.cardNumber,
    cardExpiry: state.purchase.cardExpiry,
    cardCvc: state.purchase.cardCvc,
    cardName: state.purchase.cardName,

    selectedPaymentMethod: state.purchase.selectedPaymentMethod,
    selectedPurchaseMethod: state.purchase.selectedPurchaseBtn,
  }
}

const mapDispatchToProps = (dispatch) => ({
  clearPurchaseError: () => dispatch(clearPurchaseError()),
  handleSalonNoteChange: (note) => dispatch(handleSalonNoteChange(note)),
  paymentConfirm: (...args) => dispatch(paymentConfirm(...args)),
  paymentCancel: () => dispatch(paymentCancel()),
  removeFromCart: (identifier) => dispatch(removeFromCart(identifier)),
  storeLastLocation: (location) => dispatch(storeLastLocation(location)),
  toggleFlossieDollars: () => dispatch(toggleFlossieDollars()),
})

const { STRIPE_ENABLED } = SITE_CONFIG

function InjectedPurchaseContainer(props) {
  if (STRIPE_ENABLED) {
    return (
      <ElementsConsumer>
        {({ elements, stripe }) => (
          <PurchaseContainer {...props} elements={elements} stripe={stripe} />
        )}
      </ElementsConsumer>
    )
  }

  return <PurchaseContainer {...props} />
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(InjectedPurchaseContainer)
