import React from "react"
import PropTypes from "prop-types"
import { StyledLabel } from "./styledComponents"
import EmployeeCard from "../EmployeeCard"
import { PromoTag } from "../../../components/SaleTags"

function SelectedEmployeeCard({ employee, onSale, isMobile }) {
  return (
    <StyledLabel>
      <EmployeeCard employee={employee} isMobile={isMobile} hideTag />
      {onSale && (
        <PromoTag style={{ padding: "6px 0px 0px 4px" }} align="left" />
      )}
    </StyledLabel>
  )
}

SelectedEmployeeCard.propTypes = {
  employee: PropTypes.object.isRequired,
  isMobile: PropTypes.bool,
  onSale: PropTypes.bool.isRequired,
}

export default SelectedEmployeeCard
